import * as ActionTypes from "../actions/types";
import { GET_ALL_EVENTS } from "../actions/types";

const initialState = {
  user: {},
  player: {},
  loginModal: false,
  analysisVideo: {},
  infoTeams: {},
  eventForm: {
    name: "",
    address: "",
    start_time: "",
    start_date: "",
    end_date: "",
    count_days: "",
    waiting_time_matches: "",
    description: "",
    color_primary: "#c62f2f",
    color_secondary: "#ffffff",
    logo: "",
    logoPreview: "",
    selectedEvents: "",
    selectedFields: [],
    selectedReferees: [],
    selectedCategories: [],
    directors: [],
    paymentMethod: "",
  },
  eventList: null,
  competitions: null,
  directorList: [],
  countriesNames: {},
  filterPage: null,
};

const handleFormChange = (state, e) => {
  let { name, value } = e;
  return {
    ...state,
    eventForm: {
      ...state.eventForm,
      [name]: value,
    },
  };
};

const handleRefereeCheck = (state, referee) => {
  console.log(state);
  const isInReferees = state.eventForm.selectedReferees.find(
    (ref) => ref.referee === referee.referee
  );

  if (!isInReferees && referee.isChecked) {
    return {
      ...state,
      eventForm: {
        ...state.eventForm,
        selectedReferees: state.eventForm.selectedReferees
          .filter((ref) => ref.isChecked === true)
          .concat(referee),
      },
    };
  } else if (isInReferees && !referee.isChecked) {
    return {
      ...state,
      eventForm: {
        ...state.eventForm,
        selectedReferees: state.eventForm.selectedReferees.filter(
          (ref) => ref.referee !== referee.referee
        ),
      },
    };
  } else {
    return state;
  }
};

const handleFieldCheck = (state, field) => {
  const isInFields = state.eventForm.selectedFields.find(
    (fil) => fil.fields === field.fields
  );

  if (!isInFields && field.isChecked) {
    return {
      ...state,
      eventForm: {
        ...state.eventForm,
        selectedFields: state.eventForm.selectedFields
          .filter((fil) => fil.isChecked === true)
          .concat(field),
      },
    };
  } else if (isInFields && !field.isChecked) {
    return {
      ...state,
      eventForm: {
        ...state.eventForm,
        selectedFields: state.eventForm.selectedReferees.filter(
          (fil) => fil.fields !== field.fields
        ),
      },
    };
  } else {
    return state;
  }
};

const handleDirectorCheck = (state, director) => {
  console.log("state: ", state);
  console.log("director: ", director);
  const isInDirectors = state.eventForm.directors.find(
    (dir) => dir.id === director.id
  );
  if (!isInDirectors && director.isChecked) {
    return {
      ...state,
      eventForm: {
        ...state.eventForm,
        directors: state.eventForm.directors
          .filter((dir) => dir.isChecked === true)
          .concat(director),
      },
    };
  } else if (isInDirectors && !director.isChecked) {
    return {
      ...state,
      eventForm: {
        ...state.eventForm,
        directors: state.eventForm.directors.filter(
          (dir) => dir.id !== director.id
        ),
      },
    };
  } else {
    return {
      ...state,
    };
  }
};

const handleCategoryCheck = (state, category) => {
  const isInCategories = state.eventForm.selectedCategories.find(
    (cat) => cat.id === category.id
  );

  if (!isInCategories && category.isChecked) {
    return {
      ...state,
      eventForm: {
        ...state.eventForm,
        selectedCategories: state.eventForm.selectedCategories
          .filter((cat) => cat.isChecked === true)
          .concat(category),
      },
    };
  } else if (isInCategories && !category.isChecked) {
    return {
      ...state,
      eventForm: {
        ...state.eventForm,
        selectedCategories: state.eventForm.selectedCategories.filter(
          (cat) => cat.id !== category.id
        ),
      },
    };
  } else {
    return state;
  }
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOGIN_MODAL:
      return Object.assign({}, state, {
        loginModal: action.payload,
      });

    case ActionTypes.UPDATE_PLAYER:
      return Object.assign({}, state, {
        articles: state.articles.concat(action.payload),
      });
    case ActionTypes.CREATE_PLAYER:
      return Object.assign({}, state, {
        player: { ...state.player, ...action.payload },
      });
    case ActionTypes.RESET_PLAYER:
      return Object.assign({}, state, {
        player: {},
      });
    case ActionTypes.LOGOUT_USER:
      return Object.assign({}, state, {
        user: action.payload,
      });
    case ActionTypes.UPDATE_USER:
      return Object.assign({}, state, {
        user: { ...state.user, ...action.payload },
      });
    case ActionTypes.UPDATE_ANALYSIS_VIDEO:
      return Object.assign({}, state, {
        analysisVideo: {
          ...state.analysisVideo,
          ...{ [action.payload.id]: action.payload },
        },
      });
    case ActionTypes.UPDATE_INFO_TEAMS:
      let infoTeamsTemp = { ...state.infoTeams };
      action.payload.forEach((team) => {
        infoTeamsTemp = { ...infoTeamsTemp, [team._id]: team };
      });
      return Object.assign({}, state, {
        infoTeams: infoTeamsTemp,
      });
    case ActionTypes.MODIFY_FORM:
      return handleFormChange({ ...state }, action.payload);
    case ActionTypes.MODIFY_REFEREE_CHECKED:
      console.log("payload", action.payload);
      return handleRefereeCheck({ ...state }, action.payload);
    case ActionTypes.MODIFY_FIELD_CHECKED:
      console.log("payload", action.payload);
      return handleFieldCheck({ ...state }, action.payload);
    case ActionTypes.ADD_DIRECTOR:
      if (state.directorList) {
        return {
          ...state,
          directorList: state.directorList.concat(action.payload),
        };
      } else {
        return { ...state, directorList: action.payload };
      }
    case ActionTypes.MODIFY_DIRECTOR:
      console.log("payload", action.payload);
      return handleDirectorCheck({ ...state }, action.payload);

    case ActionTypes.GET_ALL_DIRECTORS:
      console.log(action.payload);
      return {
        ...state,
        directorList: action.payload,
      };
    case ActionTypes.ADD_EVENT:
      if (state.eventList) {
        return {
          ...state,
          eventForm: initialState.eventForm,
          fieldList: initialState.fieldList,
          eventList: state.eventList.concat(action.payload),
        };
      } else {
        return {
          ...state,
          eventForm: initialState.eventForm,
          fieldList: initialState.fieldList,
          eventList: action.payload,
        };
      }
    case ActionTypes.DELETE_EVENT:
      return {
        ...state,
        eventList: state.eventList.filter(
          (event) => event._id !== action.payload
        ),
      };
    case GET_ALL_EVENTS:
      return {
        ...state,
        eventForm: initialState.eventForm,
        eventList: action.payload,
      };
    case ActionTypes.ADD_CATEGORY:
      console.log("payload", action.payload);
      return handleCategoryCheck({ ...state }, action.payload);
    case ActionTypes.GET_ALL_COMPETITIONS:
      return { ...state, competitions: action.payload };
    case ActionTypes.GET_ALL_COUNTRIES_NAMES:
      return { ...state, countriesNames: action.payload };
    case ActionTypes.SAVE_FILTER:
      return Object.assign({}, state, {
        filterPage: {
          ...state.filterPage,
          ...{ [action.payload.filterName]: action.payload.value },
        },
      });
    case ActionTypes.DELETE_FILTER:
      return { ...state, filterPage: null };
    default:
      // code block
      return state;
  }
}
export default rootReducer;
