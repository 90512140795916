import React from 'react';

import Lottie from "react-lottie";
import errorData from "../../assets/img/error.json";
import { makeStyles, Modal } from "@material-ui/core";
import successData from "../../assets/img/check.json";


const useStyles = makeStyles(theme => ({

    modalTagPlayer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        outline: "none",
        "& .finish-content": {
            display: "flex",
            minWidth: "80vh",
            paddingTop: "100px",
            position: 'absolute',
            borderRadius: "25px",
            alignItems: "center",
            background: "#181A1D",
            paddingBottom: "100px",
            flexDirection: "column",
            justifyContent: "center",
            outline: "none",
            "& h3": {
                color: "white",
                fontWeight: 600,
                fontSize: "30px",
                marginBottom: "20px",
                fontFamily: "Open Sans Condensed",
            },
            "& p": {
                color: "#717386",
                textAlign: "center",
                marginBottom: "20px",
                fontFamily: "Open Sans Condensed",
            },
            "& button": {
                color: "white",
                outline: "none",
                fontSize: "15px",
                cursor: 'pointer',
                marginLeft: "25px",
                borderRadius: '5px',
                border: "0px solid",
                padding: "20px 75px",
                fontFamily: "Montserrat",
                backgroundColor: "#014483",
            }
        }
    },
    close: {
        top: 10,
        right: 10,
        width: 32,
        height: 32,
        opacity: 0.3,
        color: "white",
        display: "flex",
        cursor: "pointer",
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
    }
}));

const defaultOptions = {
    loop: false,
    autoplay: true,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};


const InfoModal = (props) => {

    const classes = useStyles();
    const {openModal, setOpenModal, modalSuccess, msg, callback, callbackLabel} = props;

    return (
        <Modal open={openModal} className={classes.modalTagPlayer}>
            {modalSuccess ? (
            <div className='finish-content'>
                <div className={classes.close} onClick={() => setOpenModal(false) }>
                    X
                </div>
                <Lottie
                options={{ animationData: successData, ...defaultOptions }}
                style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    paddingTop: "0px",
                    paddinBottom: "0px",
                    width: "150px",
                    height: "150px",
                }}
                height={20}
                width={20}
                />
                <h3>Exito!</h3>
                <p>{msg}</p>
                { callback ? 
                <button type='button' onClick={ () => callback() }>
                    {callbackLabel ? callbackLabel : 'Finalizar'}
                </button>
                :
                <button type='button' onClick={ () => setOpenModal(false)}>
                    Finalizar
                </button>
                }

            </div>
            ) : (
            <div className='finish-content'>
                <Lottie
                options={{ animationData: errorData, ...defaultOptions }}
                style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    paddingTop: "0px",
                    paddinBottom: "0px",
                    width: "150px",
                    height: "150px",
                }}
                height={20}
                width={20}
                />
                <h3>Error!</h3>
                <p>{msg}</p>
                <p>Intentelo devuelta mas tarde</p>
                <button type='button' onClick={() => setOpenModal(false)}>
                Finalizar
                </button>

            </div>
            )}
        </Modal>
    );
};

export default React.memo(InfoModal);