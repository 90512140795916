import React, {useState, useEffect} from 'react';

import {getGpsWPlayer} from '../endpoints';
import GpsByPlayerComponent from '../components/GpsByPlayerComponent';

const GpsByPlayer = () => {

    const [statsGps, setStatsGps] = useState(null);

    async function setGetGpsWPlayer(filter) {
        let response = await getGpsWPlayer(filter);
        if(response && response.status === 200){
            setStatsGps(response.data.response);
        }else{
            console.log('error');
        }
    }

    useEffect( () => {
        let wplayer_id = window.location.href.split("/").slice(-1)[0] ;
        setGetGpsWPlayer(wplayer_id);
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className="container-fluid">
            <GpsByPlayerComponent statsGps={statsGps} />
        </div>
    );
};
export default React.memo(GpsByPlayer);