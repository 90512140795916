import React, { useState, useEffect } from "react";
import { getStatsGPSById } from "../../endpoints";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Hidden } from "@material-ui/core";

import { translate } from "../../lang/lang";

const BasicPlayerCards = (props) => {
  const { user } = props;
  let color;
  const useStyles = makeStyles(() => ({
    container: {
      position: "absolute",
      marginLeft: "30px",
    },
    cardHeader: {
      color: "white",
      borderRadius: "0",
      margin: "auto",
      padding: "10px",
      fontSize: "8px",
    },
    cardDescription: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#315059",
      color: "cyan",
      height: "85px",
    },
    row1: {
      display: "inline-block",
      margin: "5px",
    },
    font: {
      fontFamily: "Bebas-Bold",
      color: "cyan",
    },
    fontText: {
      fontFamily: "Bebas-Bold",
      color: "white",
    },
    cardContent: {
      padding: "0 5px 5px !important",
    },
  }));

  const classes = useStyles();
  const metrics = props.metrics
    ? Object.keys(props.metrics).map((key) => props.metrics[key])
    : [];

  return (
    <>
      <Hidden only={["sm", "md"]}>
        <div className={classes.container}>
          {metrics.map((metric, index) => {
            let distance = null;
            if (index === 2 || index === 3 || index === 5) {
              distance =
                metric.value >= 1000 ? metric.value / 1000 : metric.value;
            }
            switch (index) {
              case 0:
                color = "#f92f3b";
                break;
              case 2 || 3:
                color = "#28bb29";
                break;
              case 4 || 5:
                color = "#2594b4";
                break;
            }
            return (
              <div className={classes.row1} key={index}>
                <Card style={{ borderRadius: "0", width: "200px" }}>
                  <CardHeader
                    title={translate(user.lang, metric.label)}
                    titleTypographyProps={{ align: "center", variant: "body1" }}
                    subheaderTypographyProps={{ align: "center" }}
                    className={classes.cardHeader}
                    style={{ backgroundColor: color }}
                  />
                  <CardContent
                    className={classes.cardContent}
                    style={{ backgroundColor: color }}
                  >
                    <div className={classes.cardDescription}>
                      <Typography
                        className={classes.font}
                        component="h2"
                        variant="h3"
                      >
                        {distance === null ? metric.value : distance.toFixed(2)}
                      </Typography>
                      <Typography
                        className={classes.fontText}
                        variant="h6"
                        style={{ marginLeft: "15px", color: "#fff" }}
                      >
                        {distance === null ? metric.unit : `K${metric.unit}`}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </div>
            );
          })}
        </div>
      </Hidden>
    </>
  );
};

export default BasicPlayerCards;
