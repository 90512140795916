/* Retorna (filtra) los elementos segun la condición indicada */
export function filterArrayByProperty(array, property, value, operator){

    switch(operator) {
        case '>=':
            return array.filter( item => {
                return item[property] >= value
            })
        case '<=':
            return array.filter( item => {
                return item[property] <= value
            })
        case '>':
            return array.filter( item => {
                return item[property] > value
            })
        case '<':
            return array.filter( item => {
                return item[property] < value
            })
        case '!=':
            return array.filter( item => {
                return item[property] !== value
            })
        default: // = igual
            return array.filter( item => {
                return item[property] === value
            })
    }
}

export function filterObjectByProperty(object, property, value){

    let result = [];
    Object.keys(object).forEach( key => {
        if (object[key][property] === value) {
            result.push(object[key]);
        }
    });
    return result;
}

/* Devolvemos un array del objetos pero con key de una de las propiedades de los objetos ejemplo:
    Original [ {id: 12, name: isaac}, {id:15, name: isrrael} ]
    Retorno { 12: {id:12, name: isaac}, 15: {id: 15, name: isrrael} }
*/
export function getObjectFromArrayWithKeysByProperty(array, key, key2){
    let newObject = {};
    array.forEach( obj => { 
        if(obj[key]){
            newObject = {...newObject, [obj[key]]: obj } 
        }else{
            newObject = {...newObject, [obj[key2]]: obj } 
        }
    });
    return newObject;
}

export function getColorsByOptions(options){
    return options.map( () => {
        return `rgb(${Math.floor((Math.random() * 255) + 1)}, ${Math.floor((Math.random() * 255) + 1)}, ${Math.floor((Math.random() * 255) + 1)})`
    });
}

export function debounce(fn, delay, params) {
    return setTimeout( function(){ fn(params); }, delay );
}