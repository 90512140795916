export default function(params) {
  const { BASEAPI, RESOURCE, DEMO, LOGO, ENV } = params;
  return {
    intervals: {
      logout: 3600 // 1 hour in seconds
    },
    BASEAPI,
    RESOURCE,
    DEMO,
    LOGO,
    ENV,
    isProduction: true,
    isDevelopment: false
  };
}
