import React from 'react';
import clsx from 'clsx';
import Step from '@material-ui/core/Step';
import field from '../assets/img/field.jpg';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import ChatIcon from '@material-ui/icons/Chat';
import Stepper from '@material-ui/core/Stepper';
import {resourceApiEndPoint} from '../endpoints';
import GridOnIcon from '@material-ui/icons/GridOn';
import PersonIcon from '@material-ui/icons/Person';
import TextField from '@material-ui/core/TextField';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import StepConnector from '@material-ui/core/StepConnector';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
//import { useState } from 'react';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
        // backgroundImage: 'linear-gradient( 95deg, #004550, #65a8b7, #1CB9D3 )',
        },
    },
    completed: {
        '& $line': {
            // backgroundImage: 'linear-gradient( 95deg, #004550, #65a8b7, #1CB9D3 )',
        },
    },
    line: {
        height: 3,
        border: 0,
        borderRadius: 1,
        backgroundColor: 'transparent',
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        zIndex: 1,
        width: 40,
        height: 40,
        color: '#fff',
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        backgroundColor: '#ccc3',
        justifyContent: 'center',
        position: 'relative',
        transition:'all .2s',

        '&:after':{
            content: '""',
            position: 'absolute',
            top: '50%',
            transform:'translateY(-50%)',
            right: '100%',
            border:'2px solid #ccc3',
            width:'70.5px',

            
        },
        '& .MuiStepLabel-label':{
            display: 'none'
        }

        
    },
    active: {
        backgroundImage: 'linear-gradient(90deg, #FDCA40, #FDCA40)',
        transition: 'all .2s',
        
        '&:after':{
            content: '""',
            position: 'absolute',
            top: '50%',
            transform:'translateY(-50%)',
            left: '100%',
            border:'2px solid #FDCA40',
            width:'0',
            opacity:'0',
            transition: 'all .2s',
            
        },
        
    },
    completed: {
        //backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        backgroundImage: 'linear-gradient(90deg, #FDCA40, #FDCA40)',
        transition: 'all .2s',
        '&:after':{
            content: '""',
            position: 'absolute',
            top: '50%',
            transform:'translateY(-50%)',
            left: '100%',
            border:'2px solid #FDCA40',
            width:'70.5px',
            transition: 'all .2s',

            
        },
    },
});

const useStyles = makeStyles(theme => ({
  root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    fieldZoneContainer: {
        width: '100%',
        margin: 'auto',
        display: 'flex',
        maxWidth: '510px',
        minHeight: '340px',
        backgroundSize: '100%',
        flexDirection: 'column',
        justifyContent: 'space-around',
        background: `url( ${field} )`,
        '&.rotate': {
            transform: 'rotate(180deg)'
        }
    },
    fieldZone: {
        width: '100%',
        cursor: 'pointer',
        textAlign: 'center',
        transition: 'all .3s',
        '& span': {
            fontSize: '40px',
            fontWeight: '900',
        },
        '&:hover': {
            transform: 'scale(1.8)',
        },
        '&:hover .middle': {
            padding: '6px 20px',
            borderRadius: '50%',
            boxShadow: 'rgba(255, 255, 255, 0.35) 0px 0px 8px 5px',
        },
        '&:hover .border': {
            padding: '2px 30px',
            borderRadius: '50%',
            boxShadow: 'rgba(255, 255, 255, 0.35) 0px 0px 8px 5px',
        },
        '& .border-selected': {
            padding: '2px 30px',
            borderRadius: '50%',
            boxShadow: 'rgba(255, 255, 255, 0.35) 0px 0px 8px 5px',
        },
        '& .middle-selected': {
            borderRadius: '50%',
            padding: '15px 30px',
            boxShadow: 'rgba(255, 255, 255, 0.35) 0px 0px 8px 5px',
        },
        '&.rotate': {
            transform: 'rotate(180deg)',
        },
        '&.rotate:hover': {
            transform: 'rotate(180deg) scale(1.8)',
        }
    },
    tagsContainer: {
        width:'250px',
        padding: '15px',
        borderRadius: '2.6px',
        backgroundColor: '#19191D',
        border: '0.76px solid #606060',
        margin: '0 auto',
        
        '& .tag-action':{
            padding: '8px 20px',
            fontWeight: '700',
        },
        
        '& .tag-action:hover': {
            color: 'black',
            backgroundColor: '#1CB9D3',
        }
    },

    moveInLeft:{
        animation:`$moveInLeft 300ms ${theme.transitions.easing.easeInOut}`,
    },
    moveOutLeft:{
        animation:`$moveOutLeft 300ms ${theme.transitions.easing.easeInOut}`,
    },
    moveInRight:{
        animation:`$moveInRight 300ms ${theme.transitions.easing.easeInOut}`,
    },
    moveOutRight:{
        animation:`$moveInRight 300ms ${theme.transitions.easing.easeInOut}`,
    },
    stepperButtonContainer: {
        display: 'flex',
        padding: '30px',
        justifyContent: 'center',
        '& .MuiButtonBase-root': {
            margin: '0 15px',
            borderRadius:'2px',
            background: '#FDCA40',
            fontWeight: '700',
            color:'black',

            '&:disabled':{
                backgroundColor:'grey',
                color: '#404040'
            }
        },
        '& .MuiButton-containedPrimary': {
            color: 'black'
        }
    },
    stepSelectPlayerContainer: {
        display: 'flex',
        minHeight: '340px',
        alignItems: 'center',
        justifyContent: 'center',
        '& .like': {
            width: '100px',
            height: '100px',
            padding: '15px',
            cursor: 'pointer',
            transition: 'all .3s'
        },
        '& .like:hover': {
            width: '150px',
            height: '150px',
            color: '#1CB9D3'
        },
        '& .like-selected': {
            width: '150px',
            height: '150px',
            color: '#1CB9D3'
        }
    },
    avatarStepPlayerSelect: {
        width: '200px',
        height: '200px',
        backgroundColor: '#bdbdbd',
        '& .MuiSvgIcon-root, .MuiAvatar-img': {
            width: '150px',
            height: '150px',
        }
    },
    inputComment: {
        width: '80%',
        borderRadius: '8px',
        background: '#80808045',
        '& .Mui-focused': {
            border: 'none'
        },
        '& .MuiInputLabel-outlined, textarea': {
            color: '#ffffff'
        },
        '& .MuiInputBase-root:hover': {
            border: 'none'
        },
        '& .MuiInputBase-root:focus': {
            outline: 'none'
        },
        '& textarea:focus, fieldset:focus, div:focus': {
            outline: 'none'
        },
    },
    resummenContainer: {
        display: 'flex',
        justifyContent: 'center',
        '& input': {
            border: 'none',
            color: '#ffffff',
            maxWidth: '40px',
            background: 'transparent',
            borderBottom: '1px solid #ffffff38',
        }
    },
    actionsSummary: {
        margin: '40px 0 0',
    },
    itemAction: {
        margin: '0 5px',
        display: 'flex',
        padding: '0 10px',
        minHeight: '40px',
        maxWidth: '122px',
        border: '1px solid',
        alignItems: 'center',
        borderRadius: '10px',
        '& .ellipsis': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        '& .MuiAvatar-root': {
            width: '35px',
            height: '35px',
        },
        '& img': {
            width: '35px',
            height: '35px',
        }
    },
    "@keyframes moveInLeft": {
        "0%": {
            opacity: 0,
            transform: 'translateX(-100px)',
        },
    
        "80%": {
            transform: 'translateX(10px)',
        },
    
        "100%": {
            opacity: 1,
            transform: 'translate(0)',
        }
    },
    "@keyframes moveOutLeft": {
        "0%": {
            opacity: 1,
            transform: 'translate(0)',
        },
    
        "80%": {
            transform: 'translateX(10px)',
        },
    
        "100%": {
            opacity: 0,
            transform: 'translateX(-100px)',
        }
    },

    "@keyframes moveInRight": {
        "0%": {
            opacity: 0,
            transform: 'translateX(-100px)',
        },
    
        "80%": {
            transform: 'translateX(10px)',
        },
    
        "100%": {
            opacity: 1,
            transform: 'translate(0)',
        }
    },

    "@keyframes moveOutRight": {
        "0%": {
            opacity: 1,
            transform: 'translate(0)',
        },
    
        "80%": {
            transform: 'translateX(10px)',
        },
    
        "100%": {
            
            opacity: 0,
            transform: 'translateX(-100px)',
        }
    },
    
}));

const AnalysisVideoStepper = (props) => {

    const classes = useStyles();
    const { analysis, event, stepper, tagPlayerSelect, getTagActions, setActiveStep, handleFieldZoneSelected, actionByPlayersTemp, handleLike, handleCommentWrited, handleEventInfo, handleSaveEvent, eventInfo } = props
    //const [AnimationClass, setAnimationClass] = useState('next');
    let localToLeft = false


    if(tagPlayerSelect){
        localToLeft = tagPlayerSelect.teamId === analysis.teamid_local & analysis.local_on_left_to_start ? true : false
    }


    const getStepSelectPlayer = () => {
        let avatarTemp = tagPlayerSelect ? resourceApiEndPoint() + (tagPlayerSelect.img_file.charAt(0) === '/' ? tagPlayerSelect.img_file : '/' + tagPlayerSelect.img_file) : <PersonIcon />
        return  <div className={classes.stepSelectPlayerContainer}>
                    { tagPlayerSelect ? 
                        <Avatar
                            className={classes.avatarStepPlayerSelect} 
                            src={avatarTemp}
                            alt="Player name"
                        />
                    :
                        <Avatar className={classes.avatarStepPlayerSelect}>
                            {avatarTemp}
                        </Avatar>
                    }
                </div>
    }

    const getStepActionZone = () => {

        let zoneSelect = tagPlayerSelect && actionByPlayersTemp[tagPlayerSelect._id].event_field_position_id ? actionByPlayersTemp[tagPlayerSelect._id].event_field_position_id : 0
        let localPlayerToLeft = tagPlayerSelect && actionByPlayersTemp[tagPlayerSelect._id].team_id === analysis.teamid_local ? 'local' : 'visitor'

        if( localPlayerToLeft === 'local' ){
            localPlayerToLeft = localToLeft ? '' : ' rotate'
        }else{
            localPlayerToLeft = localToLeft ? ' rotate' : ''
        }

        return  <div className={classes.fieldZoneContainer + localPlayerToLeft}>
                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <div className={classes.fieldZone  + localPlayerToLeft}><span className={ zoneSelect !== 3 ? "middle" : "middle-selected" } onClick={ () => handleFieldZoneSelected(3) }>3</span></div>
                        <div className={classes.fieldZone  + localPlayerToLeft}><span className={ zoneSelect !== 10 ? "middle" : "middle-selected" } onClick={ () => handleFieldZoneSelected(10) }>10</span></div>
                        <div className={classes.fieldZone  + localPlayerToLeft}><span className={ zoneSelect !== 11 ? "middle" : "middle-selected" } onClick={ () => handleFieldZoneSelected(11) }>11</span></div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <div className={classes.fieldZone  + localPlayerToLeft}><span className={ zoneSelect !== 2 ? "middle" : "middle-selected" } onClick={ () => handleFieldZoneSelected(2) }>2</span></div>
                        <div className={classes.fieldZone  + localPlayerToLeft}><span className={ zoneSelect !== 5 ? "middle" : "middle-selected" } onClick={ () => handleFieldZoneSelected(5) }>5</span></div>
                        <div className={classes.fieldZone  + localPlayerToLeft}><span className={ zoneSelect !== 9 ? "middle" : "middle-selected" } onClick={ () => handleFieldZoneSelected(9) }>9</span></div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <div className={classes.fieldZone  + localPlayerToLeft}><span className={ zoneSelect !== 4 ? "middle" : "middle-selected" } onClick={ () => handleFieldZoneSelected(4) }>4</span></div>
                        <div className={classes.fieldZone  + localPlayerToLeft}><span className={ zoneSelect !== 8 ? "middle" : "middle-selected" } onClick={ () => handleFieldZoneSelected(8) }>8</span></div>
                        <div className={classes.fieldZone  + localPlayerToLeft}><span className={ zoneSelect !== 7 ? "middle" : "middle-selected" } onClick={ () => handleFieldZoneSelected(7) }>7</span></div>
                    </div>
                </div>
    }

    const getStepActionAction = () => {
        return  <div className={classes.tagsContainer}>
                    { getTagActions() }
                </div>
    }

    const getStepLike = () => {
        let likeSelect = tagPlayerSelect && actionByPlayersTemp[tagPlayerSelect._id].like_nolike ? "like-selected" : "like"
        return  <div className={classes.stepSelectPlayerContainer}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ThumbUpAltIcon className={likeSelect !== 'like' && actionByPlayersTemp[tagPlayerSelect._id].like_nolike === 'like' ? likeSelect : 'like'} onClick={ () => handleLike('like') } />
                        <ThumbDownIcon className={likeSelect !== 'like' && actionByPlayersTemp[tagPlayerSelect._id].like_nolike === 'nolike' ? likeSelect : 'like'} onClick={() => handleLike('nolike') } />
                    </div>
                </div>
    }

    let timeTemp = null;
    const handleComment = (e) => {
        let value = e.target.value
        if(timeTemp){
            clearTimeout(timeTemp)
        }
        timeTemp = setTimeout( function() {
            handleCommentWrited(value)
        }, 2500);
    }

    const getStepComment = () => {
        let defaultComment = tagPlayerSelect ? actionByPlayersTemp[tagPlayerSelect._id].comment : ''
        return  <div className={classes.stepSelectPlayerContainer}>
                    <TextField
                        label="Comentario"
                        multiline
                        rows="4"
                        defaultValue={defaultComment}
                        variant="outlined"
                        className={classes.inputComment}
                        onChange={handleComment}
                    />
                </div>
    }

    const getStepActionSummary = () => {
        return  <div className={classes.stepSelectPlayerContainer}>
                    
                    { tagPlayerSelect ? 
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{textAlign: 'center', margin: '15px'}}><h3>resumen de la acción</h3></div>
                        <div style={{display: 'flex'}}>
                            <div style={{margin: '5px'}}>
                                <span><b>jugador: </b></span>
                            </div>
                            <div style={{margin: '5px'}}>
                                <span>{tagPlayerSelect.name + ' ' + tagPlayerSelect.lastname}</span>
                            </div>
                        </div>
                        { actionByPlayersTemp[tagPlayerSelect._id].tag_name ? 
                            <div style={{display: 'flex'}}>
                                <div style={{margin: '5px'}}>
                                    <span><b>acción: </b></span>
                                </div>
                                <div style={{margin: '5px'}}>
                                    <span>{actionByPlayersTemp[tagPlayerSelect._id].tag_name}</span>
                                </div>
                            </div>
                        :
                            <div onClick={ () => setActiveStep(1) } style={{display: 'flex', alignItems: 'center', padding: '30px 0 20px', color: 'red', cursor: 'pointer'}}>
                                <div><span>debes asignar una acción para guardar</span></div>
                                <div style={{padding: '0 5px 0 15px', color: '#ffffff'}}><span>Ir...</span></div>
                                <div style={{color: '#ffffff'}}><SettingsBackupRestoreIcon /></div>
                            </div>
                        }
                        { actionByPlayersTemp[tagPlayerSelect._id].event_field_position_id ? 
                            <div style={{display: 'flex'}}>
                                <div style={{margin: '5px'}}>
                                    <span><b>zona: </b></span>
                                </div>
                                <div style={{margin: '5px'}}>
                                    <span>{actionByPlayersTemp[tagPlayerSelect._id].event_field_position_id}</span>
                                </div>
                            </div>
                        :
                            <div onClick={ () => setActiveStep(2) } style={{display: 'flex', alignItems: 'center', padding: '20px 0', color: 'red', cursor: 'pointer'}}>
                                <div><span>debes asignar una zona de acción para guardar</span></div>
                                <div style={{padding: '0 5px 0 15px', color: '#ffffff'}}><span>Ir...</span></div>
                                <div style={{color: '#ffffff'}}><SettingsBackupRestoreIcon /></div>
                            </div>
                        }
                        { actionByPlayersTemp[tagPlayerSelect._id].like_nolike && 
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{margin: '5px'}}>
                                    <span><b>like: </b></span>
                                </div>
                                <div style={{margin: '5px'}}>
                                    {actionByPlayersTemp[tagPlayerSelect._id].like_nolike === 'like' ? <ThumbUpAltIcon /> : <ThumbDownIcon /> }
                                </div>
                            </div>
                        }
                        { actionByPlayersTemp[tagPlayerSelect._id].comment && 
                            <div style={{display: 'flex'}}>
                                <div style={{margin: '5px'}}>
                                    <span><b>comentario: </b></span>
                                </div>
                                <div style={{margin: '5px'}}>
                                    <div>
                                        <span>{actionByPlayersTemp[tagPlayerSelect._id].comment}</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    :
                        <div>selecciona un jugador</div>
                    }
                </div>
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <>{getStepSelectPlayer()}</>;
            case 1:
                return <>{getStepActionAction()}</>;
            case 2:
                return <>{getStepActionZone()}</>;
            case 3:
                return <>{getStepLike()}</>;
            case 4:
                return <>{getStepComment()}</>;
            default:
                return <>{getStepActionSummary()}</>;
        }
    }

    function ColorlibStepIcon(props) {
        const classesStepper = useColorlibStepIconStyles();
        const { active, completed } = props;

        /*
        let iconPlayer = tagPlayerSelect ? 
                <Avatar 
                    src={ resourceApiEndPoint() + (tagPlayerSelect.img_file.charAt(0) === '/' ? tagPlayerSelect.img_file : '/' + tagPlayerSelect.img_file) }
                    alt="Player name"
                />
            : 
                <PersonAddIcon />
                */
    
        const icons = {
            1: <PersonAddIcon />,
            2: <SportsKabaddiIcon />,
            3: <GridOnIcon />,
            4: <ThumbsUpDownIcon />,
            5: <ChatIcon />,
            6: <SaveIcon />,
        };
    
        return (
            <div
                className={clsx(classesStepper.root, {
                    [classesStepper.active]: active,
                    [classesStepper.completed]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }

    const handleNext = () => {
        //setAnimationClass(stepper.activeStep);
        setActiveStep(stepper.activeStep + 1);
        console.log(stepper.activeStep);
        
    };

    const handleBack = () => {
        //setAnimationClass(stepper.activeStep);
        setActiveStep(stepper.activeStep - 1);
        console.log(stepper.activeStep);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const getSummaryActions = (event) => {

        return event.map( (action, index) => {
            let avatarTemp = resourceApiEndPoint() + (action.player.img_file.charAt(0) === '/' ? action.player.img_file : '/' + action.player.img_file)
            return  <div key={index} style={{display: 'flex', alignItems: 'center', margin: '20px 0'}}>
                        <div className={classes.itemAction}>
                            <Avatar src={avatarTemp} />
                        </div>
                        <div className={classes.itemAction}><span className="ellipsis">{action.player.name + ' ' + action.player.lastname}</span></div>
                        <div className={classes.itemAction}><span>{action.tag_name}</span></div>
                        <div className={classes.itemAction} style={{minWidth: '60px'}}><span><b>zona: </b>{action.event_field_position_id}</span></div>
                        { action.like_nolike &&
                            <div className={classes.itemAction}>
                            { action.like_nolike === 'like' ?
                                <ThumbUpAltIcon />
                            :
                                <ThumbDownIcon />
                            }
                            </div>
                        }
                        { action.comment &&
                            <div className={classes.itemAction}>
                                <span className="ellipsis">{action.comment}</span>
                            </div>
                        }
                    </div>
        });
    }

    return ( <>
        <Stepper alternativeLabel activeStep={stepper.activeStep} connector={<ColorlibConnector />}>
            {stepper.steps.map(label => (
            <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
            ))}
        </Stepper>
        <div>
            { stepper.activeStep === stepper.steps.length ? (
            <div>
                <Typography className={classes.instructions}>
                    Todos los pasos han sido completados - has terminado.
                </Typography>
                <Button onClick={handleReset} className={classes.button}>
                    Reiniciar
                </Button>
            </div>
            ) : (
            <div>
                <div className={classes.instructions}>{getStepContent(stepper.activeStep)}</div>
                <div className={classes.stepperButtonContainer}>
          
                        <Button disabled={stepper.activeStep === 0} onClick={handleBack} className={classes.button}>
                            Atrás
                        </Button>

                        { !tagPlayerSelect ? 
                            <Button disabled={true} className={classes.button}>
                                Siguiente
                            </Button>
                        :
                            stepper.activeStep === stepper.steps.length - 1 & !actionByPlayersTemp[tagPlayerSelect._id].event_field_position_id || stepper.activeStep === stepper.steps.length - 1 & !actionByPlayersTemp[tagPlayerSelect._id].tag_name ?
                                <Button disabled={true} className={classes.button}>
                                    Agregar acción
                                </Button>
                            :
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                >
                                    { stepper.activeStep === stepper.steps.length - 1 ? 'Agregar acción' : 'Siguiente' }
                                </Button>
                        }
                </div>

                <div className={classes.resummenContainer}>

                    <div>
                        <div style={{textAlign: 'center', margin: '30px'}}><h3>RESUMEN DE ACCIONES DE EVENTO</h3></div>
                        { event[0] && <>
                            <div style={{display: 'flex', margin: '15px 0'}}>
                                <div><span>Nombre del evento:</span></div><div style={{margin: '0 15px'}}><span>{event[0].tag_name}</span></div>
                            </div>
                            <div style={{display: 'flex', margin: '15px 0'}}>
                                <div><span>Minuto del evento:</span></div><div style={{margin: '0 15px'}}><input defaultValue={eventInfo.minute_event} name="minute_event" onChange={ handleEventInfo } style={{textAlign: 'center'}} type="number" max='180' min='0'></input></div>
                            </div>
                            <div style={{display: 'flex', margin: '15px 0'}}>
                                <div><span>Resultado previo:</span></div><div style={{margin: '0 15px'}}><span>Local</span></div><div style={{margin: '0 15px'}}><input name="local_score" onChange={ handleEventInfo } type="number" min='0' max='99' style={{textAlign: 'center'}} defaultValue={eventInfo.local_score}></input></div><div style={{margin: '0 15px'}}><span>Visitor</span></div><div style={{margin: '0 15px'}}><input defaultValue={eventInfo.visitor_score} name="visitor_score" onChange={ handleEventInfo } type="number" min='0' max='99' style={{textAlign: 'center'}}></input></div>
                            </div>
                            <div className={classes.actionsSummary}>
                                {getSummaryActions(event)}
                            </div>
                            <div className={classes.stepperButtonContainer}>
                                <Button onClick={ handleSaveEvent } className={classes.button} >Guardar evento</Button>
                            </div>
                        </>}
                    </div>
                </div>

            </div>
            )}
        </div>
    </>);
};
export default React.memo(AnalysisVideoStepper);