import React from "react";

import useStyles from "../assets/MakeStyles";
import Avatar from "@material-ui/core/Avatar";
import { getWPlayerById } from "../endpoints";
import { useHistory, useParams } from "react-router-dom";
import PlayerAvatar from "../assets/img/player-avatar.png";

import { translate } from "../lang/lang";

function TagPlayerComponent(props) {
  const history = useHistory();
  const params = useParams();
  const classes = useStyles();
  let { user, player, handlePhotoError, getCountryName, sponsor } = props;
  const avatarUrl = PlayerAvatar;

  const getSponsorName = (id) => {
    if (sponsor) {
      let temp = sponsor.filter((sp) => {
        return sp._id === player.sponsor_id;
      });
      if (temp[0]) {
        return temp[0].name;
      }
    }
    return null;
  };

  const handleGoToGps = async () => {
    let response = await getWPlayerById(params.playerId);
    if (response.status === 200) {
      history.push(
        `/gps/team/${response.data.response.teams[0]._id}?default-player=${params.playerId}`
      );
    } else {
      console.log("error");
    }
  };

  const handleGoToVote = () => {
    history.push(`/rating/${params.playerId}`);
  };

  const handleGoToVideoAnalisis = () => {
    history.push(`/event-video-list?wplayer_id1=${params.playerId}`);
  };

  return (
    <>
      <div className={classes.header}>
        <div className="col align-items-center">
          <div>
            {!player.validPhoto ? (
              <Avatar aria-label="recipe" className={classes.bigAvatar}>
                <img
                  src={"http://gool:3000" + avatarUrl}
                  alt="player"
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: "15px",
                    left: "7px",
                  }}
                />
              </Avatar>
            ) : (
              <Avatar aria-label="recipe" className={classes.bigAvatar}>
                <img
                  src={player.preview}
                  alt="player"
                  style={{ width: "100%" }}
                  onError={handlePhotoError()}
                />
              </Avatar>
            )}
          </div>
          <h3
            style={{
              fontFamily: "DINCondensed",
              fontSize: 42,
              fontWeight: "bold",
              marginLeft: 24,
            }}
          >
            {player.name + " " + player.lastname}
          </h3>
        </div>
        <div className={classes.btnContainer}>
          {user.clubActive && user.clubActive && user.clubActive._id && (
            <>
              {user.clubActive._id === player.wclub_id && (
                <>
                  {user.roleId !== "7" && (
                    <button className={classes.btn} onClick={handleGoToGps}>
                      {translate(user.lang, "go to gps")}
                    </button>
                  )}

                  {user.roleId !== "7" || user._id === player._id ? (
                    <button className={classes.btn} onClick={handleGoToVote}>
                      {translate(user.lang, "go to voting")}
                    </button>
                  ) : (
                    <></>
                  )}
                </>
              )}

              <button className={classes.btn} onClick={handleGoToVideoAnalisis}>
                {translate(user.lang, "go to video analysis")}
              </button>
            </>
          )}
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.card}>
          <h2 className={classes.headerCard}>{translate(user.lang, "data")}</h2>
          <div className={classes.cardContent}>
            <div className={`${classes.data} content-margin-y`}>
              <p>
                <b>{translate(user.lang, "namePlayer") + " "}</b>
                {player.name}
              </p>
              <p>
                <b>{translate(user.lang, "lastNamePlayer") + " "}</b>
                {player.lastname}
              </p>

              <p>
                <b>{translate(user.lang, "birthdate") + " "}</b>
                {player.birthdate}
              </p>

              {player.dni && (
                <p>
                  <b>DNI: </b>
                  {player.dni}
                </p>
              )}

              {player.wcountryids && (
                <p>
                  <b>Nacionalidad: </b>
                  <span className={classes.countryContainer}>
                    {player.wcountryids &&
                      getCountryName(player.wcountryids.split(" ")[0])}
                    {player.wcountryids && player.wcountryids.split(" ")[1] && (
                      <>
                        {player.wcountryids &&
                          getCountryName(player.wcountryids.split(" ")[1])}
                      </>
                    )}
                  </span>
                </p>
              )}

              {player.place_of_birth && (
                <p>
                  <b>Lugar de nacimiento: </b>
                  {player.place_of_birth}
                </p>
              )}

              {player.passport_number && (
                <p>
                  <b>Passport N°: </b>
                  {player.passport_number}
                </p>
              )}

              {player.address && (
                <p>
                  <b>Dirección: </b>
                  {player.address}
                </p>
              )}

              {player.city && (
                <p>
                  <b>Ciudad: </b>
                  {player.city}
                </p>
              )}

              {player.postal_code && (
                <p style={{ marginBottom: 0 }}>
                  <b>Código postal: </b>
                  {player.postal_code}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className={classes.card}>
          <h2 className={classes.headerCard}>
            {translate(user.lang, "sports information")}
          </h2>
          <div className={classes.cardContent}>
            <div className={`${classes.data} content-margin-y`}>
              <p>
                <b>{translate(user.lang, "club player") + " "}</b>
                {player.wclub_name}
              </p>
              <p style={{ margin: "15px 0" }}>
                <b>{translate(user.lang, "team player") + " "}</b>
                {player.teams && player.teams[0] && player.teams[0].name}
              </p>
              <p style={{ margin: "0 0 15px" }}>
                <b>{translate(user.lang, "position player") + " "}</b>
                {player.rolestring}
              </p>

              {player.wnational_team_id && (
                <p>
                  <b>Selección nacional: </b>
                  {getCountryName(player.wnational_team_id)}
                </p>
              )}

              <p style={{ margin: "15px 0" }}>
                <b>{translate(user.lang, "skillful foot") + " "}</b>
                {player.foot}
              </p>

              {player.shirtnumber && (
                <p style={{ margin: "0 0 15px" }}>
                  <b>{translate(user.lang, "dorsal player") + " "}</b>
                  {player.shirtnumber}
                </p>
              )}

              {player.height && (
                <p>
                  <b>Altura: </b>
                  {player.height}
                </p>
              )}
              {player.weight && (
                <p style={{ margin: "15px 0" }}>
                  <b>Peso: </b>
                  {player.weight}
                </p>
              )}
              {player.size && (
                <p style={{ margin: "0 0 15px" }}>
                  <b>Talla: </b>
                  {player.size}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className={classes.card}>
          <h2 className={classes.headerCard}>
            {translate(user.lang, "contact player") + " "}
          </h2>
          <div className={classes.cardContent}>
            <div className={`${classes.data} content-margin-y`}>
              <p>
                <b>{translate(user.lang, "telephone") + " N° "}</b>
                {player.mobile}
              </p>

              <p>
                <b>E-mail: </b>
                {player.email}
              </p>

              {player.social_network && (
                <p>
                  <b>Red social: </b>
                  {player.social_network}
                </p>
              )}
            </div>
          </div>
        </div>

        {player.sponsor_id && (
          <div className={classes.card}>
            <h2 className={classes.headerCard}>DATOS DEL SPONSOR</h2>
            <div className={classes.cardContent}>
              <div className={`${classes.data} content-margin-y`}>
                <p>
                  <b>Sponsor: </b>
                  {getSponsorName(player.sponsor_id)}
                </p>
                <p>
                  <b>Valor del contrato: </b>
                  {player.sponsor_contract_value}
                </p>
                <p>
                  <b>Inicio del contrato: </b>
                  {player.sponsor_start_date}
                </p>
                <p>
                  <b>Caducidad del contrato: </b>
                  {player.sponsor_end_date}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <button className={classes.btn} onClick={() => history.goBack()}>
        {translate(user.lang, "return")}
      </button>
    </>
  );
}
export default React.memo(TagPlayerComponent);
