import { makeStyles, useTheme } from '@material-ui/core/styles';

import boxDateMatch from '../assets/img/textures/box-dates-match.png';
import backgroundLogin from '../assets/img/textures/Background-login.jpg';

export const useStyleTheme = useTheme;

const useStyles = makeStyles((theme) => ({

    headerContainer: {
        display: "flex",
        position: "relative",
        justifyContent: "left",
        flexDirection: "column",
        margin: '20px 0 0',
        "& .titleContainer": {
            width: "100%",
            "& span": {
            lineHeight: "1",
            fontSize: "40px",
            fontFamily: "DINCondensed",
            },
        },
        "& .paragraphContainer": {
            width: "100%",
            "& p": {
                fontSize: "15px",
                maxWidth: '630px',
                fontFamily: "Montserrat-Medium",
            },
        },
        "& .fileInputContainer": {
            margin: "auto 0",
        },
        "& .marginRight": {
            marginRight: 20,
        },
        "& .exampleInput": {
            width: "17%",
            height: "40px",
            padding: "7px",
            color: "#6bffe6",
            fontSize: "15px",
            marginTop: "-8px",
            fontWeight: "700",
            borderRadius: "4px",
            fontFamily: "Poppins",
            textDecoration: "none",
            border: "solid 2px #6bffe6",
            backgroundColor: "transparent",
        },
        "& .fileInput": {
            width: "17%",
            height: "40px",
            padding: "7px",
            fontSize: "15px",
            color: "#131319",
            fontWeight: "700",
            cursor: "pointer",
            borderRadius: "4px",
            fontFamily: "Poppins",
            backgroundColor: "#6bffe6",
            border: "solid 2px #6bffe6",
        },
        "& .hiddenInput": {
            display: "none",
            visibility: "hidden",
        },
    },
    inputContainer: {
        color: "#FFF",
        minWidth: "80%",
        display: "flex",
        flexWrap: "wrap",
        marginBottom: "25px",
        position: "relative",
        boxSizing: "border-box",
        fontFamily: "Open Sans Condensed",
        "& .fileInputContainer": {
            height: "0",
            margin: "auto 0",
        },
        "& .largeInput": {
            width: "17%",
            marginTop: "25px",
            marginRight: 26.8,
            position: "relative",
            "& .icon": {
            top: "30%",
            zIndex: "9",
            right: "5%",
            width: "16px",
            height: "16px",
            position: "absolute",
            },
        },
        "& .exampleInput": {
            width: "17%",
            padding: "7px",
            height: "40px",
            color: "#6bffe6",
            fontSize: "15px",
            fontWeight: "700",
            marginTop: "-8px",
            borderRadius: "4px",
            fontFamily: "Poppins",
            textDecoration: "none",
            border: "solid 2px #6bffe6",
            backgroundColor: "transparent",
        },
        "& .fileInput": {
            width: "17%",
            height: "40px",
            padding: "7px",
            color: "#131319",
            fontSize: "15px",
            fontWeight: "700",
            cursor: "pointer",
            borderRadius: "4px",
            fontFamily: "Poppins",
            backgroundColor: "#6bffe6",
            border: "solid 2px #6bffe6",
        },
        "& .hiddenInput": {
            display: "none",
            visibility: "hidden",
        },
        "& .label": {
            fontSize: "15px",
            fontFamily: "Montserrat-Medium",
        },
        "& .inputField": {
            width: "100%",
            color: "#000",
            height: "40px",
            outline: "none",
            fontSize: "15px",
            borderRadius: "4px",
            padding: "0 0.75rem",
            position: "relative",
            transition: "all .1s",
            boxSizing: "border-box",
            backgroundColor: "#fff",
            border: "solid 2px #ffffff",
            fontFamily: "Montserrat-Medium",
            "&::placeholder": {
                color: "#000",
                fontSize: "15px",
            },
        },
    },
    modalCreationPlayer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        width: "70%",
        padding: "15px",
        display: "grid",
        outline: "none",
        position: "absolute",
        borderRadius: "2.6px",
        backgroundColor: "#19191D",
        border: "0.76px solid #606060",
        "& .accept": {
            border: "none",
            padding: "15px",
            cursor: "pointer",
            borderRadius: "5px",
            backgroundColor: "#6bffe6",
        },
    },
    feedbackAutoGeneratePlayer: {
        margin: "15px 0",
        maxHeight: "40vh",
        overflowY: "auto",
        backgroundColor: "#1c1e24",
        "& h3": {
            padding: "15px",
            margin: "15px 0",
        },
        "& tbody :nth-child(odd)": {
            backgroundColor: "rgba(21,23,25,.7)",
        },
        "& th": {
            padding: "15px",
            textAlign: "center",
        },
        "& table tbody tr td": {
            padding: "15px",
            textAlign: "center",
        },
    },
    sideNavBar: {
        width: "15%",
        display: "flex",
        minWidth: "215px",
        overflowY: "auto",
        padding: "10px 12px",
        flexDirection: "column",
        backgroundColor: "#1c1e24",
        fontFamily: "DINCondensed",
    },
    SubmenuContainer: {
        marginBottom: "1.5rem",
    },
    SubmenuTitle: {
        opacity: "0.28",
        fontSize: "28px",
        color: "#ffffff",
        textAlign: "left",
        fontWeight: "bold",
        lineHeight: "1.43",
        fontStyle: "normal",
        letterSpacing: "normal",
        fontStretch: "condensed",
        fontFamily: "DINCondensed",
        textTransform: 'capitalize'
    },
    SubmenuItem: {
        fontSize: "28px",
        listStyle: "none",
        textAlign: "left",
        fontWeight: "bold",
        lineHeight: "1.43",
        fontStyle: "normal",
        letterSpacing: "normal",
        fontStretch: "condensed",
        fontFamily: "DINCondensed",
        "&.Logout": {
          marginTop: "auto",
          display: 'flex',
          justifyContent: 'center'
        },
        "& .SubmenuItemLink": {
          color: "#fff",
          display: "flex",
          fontSize: "20px",
          cursor: "pointer",
          borderRadius: "3px",
          textDecoration: "none",
          boxSizing: "border-box",
          padding: ".5rem .75rem",
          textTransform: 'capitalize',
          "&:hover": {
            backgroundColor: "#25252f",
          },
          "& .Icon": {
            fill: "#fff",
            stroke: "#fff",
            maxWidth: "22.5px",
            maxHeight: "22.5px",
            marginRight: ".75em",
          },
          "&.active": {
            color: "#6bffe6",
            backgroundColor: "#25252f",
            "& .Icon": {
              fill: "#6bffe6",
              stroke: "#6bffe6",
            },
          },
          "&.Logout": {
            color: "#f5138d",
            marginTop: "auto",
          },
        },
        "& .team-list": {
          margin: '15px 0 0 0',
        },
        "& .SubmenuItemLinkGps": {
          color: "#fff",
          display: "flex",
          fontSize: "20px",
          cursor: "pointer",
          marginLeft: '50px',
          borderRadius: "3px",
          textDecoration: "none",
          boxSizing: "border-box",
          "&:hover": {
            backgroundColor: "#25252f",
          },
          "& .Icon": {
            fill: "#fff",
            stroke: "#fff",
            maxWidth: "22.5px",
            maxHeight: "22.5px",
            marginRight: ".75em",
          },
          "&.active": {
            color: "#6bffe6",
            backgroundColor: "#25252f",
            "& .Icon": {
              fill: "#6bffe6",
              stroke: "#6bffe6",
            },
          },
          "&.Logout": {
            color: "#f5138d",
            marginTop: "auto",
          },
        } 
    },
    userInfo: {
        display: 'flex',
        marginBottom: '15px',
        borderRadius: '8px',
        backgroundColor: '#131319',
        padding: '15px 0',
        '& p': {
          fontSize: '22px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '& img': {
          maxWidth: '67px',
        }
    },
    LoginContainer: {
        width: '100%',
        display: 'grid',
        padding: '15px',
        alignItems: 'center',
        justifyItems: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundImage: `url(${ backgroundLogin} )`,
    },
    LoginBox: {
        display: 'grid',
        maxWidth: '390px',
        '& .header-container .logo': {
            maxWidth: '190px',
            maxHeight: '120px'
        },
        '& .subheader-container p': {
            fontSize: '16px',
            lineHeight: '1.5',
            textAlign: 'left',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'Poppins',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            '& b':{
                fontSize: '19px'
            }
        },
        '& .foot-login-container a': {
            color: '#6cffe6',
            cursor: 'pointer',
            textDecoration: 'underline',
        },
        '& .foot-login-container p': {
            lineHeight: 1.5,
            color: '#a8a8a8',
            fontSize: '12px',
            textAlign: 'left',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontFamily: 'Poppins',
            letterSpacing: 'normal',
        },
    },
    formContainer: {
        display: 'grid',
        margin: '15px 0'
    },
    inputLogin: {
        outline: 'none',
        fontSize: '14px',
        borderRadius: '5px',
        padding: '15px 23px',
        fontFamily: 'Poppins',
        marginBottom: '0.75rem',
        border: '1px solid #ddd',
        '&::focus' :{
            boxShadow: '0px 5px 4px #fff',
        },
        '&::placeholder': {
            color: '#a5a5a5',
            fontSize: '14px',
            lineHeight: '1.56',
        }
    },
    loginButtom: {
        height: '56px',
        margin: '12px 0',
        borderRadius: '5px',
        background: '#6cffe6',
        '& span': {
            fontWeight: 500,
            color: '#151414',
            fontSize: '16px',
            textTransform: 'none',
            fontFamily: 'Poppins',
        }
    },
    incorrectLogin: {
        display: 'flex',
        color: '#ff7676',
        margin: '12px 0',
        justifyContent: 'center',
    },


    AV: {
        outline: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .finish-content": {
        minWidth: "80vh",
        paddingTop: "100px",
        paddingBottom: "100px",
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#181A1D",
        borderRadius: "25px",
        outline: "none",
        "& h3": {
            fontFamily: "Open Sans Condensed",
            fontSize: "30px",
            fontWeight: 600,
            marginBottom: "20px",
            color: "white",
        },
        "& p": {
            fontFamily: "Open Sans Condensed",
            color: "#717386",
            textAlign: "center",
            marginBottom: "20px",
        },
        "& button": {
            padding: "20px 75px",
            marginLeft: "25px",
            fontSize: "15px",
            outline: "none",
            color: "white",
            border: "0px solid",
            fontFamily: "Montserrat",
            backgroundColor: "#014483",
        },
        },
    },
    headerContainerAddClubs: {
        display: "flex",
        marginBottom: "50px",
        position: "relative",
        justifyContent: "left",
        flexDirection: "column",
        "& .titleContainer": {
            width: "100%",
            "& span": {
                lineHeight: "1",
                fontSize: "40px",
                fontFamily: "DINCondensed",
            },
        },
        "& .fileInputContainer": {
            width: "350px",
            marginTop: "20px",
            padding: "0 0 20px",
            borderRadius: "6px",
            backgroundColor: "#16171d",
        },
        "& .inputField": {
            outline: "none",
            color: "#000000",
            width: "259.4px",
            fontSize: "18px",
            marginTop: "20px",
            position: "relative",
            marginLeft: "44.5px",
            transition: "all .1s",
            borderRadius: "4.6px",
            padding: "10px 20.6px",
            boxSizing: "border-box",
            border: "1px solid white",
            backgroundColor: "#ffffff",
            fontFamily: "Montserrat-Medium",
        },
        "& .inputsFirstTeam": {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            "& input": {
                width: "259.4px",
                fontSize: "18px",
                marginTop: "20px",
                borderRadius: "4.6px",
                padding: "10px 20.6px",
                boxSizing: "border-box",
                border: "1px solid white",
                fontFamily: "Montserrat-Medium",
            },
            "& span": {
                width: "259.4px",
                color: "#6cffe6",
                fontSize: "14px",
                marginTop: "20px",
            },
            "& .error": {
                color: "red",
            },
        },
        "& .subcontainerHeader": {
            width: "350px",
            height: "60px",
            color: "#6cffe6",
            fontSize: "15px",
            fontWeight: "700",
            lineHeight: "54px",
            paddingLeft: "42px",
            borderRadius: "7px",
            position: "absolute",
            boxSizing: "border-box",
            backgroundColor: "#1c1e24",
        },
        "& .dropZoneContainer": {
            width: "100%",
            outline: "none",
            "& img": {
                outline: "none",
                width: "230.4px",
                marginTop: "20px",
                maxHeight: "240px",
            },
        },
        "& .noImage": {
            width: "100%",
            outline: "none",
            "& img": {
                width: "61.4px",
                outline: "none",
                maxHeight: "53px",
                marginTop: "20px",
            },
        },
    },
    dropNoContainer: {
        width: "259px",
        opacity: "0.8",
        padding: "7px",
        display: "flex",
        outline: "none",
        height: "234px",
        borderRadius: "9px",
        alignItems: "center",
        margin: "80px auto 0",
        flexDirection: "column",
        boxSizing: "border-box",
        justifyContent: "center",
        border: "solid 1px #6cffe6",
        "& p": {
            width: "140px",
            color: "#6cffe6",
            marginTop: "20px",
            textAlign: "center",
        },
    },
    buttonSaveContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        "& button": {
            outline: "none",
            fontSize: "17px",
            marginLeft: "25px",
            padding: "10px 15px",
        },
        "& .formSaveButton": {
            width: "247px",
            border: "none",
            height: "53.8px",
            color: "#000000",
            cursor: "pointer",
            fontWeight: "700",
            borderRadius: "4px",
            backgroundColor: "#6cffe6",
        },
    },
    resumenContainer: {
        display: 'flex',
        margin: '0 0 15px',
        '& > div' : {
            '@media (max-width: 576px)': {
                flexDirection: 'row',
                width: 'calc(100% - 30px)',
            }
        }
    },
    boxDateMatch: {
        backgroundImage: `url( ${boxDateMatch} )`,
        padding: '30px',
        borderRadius: '5.7px',
        margin: '15px 15px 0',
        backgroundSize: 'cover',
        width: 'calc(60% - 30px)',
        backgroundPosition: 'center',
        '& .MuiInputBase-root, .MuiInputBase-input' : {
            background: 'transparent',
        },
        '& .MuiStepper-root': {
            padding: '30px 0',
            background: 'transparent',
        },
        '& .MuiStep-root': {
            padding: 0,
        },
        '& .MuiStepLabel-label': {
            color: '#ffffff',
        },
        '& .space-around':{
            justifyContent: 'space-around'
        }
    },
    boxLocalVisitor: {
        borderRadius: '1.7px',
        backgroundSize: 'cover',
        width: 'calc(20% - 10px)',
        backgroundPosition: 'center',
        boxSizing: 'border-box',
        '& .MuiAutocomplete-input': {
            fontSize: '14.25px',
            textAlign: 'center',
        },
        '& .MuiInputLabel-outlined': {
            left: '50%',
            fontSize: '14.25px',
            transition: 'all .3s',
            fontFamily: 'Open Sans',
            transform: 'translate(-50%, 12px) scale(1)',
        },
        '& .expansion-panel-players': {
            color: '#ffffff',
            margin: '0 !important',
            backgroundColor: '#ffffff00',
            '& .MuiButtonBase-root': {
                padding: 0,
                minHeight: 'initial',
            },
            '& .MuiExpansionPanelSummary-content': {
                margin: 0,
            },
            '& .MuiExpansionPanelDetails-root': {
                padding: 0,
                display: 'initial',
            },
            '& .MuiExpansionPanel-root.Mui-expanded': {
                margin: 0,
                padding: '16px 0',
            },
            '& .MuiCollapse-wrapper':{
                backgroundColor:'rgba(3, 3, 5, 0.78)'
            },
            '& .MuiCollapse-wrapperInner': {
                marginBottom: '15px',
                backgroundImage: 'linear-gradient(90deg, rgba(0,19,22,1) 0%, rgba(0,17,20,1) 8%, rgba(0,8,9,1) 50%, rgba(0,19,22,1) 94%, rgba(0,19,22,1) 100%)'
            },
            '& .MuiAvatar-root': {
                width: '30px',
                height: '30px',
                backgroundColor: '#ffffff',
            },
            '& .active .MuiAvatar-root': {
                width: '26px',
                height: '26px',
                paddingTop: '0',
                border: '2px solid #1CB9D3',
                boxShadow: 'rgba(255, 255, 255, 0.1) 0px 0px 5px 2px',
            },
            '& .active span': {
                color: '#1CB9D3',
            },
            '& .inactive': {
                // opacity: '.5',
            }
        }
    },
    boxSummaryEvents: {
        margin: '0',
        padding: '15px',
        borderRadius: '5.7px',
        backgroundSize: 'cover',
        width: 'calc(100% - 30px)',
        backgroundPosition: 'center',
        backgroundImage: `url( ${boxDateMatch} )`,
    },
    expansionPanelContainer: {
        color: '#ffffff',
        margin: '0 !important',
        backgroundColor: '#ffffff00',
        '& .MuiButtonBase-root': {
            padding: 0,
            minHeight: 'initial',
        },
        '& .MuiExpansionPanelSummary-content': {
            margin: 0,
        },
        '& .MuiExpansionPanelDetails-root': {
            padding: 0,
            display: 'initial',
        },
        '& .MuiExpansionPanel-root.Mui-expanded': {
            margin: 0,
            padding: '16px 0',
        },
        '& .MuiCollapse-wrapper':{
            backgroundColor:'rgba(3, 3, 5, 0.78)'
        },
        '& .MuiCollapse-wrapperInner': {
            marginBottom: '15px',
            backgroundImage: 'linear-gradient(90deg, rgba(0,19,22,1) 0%, rgba(0,17,20,1) 8%, rgba(0,8,9,1) 50%, rgba(0,19,22,1) 94%, rgba(0,19,22,1) 100%)'
        },
        '& .MuiAvatar-root': {
            width: '30px',
            height: '30px',
            backgroundColor: '#ffffff',
        },
        '& .active .MuiAvatar-root': {
            width: '26px',
            height: '26px',
            paddingTop: '0',
            border: '2px solid #1CB9D3',
            boxShadow: 'rgba(255, 255, 255, 0.1) 0px 0px 5px 2px',
        },
        '& .active span': {
            color: '#1CB9D3',
        },
        '& .inactive': {
            // opacity: '.5',
        }
    },
    teamNameContainer:{
        display: 'flex',
        boxSizing: 'border-box',
        padding: '10px',
        '& .teamName': {
            width: '75%',
            fontSize: '16px',
            fontWeight: '700',
            alignSelf: 'center',
            textAlign: 'center',
        },
        '& .local-visitor-logo':{
            width: '45px',
            height: '45px',
        },
    },
    roleContainer:{
        position: 'relative',
        padding: '10px 20px 10px 10px',
        backgroundColor: 'rgba(133, 131, 131, 0.22)',

        '&::after':{
            left: '0',
            width: '0',
            top:'100%',
            content: '""',
            position: 'absolute',
            transition: 'all .5s',
            borderBottom: '2.5px solid #00BBD6',

            
        },
        '&:hover::after':{
            left: '0',
            top:'100%',
            content: '""', 
            width: '100%',
            position: 'absolute',
            transition: 'all .5s',
            borderBottom: '2.5px solid #00BBD6',
        }
        
    },
    playerBoxContainer:{
        margin: '5px',
        height: '46px',
        display: 'flex',
        padding: '0 6px',
        alignItems:'center',
        borderRadius: '3px',
        position: 'relative',
        transition:'all .2s',
        backgroundColor: 'rgba(21, 23, 25, 0.78)',  
        '&:hover': {
            transform: 'scale(1.05)',
            backgroundColor: 'rgba(50, 50, 50, 0.78)', 
        },
        '&::after':{
            top: '0',
            left: '0',
            content: '""',
            height: '46px',
            position: 'absolute',
            transition: 'all .2s',
            width: '100% !important',
            backgroundImage: 'linear-gradient(90deg, #00BBD6 0%, #00BBD6 100%)',
            opacity: 0,
        },
        '&:hover::after':{
            opacity:'1',
            width:'220px',
            height: '46px',
            clipPath: 'polygon(0 0, 0% 100%, 0 100%, 0 15%, 5% 0, 100% 0, 100% 85%, 95% 100%, 100% 100%, 100% 0)',
        }
    },
    mediaPlayer: {
        '& video:focus': {
            outline: 'none',
        }
    },
    modalTagPlayerAV: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paperTagPlayer: {
        width: '15%',
        padding: '15px',
        outline: 'none',
        position: 'absolute',
        borderRadius: '2.6px',
        backgroundColor: '#19191D',
        border: '0.76px solid #606060',
        '& .indicatorLeft ': {
            top:'47%',
            left: '-20%',
            width: '43px',
            height: '31px',
            position: 'absolute',
            backgroundColor: '#606060',
            transform: 'translateY(-50%)',
            clipPath: 'polygon(100% 0, 100% 100%, 50% 50%)'
        },
        '& .indicatorRight ': {
            top:'47%',
            width: '43px',
            right: '-20%',
            height: '31px',
            position: 'absolute',
            backgroundColor: '#606060',
            transform: 'translateY(-50%)',
            clipPath: 'polygon(0 0, 0 100%, 50% 50%)'
        },
    },
    paperTagAction: {
        display: 'flex',
        padding: '15px',
        borderRadius: '2.6px',
        flexDirection: 'column',
        backgroundColor: '#19191D',
        border: '0.76px solid #606060',
        '& .modalInput': {
            width: 'auto !important',
        }
    },
    tagsContainer: {
        padding: '10px 0',
        overflowY: 'auto',
        maxHeight: '300px',
        '& .tag-action':{
            color:'#D8D8D8',
            fontSize: '13px',
            textAlign: 'left',
            padding: '10px 0',
            cursor: 'pointer',
            position: 'relative',
            transition: 'all .3s',
            backgroundSize: '220%',
            textTransform: 'capitalize',
            backgroundImage: 'linear-gradient(120deg, transparent 0%, transparent 50%, #00BBD6 50%)',
            '&:hover':{
                color: 'black',
                padding:'10px',
                backgroundPosition: '100%',
            },
        },
        '& .modalInput':{
            width: '70%',
            height: '24px',
            color: 'white',
            outline: 'none',
            padding:'0 5px',
            fontWeight: '700',
            marginBottom: '5px',
            borderRadius: '3px',
            border: '1px solid #606060',
            backgroundColor: 'transparent',
            '&::placeholder':{
                transition:'all .2s',
            },
            '&:hover::placeholder':{
                color:'white',
                padding:'4px',
            }
        }
    },
    tagActionSelect : {
        color: 'black',
        textTransform: 'capitalize',
        fontSize: '13px',
        padding: '10px 0',
        backgroundColor: '#00BBD6',
    },
    resummenEventsContainer: {
        '& .resummenEventsSubContainerName': {
            display: 'flex',
            flexWrap: 'wrap',
            padding: '30px 15px 10px',
            justifyContent: 'space-between',
        },
        '& .resummenEventsSubContainerCount': {
            display: 'flex',
            flexWrap: 'wrap',
            background: '#24292B',
            borderRadius: '5.42px',
            padding: '10px 15px 25px',
            justifyContent: 'space-between',
        },
        '& .event-resummen-container': {
            textAlign: 'center',
            '& .tag-count-container': {
                cursor: 'pointer',
                transition: 'all .3s',
            },
            '& .tag-count-container:hover': {
                color: '#1CB9D3',
                transform: 'scale(1.6) translateY(-4px)',
            },
            '& .tag-container span': {
                fontSize: '13.68px',
            }
        }
    },
    titleMatch: {
        fontWeight: 700,
        textAlign: 'left',
        fontSize: '19.1px',
        fontFamily: 'Montserrat',
    },
    soccerBallIco: {
        width: 20.9,
        height: 20.9,
        marginRight: 15,
    },
    playerSelectAction:{
        outline: 'none',
    },
    addTagButton: {
        border: 'none',
        padding: '5px',
        fontWeight: 700,
        cursor: 'pointer',
        margin: '15px 0 0',
        borderRadius: '2px',
        background: '#FDCA40',
    },
    rowSessionItem: {
        cursor: 'pointer',
        '& .cell-row': {
            padding: '10px',
            textAlign: 'center',
        }
    },
    sessionGpexe: {
        '& .property': {
            fontFamily: 'DINCondensed',
            fontSize: '18px',
            margin: '10px 0',
            '& *': {
                margin: '5px 0',
                display: 'flex',
                flexDirection: 'column',
            },
            '& b': {
                fontSize: '24px',
                borderBottom: '1px solid',
            }
        }
    },
    paperGraphy: {
        top: '50%',
        left: '50%',
        padding: '15px',
        minWidth: '80vw',
        position: 'absolute',
        backgroundColor: '#19191D',
        transform: 'translate(-50%, -50%)',
        '&:focus': {
            outline: 'none'
        },
        '& .property': {
            fontFamily: 'DINCondensed',
            fontSize: '18px',
            margin: '10px 0',
            '& *': {
                margin: '5px 0',
                display: 'flex',
                flexDirection: 'column',
            },
            '& b': {
                fontSize: '24px',
                borderBottom: '1px solid',
            }
        }
    },
    root: {
        backgroundColor: '#17181B',
        width: '100%',
        '& .MuiPaper-root': {
            background: '#17181b',
        },
        '& .MuiButtonBase-root': {
            color: '#ffffff',
        },
        '& .MuiTab-textColorPrimary': {
            color: '#959597',
        },
        '& .Mui-selected': {
            color: '#ffffff',
        },
        '& .MuiTabs-indicator': {
            background: '#08BBEE',
        }
    },
    gpexeBoxSummary: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    gpexeBoxPower: {
        display: 'flex',
        justifyContent: 'center',
    },
    customTooltip: {
        opacity: '0.9',
        padding: '5px',
        borderRadius: '8px',
        background: '#000000f2',
        '& .label': {
            fontSize: '22px',
            margin: '5px 10px',
            fontFamily: 'DINCondensed',
        }
    },
    // RatingComponent
    RatingContainer: {
        margin: '0',
        borderRadius: '14.84px',
        '@media (max-width: 576px)': {
            padding: '15px',
        },
    },
    titleBody: {
        fontWeight: 700,
        color: '#FFFFFF',
        fontSize: '38px',
        marginTop: '20px',
        lineHeight: '36px',
        textTransform: 'uppercase',
        fontFamily: 'DINCondensed',
        borderBottom: '1.02px solid #FFFFFF',
    },
    inputSelect: {
        width: '100%',
        height: '40px',
        display: 'flex',
        fontWeight: 700,
        borderRadius: '8px',
        alignItems: 'center',
        transition: 'all .3s',
        backgroundColor: '#3A3C3E',
        textTransform: 'uppercase',
        justifyContent: 'space-between',
        '@media (max-width: 576px)': {
            width: '100%',
            flexDirection: 'row'
        },
        '& > span': {
            color: '#19191D',
            fontFamily: 'DINCondensed',
            fontSize: '23.75px',
            fontWeight: 700,
            lineHeight: '29px',
            marginBottom: '-5px',
            textTransform: 'initial'
        }
    },
    ratingTypeLeft: {
        borderRadius: '3.8px 0 0 3.8px',
        '@media (max-width: 576px)': {
            width: '100% !important',
            margin: '15px 10px',
        }
    },
    ratingTypeRight: {
        borderRadius: '0 3.8px 3.8px 0',
        '@media (max-width: 576px)': {
            width: '100% !important',
            margin: '15px 10px',
        }
    },
    targetRating: {
        display: 'flex',
        margin: '15px 0',
        padding: '10px 0',
        borderRadius: '5px',
        background: '#1B1C1F',
        flexDirection: 'column',
        border: '2px solid #3A3C3E',
        justifyContent: 'space-between',
        '& span' : {
            fontSize: '14px',
        },
        '& * ':{
            margin: '10px 20px'
        },
        '& h3': {
            textTransform: 'initial',
            fontFamily: 'DINCondensed',
            fontSize: '28px',
            textAlign: 'center',
        }
    },
    targetButton:  {
        border: 'none',
        padding: '15px 0',
        color: '#ffffff',
        cursor: 'pointer',
        borderRadius: '2px',
        background: '#074682',
        textTransform: 'uppercase',
    },
    avatar: {
        padding: '5px',
        border: '1px solid',
        background: '#1b1c1f',
        margin: '15px 15px 15px 0',
        '& img': {
            borderRadius: '50%',
        }
    },
    // UserVoter.js
    ratesContainer: {
        margin: '15px 0',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    userVoterTitleBody: {
        marginTop: '20px',
        fontWeight: '700',
        lineHeight: '36px',
        fontSize: '29.64px',
        textTransform: 'uppercase',
        fontFamily: 'Open Sans Condensed',
        borderBottom: '1.02px solid #FFFFFF'
    },
    rateContainer: {
        margin: '15px 0',
        flexDirection: 'column',
        justifyContent: 'start',
    },
    rateTitle: {
        padding: '15px',
        background: '#313642',
        borderRadius: '10px 10px 0 0',
        fontFamily: 'DINcondensed',
        fontSize: '24px',
    },
    rateContainerSubSkills: {
        display: 'flex',
        border: '1px solid #6F6F70',
        justifyContent: 'space-between',
    },
    skillChildContainer: {
        margin: '30px 15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 768px)': {
            margin: '15px',
        },
        '& .MuiRating-label': {
            fontSize: '1.3rem',
        }
    },
    skillChildName: {
        zIndex: '1',
        fontSize: '14px',
        maxWidth: '100px',
        overflow: 'hidden',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        background: '#17181b',
        textOverflow: 'ellipsis',
        '&:hover': {
            display: 'flex',
            maxWidth: 'inherit',
            whiteSpace: 'inherit',
        }
    },
    subSkillBox: {
        width: '100%',
        maxHeight: '260px',
        background: '#17181B',
        '@media (max-width: 768px)': {
            maxHeight: 'inherit',
        },
    },
    ratingStars: {
        '& .MuiRating-decimal, .MuiRating-icon': {
            color: '#ffffff'
        },
        '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
            color: 'rgba(255, 255, 255, 0.26)',
        }
    },
    // TagPlaomponent
    tagPlayerContainer: {
        width: "100%",
        fontFamily: "Open Sans Condensed",
    },
    countryContainer: {
        [theme.breakpoints.down(1370)]: {
        display: "flex",
        padding: "5px 0",
        },
        padding: "0 5px",
        "& img": {
        padding: "0 5px",
        maxWidth: "30px",
        },
    },
    header: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 30,
        marginBottom: 60,
    },
    bigAvatar: {
        width: "73px",
        height: "73px",
    },
    card: {
        marginBottom: 30,
        marginRight: "auto",
        width: 325,
        maxWidth: "100%",
        backgroundColor: "#16171d",
        borderRadius: 6,
    },
    cardContent: {
        padding: "0 30px",
        paddingBottom: 20,
    },
    headerCard: {
        backgroundColor: "#1c1e24",
        padding: "20px 30px",
        color: "#6cffe6",
        fontSize: 18,
        fontFamily: "Montserrat",
        borderRadius: 6,
    },
    row: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    btnContainer: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down(1370)]: {
        flexDirection: "column",
        },
    },
    btn: {
        width: 220,
        maxWidth: "100%",
        height: 44,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "transparent",
        border: "1px solid #6bffe6",
        color: "#6bffe6",
        fontFamily: "Montserrat",
        fontSize: 14,
        fontWeight: "500",
        borderRadius: 7,
        textTransform: "uppercase",
        marginLeft: 25,
        cursor: "pointer",
        [theme.breakpoints.down(1370)]: {
        marginBottom: 20,
        },
    },
    data: {
        "& p": {
        fontFamily: "Montserrat",
        fontWeight: "500",
        "& span": {
            marginLeft: 0,
        },
        },
    },

}));

export const useStyle = useStyles;

export default useStyles;