import React from 'react';

import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import CompetitionCard from './Cards/CompetitionCard';

const useStyles = makeStyles(theme => ({
    headerContainer: {
        display: 'flex',
        marginTop: '50px',
        flexDirection: 'row',
        position: 'relative',
        justifyContent: 'space-between',
        '& .title': {
            color: '#FFFFFF',
            fontSize: '23px',
            width: '264.2px',
            fontWeight: '700',
            textAlign: 'left',
            lineHeight: '44px',
            position: 'relative',
            textTransform: 'uppercase',
            font: 'Open Sans Condensed',
            '&::after': {
                left: '0',
                top: '45px',
                content: '""',
                width: '118%',
                position: 'absolute',
                borderBottom: '1px solid white',
            },
        },
        '& .eventsButton': {
            border: 'none',
            width: '224px',
            color: 'white',
            outline: 'none',
            fontSize: '12px',
            height: '38.52px',
            fontWeight: '700',
            lineHeight: '40px',
            textAlign: 'center',
            transition: 'all .2s',
            borderRadius: '1.81px',
            backgroundColor: '#074682',
            textTransform: 'uppercase',
            transform: 'translateY(11%)',
        },
        '& .eventsButton:hover': {
            transform: 'scale(1.1)',
        },
        '& .titleIcon': {
            top: '6px',
            left: '4px',
            width: '30px',
            height: '30px',
            position: 'absolute',
        },
    },
    competitionsContainer: {
       display: 'flex',
       flexWrap: 'wrap',
    },
}));

const CompetitionComponent = (props) => {

    const classes = useStyles();
    const {competitions, handleShowTeams, handleDeleteCompetition} = props

    return (
        <>
            <div className={classes.headerContainer}>
                <h1 className='title'>Competitions</h1>
                <NavLink to={'/competition/setup'} className='eventsButton'>Create Competitions</NavLink>
            </div>
            <div className={classes.competitionsContainer}>
                {competitions && competitions.map( (comp, key) =>{
                    return (
                        <CompetitionCard key={key} competition={comp} handleShowTeams={handleShowTeams} handleDeleteCompetition={handleDeleteCompetition} />
                    )
                })}
            </div>
        </>
    )
}
export default React.memo(CompetitionComponent);