import React, { useEffect, useState } from 'react';

import useStyles from '../assets/MakeStyles';
import GlobalLoading from "../components/accessories/GlobalLoading";

import {
    searchGPexeSessionById,
    searchGPexeByAthleteSessions,
    searchGPexeAthleteSessionBrief,
    searchGPexeAthleteSessionSummary,
    searchGPexeAthleteSessionPowerseries
} from "../endpoints";
import GraphyGpexeSummary from '../components/charts/GraphyGpexeSummary';
import GraphyGpexePlayerModal from '../components/charts/GraphyGpexePlayerModal';

const GpexeSession = (props) => {

    const classes = useStyles();
    const sessionId = props.match.params.sessionId;
    const [globalLoading, setGlobalLoading] = useState(true);
    const [summarySelected, setSummarySelected] = useState(1);
    const [athlete, setAthlete] = useState({headers: [], athlete_session_list: []});
    const [modalGraphics, setModalGraphics] = useState({open: false, graphics: {} });
    const [summaryData , setSummaryData] = useState([]);
    const [session, setSession] = useState({weather: {temperature: null}, tags: [''], tags_dictionary: {}});


    const handleChangeSummaryOption = (target) => {
        setSummarySelected(target.value);
    }

    const preBuildGraphics = (resp, property, item, graphics) => {
        if(resp.data.code === 200){
            if(property === 'power'){

                graphics = {...graphics, [property]: resp.data[item].data.map( val => {
                        return { color: val.color, ...val.data }
                    })
                };
   
            }else{
                graphics = {...graphics, [property]: resp.data[item]};
            }
        }
        return graphics;
    }

    const handleGetAthleteSessionsDetails = async (athlete_session_id) => {

        setGlobalLoading(true);
        let graphics = {};
        let resp = await searchGPexeAthleteSessionBrief(athlete_session_id);
        graphics = preBuildGraphics(resp, 'brief','athleteSessionBrief', graphics);
        resp = await searchGPexeAthleteSessionSummary(athlete_session_id);
        graphics = preBuildGraphics(resp, 'summary','athleteSessionSummary', graphics);
        resp = await searchGPexeAthleteSessionPowerseries(athlete_session_id);
        graphics = preBuildGraphics(resp, 'power','athleteSessionPowerSeries', graphics);
        setModalGraphics({open: true, graphics: graphics});
        setGlobalLoading(false);
    }

    const showSessionTable = () => {

        return  <table className='table gpexe-table'>
                    <thead>
                        <tr>
                            {athlete.headers.map( (h, index) => {
                                return <th key={index}><div className="unit"><b>{h.label}</b><span>{h.unit}</span></div></th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {athlete.athlete_session_list.map( (ath, index) => {
                            return  <tr
                                        key={`item-athlete-${index}`}
                                        className={`row-items ${classes.rowSessionItem}`}
                                        onClick={ () => handleGetAthleteSessionsDetails(ath.id) }
                                    >
                                        { ath.values.map( (val, indexVal) => {
                                            return <td key={`${index}-val-${indexVal}`} className="cell-row">{val}</td>
                                        })}
                                    </tr>
                        })}
                    </tbody>
                </table>
    }

    useEffect( () => {

        searchGPexeSessionById(sessionId)
        .then( (resp) => {
            if(resp.data.code === 200){
                console.log(resp.data.session)
                setSession(resp.data.session);
            }
        })
        .catch( (err) => {
          console.log(err);
        });

        searchGPexeByAthleteSessions(sessionId)
        .then( (resp) => {
            if(resp.data.code === 200){
                console.log(resp.data.athlete_sessions)
                setSummaryData( resp.data.athlete_sessions.athlete_session_list.map( (ath, index)=> {
                            let summaryTemp = {};
                            resp.data.athlete_sessions.headers.forEach( (header, indexHeader) => { 
                                if( header.label === 'dur'){
                                    summaryTemp = {...summaryTemp, [header.label]: parseInt((ath.values[indexHeader].split(":")[0] * 60)) + parseInt(ath.values[indexHeader].split(":")[1]) };
                                }else if( header.label === 'athlete'){
                                    summaryTemp = {...summaryTemp, [header.label]: ath.values[indexHeader].split(" ")[0] };
                                }else{
                                    summaryTemp = {...summaryTemp, [header.label]: ath.values[indexHeader] };
                                }
                            });
                            return summaryTemp;
                        } 
                    ) 
                );
                setAthlete(resp.data.athlete_sessions);
            }
            setGlobalLoading(false);
        })
        .catch( (err) => {
          console.log(err);
          setGlobalLoading(false);
        }); 

    }, []);

    return (<>
    <div className={classes.sessionGpexe + ' ' + classes.headerContainer}>

        <div className="titleContainer">
            <span>Session</span>
        </div>

        <div className='cols sm-2'>
            <div className="property"><b>sessions date</b><span>{session.day} {session.start_time}</span></div>
            <div className="property"><b>sessions category</b><span>{session.category_name}</span></div>
        </div>

        <div className='cols sm-2 md-4'>
            <div className="property"><b>weather</b><span>{`${Math.trunc(session.weather.temperature)}°C`}</span></div>
            {/*<div className="property"><b>match</b><span></span></div>*/}
            <div className="property"><b>tracks</b><span>{session.n_tracks}</span></div>
            <div className="property"><b>total time</b><span>{session.total_time}</span></div>
            <div className="property"><b>tags</b><span>{session.tags_dictionary[session.tags[0]]}</span></div>
        </div>

        <div className='cols sm-2'>
            {/*<div className="property"><b>drills</b><span></span></div>*/}
            <div className="property"><b>notes</b><span>{session.notes}</span></div>
        </div>

        <br/>
        <div className="titleContainer">
            <span>Details</span>
        </div>
        <br/>


        <div className="tableContainer">
            { showSessionTable() }
        </div>

        <div className="graphy-summary-container">

            <br/>
            <div className="titleContainer">
                <span>sessions summary</span>
            </div>

            <div className={classes.inputContainer}>
                <div className="largeInput">
                    <select
                        value={ summarySelected }
                        required
                        className="inputField"
                        id="summary"
                        onChange={ (e) => handleChangeSummaryOption(e.target) }
                    >
                    {athlete.headers.map( (data, index) => {
                        if(data.label === 'athlete'){
                            return (
                            <option  key={`summary-option-${index}`} value={index} disabled>
                                Summary
                            </option>
                            );
                        }else{
                            return (
                            <option key={`summary-option-${index}`} value={index}>
                                {data.label}
                            </option>
                            );
                        }
                    })}
                    </select>
                </div>
            </div>

            <GraphyGpexeSummary data={summaryData} dataKey={athlete.headers.length > 0 ? athlete.headers[summarySelected].label : ''} />
        </div>

    </div>

    <GraphyGpexePlayerModal open={modalGraphics.open} handleClose={setModalGraphics} graphics={modalGraphics.graphics} />

    { globalLoading && <GlobalLoading /> }
    </>);
};
export default GpexeSession;