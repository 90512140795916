import React from 'react'
import { ReactComponent as TitleBall }  from '../../assets/img/Shape.svg';
import {ReactComponent as TeamFlag } from '../../assets/img/Brazilflag.svg'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles( theme => ({

    section: {
        width: '98vw',
        backgroundColor: 'black'
    },

    ScoreBoardContainer: {
        backgroundImage: 'url(http://gool:3000/static/media/background-stacts.a75a27c4.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '90vw',
        height: '98%',
        display: 'flex',
        margin: '10px 10px 10px 90px',
        boxSizing: 'border-box',
        padding: '30px 65px',
        position: 'relative',
        flexDirection: 'column',
    },

    headerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '50px',
        position: 'relative',

        '& .title': {
            color: '#FFFFFF',
            fontSize: '33px',
            fontWeight: '700',
            lineHeight: '44px',
            width: '342.2px',
            textAlign: 'left',
            position: 'relative',
            textTransform: 'uppercase',
            paddingLeft: '30px',
            font: 'Open Sans Condensed',
            marginBottom: '10px',

            '&::after': {
                content: '""',
                position: 'absolute',
                borderBottom: '1px solid white',
                width: '100%',
                top: '48px',
                left: '0',
            },

        },

        '& .subtitle': {
            color: '#FFFFFF',
            fontSize: '33px',
            fontWeight: '700',
            lineHeight: '44px',
            textAlign: 'left',
            position: 'relative',
            textTransform: 'uppercase',
            paddingLeft: '30px',
            font: 'Open Sans Condensed',
            marginBottom: '30px'
        },

        '& .titleIcon': {
            position: 'absolute',
            width: '20px',
            height: '20px',
            top: '12px',
            left: '4px',

        },
    },

    tableContainer: {
        width: '100%',
        border: '0.5px solid #4a4848',
        borderRadius: '5px',
        paddingBottom: '30px',
        display: 'flex',
        flexDirection:'column',

        '& .flexRow':{
            width: '100%',
            display: 'flex',
            boxSizing: 'border-box',
            justifyContent: 'space-between',
            padding:'30px',
            position:'relative',

            '&::after': {
                content: '""',
                position: 'absolute',
                borderBottom: '1px solid white',
                width: '96.5%',
                top: '61px',
                left: '30px',
            },

            '& p':{
                fontSize:'23px',
                fontWeight: '700',
                textAlign:'center',
                width: '50px',

                '&:first-child':{
                    width:'250px',
                    textAlign:'left'
                }
            }
            
        },

        '& .MuiAvatar-root img': {
            position: 'relative',
            top: '0',
            height: 'auto',
            width: 'calc(100% - 15px)',
        },

        '& .title': {
            color: '#FFFFFF',
            fontSize: '23px',
            fontWeight: '700',
            lineHeight: '44px',
            width: '264.2px',
            textAlign: 'left',
            position: 'relative',
            textTransform: 'uppercase',
            font: 'Open Sans Condensed',
            margin: '30px 0 0 82px',

            '&::after': {
                content: '""',
                position: 'absolute',
                width: '118%',
                top: '45px',
                left: '0',
                borderBottom: '0.5px solid white',

                '&:first-child':{
                    borderBottom: '1.5px solid white',
                }
            },
        },
    },

    flag: {
        width: '42.35px',
        height: '24px',
    }
}));

export const ScoreBoard = () => {
    const tableContent = [{name: 'Boca', PJ: '16', G:'8', E:'6', P: '2', GF:'6', GC:'2', DG:'6'}, {name: 'River', PJ: '13', G:'4', E:'2', P: '8', GF:'6', GC:'2', DG:'6'}, {name: 'Independiente', PJ: '16', G:'8', E:'6', P: '2', GF:'6', GC:'2', DG:'6'}, {name: 'Racing', PJ: '16', G:'8', E:'6', P: '2', GF:'6', GC:'2', DG:'6'}, {name: 'San lorenzo', PJ: '16', G:'8', E:'6', P: '2', GF:'6', GC:'2', DG:'6'}]
    const classes = useStyles();

    return (
        <section className={classes.section}>
            <div className={classes.ScoreBoardContainer}>
                <div className={classes.headerContainer}>
                    <TitleBall className='titleIcon'/>
                    <h1 className='title'>Score Board</h1>
                </div>
                <div className={classes.tableContainer}>
                    <div className='flexRow'>
                        <p>Equipo</p><p>PJ</p> <p>G</p> <p>E</p> <p>P</p> <p>GF</p> <p>GC</p> <p>DG</p> <p>PTS</p>
                    </div>
                    {tableContent && tableContent.map((team, index) => (
                    
                    <div className='flexRow'>
                        <p>{index} <TeamFlag className={classes.flag}/></p> <p>{team.PJ}</p> <p>{team.G}</p> <p>{team.E}</p> 
                        <p>{team.P}</p> <p>{team.GF}</p> <p>{team.GC}</p> <p>{team.DG}</p>
                        <p>{team.PTS}</p>
                    </div>
                    
                    ))}
                    
                </div>
            </div>
            
        </section>
    )
}

export default ScoreBoard;