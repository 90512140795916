// src/js/store/index.js
import { createStore } from "redux";
import rootReducer from "../reducers/index";

const saveToLocalStorage = (state) => {
  try {
    const tempState = { ...state };
    delete tempState.filterPage;

    const serializedState = JSON.stringify(tempState);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.log(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

const persistedState = loadFromLocalStorage();
const store = createStore(rootReducer, persistedState);

store.subscribe(() => saveToLocalStorage(store.getState()));
export default store;

/*
import { createStore } from "redux";
import rootReducer from "../reducers/index";
const store = createStore(rootReducer);
export default store;
*/
