import React from 'react'

import Shield from '../assets/img/shield.png';
import { makeStyles } from '@material-ui/core';
import Flag from '../assets/img/Flag_of_Italy.svg';
import { ReactComponent as TitleBall }  from '../assets/img/Shape.svg';

const useStyles = makeStyles( theme => ({

    section: {
        width: '98vw',
        backgroundColor: 'black'
    },
    fixtureContainer: {
        width: '90vw',
        height: '96%',
        display: 'flex',
        padding: '30px 65px',
        position: 'relative',
        backgroundSize: 'cover',
        flexDirection: 'column',
        boxSizing: 'border-box',
        backgroundRepeat: 'no-repeat',
        margin: '10px 10px 10px 90px',
        backgroundImage: 'url(http://gool:3000/static/media/background-stacts.a75a27c4.png)',
    },
    headerContainer: {
        display: 'flex',
        marginTop: '50px',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& .title': {
            color: '#FFFFFF',
            fontSize: '33px',
            width: '342.2px',
            fontWeight: '700',
            textAlign: 'left',
            lineHeight: '44px',
            paddingLeft: '30px',
            position: 'relative',
            marginBottom: '10px',
            textTransform: 'uppercase',
            fontFamily: 'Open Sans Condensed',
            '&::after': {
                left: '0',
                top: '48px',
                content: '""',
                width: '118%',
                position: 'absolute',
                borderBottom: '1px solid white',
            },
        },
        '& .subtitle': {
            color: '#FFFFFF',
            fontSize: '33px',
            fontWeight: '700',
            textAlign: 'left',
            lineHeight: '44px',
            paddingLeft: '30px',
            position: 'relative',
            marginBottom: '30px',
            fontFamily: 'Open Sans',
            textTransform: 'uppercase',
        },
        '& .titleIcon': {
            top: '12px',
            left: '4px',
            width: '20px',
            height: '20px',
            position: 'absolute',
        },
    },
    selectPlayersContainer: {
        width: '100%',
        borderRadius: '7.6px',
        paddingBottom: '30px',
        border: '0.5px solid #4a4848',
        '& .MuiAvatar-root img': {
            top: '0',
            height: 'auto',
            position: 'relative',
            width: 'calc(100% - 15px)',
        }
    },
    positionSelectContainer: {
        padding: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    flexAroundContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 auto',
        justifyContent: 'space-around',
        '@media (min-width: 1600px)': {
            width: '75%',
        }
    },
    playersSubContainer: {
        width: '96.5%',
        margin: '0 auto',
        overflowY: 'auto',
        maxHeight: '213px', 
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: '7px',
            background: '#4F4F4F',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '7px',
        }
    },
    selectHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .title': {
            color: '#FFFFFF',
            fontSize: '23px',
            width: '264.2px',
            fontWeight: '700',
            textAlign: 'left',
            lineHeight: '44px',
            position: 'relative',
            fontFamily: 'Open Sans',
            margin: '30px 0 0 82px',
            textTransform: 'uppercase',
            '&::after': {
                left: '0',
                top: '45px',
                content: '""',
                width: '118%',
                position: 'absolute',
                borderBottom: '1px solid white',
            },
        },
        '& .eventsButton': {
            width: '224px',
            border: 'none',
            color: 'white',
            outline: 'none',
            fontSize: '12px',
            height: '38.52px',
            fontWeight: '700',
            lineHeight: '40px',
            textAlign: 'center',
            transition: 'all .2s',
            borderRadius: '1.81px',
            margin: '30px 27px 0 0',
            backgroundColor: '#074682',
            textTransform: 'uppercase',
            transform: 'translateY(11%)',
        },
        '& .eventsButton:hover': {
            transform: 'scale(1.1)',
        },
    },
    middleBar: {
        width: '10px',
        fontSize: '30px',
        alignSelf: 'center',
    },
    containerFixtureTeam: {
        display: 'flex',
        cursor: 'pointer',
        fontSize: '15.2px',
        alignItems: 'center',
        flexDirection: 'column',
        textTransform: 'capitalize',
        '& .team-fixture': {
            width: '88px',
            height: '50px',
        }
    },
    journeysContainer: {
        width: '100%',
    },
    teamMatchcontainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        margin: '20px auto',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .flag': {
            width: 'auto',
            height: '50px',
            padding: '10px',
            maxWidth: '50px',
            minWidth: '50px',
            cursor: 'pointer',
            borderRadius: '50%',
        }
    },
    scoreContainer: {
        display: 'flex',
        borderRadius: '3px',
        background: 'rgba(216, 216, 216, 0)',
        color: '#fff',
        width: '52px',
        height: '50px',
        justifyContent:'center',
        alignItems: 'center',
        border: '1px solid white',
        '& input':{
            backgroundColor: 'transparent',
            width: '10px',
            border: 'none',
            color: 'white',
            fontSize: '17px',
        },
        '& input::-webkit-outer-spin-button':{
            appearance: 'none',
            margin: '0',
        },
        '& input::-webkit-inner-spin-button':{
            appearance: 'none',
            margin: '0',
        }
    },
    nameTeamMatchContainer:{
        width: '25%',
        color: '#fff',
        height: '49px',
        display: 'flex',
        borderRadius: '7px',
        alignItems: 'center',
        justifyContent:'center',
        background: 'rgba(216, 216, 216, 0.2)',
    },
}))

export const CompetitionFixtureComponent = (props) => {

    const classes = useStyles()
    const {competition, fixture} = props;

    const getTeamsInGenerateFixture = () => {

        if(competition){
            return competition.team_ids.map( (team, index) => {
                return  <div key={index} className={classes.containerFixtureTeam}>
                            <img className="team-fixture" src={Flag} alt={team.club_name} />
                            <p>{team.club_name}</p>
                        </div>
            })
        }
    }

    const showMatches = (journeysIndex) => {
        return fixture[journeysIndex].map( (match, index) => {
            //console.log(match)
            return  <div key={index} className={classes.teamMatchcontainer}>
                        <div className={classes.nameTeamMatchContainer}><p>{match[0].club_name}</p></div>
                        <img style={{border: '2px solid red'}} className="flag" src={ match[0].img_file ? match[0].img_file : Shield } alt={match[0].club_name}/>
                        <div className={classes.scoreContainer}>
                            <input defaultValue='0' type="number"/>
                        </div>

                        <p className={classes.middleBar}>-</p>

                        <div className={classes.scoreContainer}>
                            <input defaultValue='0' type="number"/>
                        </div>
                        <img style={{border: '2px solid red'}} className="flag" src={ match[1].img_file ? match[1].img_file : Shield } alt={match[1].club_name} />
                        <div className={classes.nameTeamMatchContainer}>{match[1].club_name}</div>
                    </div>
        })
    }

    const showFixture = () => {

        return fixture.map( (journeys, key) => {
            return  <div key={key} className={classes.journeysContainer} >
                        <h3>journey {key + 1}</h3>
                        { showMatches(key) }
                    </div>
        })
    }

    return (
    <section className={classes.section}>
        { competition && 
            <div className={classes.fixtureContainer}>
                <div className={classes.headerContainer}>
                    <TitleBall className='titleIcon'/>
                    <h1 className='title'>competition data</h1>
                    <h2 className='subtitle'>{competition.name}</h2>
                </div>





                { false && 
                <div className={classes.selectPlayersContainer}>
                    <div className={classes.selectHeader}>       
                        <h2 className='title'>generate fixture</h2>
                        <button className='eventsButton'>Randomize Teams</button>
                    </div>
                    <div className={classes.positionSelectContainer}>
                        <div className={classes.flexBetweenContainer}>
                            {/* { getRolestring('general') } posición de jugadores */}
                        </div>

                        <div className={classes.clearSelectAllPlayers}>
                            {/* <div className={classes.selectAllNone} onClick={ () => handleSelectAll(false) }><span>-</span></div> */}
                            {/* <div className={classes.selectAllNone} onClick={ () => handleSelectAll(true) }><span>+</span></div> */}
                        </div>
                    </div>

                    <div className={"cols sm-4 md-6 lg-8 " + classes.playersSubContainer} >
                        {getTeamsInGenerateFixture()}
                    </div>
                </div>
                }




                <div className={classes.flexAroundContainer}>
                    { showFixture() }
                </div>

            </div>
        }
    </section>
    )
}
export default React.memo(CompetitionFixtureComponent);