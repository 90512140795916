import React from "react";

import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  Radar,
  Tooltip,
  Legend,
  PolarRadiusAxis,
} from "recharts";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chartBar: {
    background: "white",
    position: "relative",
    "& > svg": {
      margin: "15px auto",
    },
    transform: "translateX(45px)",
    "& .recharts-default-tooltip": {
      backgroundColor: "transparent !important",
    },
  },
  chart: {
    marginTop: "20px",
    width: "100%",
    marginLeft: "50px",
  },
}));

const GetGraphyCardPlayer = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.chart}>
      <RadarChart outerRadius={90} data={data} width={420} height={250}>
        <PolarGrid gridType="circle" />
        <PolarAngleAxis
          axisLine={false}
          dataKey="prueba" //rate
          tick={{ fill: "white" }}
          domain={[0, 5]}
        />
        <PolarRadiusAxis tick={false} tickCount={6} axisLine={false} domain={[0, 5]} />
        <Radar
          dataKey="avg"
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.6}
        />
        <Tooltip />
        <Legend />
      </RadarChart>
    </div>
  );
};
export default React.memo(GetGraphyCardPlayer);
