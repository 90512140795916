import * as ActionTypes from "./types";
import { GET_ALL_EVENTS } from "./types";

export function create_player(payload) {
  return { type: ActionTypes.CREATE_PLAYER, payload };
}

export function unset_player() {
  return { type: ActionTypes.RESET_PLAYER };
}

export function update_player(payload) {
  return { type: ActionTypes.UPDATE_PLAYER, payload };
}

export function update_user(payload) {
  return { type: ActionTypes.UPDATE_USER, payload };
}

export function logout_user(payload) {
  return { type: ActionTypes.LOGOUT_USER, payload };
}

export function update_analysis_video(payload) {
  return { type: ActionTypes.UPDATE_ANALYSIS_VIDEO, payload };
}

export function update_info_teams(payload) {
  return { type: ActionTypes.UPDATE_INFO_TEAMS, payload };
}

export const modifyFormHandler = (event) => {
  return { type: ActionTypes.MODIFY_FORM, payload: event };
};

export const modifyFormRefereeHandler = (referee) => {
  return { type: ActionTypes.MODIFY_REFEREE_CHECKED, payload: referee };
};

export const login_modal = (payload) => {
  return { type: ActionTypes.LOGIN_MODAL, payload: payload };
};

export const modifyFormFieldHandler = (field) => {
  console.log(field);
  return {
    type: ActionTypes.MODIFY_FIELD_CHECKED,
    payload: field,
  };
};

export const modifyFormDirectorHandler = (director) => {
  console.log("Este es el director mandado: ", director);
  return {
    type: ActionTypes.MODIFY_DIRECTOR,
    payload: director,
  };
};

export const addDirectorHandler = (director) => {
  console.log(director);
  return {
    type: ActionTypes.ADD_DIRECTOR,
    payload: director,
  };
};

export const addEventHandler = (event) => {
  return { type: ActionTypes.ADD_EVENT, payload: event };
};

export const addEventLogoHandler = (logo) => {
  console.log(logo);
  return {
    type: ActionTypes.ADD_EVENT_LOGO,
    payload: logo,
  };
};

export const deleteEventHandler = (index) => {
  return { type: ActionTypes.DELETE_EVENT, payload: index };
};

export const modifyFormCategoryHandler = (category) => {
  console.log(category);
  return {
    type: ActionTypes.ADD_CATEGORY,
    payload: category,
  };
};

export const getAllEventHandler = (events) => {
  return { type: GET_ALL_EVENTS, payload: events };
};

export const getAllDirectorHandler = (directors) => {
  return {
    type: ActionTypes.GET_ALL_DIRECTORS,
    payload: directors,
  };
};

export const getAllCompetitionsHandler = (competitions) => {
  return {
    type: ActionTypes.GET_ALL_COMPETITIONS,
    payload: competitions,
  };
};

export const addCompetitionHandler = (event) => {
  console.log(event);
  return {
    type: ActionTypes.ADD_COMPETITION,
    payload: event,
  };
};

export const getAllCountriesNames = (countrieName) => {
  return {
    type: ActionTypes.GET_ALL_COUNTRIES_NAMES,
    payload: countrieName,
  };
};

export const saveFilter = (filterName, data) => {
  return {
    type: ActionTypes.SAVE_FILTER,
    payload: {
      filterName,
      value: data,
    },
  };
};

export const clearFilter = () => {
  return {
    type: ActionTypes.DELETE_FILTER,
    payload: null,
  };
};
