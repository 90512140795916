import React from 'react';

import { useStyle } from '../../assets/MakeStyles';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

const GraphyGpexeSummary = (props) => {

    const {data, dataKey} = props;
    const classes = useStyle();

    //console.log(dataKey, data)

    const getUnit = (unit) => {

        switch(unit) {
            case 'dur':
                return `. seg`
            case 'dist / sp Z5':
                return `. m`
            case 'EDI':
                return `. %`
            case 'MPE':
                return `. n`
            case 'MPE rec av p':
                return `. %`
            case 'MPE rec av t':
                return `. seg`
            case 'dist':
                return `. m`
            default:
                return null
          }
    }

    const getUnitName = (unit) => {

        switch(unit) {
            case 'dur':
                return `duration`
            case 'dist / sp Z5':
                return `distance / sp Z5`
            case 'EDI':
                return `eq distance index`
            case 'MPE':
                return `met power events`
            case 'MPE rec av p':
                return `. met av power events`
            case 'MPE rec av t':
                return `MPE rec avg time`
            case 'dist':
                return `distance`
            default:
                return null
          }
    }

    function CustomTooltip({ payload, label, active }) {
        
        if (active && payload) {
            return (
                <div className={classes.customTooltip}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <p className="label">{payload[0].payload.athlete}</p>
                    </div>
                    <p className="label"><b>{getUnitName(payload[0].name)}: </b> {payload[0].value + getUnit(payload[0].name)}</p>

                </div>
            )
        }
        return null;
    }

    return (
    <BarChart
        width={900}
        height={400}
        data={data}
        margin={{
            top: 20, right: 30, left: 20, bottom: 5,
        }}
    >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="athlete" />
        <YAxis 
            yAxisId="left"
            orientation="left"
            stroke="#8884d8"
            unit={getUnit(dataKey)}
            domain={[0, Math.ceil( Math.max.apply(Math, data.map(function(o) { return o[dataKey]; })) + parseInt( Math.max.apply( Math, data.map(function (o) { return o[dataKey]; } ) ) * 0.10 ) ) ]}
        />
        <Tooltip cursor={{ strokeDasharray: '3 3' }}  content={<CustomTooltip />} />
        <Legend />
        <Bar yAxisId="left" dataKey={dataKey} fill="#8884d8" />
    </BarChart>
    );
};
export default GraphyGpexeSummary;