import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { update_user } from "../../actions/index";
import ReactPlayer from "react-player";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import Tooltip from "@material-ui/core/Tooltip";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import {
  getWPlayerById,
  resourceApiEndPoint,
  getCountries,
  getSponsor,
  getUserVoted,
  getStatsGPSById,
  getAnalysisVideoByUsername,
} from "../../endpoints";
import GlobalLoading from "../../components/accessories/GlobalLoading";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Container, Grid, Hidden } from "@material-ui/core";

import NavCardPlayer from "../../components/NavCardPlayer/NavCardPlayer";
import BasicPlayerInfo from "../../components/BasicPlayerInfo/BasicPlayerInfo";
import BasicPlayerIndicators from "../../components/BasicPlayerIndicators/BasicPlayerIndicators";
import image from "../../assets/img/fondo.png";
import MID from "../../assets/img/MID.png";
import GPK from "../../assets/img/GPK.png";
import FWD from "../../assets/img/FWD.png";
import DEF from "../../assets/img/DEF.png";
import notposition from "../../assets/img/notposition.png";
import notvideo from "../../assets/img/notvideo2.jpeg";

import GetGraphyCardPlayer from "../../components/charts/GetGraphyCardPlayer";
import PlayerAvatar from "../../assets/img/player-avatar.png";
import BasicPlayerCards from "../../components/BasicPlayerCards/BasicPlayerCards";

import { translate } from "../../lang/lang";

const useStyles = makeStyles(() => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    fontFamily: "unset",
  },
  container: {
    paddingTop: "20px",
    paddingBottom: "20px",
    fontFamily: "Bebas-Bold",
  },
  img: {
    height: "90%",
    width: "100%",
    position: "relative",
    marginTop: "10px",
    //marginLeft: "83px",
  },
  imgPhoto: {
    height: "70%",
    width: "70%",
    position: "relative",
    //marginLeft: "83px",
  },
  imgField: {
    position: "relative",

    width: "80%",
    height: "80%",
  },
  bg: {
    position: "relative",
    width: "100%",
    // background: `url(${image})`,
    backgroundImage: `url(${image})`,
    //backgroundRepeat: "no-repeat",
    //backgroundAttachment: "fixed",
    //backgroundSize: "cover",
    "& .langs": {
      top: "5px",
      right: "15px",
      display: "flex",
      position: "absolute",
      "& span": {
        padding: "0 5px",
        cursor: "pointer",
        fontSize: "14px",
      },
      "& .selected": {
        color: "#6cffe6",
      },
    },
  },
  avatarContainer: {
    textAlign: "center",
    padding: "15px",
  },
  imgAvatar: {
    width: "70%",
    top: "15px",
    left: "7px",
  },
  notvideo: {
    height: "60%",
    width: "60%",
    position: "relative",
    marginTop: "20px",
    // margin: "auto !important",
  },
  notvideocont: {
    textAlign: "center",
  },
  flag: {
    width: "6%",
    height: "2%",
  },
  icon: {
    marginLeft: "20px",
    cursor: "pointer",
  },
}));

const PlayerCard = (props) => {
  const id = props.match.params.id;
  const avatarUrl = PlayerAvatar;
  const { user, set_update_user } = props;
  const langs = ["en", "es", "it"];
  const role = user.roleName;
  const [player, setPlayer] = useState({
    photo: "",
    validPhoto: true,
  });
  const [matches, setMatches] = useState([]);
  const [sponsor, setSponsor] = useState([]);
  const [countries, setCountries] = useState([]);
  const [userVoted, setUserVoted] = useState([]);
  const [userStats, setUserStats] = useState([]);
  const [videoAnalysis, setVideoAnalysis] = useState([]);
  const [globalLoading, setGlobalLoading] = useState(true);
  const playerId = props.match.params.id;
  const classes = useStyles();
  const [view, setView] = useState(0);
  const [scroll, setScroll] = useState(0);
  const [url, setURL] = useState("");
  const name = "GLEIBER ENRIQUE GRANADO ALDANA - GEGA";
  let content;
  let field;

  const changeLang = (lan) => {
    set_update_user({ ...user, lang: lan });
  };

  useScrollPosition(({ prevPos, currPos }) => {
    //console.log(currPos.y);
    setScroll(currPos.y);
  });

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  const buildLangSelected = () => {
    return langs.map((la, index) => {
      return (
        <p key={`lang-${index}`}>
          <span
            className={
              user.lang
                ? user.lang === la
                  ? "selected"
                  : ""
                : la === "en"
                ? "selected"
                : ""
            }
            onClick={() => changeLang(la)}
          >
            {la}
          </span>
          {index === langs.length - 1 ? "" : "|"}
        </p>
      );
    });
  };

  const getCountryName = (countryId) => {
    if (countryId) {
      let countrySelected = countries.filter((country) => {
        return country.wid === countryId;
      });
      return countrySelected[0] ? (
        <span className={classes.country}>
          {countrySelected[0].name}{" "}
          <img
            alt="flag"
            src={`${resourceApiEndPoint()}/${countrySelected[0].img_file}`}
            className={classes.flag}
          ></img>
        </span>
      ) : null;
    } else {
      return null;
    }
  };

  const findUserVoted = async () => {
    const response = await getUserVoted(playerId);
    const obj = await response.data;
    const newObj = Object.keys(obj).map((key) => {
      obj[key].prueba = translate(user.lang, obj[key].rate);
      return obj[key];
    });
    setUserVoted(newObj);
  };

  const getUserStatsGPS = async () => {
    const response = await getStatsGPSById(playerId);
    const obj = await response.data;
    setUserStats(obj);
  };

  const getVideoAnalysis = async () => {
    const response = await getAnalysisVideoByUsername(user.username, {
      wplayer_id1: id,
    });
    if (response.status === 200) {
      let confirmVideo = true;
      response.data.response.map(async (match) => {
        if (confirmVideo) {
          match.analysisVideoEvents.length > 0
            ? match.analysisVideoEvents.map((item) => {
                item.event_action.length > 0
                  ? item.event_action.map((event) => {
                      if (event.wplayer_id === id) {
                        setURL(match.video_filename);
                        confirmVideo = false;
                      }
                    })
                  : console.log("");
              })
            : console.log("");
        }
      });
    }
    const obj = await response.data;
    setVideoAnalysis(obj);
  };
  useEffect(() => {
    const findPlayer = async () => {
      let response = await getWPlayerById(playerId);
      if (response.status === 200) {
        setPlayer({
          ...response.data.response,
          validPhoto: response.data.response.img_file ? true : false,
          preview: response.data.response.img_file
            ? `${resourceApiEndPoint()}/${response.data.response.img_file}`
            : "",
        });
        getCountries().then((resp) => {
          setCountries(resp.data.response);
        });

        getSponsor().then((response) => {
          setSponsor(response.data.response);
          setGlobalLoading(false);
        });
      } else {
        console.log("error");
      }
    };
    findPlayer();
    //findUserVoted();
    getUserStatsGPS();
    getVideoAnalysis();
  }, []);

  useEffect(() => {
    findUserVoted();
  }, [user.lang]);

  useEffect(() => {
    document.body.classList.add("card-player");
    return () => {
      document.body.classList.remove("card-player");
    };
  });
  console.log(url);
  let metrics = userStats.profile_metrics ? userStats.profile_metrics : [];

  switch (player.rolestring) {
    case "MID":
      field = MID;
      break;
    case "DEF":
      field = DEF;
      break;
    case "FWD":
      field = FWD;
      break;
    case "GKP":
      field = GPK;
      break;
    default:
      field = notposition;
      break;
  }
  content = (
    <>
      {globalLoading && <GlobalLoading />}
      <Hidden only={["sm", "md"]}>
        <Grid item xs={2} md={2} lg={2} className={classes.avatarContainer}>
          {!player.validPhoto ? (
            <img
              src={"http://gool:3000" + avatarUrl}
              alt="player"
              className={classes.imgAvatar}
            />
          ) : (
            <img
              src={player.preview}
              alt="player"
              className={classes.imgAvatar}
            />
          )}
        </Grid>
      </Hidden>
      <Grid item xs={12} md={8} lg={7}>
        <BasicPlayerInfo
          user={user}
          player={player}
          sponsor={sponsor}
          getCountryName={getCountryName}
          name={name}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <img className={classes.imgField} src={field} alt="" />
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <BasicPlayerIndicators
          user={user}
          player={player}
          sponsor={sponsor}
          getCountryName={getCountryName}
          name={name}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <BasicPlayerCards props={playerId} metrics={metrics} user={user} />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        {url !== "" ? (
          <ReactPlayer
            url={url}
            width={"100%"}
            height={"90%"}
            style={{ marginTop: "20px" }}
          />
        ) : (
          <div className={classes.notvideocont}>
            <img src={notvideo} alt="not video" className={classes.notvideo} />
          </div>
        )}
      </Grid>
      <Hidden only={["sm", "md"]}>
        <Grid item xs={12} md={12} lg={6}>
          <GetGraphyCardPlayer props={playerId} data={userVoted} />
        </Grid>
      </Hidden>
    </>
  );

  return (
    <div className={classes.bg}>
      <section>
        <div className="langs">
          {buildLangSelected()}
          {role !== "Admin" && role !== "Directivo" ? (
            <LightTooltip title={translate(user.lang, "sign out")}>
              <ExitToAppIcon
                className={classes.icon}
                onClick={() => props.handleLogout()}
              />
            </LightTooltip>
          ) : (
            ""
          )}
        </div>
        {props.children}
      </section>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <NavCardPlayer
              user={user}
              view={view}
              setView={setView}
              {...props}
            />
          </Grid>
          {content}
        </Grid>
      </Container>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    set_update_user: (user) => dispatch(update_user(user)),
  };
}

export default connect(null, mapDispatchToProps)(PlayerCard);
