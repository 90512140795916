import React, {useEffect} from 'react';

import Modal from '@material-ui/core/Modal';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Box from '@material-ui/core/Box';


import { Radar, RadarChart, PolarGrid, Legend, PolarRadiusAxis } from 'recharts';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }


const useStyles = makeStyles( theme => ({
    paper: {
        top: '50%',
        left: '50%',
        padding: '15px',
        minWidth: '80vw',
        position: 'absolute',
        backgroundColor: '#19191D',
        transform: 'translate(-50%, -50%)',
        '&:focus': {
            outline: 'none'
        }
    },
    root: {
        backgroundColor: '#17181B',
        width: '100%',
        '& .MuiPaper-root': {
            background: '#17181b',
        },
        '& .MuiButtonBase-root': {
            color: '#ffffff',
        },
        '& .MuiTab-textColorPrimary': {
            color: '#959597',
        },
        '& .Mui-selected': {
            color: '#ffffff',
        },
        '& .MuiTabs-indicator': {
            background: '#08BBEE',
        }
        
    },
    chartContainer: {
        display: 'flex',
        background: '#17181B',
        justifyContent: 'center'}
}));

const RadarGraphyRates = (props) => {

    const theme = useTheme();
    const classes = useStyles();
    const {open, handleClose, stats} = props;

    const handleOnClose = () => {
        handleClose(false);
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    //console.log(stats)

    const buildChart = (data) => {

        let dataTemp = [];
        let color = [];

        let charts = data.map( (skill, indexParent) => {

            //console.log((indexParent + 1), stats.length )

            if( (indexParent + 1) === data.length ){
                dataTemp[indexParent] = {...dataTemp[indexParent], [skill.name]: skill.rate }
                dataTemp[0] = {...dataTemp[0], [skill.name]: skill.rate }
            }else if(indexParent === 0){
                dataTemp.push({ subject: skill.name, [skill.name]: skill.rate, fullMark: 150 })
                dataTemp.push({ subject: data[(indexParent + 1)].name, [skill.name]: skill.rate, fullMark: 150 })
            }else{
                dataTemp[indexParent] = {...dataTemp[indexParent], [skill.name]: skill.rate }
                //console.log(indexParent, data)
                dataTemp.push({ subject: data[(indexParent + 1)].name, [skill.name]: skill.rate, fullMark: 150 })
            }
            
            color[0] = Math.floor(Math.random() * 256);
            color[1] = Math.floor(Math.random() * 256);
            color[2] = Math.floor(Math.random() * 256);
            color[3] = `rgb(${color[0]}, ${color[1]}, ${color[2]} )`
            color[4] = `rgb(${color[0]}, ${color[1]}, ${color[2]}, 0.5 )`

            let gradient = <defs key={indexParent}>
                                <linearGradient id={`gradient-${skill.name.replace(/ /g, "")}`} x1="100%" y1="25%" x2="25%" y2="100%">
                                    <stop offset="0%" stopColor={color[3]} stopOpacity={1}/>
                                    <stop offset="100%" stopColor={color[3]} stopOpacity={0}/>
                                </linearGradient>
                            </defs>

            let radar = <Radar key={indexParent} name={`${skill.name}`} dataKey={`${skill.name}`} stroke={color[4]} fill={`url(#gradient-${skill.name.replace(/ /g, "")})`} fillOpacity={0.6} strokeWidth="3" />

            return {gradient: gradient, radar: radar};
        });

        return  <div style={{background: '#17181B', display: 'flex', justifyContent: 'center'}}>

                        <RadarChart cx={200} cy={200} outerRadius={180} width={400} height={480} data={dataTemp} >

                        { charts.map( gradient => {
                            return gradient.gradient
                        }) }

                        <PolarGrid strokeDasharray="2 2" fillOpacity={0.2} />
                        <PolarRadiusAxis angle={90} domain={[0, 5]} stroke="#000000" ticks={[1, 2, 3, 4, 5]} tick={{ fill: 'white' }} />
                        
                        { charts.map( radar => {
                            return radar.radar
                        }) }

                        <Legend wrapperStyle={{color: 'white'}} />
                    </RadarChart>
                </div>

    }

    useEffect( () => {


    }, [open]);

    return (

        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            onClose={handleOnClose}
            open={open}
        >
            <div className={classes.paper}>

                { open && stats && 
            
                <div className={classes.root}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Principal skills"  />

                            {   stats.map( (skill, key) => {
                                    return <Tab key={skill.name + '-' + key} label={skill.name}  />
                                })
                            }

                        </Tabs>
                    </AppBar>

                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>

                            {buildChart(stats)}

                        </TabPanel>

                        {   stats.map( (skill, key) => {
                                return  <TabPanel key={skill.name + '-' + key} value={value} index={(key + 1)} dir={theme.direction}>
                                            { buildChart(skill.skills) }
                                        </TabPanel>
                            })
                        }

                    </SwipeableViews>
                </div>
                }
            </div>
        </Modal>
    );
};
export default React.memo(RadarGraphyRates);