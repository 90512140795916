import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles, Modal, Fade, Grid, Input } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import searchIcon from "../assets/img/searchBold.svg";

import {
  getFilesByFolder,
  addFile,
  deleteFile,
  getFoldersByUserId,
  addFolder,
  deleteFolder,
  getPlayersForFiles,
  getFileById,
  getFileByUserId,
  getTeambyClubId,
  getPlayersByTeamId,
  getAllPlayers,
} from "../endpoints";
import Paginator from "../components/accessories/Paginator";
import GlobalLoading from "../components/accessories/GlobalLoading";
import ListFiles from "../components/ListFiles/ListFiles";
import Upload from "../components/accessories/upload/upload";

import { translate } from "../lang/lang";
import SnackBarDelete from "../components/snackbarDelete";
import NavCardPlayer from "../components/NavCardPlayer/NavCardPlayer";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    "& h2": {
      fontSize: 40,
      fontFamily: "DINCondensed",
    },
    "& p": {
      fontSize: 15,
      marginTop: 5,
      maxWidth: "50%",
      lineHeight: 1.6,
      fontFamily: "Montserrat-Medium",
    },
  },
  handlers: {
    marginTop: 20,
    display: "flex",
    justifyContent: "space-between",
  },
  searchContainer: {
    width: "50%",
    display: "flex",
    "& .inputField": {
      width: 180,
      color: "#000",
      height: "44px",
      outline: "none",
      fontSize: "15px",
      borderRadius: "4px",
      padding: "0 0.5rem",
      position: "relative",
      transition: "all .1s",
      boxSizing: "border-box",
      backgroundColor: "#fff",
      border: "solid 2px #ffffff",
      fontFamily: "Montserrat-Medium",
      "&::placeholder": {
        color: "#000",
        fontSize: "15px",
        fontFamily: "Montserrat-Medium",
      },
      "& input": {
        width: "100%",
        border: "none",
        height: "100%",
        outline: "none",
        fontSize: "15px",
        background: "transparent",
        fontFamily: "Montserrat-Medium",
        "&::placeholder": {
          color: "#000",
          fontSize: "15px",
          fontFamily: "Montserrat-Medium",
        },
      },
      marginRight: 20,
    },
  },
  icon: {
    right: 6,
    top: "50%",
    zIndex: "9",
    width: "16px",
    height: "16px",
    position: "absolute",
    transform: "translateY(-50%)",
  },
  createBtnContainer: {
    "& button": {
      width: 180,
      height: 44,
      fontSize: 15,
      color: "black",
      border: "none",
      borderRadius: 4,
      cursor: "pointer",
      fontWeight: "700",
      fontFamily: "Montserrat",
      backgroundColor: "#6cffe6",
      marginRight: "10px",
    },
  },
  close: {
    top: 10,
    right: 10,
    width: 32,
    height: 32,
    opacity: 0.3,
    color: "black",
    display: "flex",
    cursor: "pointer",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
  },
  formTitle: {
    fontSize: 28,
    color: "black",
    fontFamily: "Poppins",
  },
  form: {
    width: "100%",
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    marginRight: "5px",
    "& input": {
      padding: 17,
      borderRadius: 4,
      marginBottom: 13,
      fontFamily: "Poppins",
      border: "1px solid #d3d3d3",

      "&::placeholder": {
        color: "#a5a5a5",
        fontFamily: "Poppins",
      },
      "&[type='submit']": {
        height: 50,
        padding: 0,
        width: "100%",
        border: "none",
        display: "flex",
        cursor: "pointer",
        marginLeft: "auto",
        fontWeight: "bold",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#6cffe6",
      },
    },
    "& select": {
      padding: 17,
      borderRadius: 10,
      marginBottom: 13,
      fontFamily: "Poppins",
      border: "1px solid #d3d3d3",
      "&::placeholder": {
        color: "#a5a5a5",
      },
    },
  },
  modalContainer: {
    width: 400,
    top: "50%",
    left: "50%",
    padding: 37,
    maxWidth: "70%",
    borderRadius: 6,
    position: "absolute",
    backgroundColor: "white",
    transform: "translate(-50%,-50%)",
  },
  ul: {
    color: "#000000",
  },
  btnsecondary: {
    backgroundColor: "#131319 !important",
    border: "2px solid #6BFFE6 !important",
    color: "#6BFFE6 !important",
  },
  multiSelect: {
    marginBottom: "10px",
    borderRadius: 4,
  },
  label: {
    fontFamily: "Poppins !important",
    fontSize: "14px",
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FilesFolders = (props) => {
  const { user } = props;
  const clubs = user.wclubs;

  let query = useQuery();
  let folderList = [];
  const classes = useStyles();
  const [cb, setCb] = useState(true);
  const [uploadFile, setUploadFile] = useState({
    name: "",
    observations: "",
    upfile: null,
    folderId: "",
  });
  const initialState = {
    name: "",
    observations: "",
    upfile: null,
    folderId: "",
  };
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState("none");
  const [dataTable, setDataTable] = useState([]);
  const [folder, setFolder] = useState("");
  const [folders, setFolders] = useState([]);
  const [page, setPage] = useState(query.get("page") ? query.get("page") : 1);
  const [totalFiles, setTotalFiles] = useState(0);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [openAddFolder, setopenAddFolder] = useState(false);
  const [openAddFile, setOpenAddFile] = useState(false);
  const [file, setFile] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [msg, setMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [id, setId] = useState(null);
  const [search, setSearch] = useState("");
  const [view, setView] = useState(4);
  const [players, setPlayers] = useState([]);
  const [filesByUser, setFilesByUser] = useState([]);

  const idParams = props.match.params.id;

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const clearFileForm = () => {
    setUploadFile(initialState);
    setFile(null);
    setOpenAddFile(!openAddFile);
  };

  //// CRUD FILES
  ///READ
  useEffect(() => {
    setGlobalLoading(true);
    getFilesByFolder(selectedFolder)
      .then((res) => {
        console.log(res);
        setDataTable(res.data.data);
        setGlobalLoading(false);
      })
      .catch((err) => {
        setGlobalLoading(false);
      });
  }, [selectedFolder, cb]);

  ////CREATE
  const handleSubmitFile = (event) => {
    setGlobalLoading(true);
    event.preventDefault();
    const uploadFileData = { ...uploadFile, users: users };
    addFile(uploadFileData)
      .then((res) => {
        clearFileForm();
        setCb(!cb);
        setGlobalLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setGlobalLoading(false);
      });
  };

  ////DELETE
  const removeFile = (id) => {
    setGlobalLoading(true);
    deleteFile(id)
      .then((res) => {
        setId(null);
        setCb(!cb);
        setGlobalLoading(false);
      })
      .catch((err) => {
        setGlobalLoading(false);
        console.log(err);
      });
  };

  //// CRUD FOLDERS
  ////READ
  useEffect(() => {
    setGlobalLoading(true);
    getFoldersByUserId(user._id)
      .then((res) => {
        handleData(res.data.data);
        setGlobalLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setGlobalLoading(false);
      });
  }, [cb]);

  const handleData = (data) => {
    if (data !== undefined) {
      for (var key in data) {
        folderList.push({
          id: key,
          name: data[key].name,
        });
      }
      setSelectedFolder(folderList[0].id);
      setFolders(folderList);
    } else {
      console.log(data);
    }
  };

  ///CREATE / UPDATE TODO: Hace falta un endpoint que retorne 1 sola carpeta por ID
  const handleSubmitFolder = (event) => {
    event.preventDefault();
    setGlobalLoading(true);
    addFolder({ name: folder })
      .then((res) => {
        setFolder("");
        setopenAddFolder(false);
        setCb(!cb);
        setGlobalLoading(false);
      })
      .catch((err) => {
        setGlobalLoading(true);
        console.log(err);
      });
  };

  ////DELETE
  const removeFolder = (id) => {
    setGlobalLoading(true);
    deleteFolder(id)
      .then((res) => {
        setGlobalLoading(false);
        setMsg(translate(user.lang, "Successful process"));
        setId(null);
        setCb(!cb);
        sleep(2000).then(() => setSnackbarOpen(false));
      })
      .catch((err) => {
        setGlobalLoading(true);
      });
  };

  const getTeamsByClub = () => {
    clubs.map((club) => {
      return getTeambyClubId(club._id)
        .then((res) => {
          setTeams(res.data.response);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const getPlayerByTeam = () => {
    setGlobalLoading(true);
    if (team !== "none") {
      getPlayersByTeamId(team)
        .then((res) => {
          setPlayers(res.data.response !== undefined ? res.data.response : []);
          setGlobalLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setGlobalLoading(false);
        });
    } else {
      getAllPlayers()
        .then((res) => {
          setPlayers(res.data.response !== undefined ? res.data.response : []);
          setGlobalLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setGlobalLoading(false);
        });
    }
  };

  useEffect(() => {
    getTeamsByClub();
  }, []);

  useEffect(() => {
    if (idParams !== undefined) {
      getFileByUserId(idParams).then((res) => {
        const response = res.data.response;
        response.map((files) => {
          getFileById(files.file_id).then((res) => {
            setFilesByUser((prev) => [...prev, res.data.data]);
          });
        });
      });
    }
  }, []);

  useEffect(() => {
    getPlayerByTeam();
  }, [team]);
  return (
    <>
      {idParams !== undefined ? (
        <NavCardPlayer {...props} view={view} setView={setView} />
      ) : null}

      <section className={classes.header}>
        <h2>{translate(user.lang, "file management")}</h2>
        <p>{translate(user.lang, "file description")}</p>
      </section>
      <section className={classes.handlers}>
        <div className={classes.searchContainer}>
          {idParams !== undefined ? (
            <></>
          ) : (
            <select
              required
              className="inputField"
              id="role"
              value={selectedFolder}
              onChange={(event) => setSelectedFolder(event.target.value)}
              // onChange={e => handleChange(e.target)}
              // disabled={categories.length === 0}
            >
              {folders.map((folder) => {
                return (
                  <option key={folder.id} value={folder.id}>
                    {folder.name}
                  </option>
                );
              })}
            </select>
          )}

          <div className="inputField">
            <input
              name="query"
              placeholder={translate(user.lang, "search")}
              id="query"
              autoComplete="off"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
            <img className={classes.icon} src={searchIcon} alt="search" />
          </div>
        </div>
        {idParams !== undefined ? (
          <></>
        ) : (
          <div className={classes.createBtnContainer}>
            <button
              onClick={() => setopenAddFolder(!openAddFolder)}
              className={classes.btnsecondary}
            >
              {translate(user.lang, "add folder")}
            </button>
            <button onClick={() => setOpenAddFile(!openAddFile)}>
              {translate(user.lang, "add file")}
            </button>
          </div>
        )}
      </section>
      {globalLoading ? (
        <GlobalLoading />
      ) : (
        <ListFiles
          files={dataTable}
          filesByUser={filesByUser}
          user={props.user}
          removeFile={removeFile}
          id={id}
          setId={setId}
          msg={translate(user.lang, "remove file msg")}
          search={search}
          idParams={idParams}
        />
      )}
      <section>
        <Paginator
          totalItems={totalFiles}
          handleSetFilters={(value) => {
            setPage(value);
          }}
        />
      </section>

      <Modal open={openAddFolder} closeAfterTransition>
        <Fade in={openAddFolder}>
          <div className={classes.modalContainer}>
            <div
              className={classes.close}
              onClick={() => setopenAddFolder(!openAddFolder)}
            >
              X
            </div>
            <h2 className={classes.formTitle}>
              {translate(user.lang, "add folder")}
            </h2>
            <form
              className={classes.form}
              autoComplete="off"
              onSubmit={handleSubmitFolder}
            >
              <input
                type="text"
                name="name"
                placeholder={translate(user.lang, "namePlayer")}
                value={folder}
                onChange={(event) => setFolder(event.target.value)}
                required
              />
              <input
                type="submit"
                value={translate(user.lang, "create")}
                disabled={globalLoading}
              />
              <List dense={false}>
                {folders.map((folder) => {
                  return (
                    <ListItem
                      className={classes.ul}
                      alignItems="center"
                      key={folder.id}
                    >
                      <ListItemText
                        key={folder.id}
                        id={folder.id}
                        primary={`${folder.name}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="comments"
                          onClick={() => {
                            setMsg(translate(user.lang, "remove folder msg"));
                            setSnackbarOpen(true);
                            setId(folder.id);
                          }}
                        >
                          <DeleteIcon style={{ color: "#1C1E24" }} />
                        </IconButton>
                        {/* <IconButton edge="end" aria-label="comments">
                          <EditIcon style={{ color: "#1C1E24" }} />
                        </IconButton> */}
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            </form>
          </div>
        </Fade>
      </Modal>
      <Modal open={openAddFile} closeAfterTransition>
        <Fade in={openAddFile}>
          <div className={classes.modalContainer}>
            <div className={classes.close} onClick={clearFileForm}>
              X
            </div>
            <h2 className={classes.formTitle}>
              {translate(user.lang, "add file")}
            </h2>
            <form
              className={classes.form}
              autoComplete="off"
              onSubmit={handleSubmitFile}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormControl variant="outlined" fullWidth>
                    <input
                      type="text"
                      name="name"
                      placeholder={translate(user.lang, "name report")}
                      onChange={(e) =>
                        setUploadFile({ ...uploadFile, name: e.target.value })
                      }
                      value={uploadFile.name}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      {translate(user.lang, "folders")}
                    </InputLabel>
                    <Select
                      required
                      className={classes.multiSelect}
                      labelId="demo-simple-select-label"
                      value={uploadFile.folderId}
                      onChange={(e) =>
                        setUploadFile({
                          ...uploadFile,
                          folderId: e.target.value,
                        })
                      }
                    >
                      {folders.map((folder) => {
                        return (
                          <MenuItem key={folder.id} value={folder.id}>
                            {folder.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="user-label" className={classes.label}>
                      {translate(user.lang, "team player")}
                    </InputLabel>
                    <Select
                      required
                      className={classes.multiSelect}
                      id="users"
                      value={team}
                      onChange={(e) => setTeam(e.target.value)}
                    >
                      <MenuItem value={"none"}>
                        {translate(user.lang, "none")}
                      </MenuItem>
                      {teams.map((team) => {
                        return (
                          <MenuItem key={team._id} value={team._id}>
                            {`${team.name}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      {translate(user.lang, "users")}
                    </InputLabel>
                    <Select
                      multiple
                      required
                      className={classes.multiSelect}
                      id="users"
                      value={users}
                      onChange={(e) => setUsers(e.target.value)}
                      renderValue={(selected) => users.length}
                      MenuProps={MenuProps}
                    >
                      {players.map((player) => {
                        return (
                          <MenuItem key={player._id} value={player._id}>
                            <Checkbox
                              checked={users.indexOf(player._id) > -1}
                            />
                            <ListItemText
                              primary={`${player.name} ${player.lastname}`}
                            />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl variant="outlined" fullWidth>
                  <input
                    type="text"
                    name="observations"
                    placeholder={translate(user.lang, "observation report")}
                    onChange={(e) =>
                      setUploadFile({
                        ...uploadFile,
                        observations: e.target.value,
                      })
                    }
                    value={uploadFile.observations}
                    required
                  />
                </FormControl>
              </Grid>
              <Upload
                file={file}
                setFile={setFile}
                // isLoading={isLoading}
                uploadFile={uploadFile}
                setUploadFile={setUploadFile}
                text={translate(user.lang, "attachment")}
              />
              <input
                type="submit"
                value={translate(user.lang, "create")}
                disabled={globalLoading}
              />
            </form>
          </div>
        </Fade>
      </Modal>
      <SnackBarDelete
        msg={msg}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        id={id}
        setId={setId}
        removeFolder={removeFolder}
        accept={translate(user.lang, "accept")}
      />
    </>
  );
};

export default FilesFolders;
