import React, { useState, useEffect } from "react";
import environment from "environment";

import {
  getPlayers,
  getClubs,
  getWPlayerById,
  deleteWPlayerById,
  postCSVPlayerList,
  getWClubByName,
  fetchCategoriesByClub,
  getTeamsByCategoryID,
} from "../endpoints";

import { connect } from "react-redux";
import { translate } from "../lang/lang";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import useStyles from "../assets/MakeStyles";
import { withRouter } from "react-router-dom";
import { saveFilter, clearFilter } from "../actions";
import clearIcon from "../assets/img/clear-icon.svg";
import searchIcon from "../assets/img/searchBold.svg";
import Paginator from "../components/accessories/Paginator";
import Select from "../components/accessories/Select/Select";
import { getObjectFromArrayWithKeysByProperty } from "../services";
import GlobalLoading from "../components/accessories/GlobalLoading";
import ListPlayersComponent from "../components/ListPlayersComponent/ListPlayersComponent";

const BASE_API_RESOURCE = environment.BASEAPI;

function ListPlayers(props) {
  const { user } = props;

  let fieldAndHeaders = {
    image: "",
    team: "",
    players: "",
    category: "",
    club: "wclubNames",
    position: "rolestring",
    actions: "",
  };

  let positions = [
    {
      wid: "GKP",
      name: "GKP",
    },
    {
      wid: "DEF",
      name: "DEF",
    },
    {
      wid: "MID",
      name: "MID",
    },
    {
      wid: "FWD",
      name: "FWD",
    },
  ];

  const classes = useStyles();
  const [teams, setTeams] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [params, setParams] = useState({});
  const [players, setPlayers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [creations, setCreations] = useState(null);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [timeOutInput, setTimeOutInput] = useState(0);
  const [globalLoading, setGlobalLoading] = useState(true);
  const [defaultCategory, setDefaultCategory] = useState(null);
  const [creationsPlayerModal, setCreationsPlayerModal] = useState(false);

  const setPush = (value) => {
    props.history.push({ search: "?page=" + value });
  };

  const handleChange = (data) => {
    if (data.id === "category") {
      setGlobalLoading(true);
      setDefaultCategory(data.value);
      makeParams("categoryId", data.value);
      handleTeams(data.value);
    }
    if (data.id === "team") {
      setGlobalLoading(true);
      makeParams("teamId", data.value);
      setGlobalLoading(false);
    }
  };

  const handleTeams = (id) => {
    getTeamsByCategoryID(id)
      .then((res) => {
        console.log("team", res);
        setTeams(res.data.response);
        setGlobalLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setGlobalLoading(false);
      });
  };

  const makeParams = (key, value) => {
    setGlobalLoading(true);
    let getParams = { ...params, [key]: value };
    if (key === "wclubId") {
      getParams = { ...getParams, page: 1 };
    }

    if (params.hasOwnProperty(key) && key !== "page") {
      getParams = { ...getParams, page: 1 };
      setPush(getParams["page"]);
    }

    setParams(getParams);

    if (props.filterPage) {
      listPlayers({ ...props.filterPage.playerList, ...getParams });
    } else {
      listPlayers(getParams);
    }

    if (key === "page") {
      setPush(getParams["page"]);
    }

    if (props.filterPage) {
      props.saveFilter("playerList", {
        ...props.filterPage.playerList,
        ...getParams,
      });
    } else {
      props.saveFilter("playerList", getParams);
    }
  };

  const removeParams = (key) => {
    makeParams(key, "");
    setGlobalLoading(true);
    let { [key]: omit, ...paramsRem } = params;
    setParams(paramsRem);
    listPlayers(paramsRem);
    setPush(1);
  };

  const listPlayers = (params) => {
    getPlayers(params).then((response) => {
      console.log(response);
      //      console.log(response);
      if (!response || !response.data) {
        setGlobalLoading(false);
        return;
      }
      setPlayers(response.data.response);
      setTotalPlayers(response.data.totalPlayers);
      setGlobalLoading(false);
    });
  };

  const handleGoToGps = async (player) => {
    let response = await getWPlayerById(player._id);
    if (response.status === 200) {
      props.history.push(
        `/gps/team/${response.data.response.teams[0]._id}?default-player=${player._id}`
      );
    } else {
      console.log("error");
    }
  };

  const handleGoToGpexe = async () => {
    props.history.push(`/gpexe/sessions`);
  };

  const handleGoEditPlayer = (player) => {
    props.history.push(`/edit-player/${player._id}`);
  };

  const handleGoDeletePlayer = async (player) => {
    setGlobalLoading(true);
    let response = await deleteWPlayerById(player._id);
    if (response.code === 200) {
      alert("El jugador ha sido eliminado con exito");
      setPlayers(
        players.filter((pl) => {
          return pl.wid !== player.wid;
        })
      );
    } else {
      alert("error");
    }
    setGlobalLoading(false);
  };

  const handleGoTagPlayer = (player) => {
    props.history.push(`/tag-player/${player._id}`);
  };

  const handleInputSubmit = (e) => {
    if (!e.target.value) {
      makeParams("prefix_value", "");
      // removeParams("prefix_value");
      return;
    }
    if (timeOutInput !== 0) {
      clearTimeout(timeOutInput);
    }
    setTimeOutInput(
      setTimeout(
        (e) => {
          setGlobalLoading(true);
          makeParams("prefix_value", e);
        },
        1000,
        e.target.value
      )
    );
  };

  const HandlepostCSVPlayerList = async (file) => {
    setGlobalLoading(true);
    let response = await postCSVPlayerList(file);
    if (response.code) {
      console.log(response.creations);
      setCreations(response.creations);
      setCreationsPlayerModal(true);
    } else {
      console.log("error");
    }
    setGlobalLoading(false);
  };

  const getCreationsSuccessful = () => {
    let returnTable = creations["successful-creations"].map((cr, index) => {
      return (
        <tr key={index}>
          <td>{`${cr.name} ${cr.lastname && cr.lastname}`}</td>
        </tr>
      );
    });

    return (
      <table className="table">
        <thead>
          <tr>
            <th>Nombre</th>
          </tr>
        </thead>
        <tbody>{returnTable}</tbody>
      </table>
    );
  };

  const getCreationsFailed = () => {
    let returnTable = creations["failed-creations"].map((cr, index) => {
      return (
        <tr key={index}>
          <td>{`${cr.name} ${cr.lastname && cr.lastname} `}</td>
          <td>{`${cr.message}`}</td>
        </tr>
      );
    });

    return (
      <table className="table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Mensaje</th>
          </tr>
        </thead>
        <tbody>{returnTable}</tbody>
      </table>
    );
  };

  let clubNames = getObjectFromArrayWithKeysByProperty(clubs, "wid", "_id");

  let clubSelected =
    params["wclubId"] ||
    (props.filterPage &&
      props.filterPage.playerList &&
      props.filterPage.playerList.wclubId) ||
    null;
  if (clubSelected !== undefined)
    clubSelected = clubNames[clubSelected]
      ? clubNames[clubSelected].name
      : clubSelected;

  const handleSelected = () => {
    clubSelected &&
      getWClubByName(clubSelected)
        .then((response) => {
          //const id = response.data.response[0]._id;
          fetchCategoriesByClub(response.data.response[0]._id)
            .then((response) => {
              setCategories(response.data.response);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
  };

  useEffect(() => {
    console.log("aqui", props);
    if (props.filterPage) {
      makeParams("page", props.filterPage.playerList.page);
    } else {
      makeParams("page", 1);
    }
    setGlobalLoading(true);
    getClubs().then((response) => {
      setClubs(response.data.response);
    });
  }, []);

  useEffect(() => {
    handleSelected();
  }, [clubSelected]);

  useEffect(() => {
    if (
      props.filterPage &&
      props.filterPage.playerList &&
      props.filterPage.playerList.categoryId
    ) {
      handleTeams(props.filterPage.playerList.categoryId);
    }
  }, [categories, defaultCategory]);

  return (
    <>
      <div className={classes.headerContainer}>
        <div className="titleContainer">
          <span>{translate(user.lang, "list of players")}</span>
        </div>
        <div className="paragraphContainer">
          <p>{translate(user.lang, "list-player-description")}</p>
        </div>
        <div
          style={{ position: "absolute", top: 0, right: 0, display: "flex" }}
        >
          {clubs.length > 0 ? ( //Oculto temporalmente
            <div className="fileInputContainer marginRight">
              <a
                className="exampleInput"
                href={`${BASE_API_RESOURCE}/bundles/app/files/example-player.xlsx`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {translate(user.lang, "download examples")}
              </a>
            </div>
          ) : null}

          {clubs.length > 0 ? ( //Oculto temporalmente
            <div className="fileInputContainer">
              <label htmlFor="fileInput">
                <span className="fileInput">
                  {translate(user.lang, "import players")}
                </span>
              </label>
              <input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={(event) =>
                  HandlepostCSVPlayerList(event.target.files[0])
                }
                className="hiddenInput"
                name="fileInput"
                id="fileInput"
                type="file"
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className={classes.inputContainer}>
        <div className="largeInput marginRight">
          {clubs.length > 0 ? (
            <input
              defaultValue={
                props.filterPage ? props.filterPage.playerList.prefix_value : ""
              }
              name="Jugador"
              placeholder={translate(user.lang, "player")}
              className="inputField"
              onChange={(e) => handleInputSubmit(e)}
            />
          ) : null}
          <img className="icon" src={searchIcon} alt="" />
        </div>
        <div className="largeInput">
          {clubs.length > 0 ? (
            <Select
              keyParam={"wid"}
              search
              value={
                props.filterPage &&
                props.filterPage.playerList &&
                props.filterPage.playerList.wclubId
                  ? props.filterPage.playerList.wclubId
                  : ""
              }
              idSelect="club"
              nameSelect={"Club"}
              selectedItem={clubSelected}
              newItemSelected={makeParams}
              items={clubs}
              keyName="wclubId"
              newItemSelectedEmpty={removeParams}
              component={"ListPlayers"}
              //disable={selectDisable}
            />
          ) : null}
        </div>
        {clubs.length > 0 ? (
          <div className="largeInput">
            <select
              //autoSave={false}
              value={
                props.filterPage &&
                props.filterPage.playerList &&
                props.filterPage.playerList.categoryId
                  ? props.filterPage.playerList.categoryId
                  : defaultCategory || "0"
              }
              required
              className="inputField"
              id="category"
              onChange={(e) => handleChange(e.target)}
              disabled={categories.length === 0}
            >
              <option value="0" disabled>
                {translate(user.lang, "category")}
              </option>
              {/* <option value='1'>VER TODOS</option> */}
              {categories.map((data, index) => {
                return (
                  <option key={index} value={data._id}>
                    {data.name}
                  </option>
                );
              })}
            </select>
          </div>
        ) : null}
        {clubs.length > 0 ? (
          <div className="largeInput">
            <select
              value={
                props.filterPage &&
                props.filterPage.playerList &&
                props.filterPage.playerList.categoryId &&
                props.filterPage.playerList.teamId
                  ? props.filterPage.playerList.teamId
                  : defaultCategory || "0"
              }
              required
              className="inputField"
              id="team"
              disabled={teams.length === 0}
              onChange={(e) => handleChange(e.target)}
            >
              <option value="0" disabled>
                {translate(user.lang, "team")}
              </option>

              {/* <option value='1'>VER TODOS</option> */}
              {teams.map((data, index) => {
                return (
                  <option key={index} value={data._id}>
                    {data.name}
                  </option>
                );
              })}
            </select>
          </div>
        ) : null}

        {clubs.length > 0 ? (
          <div className="largeInput">
            <Select
              value={
                props.filterPage &&
                props.filterPage.playerList &&
                props.filterPage.playerList.rolestring
                  ? props.filterPage.playerList.rolestring
                  : ""
              }
              keyParam={"wid"}
              idSelect={"rolestring"}
              selectedItem={params["rolestring"]}
              nameSelect={translate(user.lang, "position")}
              newItemSelected={makeParams}
              items={positions}
              uppercase={true}
              keyName="rolestring"
              newItemSelectedEmpty={removeParams}
              //disable={selectDisable}
            />
          </div>
        ) : null}

        {props.filterPage &&
        props.filterPage.playerList &&
        Object.keys(props.filterPage.playerList).length < 2 ? null : (
          <div
            title="Borrar filtros"
            onClick={() => {
              props.clearFilter();
              props.history.push("/");
            }}
            style={{
              top: 35,
              right: 0,
              cursor: "pointer",
              position: "absolute",
            }}
          >
            <img src={clearIcon} alt="Clear Filters" />
          </div>
        )}
      </div>

      {globalLoading ? (
        <GlobalLoading />
      ) : (
        <div className="tableContainer">
          <ListPlayersComponent
            user={props.user}
            players={players}
            loading={globalLoading}
            clubSelect={clubSelected}
            totalPlayers={totalPlayers}
            handleGoToGps={handleGoToGps}
            handleGoToGpexe={handleGoToGpexe}
            handleGoTagPlayer={handleGoTagPlayer}
            handleGoEditPlayer={handleGoEditPlayer}
            handleGoDeletePlayer={handleGoDeletePlayer}
            fieldAndHeaders={fieldAndHeaders}
            countries={props.countriesNames}
          />
        </div>
      )}

      <Paginator
        totalItems={totalPlayers}
        handleSetFilters={(value) => {
          makeParams("page", value);
        }}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalCreationPlayer}
        open={creationsPlayerModal}
        closeAfterTransition
      >
        <Fade in={creationsPlayerModal}>
          <div className={classes.paper}>
            {creations && (
              <>
                <div className={classes.feedbackAutoGeneratePlayer}>
                  <h3>Jugadores creados</h3>
                  {getCreationsSuccessful()}
                </div>

                <div className={classes.feedbackAutoGeneratePlayer}>
                  <h3>Jugadores no creados</h3>
                  {getCreationsFailed()}
                </div>
              </>
            )}

            <button
              className="accept"
              onClick={() => setCreationsPlayerModal(false)}
            >
              Aceptar
            </button>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return { countriesNames: state.countriesNames, filterPage: state.filterPage };
};

export default withRouter(
  connect(mapStateToProps, { saveFilter, clearFilter })(ListPlayers)
);
