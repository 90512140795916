import React, { useEffect, useState } from "react";

import TagPlayerComponent from "../components/TagPlayerComponent";
import GlobalLoading from "../components/accessories/GlobalLoading";
import {
  getWPlayerById,
  resourceApiEndPoint,
  getCountries,
  getSponsor,
} from "../endpoints";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  country: {
    [theme.breakpoints.down(1370)]: {
      display: "flex",
      alignItems: "center",
    },
  },
}));

function TagPlayer(props) {
  const classes = useStyles();
  const playerId = props.match.params.playerId;
  const {user} = props;

  const [player, setPlayer] = useState({
    photo: "",
    validPhoto: true,
  });

  const [sponsor, setSponsor] = useState([]);
  const [countries, setCountries] = useState([]);
  const [globalLoading, setGlobalLoading] = useState(true);

  const handlePhotoError = () => {
    //setPlayer({...player, validPhoto: false});
  };

  const getCountryName = (countryId) => {
    if (countryId) {
      let countrySelected = countries.filter((country) => {
        return country.wid === countryId;
      });
      return countrySelected[0] ? (
        <span className={classes.country}>
          {countrySelected[0].name}{" "}
          <img
            alt="flag"
            src={`${resourceApiEndPoint()}/${countrySelected[0].img_file}`}
          ></img>
        </span>
      ) : null;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const findPlayer = async () => {
      let response = await getWPlayerById(playerId);
      if (response.status === 200) {
        setPlayer({
          ...response.data.response,
          validPhoto: response.data.response.img_file ? true : false,
          preview: response.data.response.img_file
            ? `${resourceApiEndPoint()}/${response.data.response.img_file}`
            : "",
        });

        getCountries().then((resp) => {
          setCountries(resp.data.response);
        });

        getSponsor().then((response) => {
          setSponsor(response.data.response);
          setGlobalLoading(false);
        });
      } else {
        console.log("error");
      }
    };
    findPlayer();
  }, []);

  return (
    <div className="container-fluid">
      <TagPlayerComponent
        user={user}
        player={player}
        sponsor={sponsor}
        getCountryName={getCountryName}
        handlePhotoError={handlePhotoError}
      />
      {globalLoading && <GlobalLoading />}
    </div>
  );
}
export default React.memo(TagPlayer);
