import React from "react";

import { makeStyles } from "@material-ui/core/styles";
//import backgroundStacts from '../assets/img/textures/background-stacts.png';
import MoreVertIcon from "@material-ui/icons/MoreVert";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import DeleteIcon from "@material-ui/icons/Delete";
import { translate } from "../lang/lang";

import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    justifyContent: "left",
    "& .titleContainer": {
      width: "100%",
      "& span": {
        fontSize: "40px",
        color: "#ffffff",
        fontFamily: "DINCondensed",
      },
    },
    "& .subTitleContainer": {
      margin: "15px 0",
      maxWidth: "630px",
      "& span": {
        width: "777px",
        height: "51px",
        color: "#ffffff",
        fontSize: "15px",
        fontFamily: "Montserrat-Medium",
        textTransform: "uppercase",
      },
    },
  },
  matchesTableContainer: {
    overflow: "hidden",
    borderRadius: "10px",
    background: "#1c1e24",
  },
  matchesTable: {
    textAlign: "left",
    "& tr": {
      height: "64.5px",
    },
    "& tr > th, tr > td": {
      padding: "15px 30px",
    },
    "& tr > td": {
      fontSize: "14px",
      //fontWeight: 600,
      //lineHeight: 1.2,
      color: "#ffffff",
      textAlign: "left",
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: "normal",
      //fontFamily: 'DINCondensed',
    },
    "& thead": {
      fontFamily: "Montserrat-SemiBold",
      textTransform: "uppercase",
      fontSize: "19px",
      fontWeight: 600,
      color: "#6bffe6",
      background: "#1C1E24",
    },
    "& tbody td:first-child": {
      minWidth: "150px",
    },
    "& td:last-child": {
      //padding: 0
    },
  },
  MoreVertIcon: {
    cursor: "pointer",
    color: "#6bffe6",
  },
  Popover: {
    padding: "10px",
    "& .options-container": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    "& .Popover-option-icon": {
      margin: "5px 5px 5px 0",
      color: "#4ddac3",
    },
  },
}));

const EventVideoListComponent = (props) => {
  const classes = useStyles();
  const { matches, handleShowEvents, user } = props;
  const [anchorEl, setAnchorEl] = React.useState({
    anchorEl: null,
    name: null,
  });

  const handleClick = (event) => {
    setAnchorEl({
      ...anchorEl,
      anchorEl: event.currentTarget,
      name: event.currentTarget.getAttribute("name"),
    });
  };

  const handleClose = () => {
    setAnchorEl({ anchorEl: null, name: null });
  };

  const open = Boolean(anchorEl.anchorEl);
  const id = open ? anchorEl.name : undefined;

  const showList = () => {
    if (matches) {
      return (
        <table className={classes.matchesTable}>
          <thead>
            <tr>
              <th>{translate(user.lang, "date")}</th>
              <th colSpan="2">{translate(user.lang, "match")}</th>
            </tr>
          </thead>
          <tbody>
            {matches.map((match, key) => {
              return (
                <tr
                  key={key}
                  style={{ background: key % 2 ? "#1B1D24" : "#16191B" }}
                >
                  <td>{match.match_date}</td>
                  <td style={{ width: "100%" }}>{match.name}</td>
                  <td>
                    <MoreVertIcon
                      className={classes.MoreVertIcon}
                      onClick={handleClick}
                      name={`simple-popover-${key}`}
                    />
                    <Popover
                      id={`simple-popover-${key}`}
                      open={id === `simple-popover-${key}` ? open : false}
                      anchorEl={anchorEl.anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <div className={classes.Popover}>
                        <div
                          className="options-container"
                          onClick={() => handleShowEvents(match)}
                        >
                          <PlayArrowIcon className="Popover-option-icon" />
                          <span>Ver video</span>
                        </div>
                        <div className="options-container">
                          <DeleteIcon className="Popover-option-icon" />
                          <span>Eliminar</span>
                        </div>
                      </div>
                    </Popover>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className={classes.headerContainer}>
        <div className="titleContainer">
          <span>{translate(user.lang, "list of videos")}</span>
        </div>
        <div className="subTitleContainer">
          <span>{translate(user.lang, "list of videos text")}</span>
        </div>
      </div>

      <div className={classes.matchesTableContainer}>{showList()}</div>
    </>
  );
};
export default React.memo(EventVideoListComponent);
