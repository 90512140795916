import React, { useState, useEffect } from "react";

import "./AddPlayers.css";
import moment from "moment";

import { connect } from "react-redux";
import es from "date-fns/locale/es";

import nounImage from "../../assets/img/Camera.svg";
import "react-datepicker/dist/react-datepicker.css";
import { withStyles } from "@material-ui/core/styles";

import RadioGroupUI from "@material-ui/core/RadioGroup";
import DatePicker, { registerLocale } from "react-datepicker";
import Dropzone from "../../components/accessories/Dropzone";
import { /*create_player,*/ unset_player } from "../../actions/index";
import GlobalLoading from "../../components/accessories/GlobalLoading";
import { makeStyles, Radio, FormControlLabel } from "@material-ui/core";
import {
  getClubs,
  getSponsor,
  getTeamById,
  getCountries,
  updateWplayer,
  postNewPlayers,
  getWPlayerById,
  getTeambyClubId,
  resourceApiEndPoint,
} from "../../endpoints";
import InfoModal from "../../components/modals/InfoModal";

import { translate } from "../../lang/lang";

registerLocale("es", es);

function mapDispatchToProps(dispatch) {
  return {
    //set_create_player: player => dispatch(create_player(player)),
    reset_player: () => dispatch(unset_player()),
  };
}

const CustomRadioGroup = withStyles({
  root: {
    flexDirection: "row",
  },
})(RadioGroupUI);

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    position: "relative",
    marginBottom: "50px",
    justifyContent: "left",
    flexDirection: "column",
    "& .titleContainer": {
      width: "100%",
      "& span": {
        lineHeight: "1",
        fontSize: "40px",
        fontFamily: "DINCondensed",
      },
    },
    "& .fileInputContainer": {
      width: "333px",
      minHeight: "166px",
      marginTop: "20px",
      borderRadius: "6px",
      backgroundColor: "#16171d",
    },
    "& .subcontainerHeader": {
      width: "333px",
      height: "60px",
      color: "#6cffe6",
      fontSize: "15px",
      fontWeight: "700",
      lineHeight: "54px",
      position: "absolute",
      borderRadius: "7px",
      textAlign: "center",
      backgroundColor: "#1c1e24",
      font: "Montserrat-SemiBold",
    },
    "& .dropZoneContainer": {
      width: "100%",
      outline: "none",
      cursor: "pointer",
      "& img": {
        outline: "none",
        maxHeight: "65px",
      },
      "& .playerImageContainer": {
        backgroundColor: "#fff",
        width: 65,
        height: 65,
        borderRadius: "50%",
        margin: " 70px auto -60px",
        overflow: "hidden",
        boxSizing: "border-box",
        "& img": {
          height: "100%",
        },
      },
    },
  },

  dropNoContainer: {
    height: "100%",
    padding: "7px 40px",
    width: "282px",
    display: "flex",
    outline: "none",
    color: "#6cffe6",
    borderRadius: "9px",
    textAling: "center",
    alignItems: "center",
    margin: "80px auto 20px",
    boxSizing: "border-box",
    justifyContent: "space-around",
    border: "solid 1px #6cffe6",
  },
  formContainer: {
    display: "flex",
    borderRadius: "6px",
    marginBottom: "20px",
    boxSizing: "border-box",
    flexDirection: "column",
    backgroundColor: "#16171d",
    justifyContent: "space-around",
    "& .formHeader": {
      height: "60px",
      width: "100%",
      display: "flex",
      color: "#6cffe6",
      borderRadius: "7px",
      padding: "19px 52px",
      alignItems: "center",
      boxSizing: "border-box",
      backgroundColor: "#1c1e24",
    },
  },
  inputContainer: {
    color: "#FFF",
    minWidth: "80%",
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: "52px",
    position: "relative",
    marginBottom: "35px",
    boxSizing: "border-box",
    fontFamily: "Open Sans Condensed",
    "& .largeInput": {
      width: "21%",
      marginTop: "35px",
      marginRight: "2.5%",
      //   "& option": {
      //     background: "#ccc",
      //   },
      "& .error": {
        color: "red",
      },
    },
    "& .react-datepicker-wrapper": {
      width: "100%",
    },
    "& .label": {
      fontSize: "15px",
      fontFamily: "Open Sans Condensed",
    },
    "& .required": {
      color: "red",
    },
    "& .inputField": {
      width: "100%",
      outline: "none",
      color: "#000000",
      fontSize: "18px",
      marginTop: "2px",
      fontWeight: "700",
      position: "relative",
      padding: "8px 22.6px",
      transition: "all .1s",
      borderRadius: "4.6px",
      boxSizing: "border-box",
      border: "1px solid white",
      backgroundColor: "#ffffff",
      fontFamily: "Open Sans Condensed",
    },
    "& .checkedContainer": {
      width: "20px",
      height: "20px",
      position: "relative",
      background: "#2E333F",
    },
    "& .checked": {
      top: "3px",
      left: "3px",
      width: "14px",
      height: "14px",
      background: "#00BDD8",
      position: "absolute",
    },
  },
  buttonSaveContainer: {
    width: "100%",
    display: "flex",
    marginTop: "35px",
    flexDirection: "row",
    marginBottom: "35px",
    justifyContent: "flex-end",

    "& button": {
      fontSize: "17px",
      outline: "none",
      marginLeft: "25px",
      padding: "10px 15px",
    },
    "& .formSaveButton": {
      border: "none",
      width: "247px",
      color: "#000000",
      height: "53.8px",
      cursor: "pointer",
      fontWeight: "700",
      borderRadius: "4px",
      backgroundColor: "#6cffe6",
    },
  },
  nationalitiesContainer: {
    display: "flex",
    justifyContent: "space-around",
    "& span": {
      color: "#67f5e7",
    },
    "& img": {
      width: "20px",
    },
  },
  coutrySelected: {
    "&:hover .remove": {
      visibility: "inherit",
    },
    "& .remove": {
      cursor: "pointer",
      margin: "0 5px",
      fontWeight: 900,
      visibility: "hidden",
    },
  },
}));

const playerBlank = {
  //photo: "",
  //validPhoto: "",
  //preview: "",
  upload_photo_filename: null,
  name: "",
  lastname: "",
  dni: "",
  birthdate: "",
  wcountryids: "",
  //place_of_birth: "",
  passport_number: "",
  address: "",
  city: "",
  postale_code: "",
  height: "",
  weight: "",
  //nickname: "",
  //social_network: "",
  //user_rrss: "",
  email: "",
  mobile: "",
  natural_foot: "Right",
  //club: "",
  //national_team: "",
  rolestring: "0",
  wteamid: "",
  size: "",
  position: "",
  shirtnumber: "",
  sponsor_id: "",
  sponsor_contract_value: "",
  sponsor_start_date: "",
  sponsor_end_date: "",
  federal_law: "",
  economic_law: "",
  image_rights: "",
  countryofbirth: "",
  cityofbirth: "",
  currentcity: "",
  currentcountry: "",
};

function AddPlayer({ set_create_player, reset_player, ...props }) {
  const playerId = props.match ? props.match.params.playerId : null;
  const user = props.user;

  const classes = useStyles();
  const [age, setAge] = useState(0);
  const [clubs, setClubs] = useState([]);
  const [teams, setTeams] = useState([]);
  const [sponsor, setSponsor] = useState([]);
  const [birthDate, setBirthDate] = useState();
  const [countries, setCountries] = useState([]);
  const [endSponsor, setEndSponsor] = useState();
  const [nameError, setNameError] = useState(false);
  const [teamError, setTeamError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [upImg, setUpImg] = useState({ result: "" });
  const [startSponsor, setStartSponsor] = useState();
  const [defaultClub, setDefaultClub] = useState(null);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [currentPlayer, setCurrentPlayer] = useState(playerBlank);

  const handleSubmit = () => {
    if (!currentPlayer.name) {
      return setNameError(true);
    }
    if (!currentPlayer.lastname) {
      return setLastNameError(true);
    }
    if (!currentPlayer.wteamid) {
      return setTeamError(true);
    }

    let sendPlayer = {};
    Object.keys(currentPlayer).forEach((key) => {
      if (currentPlayer[key] !== "") {
        sendPlayer = { ...sendPlayer, [key]: currentPlayer[key] };
      }
    });

    if (playerId) {
      //console.log(currentPlayer)
      setGlobalLoading(true);
      updateWplayer(playerId, sendPlayer)
        .then((response) => {
          console.log("coño", response);

          setGlobalLoading(false);
          reset_player();
          if (!response.error) {
            alert(response.message);
          } else {
            setOpenModal(true);
            setModalSuccess(true);
          }
        })
        .catch(() => {
          setGlobalLoading(false);
          setOpenModal(true);
          setModalSuccess(false);
        });
    } else {
      setGlobalLoading(true);
      postNewPlayers(sendPlayer)
        .then((response) => {
          setGlobalLoading(false);
          reset_player();
          setOpenModal(true);
          setModalSuccess(response.error ? false : true);
        })
        .catch(() => {
          setGlobalLoading(false);
          setOpenModal(true);
          setModalSuccess(false);
        });
    }
  };

  const handleDrop = (dropped) => {
    if (dropped && dropped.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setUpImg({ result: reader.result, field: dropped[0] })
      );
      reader.readAsDataURL(dropped[0]);
    }
    //setCurrentPlayer({...currentPlayer, upload_photo_filename: dropped[0] });
  };

  const handleChange = (target) => {
    console.log(currentPlayer);
    if (target.id === "name") {
      setNameError(false);
    }
    if (target.id === "lastname") {
      setLastNameError(false);
    }
    if (target.id === "wclubid") {
      setDefaultClub(target.value);
      getTeambyClubId(target.value).then((response) => {
        setTeams(response.data.response);
      });
      return null;
    }

    if (target.id === "wcountryids") {
      let arrayNationSelected = currentPlayer.wcountryids.split(" ");
      let newWcountryids = arrayNationSelected[0];
      if (newWcountryids < 1) {
        newWcountryids = target.value;
      } else if (target.value !== newWcountryids) {
        arrayNationSelected[1] = target.value;
        newWcountryids = `${newWcountryids} ${arrayNationSelected[1]}`;
      }
      setCurrentPlayer({ ...currentPlayer, [target.id]: newWcountryids });
      return null;
    }
    setCurrentPlayer({ ...currentPlayer, [target.id]: target.value });
  };

  const handleChangeDate = (data, value) => {
    var month = value.getMonth() + 1;
    var day = value.getDate();
    var year = value.getFullYear();
    let date = `${day}-${month}-${year}`;
    switch (data) {
      case "birthdate":
        setAge(moment().diff(value, "years"));
        setBirthDate(value);
        setCurrentPlayer({ ...currentPlayer, [data]: date });
        break;
      case "sponsor_start_date":
        if (value > endSponsor) {
          setEndSponsor(value);
          setCurrentPlayer({
            ...currentPlayer,
            [data]: date,
            sponsor_end_date: value,
          });
        } else {
          setStartSponsor(value);
          setCurrentPlayer({ ...currentPlayer, [data]: date });
        }
        break;
      case "sponsor_end_date":
        if (value < startSponsor) {
          setStartSponsor(value);
          setCurrentPlayer({
            ...currentPlayer,
            [data]: date,
            sponsor_start_date: value,
          });
        } else {
          setEndSponsor(value);
          setCurrentPlayer({ ...currentPlayer, [data]: date });
        }
        break;
      default:
        break;
    }
    //create_player({[data]: moment(value).format("DD-MM-YYYY")});
  };

  const StyledLabel = ({ value }) => {
    return <p className="label">{value}</p>;
  };

  const StyledUncheckedRadio = () => {
    return <div className="checkedContainer"></div>;
  };

  const StyledCheckedRadio = () => {
    return (
      <div className="checkedContainer">
        <div className="checked"></div>
      </div>
    );
  };

  const removeCountrySelected = (countryId) => {
    let arrayNationSelected = currentPlayer.wcountryids.split(" ");
    let refreshNationsSelected = arrayNationSelected.filter((nat) => {
      return nat !== countryId;
    });
    setCurrentPlayer({
      ...currentPlayer,
      wcountryids: refreshNationsSelected[0] ? refreshNationsSelected[0] : "",
    });
  };

  const getCountryName = (countryId) => {
    if (countryId) {
      let countrySelected = countries.filter((country) => {
        return country.wid === countryId;
      });
      return countrySelected[0] ? (
        <span className={classes.coutrySelected}>
          {countrySelected[0].name}{" "}
          <img
            alt="flag"
            src={`${resourceApiEndPoint()}/${countrySelected[0].img_file}`}
          ></img>
          <span
            className="remove"
            onClick={() => removeCountrySelected(countryId)}
          >
            X
          </span>
        </span>
      ) : null;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (upImg) {
      setCurrentPlayer({
        ...currentPlayer,
        upload_photo_filename: upImg.field,
      });
    }
  }, [upImg]);

  useEffect(() => {
    if (!playerId) {
      setCurrentPlayer(playerBlank);
      setAge(0);
    }
  }, [playerId]);

  useEffect(() => {
    setGlobalLoading(true);

    getCountries().then((response) => {
      setCountries(response.data.response);
    });

    getSponsor().then((response) => {
      setSponsor(response.data.response);
    });

    getClubs().then((response) => {
      setClubs(response.data.response);
      setGlobalLoading(false);
    });

    if (playerId) {
      const findPlayer = async () => {
        let response = await getWPlayerById(playerId);
        if (response.status === 200) {
          let player = response.data.response;

          if (player.teams[0]._id) {
            let responseClub = await getTeamById(player.teams[0]._id);
            if (responseClub.status === 200) {
              setDefaultClub(responseClub.data.response.club_id);
              getTeambyClubId(responseClub.data.response.club_id).then(
                (resp) => {
                  setTeams(resp.data.response);
                }
              );
            }
          }

          let birthDateTemp = null;

          if (player.birthdate) {
            birthDateTemp = player.birthdate.split("-");
            let edad = new Date(
              `${birthDateTemp[2]}-${
                birthDateTemp[1].length === 2
                  ? birthDateTemp[1]
                  : "0" + birthDateTemp[1]
              }-${
                birthDateTemp[0].length === 2
                  ? birthDateTemp[0]
                  : "0" + birthDateTemp[0]
              }`
            );
            setAge(moment().diff(edad, "years"));
          }

          player = {
            //photo: '',
            validPhoto: player.img_file ? true : false,
            preview: player.img_file
              ? `${resourceApiEndPoint()}/${player.img_file}`
              : "",
            name: player.name,
            lastname: player.lastname,
            dni: player.dni ? player.dni : "",
            birthdate: player.birthdate ? player.birthdate : "",
            wcountryids: player.wcountryids ? player.wcountryids : "",
            //place_of_birth: '',
            passport_number: player.passport_number
              ? player.passport_number
              : "",
            address: player.address ? player.address : "",
            city: player.city ? player.city : "",
            postale_code: player.postal_code ? player.postal_code : "",
            height: player.height ? player.height : "",
            weight: player.weight ? player.weight : "",
            //nickname: '',
            //social_network: '',
            //user_rrss: '',
            email: player.email ? player.email : "",
            mobile: player.mobile ? player.mobile : "",
            natural_foot: player.foot ? player.foot : "Right",
            //club: player.wclub_id ? player.wclub_id : '',
            //national_team: '',
            wteamid: player.teams[0]._id,
            size: player.size ? player.size : "",
            rolestring: player.rolestring ? player.rolestring : "",
            shirtnumber: player.shirtnumber ? player.shirtnumber : "",
            sponsor_id: player.sponsor_id ? player.sponsor_id : "",
            sponsor_contract_value: player.sponsor_contract_value
              ? player.sponsor_contract_value
              : "",
            sponsor_start_date: player.sponsor_start_date
              ? player.sponsor_start_date
              : "",
            sponsor_end_date: player.sponsor_end_date
              ? player.sponsor_end_date
              : "",
          };
          setCurrentPlayer(player);
        } else {
          console.log("error");
        }
      };
      findPlayer();
    } else {
      setCurrentPlayer(currentPlayer);
    }

    if (user.clubActive) {
      handleChange({ id: "wclubid", value: user.clubActive._id });
    }
  }, []);

  return (
    <>
      <div className={classes.headerContainer}>
        <InfoModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          modalSuccess={modalSuccess}
          msg={
            modalSuccess
              ? "El jugador fue creado"
              : "El jugador no pudo ser creado"
          }
        />

        <div className="titleContainer">
          <span>
            {playerId
              ? translate(user.lang, "update player")
              : translate(user.lang, "create player")}
          </span>
        </div>

        <div className="fileInputContainer">
          <div className="subcontainerHeader">
            <h2>{translate(user.lang, "update player")}</h2>
          </div>
          <div className="dropZoneContainer">
            {!upImg.result && currentPlayer.preview && (
              <div className="playerImageContainer">
                <img src={currentPlayer.preview} alt={currentPlayer.name} />
              </div>
            )}
            <Dropzone
              propClass={classes.dropNoContainer}
              onDrop={(dropped) => handleDrop(dropped)}
              img={upImg.result || nounImage}
              string={
                upImg.result
                  ? null
                  : playerId
                  ? translate(user.lang, "edit image")
                  : translate(user.lang, "upload image")
              }
            />
          </div>
        </div>
      </div>

      <div className={classes.formContainer}>
        <div className="formHeader">
          <h2>{translate(user.lang, "demographic data")}</h2>
        </div>
        <div className={classes.inputContainer}>
          <div className="largeInput">
            <span className="">{translate(user.lang, "name demographic")}</span>
            <input
              className="inputField largeInput"
              value={currentPlayer ? currentPlayer.name : ""}
              placeholder="Nombres*"
              type="text"
              id="name"
              onChange={(e) => handleChange(e.target)}
            />
            {nameError && <span className="error">El nombre es requerido</span>}
          </div>
          <div className="largeInput">
            <span className="">
              {translate(user.lang, "last name demographic")}
            </span>
            <input
              className="inputField largeInput"
              value={currentPlayer ? currentPlayer.lastname : ""}
              placeholder="Apellido*"
              type="text"
              id="lastname"
              onChange={(e) => handleChange(e.target)}
            />
            {lastNameError && (
              <span className="error">Lastname is required</span>
            )}
          </div>
          <div className="largeInput">
            <span className="">
              {translate(user.lang, "birthday demographic")}
            </span>
            <DatePicker
              value={currentPlayer ? currentPlayer.birthdate : birthDate}
              locale="es"
              selected={birthDate}
              onChange={(date) => handleChangeDate("birthdate", date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="dd-MM-yyyy"
              className="inputField largeInput"
              //customInput={<CustomInput />}
            />
          </div>
          <div className="largeInput">
            <span className="">{translate(user.lang, "age demographic")}</span>
            <p className="inputField largeInput">{age === 0 ? "Edad" : age}</p>
          </div>

          <div className="largeInput">
            <span className="">{translate(user.lang, "dni demographic")}</span>
            <input
              value={currentPlayer ? currentPlayer.dni : ""}
              className="inputField largeInput"
              placeholder="DNI"
              type="number"
              id="dni"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          <div className="largeInput">
            <span className="">
              {translate(user.lang, "passport demographic")}
            </span>
            <input
              value={currentPlayer ? currentPlayer.passport_number : ""}
              className="inputField largeInput"
              type="text"
              placeholder="Pasaporte"
              id="passport_number"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          {countries && (
            <div className="largeInput">
              <span className="">
                {translate(user.lang, "nationality demographic")}
              </span>
              <select
                value={
                  playerId && currentPlayer.wcountryids !== ""
                    ? currentPlayer.wcountryids.split(" ")[0]
                    : "0"
                }
                id="wcountryids"
                className="inputField largeInput"
                onChange={(e) => handleChange(e.target)}
              >
                <option value="0" disabled>
                  {translate(user.lang, "nationality demographic placeholder")}
                </option>
                {countries.map((data, id) => {
                  return (
                    <option value={data.wid} key={id}>
                      {data.name}
                    </option>
                  );
                })}
              </select>
              <div className={classes.nationalitiesContainer}>
                {currentPlayer.wcountryids && (
                  <>
                    {getCountryName(currentPlayer.wcountryids.split(" ")[0])}
                    {currentPlayer.wcountryids.split(" ")[1] && (
                      <>
                        {getCountryName(
                          currentPlayer.wcountryids.split(" ")[1]
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          <div className="largeInput">
            <span className="">
              {translate(user.lang, "country of birth demographic")}
            </span>
            <input
              value={currentPlayer ? currentPlayer.countryofbirth : ""}
              className="inputField largeInput"
              type="text"
              placeholder={translate(user.lang, "country of birth demographic")}
              name="city"
              id="countryofbirth"
              onChange={(e) => handleChange(e.target)}
            />
          </div>

          <div className="largeInput">
            <span className="">
              {translate(user.lang, "location demographic")}
            </span>
            <input
              value={currentPlayer ? currentPlayer.cityofbirth : ""}
              className="inputField largeInput"
              type="text"
              placeholder={translate(user.lang, "location demographic")}
              name="city"
              id="cityofbirth"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          <div className="largeInput">
            <span className="">
              {translate(user.lang, "address demographic")}
            </span>
            <input
              defaultValue={currentPlayer ? currentPlayer.address : ""}
              className="inputField largeInput"
              placeholder="Dirección"
              type="text"
              id="address"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          <div className="largeInput">
            <span className="">
              {translate(user.lang, "city address demographic")}
            </span>
            <input
              defaultValue={currentPlayer ? currentPlayer.curentcity : ""}
              className="inputField largeInput"
              placeholder={translate(user.lang, "city address demographic")}
              type="text"
              id="currentcity"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          <div className="largeInput">
            <span className="">
              {translate(user.lang, "country address demographic")}
            </span>
            <input
              defaultValue={currentPlayer ? currentPlayer.currentcountry : ""}
              className="inputField largeInput"
              placeholder={translate(user.lang, "country address demographic")}
              type="text"
              id="currentcountry"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          <div className="largeInput">
            <span className="">
              {translate(user.lang, "code postal demographic")}
            </span>
            <input
              defaultValue={currentPlayer ? currentPlayer.postale_code : ""}
              className="inputField largeInput"
              placeholder="Código Postal"
              type="number"
              id="postal_code"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          <div className="largeInput">
            <span className="">
              {translate(user.lang, "phone demographic")}
            </span>
            <input
              defaultValue={currentPlayer ? currentPlayer.mobile : ""}
              className="inputField largeInput"
              placeholder="Teléfono"
              type="phone"
              id="mobile"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          <div className="largeInput">
            <span className="">
              {translate(user.lang, "email demographic")}
            </span>
            <input
              defaultValue={currentPlayer ? currentPlayer.email : ""}
              className="inputField largeInput"
              placeholder="Email"
              type="email"
              id="email"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          <div className="largeInput">
            <span className="">
              {translate(user.lang, "Derecho Federativo")}
            </span>
            <input
              defaultValue={currentPlayer ? currentPlayer.federal_law : ""}
              className="inputField largeInput"
              placeholder="Derecho Federativo"
              type="text"
              id="federal_law"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          <div className="largeInput">
            <span className="">
              {translate(user.lang, "Derecho Económico")}
            </span>
            <input
              defaultValue={currentPlayer ? currentPlayer.economic_law : ""}
              className="inputField largeInput"
              placeholder="Derecho Económico"
              type="text"
              id="economic_law"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          <div className="largeInput">
            <span className="">
              {translate(user.lang, "Derecho de Imagen")}
            </span>
            <input
              defaultValue={currentPlayer ? currentPlayer.image_rights : ""}
              className="inputField largeInput"
              placeholder="Derecho de Imagen"
              type="text"
              id="image_rights"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
        </div>
      </div>

      <div className={classes.formContainer}>
        <div className="formHeader">
          <h2>
            {user.roleId !== "1"
              ? translate(user.lang, "data team")
              : translate(user.lang, "club data")}
          </h2>
        </div>
        <div className={classes.inputContainer}>
          {clubs && user.roleId === "1" && (
            <div className="largeInput">
              <span className="">Club</span>
              <select
                value={defaultClub ? defaultClub : "0"}
                required
                className="inputField largeInput"
                id="wclubid"
                onChange={(e) => handleChange(e.target)}
              >
                <option value="0" disabled>
                  Club*
                </option>
                {clubs.map((data, index) => {
                  return (
                    <option key={index} value={data._id}>
                      {data.name}
                    </option>
                  );
                })}
              </select>
              {teamError && <span className="error">Seleccione un Club</span>}
            </div>
          )}

          <div className="largeInput">
            <span className="">{translate(user.lang, "team player")}</span>
            <select
              value={currentPlayer.wteamid ? currentPlayer.wteamid : "1"}
              disabled={!clubs}
              className="inputField largeInput"
              required
              id="wteamid"
              onChange={(e) => handleChange(e.target)}
            >
              <option value="1" disabled>
                {translate(user.lang, "team player")}
              </option>
              {teams &&
                teams.map((data, index) => {
                  return (
                    <option key={index} value={data._id}>
                      {data.name}
                    </option>
                  );
                })}
            </select>
            {teamError && <span className="error">Seleccione un Equipo</span>}
          </div>
        </div>
      </div>

      <div className={classes.formContainer}>
        <div className="formHeader">
          <h2>{translate(user.lang, "player data")}</h2>
        </div>
        <div className={classes.inputContainer}>
          <div className="largeInput">
            <span className="">{translate(user.lang, "position player")}</span>
            <select
              value={currentPlayer ? currentPlayer.rolestring : "0"}
              className="inputField largeInput"
              id="rolestring"
              onChange={(e) => handleChange(e.target)}
            >
              <option value="0" disabled>
                {translate(user.lang, "position player")}
              </option>
              <option value={"GKP"}>Arquero</option>
              <option value={"DEF"}>Defensor</option>
              <option value={"MID"}>Volante</option>
              <option value={"FWD"}>Delantero</option>
            </select>
          </div>
          <div className="largeInput">
            <span className="">{translate(user.lang, "dorsal player")}</span>
            <input
              defaultValue={currentPlayer ? currentPlayer.shirtnumber : ""}
              className="inputField largeInput"
              placeholder="Número"
              type="number"
              id="shirtnumber"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          <div className="largeInput">
            <span className="">{translate(user.lang, "weight")}</span>
            <input
              defaultValue={currentPlayer ? currentPlayer.weight : ""}
              className="inputField largeInput"
              placeholder="Peso (kg) Ej: 80"
              type="number"
              id="weight"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          <div className="largeInput">
            <span className="">{translate(user.lang, "height")}</span>
            <input
              defaultValue={currentPlayer ? currentPlayer.height : ""}
              className="inputField largeInput"
              placeholder="Altura (cm) Ej: 190"
              type="number"
              id="height"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          <div className="largeInput">
            <span className="">{translate(user.lang, "size")}</span>
            <input
              defaultValue={currentPlayer ? currentPlayer.size : ""}
              className="inputField largeInput"
              placeholder="Talle"
              type="text"
              id="size"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          <div className="largeInput">
            <span className="">{translate(user.lang, "skillful foot")}</span>
            <CustomRadioGroup
              defaultValue={
                currentPlayer & currentPlayer.natural_foot
                  ? currentPlayer.natural_foot
                  : "Right"
              }
              className="checkboxContainer"
              id="natural_foot"
              onChange={(e) => {
                setCurrentPlayer({
                  ...currentPlayer,
                  natural_foot: e.target.value,
                });
              }}
            >
              <FormControlLabel
                value="Right"
                control={
                  <Radio
                    icon={<StyledUncheckedRadio />}
                    checkedIcon={<StyledCheckedRadio />}
                  />
                }
                label={<StyledLabel value={translate(user.lang, "rigth")} />}
              />
              <FormControlLabel
                value="Left"
                control={
                  <Radio
                    icon={<StyledUncheckedRadio />}
                    checkedIcon={<StyledCheckedRadio />}
                  />
                }
                label={<StyledLabel value={translate(user.lang, "left")} />}
              />
            </CustomRadioGroup>
          </div>
        </div>
      </div>

      <div className={classes.formContainer}>
        <div className="formHeader">
          <h2>{translate(user.lang, "sponsor details")}</h2>
        </div>
        <div className={classes.inputContainer}>
          {sponsor && (
            <div className="largeInput">
              <span className="">{translate(user.lang, "sponsor")}</span>

              <select
                value={
                  currentPlayer.sponsor_id ? currentPlayer.sponsor_id : "0"
                }
                id="sponsor_id"
                className="inputField select-css"
                onChange={(e) => handleChange(e.target)}
              >
                <option value="0" disabled>
                  Sponsor
                </option>
                {sponsor.map((data, id) => {
                  return (
                    <option value={data._id} key={id}>
                      {data.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <div className="largeInput">
            <span className="">{translate(user.lang, "contract value")}</span>
            <input
              defaultValue={
                currentPlayer ? currentPlayer.sponsor_contract_value : ""
              }
              className="inputField largeInput"
              placeholder="Valor de contrato"
              type="number"
              id="sponsor_contract_value"
              onChange={(e) => handleChange(e.target)}
            />
          </div>
          <div className="largeInput">
            <span className="">
              {translate(user.lang, "start of the contract")}
            </span>
            <DatePicker
              value={currentPlayer ? currentPlayer.sponsor_start_date : ""}
              locale="es"
              selected={startSponsor}
              onChange={(date) => handleChangeDate("sponsor_start_date", date)}
              selectsStart
              startDate={startSponsor}
              endDate={endSponsor}
              dateFormat="dd-MM-yyyy"
              className="inputField largeInput"
              //customInput={<CustomInput />}
            />
          </div>
          <div className="largeInput">
            <span className="">
              {translate(user.lang, "termination of the contract")}
            </span>
            <DatePicker
              value={currentPlayer ? currentPlayer.sponsor_end_date : ""}
              locale="es"
              selected={endSponsor}
              onChange={(date) => handleChangeDate("sponsor_end_date", date)}
              selectsEnd
              startDate={startSponsor}
              endDate={endSponsor}
              minDate={startSponsor}
              dateFormat="dd-MM-yyyy"
              className="inputField largeInput"
              //customInput={<CustomInput />}
            />
          </div>
        </div>
      </div>

      <div className={classes.buttonSaveContainer}>
        <button className="formSaveButton" onClick={() => handleSubmit()}>
          {playerId
            ? translate(user.lang, "update player")
            : translate(user.lang, "create player")}
        </button>
      </div>
      {globalLoading && <GlobalLoading />}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    player: state.player,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddPlayer);
