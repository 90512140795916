import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core';

import { translate } from '../../lang/lang';

import 'react-datepicker/dist/react-datepicker.css';
import InfoModal from "../../components/modals/InfoModal";
import GlobalLoading from '../../components/accessories/GlobalLoading';
import {getClubs, fetchCategoriesByClub, postNewTeam} from '../../endpoints';

const useStyles = makeStyles(theme => ({

    headerContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        justifyContent: 'left',
        marginBottom: '50px',
        '& .titleContainer': {
            width: '100%',
            "& span": {
                fontFamily: "DINCondensed",
                fontSize: "40px",
                lineHeight: "1",
            },
        },
        "& .inputField":{
            color: "#000000",
            width: "259.4px",
            border: "1px solid white",
            outline: "none",
            padding: "10px 22.6px",
            position: "relative",
            fontSize: "18px",
            boxSizing: "border-box",
            transition: "all .1s",
            borderRadius: "4.6px",
            backgroundColor: "#ffffff",
            marginTop: "20px",
            marginLeft: "44.5px",
            fontFamily: "Montserrat-Medium"
        },
    },
    formContainer: {
        boxSizing: 'border-box',
        display: "flex",
        justifyContent: 'space-around',
        alignItems: "space-around",
        borderRadius: "6px",
        backgroundColor: "#16171d",
        flexDirection: 'column',
        marginBottom: '20px',
        "& .formHeader": {
            boxSizing: "border-box",
            padding: "19px 52px",
            height: "60px",
            width: "100%",
            borderRadius: "7px",
            backgroundColor: "#1c1e24",
            display: 'flex',
            alignItems: 'center',
            color: "#6cffe6",
        }
    },
    inputContainer:  {
        minWidth: '80%',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '35px',
        paddingLeft: "52px",
        position:'relative',
        boxSizing: 'border-box',
        fontFamily: 'Open Sans Condensed',
        color:'#FFF',
        '& .largeInput': {
            width: '21%',
            marginRight: '2.5%',
            marginTop: '35px',
            '& option': {
                background:'#181A1D',
                color: '#fff',
            },
            '& .error': {
                color:'red',
            },
        },
        '& .react-datepicker-wrapper': {
            width:'100%'
        },
        '& .label': {
            fontSize: '15px',
            fontFamily: 'Open Sans Condensed',
        },
        '& .required': {
            color: 'red',
        },
        '& .inputField': {
            width: '100%',
            color: '#000000',
            fontSize: "18px",
            fontWeight: "700",
            outline:'none',
            marginTop: '2px',
            padding: '8px 22.6px',
            position: 'relative',
            transition: 'all .1s',
            borderRadius: '4.6px',
            boxSizing: 'border-box',
            border: '1px solid white',
            backgroundColor:'#ffffff',
            fontFamily: 'Open Sans Condensed'
        },
        '& .checkedContainer': {
            position: 'relative',
            width: '20px',
            height: '20px',
            background: '#2E333F'
        },
        '& .checked': {
            position: 'absolute',
            width: '14px',
            height: '14px',
            top: '3px',
            background: '#00BDD8',
            left: '3px',
        },
    },
    buttonSaveContainer : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '100%',
        marginTop: '35px',
        marginBottom: '35px',
        '& button': {
            padding: '10px 15px',
            marginLeft: '25px',
            fontSize:'17px',
            outline: 'none'
        },
        '& .formSaveButton': {
            cursor: 'pointer',
            width: "247px",
            height: "53.8px",
            backgroundColor: "#6cffe6",
            border:"none",
            color: "#000000",
            fontWeight: "700",
            borderRadius: "4px"
        },
    },
}));

export const AddTeam = (props) => {

    const { user } = props;
    const classes = useStyles();
    const [name, setName] = useState('');
    const [clubs, setClubs] = useState('');
    const [nameError, setNameError] = useState(false);
    const [clubError, setClubError] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [categories, setCategories] = useState(null);
    const [clubSelected, setClubSelected] = useState(null);
    const [modalSuccess, setModalSuccess] = useState(false);
    const [categoryError, setCategoryError] = useState(false);
    const [globalLoading, setGlobalLoading] = useState(false);
    const [categorySelected, setCategorySelected] = useState(null);

    const handleChange = (data) => {

        switch(data.id) {
            case 'club_id':
                setClubError(false)
                return setClubSelected(data.value);
            case 'category_id':
                setCategoryError(false)
                return setCategorySelected(data.value);
            default:
                setName(data)
                setNameError(false);
        }
    }

    const handleSubmit = () => {
        
        if(!clubSelected) {
            return setClubError(true);
        }

        if(!categorySelected) {
            return setCategoryError(true);
        }
        
        if(!name) {
            return setNameError(true);
        }
     
        setGlobalLoading(true);

        postNewTeam({ 'name': name, "category_id": categorySelected, "club_id": clubSelected })

        .then((response)=>{
            setModalSuccess(!response.error ? true : false);
            setGlobalLoading(false)
            setOpenModal(true)
        })
        .catch(()=>{
            setGlobalLoading(false)
            setOpenModal(true);
            setModalSuccess(false);
        })
    }

    useEffect( () => {
        if(clubSelected){
            fetchCategoriesByClub(clubSelected).then( (response) => {
                setCategories(response.data.response);
            });
        }
    }, [clubSelected]);

    useEffect(() => {
        getClubs().then((response) => {
            setClubs(response.data.response);
        });
        if(user && user.clubActive){
            handleChange({id: 'club_id', value: user.clubActive._id});
        }
    }, []);

    return (<>

        <div className={classes.headerContainer}>
            <div className='titleContainer'>
                <span>{translate(user.lang, 'create team')}</span>
            </div>
        </div>

        <div className={classes.formContainer}>

            <div className="formHeader">
                <h2>{translate(user.lang, 'general data')}</h2>
            </div>
            
            <div className={classes.inputContainer}>

                { clubs && user._id === '1' &&
                    <div className="largeInput">
                        {}
                        <select
                            defaultValue="1"
                            required
                            className='inputField largeInput'
                            id='club_id'
                            onChange={(e)=>handleChange(e.target)}>
                                <option value="1" disabled>Club*</option>
                                {clubs.map((data, index)=> {
                                    return <option key={index} value={data._id}>{data.name}</option>
                                })}
                        </select>
                        { clubError &&
                            <span className="error">{translate(user.lang, 'general data')}</span>
                        }
                    </div>
                }

                { categories &&
                    <div className="largeInput">
                        {}
                        <select
                            defaultValue="0"
                            required
                            className='inputField largeInput'
                            id='category_id'
                            onChange={(e)=>handleChange(e.target)}>
                                <option value="0" disabled>{translate(user.lang, 'category')}*</option>
                                {categories.map((data, index)=> {
                                    return <option key={index} value={data._id}>{data.name}</option>
                                })}
                        </select>
                        { categoryError &&
                            <span className="error">Seleccione una Categoria</span>
                        }
                    </div>
                }

                <div className="largeInput">
                    <input className='inputField' value={name} placeholder={translate(user.lang, 'team name')} type="text" id="name" onChange={(e)=>handleChange(e.target.value)}/>
                        { nameError &&
                            <span className="error">El campo es obligatorio</span>
                        }
                </div>
            </div>
        </div>

        <div className={classes.buttonSaveContainer}>
            <button className='formSaveButton' onClick={ () => handleSubmit() }>{translate(user.lang, 'create team')}</button>
        </div>

        <InfoModal 
            openModal={openModal}
            setOpenModal={setOpenModal}
            modalSuccess={modalSuccess}
            msg={modalSuccess ? translate(user.lang, 'create-team-msg') : translate(user.lang, 'no-create-team-msg')}
        />

        { globalLoading &&  <GlobalLoading /> }
        
    </>)
}
export default React.memo(AddTeam);