import React, { Component } from "react";

import "./Select.css";

import {
  filterArrayByProperty,
} from "../../../services";

class SelectBox extends Component {
  state = {
    items: this.props.items || [],
    showItems: false,
  };
  inputRef = React.createRef();
  UNSAFE_componentWillMount = () => {
    document.addEventListener("mousedown", this.handleClick, false);
  };
  componentDidMount = () => {
    if (this.props.value) {
      // console.log("ref", this.inputRef);
      let filterTemp = filterArrayByProperty(
        this.props.items,
        this.props.keyParam,
        this.props.value
      );

      if(filterTemp[0]){
        filterTemp = filterTemp[0].name;
      }

      // console.log(filterTemp);
      if (filterTemp && this.inputRef.current)
        this.inputRef.current.value = filterTemp;
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClick, false);
  };
  handleClick = (e) => {
    if (this.node.contains(e.target)) return;
    this.handleClickOutside();
  };
  handleClickOutside = () => {
    this.setState({
      showItems: false,
    });
  };
  dropDown = () => {
    this.setState(
      (prevState) => ({
        showItems: !prevState.showItems,
      }),
      () => {
        if (this.state.showItems) {
          //this.inputRef.current.focus();
        }
      }
    );
  };
  selectItem = (item) => {
    if (item.wid) {
      this.props.newItemSelected(this.props.keyName, item.wid);
    } else {
      this.props.newItemSelected(this.props.keyName, item._id);
    }
    //this.props.component !== "AnalisisVideo" ? this.props.newItemSelected(this.props.keyName, item.wid) : this.props.newItemSelected(this.props.keyName, item._id)
    this.setState(
      (prevState) => ({
        showItems: !prevState.showItems,
      }),
      () => {
        if (this.inputRef.current) {
          this.inputRef.current.value = item.name;
        }
      }
    );
  };

  selectItemEmpty = () => {
    this.props.newItemSelectedEmpty(this.props.keyName);
    this.setState(
      (prevState) => ({
        showItems: !prevState.showItems,
      }),
      () => {
        if (this.inputRef.current) {
          this.inputRef.current.value = "";
        }
      }
    );
  };

  normalize = (function() {
    let from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüû",
      to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuu",
      mapping = {};

    for (let i = 0, j = from.length; i < j; i++)
      mapping[from.charAt(i)] = to.charAt(i);

    return function(str) {
      let ret = [];
      for (let i = 0, j = str.length; i < j; i++) {
        let c = str.charAt(i);
        if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
        else ret.push(c);
      }
      return ret.join("");
    };
  })();

  handleChange = (e) => {
    const newItems = this.state.items;
    const itemsFiltered = newItems.filter((el) => {
      const item = this.normalize(el.name.toLowerCase());

      return item.includes(e.target.value.toLowerCase());
    });

    if (e.target.value.length > 0) {
      this.setState({
        items: itemsFiltered,
      });
    } else {
      this.setState({
        items: this.props.items || [],
      });
    }
  };

  render() {
    let selectedItem = this.props.selectedItem ? this.props.selectedItem : "";
    let before = this.props.before ? "before" : "";

    return (
      <>
        <div
          ref={(node) => (this.node = node)}
          className={`select-box--box ${this.props.disable ? "disable" : ""}`}
        >
          <div className="select-box--container">
            {this.props.before ? (
              <span
                className={`status status--${selectedItem.toLowerCase()}`}
              ></span>
            ) : null}

            <div className={`select-box--selected-item ${before}`}>
              {this.props.search && (
                <input
                  ref={this.inputRef}
                  className="inputSearchClub"
                  type="text"
                  name="club"
                  id={this.props.idSelect}
                  onChange={this.handleChange}
                  onClick={this.dropDown}
                  placeholder={this.props.nameSelect}
                />
              )}
              {(!this.props.search && selectedItem) || this.props.nameSelect}
            </div>
            <div className="select-box--arrow" onClick={this.dropDown}>
              <span
                className={`${
                  this.state.showItems
                    ? "select-box--arrow-up"
                    : "select-box--arrow-down"
                }`}
              />
            </div>
            <div
              className="select-box--items"
              style={{ display: this.state.showItems ? "block" : "none" }}
            >
              <div
                onClick={() => this.selectItemEmpty()}
                style={{ textTransform: "uppercase" }}
              >
                Ver todos
              </div>
              {this.state.items.map((item, index) => (
                <div
                  key={index}
                  onClick={() => this.selectItem(item)}
                  className={
                    selectedItem.toLowerCase() === item.name.toLowerCase()
                      ? "selected"
                      : ""
                  }
                  style={{
                    textTransform: this.props.uppercase
                      ? "uppercase"
                      : "capitalize",
                  }}
                >
                  {item.name ? item.name.toLowerCase() : ""}
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SelectBox;
