import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dropContainer: {
    outline: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "3px dashed rgba(255,255,255,0.8)",
    minWidth: "500px",
    width: "100%",
    minHeight: "160px",
    backgroundColor: "rgba(223, 230, 237, 0.3)",
    fontFamily: "Open Sans Condensed",
    zIndex: "1000",
  },
}));

const Dropzone = props => {
  const classes = useStyles();

  const onDrop = useCallback(acceptedFiles => {
    props.onDrop(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <div className={classes.dropContainer}>
          <p>Drop player image here</p>
        </div>
      ) : (
        <div className={props.propClass}>
          <img src={props.img} alt='icon-profile' />
          {props.string && <p>{props.string}</p>}
        </div>
      )}
    </div>
  );
};

export default Dropzone;
