import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { getWPlayerById } from "../../endpoints";

import { translate } from "../../lang/lang";

const useStyles = makeStyles(() => ({
  root: {
    "& > *": {
      margin: "1px",
      fontFamily: "Bebas-Bold",
    },
  },
  margin: {
    //marginLeft: theme.spacing(10),
  },
  btn: {
    backgroundColor: "#1E4249",
    color: "#E1F5E9",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#0A1A24",
      color: "#B1EFF4",
    },
    fontFamily: "Bebas-Bold !important",
    fontSize: "1.5rem !important",
  },
  active: {
    backgroundColor: "#0A1A24",
    color: "#B1EFF4",
    fontFamily: "Bebas-Bold",
    fontSize: "24px",
  },
  btnOldTheme: {
    backgroundColor: "#131319",
    color: "#E1F5E9",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#0A1A24",
      color: "#B1EFF4",
    },
    fontFamily: "Bebas-Bold !important",
    fontSize: "1.5rem !important",
  },
  activeOldTheme: {
    backgroundColor: "#0A1A24",
    color: "#B1EFF4",
    fontFamily: "Bebas-Bold",
    fontSize: "24px",
  },

  link: {
    textDecoration: "none",
  },
}));

const NavCardPlayer = (props) => {
  const { user } = props;

  const id = props.match.params.id;
  const [teamId, setTeamId] = useState("");

  useEffect(() => {
    getWPlayerById(id)
      .then((res) => {
        setTeamId(res.data.response.teams[0]._id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.view]);

  const classes = useStyles();
  return (
    <div className={classes.margin}>
      <div className={classes.root}>
        <Link to={`/card-player/${id}`} className={classes.link}>
          <Button
            className={props.view === 0 ? classes.active : classes.btn}
            variant="contained"
            onClick={() => {
              props.setView(0);
            }}
            style={{ fontFamily: "Bebas-Bold", fontSize: "24px" }}
          >
            {translate(user.lang, "summary")}
          </Button>
        </Link>
        <Link to={`/card-player/${id}/rating/${id}`} className={classes.link}>
          <Button
            className={props.view === 1 ? classes.active : classes.btn}
            variant="contained"
            onClick={() => {
              props.setView(1);
            }}
          >
            {translate(user.lang, "assessment")}
          </Button>
        </Link>
        <Link
          to={`/card-player/${id}/gps/team/${teamId}?default-player=${id}`}
          className={classes.link}
        >
          <Button
            className={props.view === 2 ? classes.active : classes.btn}
            variant="contained"
            onClick={() => {
              props.setView(2);
            }}
            style={{ fontFamily: "Bebas-Bold", fontSize: "24px" }}
          >
            {translate(user.lang, "gps")}
          </Button>
        </Link>
        <Link
          to={`/card-player/${id}/event-video-list?wplayer_id1=${id}`}
          className={classes.link}
        >
          <Button
            className={props.view === 3 ? classes.active : classes.btn}
            variant="contained"
            onClick={() => {
              props.setView(3);
            }}
            style={{ fontFamily: "Bebas-Bold", fontSize: "24px" }}
          >
            {translate(user.lang, "video analysis")}
          </Button>
        </Link>
        <Link to={`/card-player/${id}/myfolders`} className={classes.link}>
          <Button
            className={props.view === 4 ? classes.active : classes.btn}
            variant="contained"
            onClick={() => {
              props.setView(4);
            }}
            style={{ fontFamily: "Bebas-Bold", fontSize: "24px" }}
          >
            {translate(user.lang, "my files")}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NavCardPlayer;
