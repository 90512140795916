import React, { useEffect, useState } from 'react';

import useStyles from '../assets/MakeStyles';
import GlobalLoading from "../components/accessories/GlobalLoading";
import { translate } from '../lang/lang';

import {
    searchGPexeTracksByTeams,
} from "../endpoints";

const GpexeTrackList = (props) => {

    const classes = useStyles();
    const {teamOfClub, user} = props;
    const queryString = require("query-string");
    const [globalLoading, setGlobalLoading] = useState(true);
    const [tracks, setTracks] = useState({tracks: {}, teamSelected: null});
    const [params, setParams] = useState({team_id: queryString.parse(props.location.search).team_id, limit: 10000, offset: 0});

    const handleChange = (data) => {

        if (data.id === "team") {
            setGlobalLoading(true);
            setPush({...params, team_id: data.value});
            setParams({...params, team_id: data.value});
        }
    };

    const setPush = ({team_id, limit, offset}) => {
        props.history.push({ search: `?limit=${limit}&offset=${offset}&team_id=${team_id}` });
    };

    const showTrackTable = () => {

        return  <table className='table gpexe-table'>
                    <thead>
                        <tr>
                            <th>athlete</th>
                            <th>device</th>
                            <th>date</th>
                            <th>start time</th>
                            <th>duration</th>
                            <th>sessions</th>
                            <th>dist m</th>
                            <th>dist / sp z5 m</th>
                            <th>edi %</th>
                        </tr>
                    </thead>
                    <tbody>
                        { Object.keys(tracks.tracks[tracks.teamSelected]).map( key => {
                        return  <tr key={key} className={`row-items ${classes.rowSessionItem}`} >
                                    <td className="cell-row">{tracks.tracks[tracks.teamSelected][key].athlete}</td>
                                    <td className="cell-row">{tracks.tracks[tracks.teamSelected][key].device}</td>
                                    <td className="cell-row">{new Date(tracks.tracks[tracks.teamSelected][key].timestamp).toLocaleDateString("en-US")}</td>
                                    <td className="cell-row">{new Date(tracks.tracks[tracks.teamSelected][key].timestamp).toLocaleTimeString("en-US")}</td>
                                    <td className="cell-row">{tracks.tracks[tracks.teamSelected][key].duration_formatted}</td>
                                    <td className="cell-row">{tracks.tracks[tracks.teamSelected][key].sessions}</td>
                                    <td className="cell-row">{tracks.tracks[tracks.teamSelected][key].template_fields.total_distance.value}</td>
                                    <td className="cell-row">{tracks.tracks[tracks.teamSelected][key].template_fields.athletesessionspeedzone_distance_5.value}</td>
                                    <td className="cell-row">{tracks.tracks[tracks.teamSelected][key].template_fields.equivalent_distance_index.value}</td>
                                </tr>
                        })
                        }
                        </tbody>
                </table>
    }

    useEffect( () => {

        if(params.team_id){
            if( !tracks.tracks[params.team_id] ){
                searchGPexeTracksByTeams({ team_id: params.team_id, limit: 10000, offset: 0 })
                .then( (resp) => {
                    if(resp.data.code === 200){console.log(resp.data.tracks)
                        setTracks({
                            teamSelected: params.team_id,
                            tracks: {...tracks.tracks, [params.team_id] : resp.data.tracks }
                        });
                    }else{
                        setTracks({
                            teamSelected: params.team_id,
                            tracks: {...tracks.tracks, [params.team_id] : [] }
                        });
                    }
                    setGlobalLoading(false);
                })
                .catch( (err) => {
                  console.log(err);
                  setGlobalLoading(false);
                });
            }else{
                setTracks({...tracks, teamSelected: params.team_id});
                setGlobalLoading(false);
            }
            
        }else{
            if( teamOfClub.length > 0 ){
                setParams({...params, team_id: teamOfClub[0]._id});
                setPush({...params, team_id: teamOfClub[0]._id});
            }
        }

        if(!props.location.search && tracks.teamSelected){
            setPush({...params, team_id: tracks.teamSelected});
        }

    }, [props.location.search, teamOfClub]);

    return (<>
    <div className={classes.headerContainer}>

        <div className="titleContainer">
            <span>{translate(user.lang, 'gpexe tracks listing')}</span>
        </div>

        <div className="paragraphContainer">
            <p>{translate(user.lang, 'gpexe-tracks-title')}</p>
        </div>

        <div className={classes.inputContainer}>
            <div className="largeInput">
                <select
                    value={ params.team_id ? params.team_id : 0 }
                    required
                    className="inputField"
                    id="team"
                    onChange={ (e) => handleChange(e.target) }
                >
                <option value="0" disabled>
                    {translate(user.lang, 'teams')}
                </option>
                {teamOfClub.map((data, index) => {
                    return (
                    <option key={index} value={data._id}>
                        {data.name}
                    </option>
                    );
                })}
                </select>
            </div>
        </div>

        {globalLoading ? (
            <GlobalLoading />
        ) : (
            <div className="tableContainer">
                {/*console.log(tracks.tracks[tracks.teamSelected])*/}
                { tracks.tracks[tracks.teamSelected].length > 0 ?
                    <div>{showTrackTable()}</div>
                :
                    <div>{translate(user.lang, 'no tracks')}</div>
                }
            </div>
        )}

    </div>
    </>);
};
export default React.memo(GpexeTrackList);