import React, {useState} from 'react';

import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {ScatterChart, Scatter, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, Legend, ReferenceLine} from 'recharts';

const useStyles = makeStyles(theme => ({
    customTooltip: {
        background: '#000000bd',
        borderRadius: '8px',
        padding: '5px',
        opacity: '0.9'
    },
    scatterChart: {
        transform: 'translateX(-50px)',
        '& > .recharts-surface': {
            width: 'calc(100% - 100px)',
            overflow: 'visible'
        },
        '& .yAxis .recharts-layer': {
            transform: 'translateX(5px)'
        },
        '& .xAxis > line': {
            transform: 'translateY(-10px)'
        },
        '& .recharts-cartesian-grid-horizontal, .recharts-cartesian-grid-vertical':{
            display: 'none'
        },
        '& .recharts-reference-line text': {
            transform: 'translateX(415px)'
        },
        '& .recharts-legend-wrapper': {
            bottom: '40px !important'
        }
    },
    rangeCartesianContainer: {
        borderRadius: '9.12px',
        border: '1.2px solid #979797',
        width: '100%', display: 'flex',
        marginTop: '-15px',
    },
    rangeCartesianInputContainer: {
        width: '70%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        '& .MuiFormControl-root': {
            maxWidth: '60px',
            padding: '20px 10px 5px',
            border: '1.2px solid #979797',
            borderRadius: '3.42px',
        },
        '& .MuiFilledInput-input, .MuiInputLabel-filled': {
            padding: '0',
            color: '#ffffff',
            fontSize: '12px'
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent'
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            color: '#ffffff',
            transform: 'translate(5px, 5px) scale(0.70)',
        },
        '& .MuiFilledInput-underline:before':{ 
            borderBottom: 'none'
        },
    },
    rangeCartesianTitle: {
        width: '30%',
        height: '63px',
        display: 'flex',
        alignItems: 'center',
        background: '#313642',
        '& span:before': {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            margin: '0px 5px 0 0',
            content: 'close-quote',
            display: 'inline-block',
        },
        '& span.xAxys:before': {
            backgroundColor: '#44a0bb',
        },
        '& span.yAxys:before': {
            backgroundColor: '#d9b828',
        }
        
    },
    invalidInput: {
        border: '2px solid #d11f1f!important',
        animationDelay: '.2s',
        animation: `$errorActive 500ms ${theme.transitions.easing.easeInOut}`,
    },
    validInput: {
        transition: 'all .2s',
        border: '1.2px solid #979797'
    },
    '@keyframes errorActive': {
        "0%, 100%": {
            transform: 'translateX(0)',
        },
        "10%, 30%, 50%,70%": {
            transform: 'translateX(-3px)',
        },
        "20%, 40%, 60%": {
            transform: 'translateX(3px)'
        },
        "80%": {
            transform: 'translateX(2px)'
        },
        "90%": {
            transform: 'translateX(-2px)'
        }
    },
}));

const GetTeamEnergyExpediture = (props) => {

    const classes = useStyles();
    const {resetChartBuilderData} = props;
    
    let {data, playersFilters} = resetChartBuilderData();

    const [rangeCartesian, setRangeCartesian] = useState({
        xMin: { valid: true , value: 1 },
        xMax: { valid: true , value: 1 },
        yMin: { valid: true , value: 1 },
        yMax: { valid: true , value: 1 },
    })

    let maxEnergy = 0
    let minEnergy = 0
    let averageX = 0
    let averageY = 0
    
    let minX = 0
    let maxX = 0
    let minY = 0
    let maxY = 0

    let handleChangeCartesian = (e) => {

        let {name, value} = e.target;
        let rangeCartesianTemp = {...rangeCartesian}
        value = parseInt(value) 
        //console.log("valueTemp: ", valueTemp);
        //if( value > 0){
            if(name === 'xMin' || name === 'xMax'){

                rangeCartesianTemp = {
                    xMin: { valid: true , value: rangeCartesianTemp['xMin'].value },
                    xMax: { valid: true , value: rangeCartesianTemp['xMax'].value },
                    yMin:  rangeCartesianTemp['yMin'] ,
                    yMax: rangeCartesianTemp['yMax'],
                }
            } else {
                rangeCartesianTemp = {
                    xMin: rangeCartesianTemp['xMin'],
                    xMax: rangeCartesianTemp['xMax'],
                    yMin: { valid: true, value: rangeCartesianTemp['yMin'].value },
                    yMax: { valid: true, value: rangeCartesianTemp['yMax'].value },
                }
            }

            switch(name) {
                case 'xMin':
                    if( rangeCartesianTemp.xMax.value < value ) { 
                        // rangeCartesianTemp = {...rangeCartesianTemp, xMax: value}
                       let xMaxTemp = { ...rangeCartesianTemp['xMax'], valid: false }
                       rangeCartesianTemp = { ...rangeCartesianTemp, xMax: xMaxTemp }
                    } 
                    break;

                case 'xMax':
                    if( rangeCartesianTemp.xMin.value > value ) { 
                        // rangeCartesianTemp = {...rangeCartesianTemp, xMin: value} 
                        let xMinTemp = { ...rangeCartesianTemp['xMin'], valid: false }
                        rangeCartesianTemp = { ...rangeCartesianTemp, xMin: xMinTemp }
                    }
                    break;
                case 'yMin':
                    if( rangeCartesianTemp.yMax.value < value ) { 
                        // rangeCartesianTemp = {...rangeCartesianTemp, yMax: value}
                        let yMaxTemp = { ...rangeCartesianTemp['yMax'], valid: false }
                        rangeCartesianTemp = { ...rangeCartesianTemp, yMax: yMaxTemp }
                    } 
                    break;

                case 'yMax': 
                    if( rangeCartesianTemp.yMin.value > value ) { 
                        // rangeCartesianTemp = {...rangeCartesianTemp, yMin: value}
                        let yMinTemp = { ...rangeCartesianTemp['yMin'], valid: false }
                        rangeCartesianTemp = { ...rangeCartesianTemp, yMin: yMinTemp }
                    } 
                    break;

                default:
                    // valueTemp = {...valueTemp, valid: true}
                    break;
            }

            //console.log(name, valueTemp)
            let valueTemp = rangeCartesianTemp[name]
            valueTemp = { ...valueTemp, value: value }
            
            rangeCartesianTemp = {...rangeCartesianTemp, [name]: valueTemp }

            //console.log("rangeCartesianTemp: ", rangeCartesianTemp)
            setRangeCartesian(rangeCartesianTemp);
        //}
    }

    Object.keys(data).map( (key) => {
        Object.keys(data[key]).map( (index) => {
            if( data[key][index]['z'] > maxEnergy ){
                maxEnergy = data[key][index]['z'];
                if(minEnergy === 0){
                    minEnergy = maxEnergy;
                }
            }
            if(minEnergy > data[key][index]['z'] ){
                minEnergy = data[key][index]['z']
            }
            if( data[key][index]['x'] > maxX ){
                maxX = data[key][index]['x'];
                if(minX === 0){
                    minX = maxX;
                }
            }
            if(minX > data[key][index]['x'] ){
                minX = data[key][index]['x']
            }
            if( data[key][index]['y'] > maxY ){
                maxY = data[key][index]['y'];
                if(minY === 0){
                    minY = maxY;
                }
            }
            if(minY > data[key][index]['y'] ){
                minY = data[key][index]['y']
            }
            return null
        })
        return null
    });

    let rangeEnergy = maxEnergy - minEnergy;

    Object.keys(data).map( (key) => {
        Object.keys(data[key]).map( (index) => {
            let percentageOriginal = (maxEnergy - data[key][index]['z']) * (100/rangeEnergy);
            let equivalentPercentage = ( 1960 - (1960/100 * percentageOriginal ) ) + 40
            data[key][index] = {
                ...data[key][index], P_energy: Math.round(equivalentPercentage)
            }
            averageX = averageX + data[key][index]['x'] ;
            averageY = averageY + data[key][index]['y'] ;
            return null
        })
        return null
    });

    averageX = averageX / playersFilters.length
    averageY = averageY / playersFilters.length

    let diffUpX = maxX - averageX
    let diffDownX = averageX - minX
    let diffUpY = maxY - averageY
    let diffDownY = averageY - minY

    if (diffUpX >= diffDownX){
        maxX = diffUpX + (diffUpX / 10)
        minX = Math.floor(averageX - maxX)
        maxX = Math.ceil(averageX + maxX)
    }else{
        minX = diffDownX + (diffDownX / 10)
        maxX = Math.ceil(averageX + minX)
        minX = Math.floor(averageX - minX)
    }

    if (diffUpY >= diffDownY){
        maxY = diffUpY + (diffUpY / 10)
        minY = Math.floor(averageY - maxY)
        maxY = Math.ceil(averageY + maxY)
    }else{
        minY = diffDownY + (diffDownY / 10)
        maxY = Math.ceil(averageY + minY)
        minY = Math.floor(averageY - minY)
    }

    let shape = [
        {shape: "star", color: "#8884d8"},
        {shape: "triangle", color: "#52BE80"},
        {shape: "diamond", color: "#5DADE2"},
        {shape: "circle", color: "#F1C40F"},
        {shape: "cross", color: "#C0392B"},
        {shape: "pentagon", color: "#D0D3D4"} 
    ]

    function CustomTooltipScatter({ payload, label, active }) {

        if (active && payload) {
          return (
            <div className={classes.customTooltip}>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Avatar 
                        src={ payload[0].payload.player_picture }
                        alt="Player name"
                        style={ {backgroundColor: '#ffffff', width: '52.16px', height: '52.16px', paddingTop: '0'} }
                    />
                </div>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <p className="label"><b>{payload[0].payload.player_name}</b></p>
                </div>
                <br/>
                { false && 
                    <p className="label"><b>{payload[0].name}: </b> {payload[0].payload.x}</p>
                }
                <p className="label"><b>Time/Min: </b> {payload[0].payload.x} ({payload[0].payload.time})</p>
                <p className="label"><b>{payload[1].name}: </b> {payload[0].payload.y}</p>
                <p className="label"><b>Energy: </b> {payload[0].payload.z}</p>
                { false && 
                    <p className="label"><b>{payload[2].name}: </b> {payload[0].payload.z}</p>
                }
            </div>
          );
        }
        return null;
    }
    //console.log(rangeCartesian)
    return (
    <div style={{marginTop: '-30px'}}>
        <ScatterChart
            className={classes.scatterChart}
            width={570}
            height={570}
            margin={{
                top: 20, right: 20, bottom: 20, left: 20,
            }}
        >
            <CartesianGrid fill="#18191d" />
            <XAxis type="number" dataKey="x" name="Time" unit="-Min" domain={[minX, maxX]} />
            <YAxis type="number" dataKey="y" name="Watts" unit="-Watts" domain={[minY, maxY]} />
            <ZAxis type="number" dataKey="P_energy" range={[40, 2000]} name="Energy" />
            <ReferenceLine x={averageX} stroke="red" label={`Average Watts: ` + parseFloat(averageY).toFixed(2) + ` /  Time/Min: ` + parseFloat(averageX).toFixed(2)} />
            <ReferenceLine y={averageY} stroke="red" />

            { rangeCartesian.xMin.valid  &&
                <ReferenceLine x={rangeCartesian.xMin.value} stroke="#44a0bb" />
            }

            { rangeCartesian.xMax.valid && 
                rangeCartesian.xMax.valid &&
                <ReferenceLine x={rangeCartesian.xMax.value} stroke="#44a0bb" />
            }

            { rangeCartesian.yMin.valid   &&
                <ReferenceLine y={rangeCartesian.yMin.value} stroke="#d9b828" />
            }

            { rangeCartesian.yMax.valid   &&
                <ReferenceLine y={rangeCartesian.yMax.value} stroke="#d9b828" />
            }

            <Tooltip content={<CustomTooltipScatter />}/>
            <Legend />
            { 
                Object.keys(data).map( (key, index) => {
                    return <Scatter key={key+"Scatter"} name={key} data={data[key]} fill={shape[index].color} shape="circle" />
                })
            }
        </ScatterChart>

        <div className={classes.rangeCartesianContainer} >
            <div style={{width: '50%', display: 'flex'}}>
                <div style={{borderRadius: '9.12px 0 0 9.12px'}} className={classes.rangeCartesianTitle}><span className="xAxys" style={{margin: 'auto'}}>Time</span></div>
                <div className={classes.rangeCartesianInputContainer}>
                    <TextField
                        type="number"
                        name="xMin"
                        label="Min."
                        variant="filled"
                        value={rangeCartesian.xMin.value ? rangeCartesian.xMin.value : ""}
                        onChange={handleChangeCartesian}
                        className={!rangeCartesian.xMin.valid ? classes.invalidInput : classes.validInput}
                    />
                    <TextField
                        type="number"
                        name="xMax"
                        label="Max."
                        variant="filled"
                        value={rangeCartesian.xMax.value ? rangeCartesian.xMax.value : ""}
                        onChange={handleChangeCartesian}
                        className={!rangeCartesian.xMax.valid ? classes.invalidInput : classes.validInput}

                    />
                </div>
            </div>
            <div style={{width: '50%', display: 'flex'}}>
                <div className={classes.rangeCartesianTitle}><span className="yAxys" style={{margin: 'auto'}}>Watts</span></div>
                <div className={classes.rangeCartesianInputContainer}>
                    <TextField
                        type="number"
                        name="yMin"
                        label="Min."
                        variant="filled"
                        value={rangeCartesian.yMin.value ? rangeCartesian.yMin.value : ""}
                        onChange={handleChangeCartesian}
                        className={!rangeCartesian.yMin.valid ? classes.invalidInput : classes.validInput}

                    />
                    <TextField
                        type="number"
                        name="yMax"
                        label="Max."
                        variant="filled"
                        value={rangeCartesian.yMax.value ? rangeCartesian.yMax.value : ""}
                        onChange={handleChangeCartesian}
                        className={!rangeCartesian.yMax.valid ? classes.invalidInput : classes.validInput}

                    />
                </div>
            </div>
        </div>
    </div>
    );
};
export default React.memo(GetTeamEnergyExpediture);