import React, { useState } from "react";

import { login } from "../endpoints";
//import {debounce} from '../services';
import { connect } from "react-redux";
import Logo from "../components/Logo/Logo";
import useStyles from "../assets/MakeStyles";
import { useHotkey } from "@react-hook/hotkey";
import { update_user } from "../../js/actions/index";
import { useHistory, useLocation } from "react-router-dom";
import GlobalLoading from "../components/accessories/GlobalLoading";

const Login = (props) => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { handleLoginModal, set_update_user } = props;
  const [globalLoading, setGlobalLoading] = useState(false);
  const [loginForm, setLoginForm] = useState({
    username: null,
    password: null,
  });
  const [incorrectLogin, setIncorrecLogin] = useState(false);
  //let myDebounceFormLogin = null;

  const handleLogin = async () => {
    if (loginForm.username) {
      setGlobalLoading(true);
      let response = await login(loginForm);
      if (!response.error) {
        localStorage.setItem("apikey", response.user.apikey);
        localStorage.setItem("userId", response.user._id);
        let userTemp = response.user;
        if (response.user.wclubs && response.user.wclubs.length > 0) {
          userTemp = { ...userTemp, clubActive: response.user.wclubs[0] };
        }
        set_update_user(userTemp);
        // if (location.pathname === "/login") {
        const user = JSON.parse(localStorage.getItem("state")).user;
        const role = user.roleName;
        if (role !== "Admin" && role !== "Directivo") {
          history.push(`/card-player/${user._id}`);
        } else {
          history.push("/");
        }
      }
      // } else {
      //   console.log("error");
      //   setGlobalLoading(false);
      //   setIncorrecLogin(true);
      //   handleLoginModal.set_login_modal(false);
      // }
    }
  };

  const ref = useHotkey("enter", handleLogin);

  const handleLoginForm = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
    //if(myDebounceFormLogin){ clearTimeout(myDebounceFormLogin) };
    //myDebounceFormLogin = debounce(setLoginForm, 500, {...loginForm, [e.target.name] : e.target.value} );
  };

  return (
    <>
      <div className={classes.LoginContainer}>
        <div className={classes.LoginBox}>
          <div className="header-container">
            <Logo fontSize="51px" />
          </div>

          <div className="subheader-container">
            <p>
              <b>Iniciá sesión.</b>
            </p>
            <p>Por favor, ingresá tu email y contraseña</p>
          </div>

          <div className={classes.formContainer}>
            <input
              className={classes.inputLogin}
              ref={ref}
              name="username"
              type="email"
              placeholder="Usuario (email)"
              onChange={(e) => handleLoginForm(e)}
            />
            <input
              className={classes.inputLogin}
              ref={ref}
              name="password"
              type="password"
              placeholder="Contraseña"
              onChange={(e) => handleLoginForm(e)}
            />
            <div
              onClick={() => handleLogin()}
              className={classes.loginButtom + " default-buttom"}
            >
              <span>Iniciar sesión</span>
            </div>
          </div>

          {incorrectLogin && (
            <div className={classes.incorrectLogin}>
              <span>Usuario o contraseña incorrecta</span>
            </div>
          )}

          <div className="foot-login-container">
            <p>
              Al continuar, aceptas nuestras{" "}
              <a href="/login"> Politicas de privacidad</a> y nuestras{" "}
              <a href="/login">Condiciones de uso general</a>
            </p>
            {/* <a
              href="C:\\xampp\\htdocs\\gool-api\\app/../web/uploads/brochures/AXYesKowUWvYWK_ZKG4N.jpeg"
              download
            >
              Download Text
            </a> */}
          </div>
        </div>
      </div>
      e{globalLoading && <GlobalLoading />}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    set_update_user: (user) => dispatch(update_user(user)),
  };
}

const AppLogin = connect(null, mapDispatchToProps)(Login);
export default React.memo(AppLogin);
