import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import CompetitionSetupComponent from '../components/CompetitionFixtureComponent';

import  { getCompetitionsById, getFixtureByCompetitionId, 
    //getObjectFromArrayWithKeysByProperty, 
    generateFixtureByCompetitionId } from '../endpoints'; //Probablemente temporal el torneo deberia estar ya setteado en el redux

const mapStateToProps = state => {
    return { user: state.user, competition: state.competition };
};

const AppCompetitionFixture = (props) => {

    const [competition, setCompetition] = useState(null); //Probablemente temporal el torneo deberia estar ya setteado en el redux
    //const [teamsCompetition, setTeamsCompetition] = useState(null)
    const [fixture, setFixture] = useState([])

    useEffect( () => {

        async function searchCompetitionById() {

            let response = await getCompetitionsById('AXDo-V3TNEw0YmD29YMj') //Probablemente temporal el torneo deberia estar ya setteado en el redux
            let loadCompetition = response.data.response;
            setCompetition( loadCompetition ) //Probablemente temporal el torneo deberia estar ya setteado en el redux
            //setTeamsCompetition( getObjectFromArrayWithKeysByProperty(response.data.response.team_ids, '_id') )

            response = await getFixtureByCompetitionId('AXDo-V3TNEw0YmD29YMj')
            if(response.data.rounds && response.data.rounds.length > 0){
                setFixture(response.data.rounds)
            }else{
                response = await generateFixtureByCompetitionId('AXDo-V3TNEw0YmD29YMj')
                let newFixture = response.response.map( journeys => {
                    return journeys.map( journey => {
                        return journey.map( match => {
                            return loadCompetition.team_ids[match - 1]
                        })
                    })
                })
                setFixture(newFixture)
                /*
                console.log(response.response)
                console.log( newFixture )
                */
            }
        }
        searchCompetitionById()

    },[])

    /*
    console.log(competition)
    console.log(teamsCompetition)
    console.log(fixture)
    */

    return (<>
        <CompetitionSetupComponent competition={competition} fixture={fixture} />
    </>);
};
const CompetitionSetup = connect(mapStateToProps, null)(AppCompetitionFixture);
export default React.memo(CompetitionSetup);