import React, {useState, useEffect} from 'react'
import { makeStyles} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../../assets/img/search.svg';
import { ReactComponent as TitleIcon} from '../../../assets/img/trophy-ball.svg';
import { connect } from "react-redux";
import { modifyFormCategoryHandler } from "../../../actions/index"
import { CategoryCheckBox } from '../../Cards/CategoryCheckBox'
import { getAllCompetitions } from '../../../endpoints';


const useStyles = makeStyles(theme => ({
    stepscontainer: {
        width: '540px',
        position: 'relative',
        display: 'flex',
        margin: '0 auto',
        justifyContent: 'space-between',

        '&::after': {
            content: '""',
            position: 'absolute',
            width: '96%',
            border: 'solid 1.5px #B2B2B2',
            top: '6.5px',
            left: '5px',
        },

        '&::before': {
            content: '""',
            position: 'absolute',
            width: '23%',
            border: 'solid 1.5px #FDCA40',
            top: '6.5px',
            left: '5px',
            zIndex: '3'
        },

        '& .yellowStep': {
            width: '16.07px',
            height: '16.07px',
            borderRadius: '100%',
            backgroundColor: '#FDCA40',
            zIndex: '3',
        },

        '& .greyStep': {
            width: '16.07px',
            height: '16.07px',
            borderRadius: '100%',
            backgroundColor: '#B2B2B2',
            zIndex: '3',
        },
    },

    nameContainer: {
        width: '630px',
        display: 'flex',
        margin: '10px auto',
        justifyContent: 'space-between',
        

        '& .stepName': {
            fontSize: '12px',
            textAlign: 'center',
            fontWeight: '400',
            
           
        }
    },

    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '50px',
        position: 'relative',

        '& .title': {
            color: '#FFFFFF',
            fontSize: '23px',
            fontWeight: '700',
            lineHeight: '44px',
            width: '264.2px',
            textAlign: 'left',
            position: 'relative',
            textTransform: 'uppercase',
            paddingLeft: '30px',
            font: 'Open Sans Condensed',

            '&::after': {
                content: '""',
                position: 'absolute',
                borderBottom: '1px solid white',
                width: '118%',
                top: '45px',
                left: '0',
            },

        },

        '& .eventsButton': {
            backgroundColor: '#074682',
            borderRadius: '1.81px',
            width: '224px',
            height: '38.52px',
            border: 'none',
            textTransform: 'uppercase',
            color: 'white',
            fontWeight: '700',
            textAlign: 'center',
            transform: 'translateY(11%)',
            outline: 'none',
            transition: 'all .2s',
            lineHeight: '40px',
            fontSize: '12px'
        },

        '& .eventsButton:hover': {
            transform: 'scale(1.1)'
        },

        '& .titleIcon': {
            position: 'absolute',
            width: '30px',
            height: '30px',
            top: '3px',
            left: '0px',

        },
    },

    fieldsContainer: {
        border: '0.5px solid #4a4848',
        backgroundColor: 'rgba(21, 23, 25, 0.78)',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: '7.6px',
        padding: '32px',
        positon: 'relative',
        marginTop: '50px',

        '& .subtitle': {
            fontSize: '19px',
            textTransform: 'uppercase',
            color: '#FFFFFF',
            position: 'relative',
            opacity: '1',


            '&::after':{
                content: '""',
                position:'absolute',
                width: '100%',
                top:'21px',
                left:'0',
                borderBottom:'1px solid #FFFFFF'
            }
        }, 

        '& .input': {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            width: '450px',
            marginTop: '10px'
            
        },


        '& .label': {
            fontSize:'10px',
            marginLeft: '5px',
            display: 'flex',
            flexDirection: 'column'

        },

        '& .inputField': {
            marginTop: '2px',
            border: '1px solid white',
            borderRadius: '4.6px',
            backgroundColor:'transparent',
            padding: '8px 6px',
            outline:'none',
            color: 'white',
            transition: 'all .1s',
            boxSizing: 'border-box',
            position: 'relative',
        },

        '& .inputField:focus': {
            transform: 'scale(1.01)'
        },

        '& .inputFileField' : {
            visibility: 'hidden',
            opacity: '0'
        },

        '& .icon': {
            width: '90px',
            height: '68px',
            position: 'absolute',
            top: '32px',
            left: '23px'
        },

        '& .inputColor': {
            width: '104px',
            border: 'none',
            marginTop: '15px'
        },

        '& .searchIcon': {
            position: 'absolute',
            width: '20px',
            height: '20px',
            top: '22px',
            right: '7px',
            filter: 'invert(1)'
        },

        '& .checkBoxContainer': {
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
        },

        '& .checkboxlabel': {
            backgroundColor: '#0E0F0F',
            fontSize: '15px',
            padding: '10px',
            borderRadius: '8px',

        },

        '& .checkbox': {
            visibility: 'hidden',
            opacity: '0'
        },
        '& .isActive': {
            border: '2px solid #00BBD6',
        }

        
    },

}));

export const EventCategories = ({ eventForm, categoriesList, modifyFormCategoryHandler, ...props }) => {
    
    const classes = useStyles();
    console.log(eventForm);

    useEffect(() => {
        loadCategories();
    }, [])
    const [categories, setCategories] = useState([]);

    const loadCategories = async() => {
        let response = await getAllCompetitions();
        console.log(response.data)
        setCategories(response.data.response)
    }

    return (
        <>
            <div className={classes.stepscontainer}>
                <span className='yellowStep'></span>
                <span className='yellowStep'></span>
                <span className='greyStep'></span>
                <span className='greyStep'></span>
                <span className='greyStep'></span>
            </div>
            <div className={classes.nameContainer}>
                <p className={'stepName'}>Datos Generales</p>
                <p className={'stepName'}>Competencias</p>
                <p className={'stepName'}>Organización</p>
                <p className={'stepName'}>Directores</p>
                <p className={'stepName'}>Medio de pago</p>
            </div>
            <div className={classes.headerContainer}>
                <TitleIcon className='titleIcon'/>
                <h1 className='title'>Competitions</h1>
                <NavLink to={'/formEventOrganization'} className='eventsButton'>Continuar</NavLink>
            </div>
            <div className={classes.fieldsContainer}>
                <h2 className='subtitle'>Event Competitions</h2>   
                <div className='input'>
                    <label className='label' htmlFor="name">Search and select competitions for your event <span><SearchIcon className='searchIcon'/></span></label>
                    <input className='inputField largeInput' type="text" id="name"/>
                </div>
                <div className='checkBoxContainer'>
                    {categories && categories.map((cat, index) => {
                        console.log(cat);
                        return(
                            <>  
                                <CategoryCheckBox modifyFormCategoryHandler={modifyFormCategoryHandler} eventForm={eventForm} name={cat.name} id={cat._id}/>
                            </>
                        )}
                    )}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        eventForm: state.eventForm,
        categoriesList: state.categoriesList
    }
}

export default connect(mapStateToProps, {
    modifyFormCategoryHandler
})(EventCategories);