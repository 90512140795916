import React, { useState } from "react";

import "./AddClub.css";
import es from "date-fns/locale/es";
import { connect } from "react-redux";
import useStyles from '../../assets/MakeStyles';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nounImage from "../../assets/img/Camera.svg";
import InfoModal from "../../components/modals/InfoModal";
import Dropzone from "../../components/accessories/Dropzone";
import { newFootballClubCategoryTeam } from "../../endpoints";
import { create_player, unset_player } from "../../actions/index";
import GlobalLoading from "../../components/accessories/GlobalLoading";

import { translate } from "../../lang/lang";

registerLocale("es", es);

function mapDispatchToProps(dispatch) {
  return {
    set_create_player: player => dispatch(create_player(player)),
    reset_player: () => dispatch(unset_player()),
  };
}

function AddClub({ set_create_player, reset_player, ...props }) {

  const classes = useStyles();
  const [name, setName] = useState("");
  const [upImg, setUpImg] = useState("");
  const [team, setTeam] = useState("Top Team");
  const [nameError, setNameError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [category, setCategory] = useState("Professional");
  const [globalLoading, setGlobalLoading] = useState(false);
  const {user} = props;
 
  const handleSubmit = () => {

    if (!name || !category || !team) {
      return setNameError(true);
    }

    setGlobalLoading(true);

    newFootballClubCategoryTeam({
      name: name,
      img_file: upImg.field,
      category: category,
      team: team,
    })
      .then( () => {
        setGlobalLoading(false);
        reset_player();
        setOpenModal(true);
        setModalSuccess(true);
      })
      .catch( () => {
        setGlobalLoading(false);
        setOpenModal(true);
        setModalSuccess(false);
      });
  };

  const handleDrop = dropped => {

    if (dropped && dropped.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setUpImg({ result: reader.result, field: dropped[0] })
      );
      reader.readAsDataURL(dropped[0]);
    }
  };

  const handleChange = data => {
    switch (data.id) {
      case "category":
        setCategory(data.value);
        break;
      case "team":
        setTeam(data.value);
        break;
      default:
        setName(data.value);
    }
    setNameError(false);
  };

  return (<>
    
    <div className={classes.headerContainerAddClubs}>
      <div className='titleContainer'>
        <span> {translate(user.lang, "create club")} </span>
      </div>
      <div className='fileInputContainer'>
        <div className='subcontainerHeader'>
          <h3><span> {translate(user.lang, "data club")} </span></h3>
        </div>
        <div className={`${!upImg ? "noImage" : "dropZoneContainer"}`}>
          <Dropzone
            propClass={classes.dropNoContainer}
            onDrop={dropped => handleDrop(dropped)}
            img={upImg.result || nounImage}
            string={upImg.result ? "" : translate(user.lang, "load shield")}
          />
        </div>

        <input
          className='inputField'
          value={name}
          placeholder={translate(user.lang, "name club")}
          type='text'
          id='name'
          onChange={e => handleChange(e.target)}
        />

        <div className='inputsFirstTeam'>
          {/* <span>Primera categoria y equipo del Club </span>
          <input
            value={category}
            placeholder="Nombre de la categoria"
            type="text"
            id="category"
            onChange={(e) => handleChange(e.target)}
          />
          <input
            value={team}
            placeholder="Nombre del equipo"
            type="text"
            id="team"
            onChange={(e) => handleChange(e.target)}
          />
          <span>Puedes crear otras categorías y equipos luego.</span> */}
          {nameError && (
            <span className='error'>{translate(user.lang, "create club err")}</span>
          )}
        </div>
      </div>
    </div>
    <div className={classes.buttonSaveContainer}>
      <button className='formSaveButton' onClick={() => handleSubmit()}>
        {translate(user.lang, "create club")}
      </button>
    </div>

    <InfoModal 
        openModal={openModal}
        setOpenModal={setOpenModal}
        modalSuccess={modalSuccess}
        msg={modalSuccess ? 'El club fue creado' : 'El club no pudo ser creado'}
    />

    {globalLoading && <GlobalLoading />}

  </>);
}

const mapStateToProps = state => {
  return {
    player: state.player,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClub);
