import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Modal from '@material-ui/core/Modal';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import SwipeableViews from 'react-swipeable-views';
import { useStyle, useStyleTheme } from '../../assets/MakeStyles';

import {ScatterChart, Scatter, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

function TabPanel(props) {

    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box p={3}>
                {children}
            </Box>
            )}
        </div>
    );
}

const GraphyGpexePlayerModal = (props) => {

    const classes = useStyle();
    const theme = useStyleTheme();
    const [value, setValue] = useState(0);
    const {open = false, handleClose, graphics} = props;

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleOnClose = () => {
        handleClose({open: false, graphics: {} });
    }

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const showHeaderGraphy = () => {

        return  <div className="cols sm-5">
                    <div></div>
                    <div className="property">
                        <b>athlete</b><span>{graphics.brief.player.lastname} {graphics.brief.player.name}</span>
                    </div>
                    <div></div>
                    <div className="property">
                        <b>session date</b><span>{graphics.brief.origin_formatted}</span>
                    </div>
                    <div></div>
                </div>
    }

    function CustomTooltipScatter({ payload, label, active }) {

        if (active && payload) {
          return (
            <div className={classes.customTooltip}>

                { payload[0] &&
                
                    Object.keys(payload[0].payload).map( (key, index) => {
                        if(key === 'color'){
                            return  <div key={`custom-tool-${index}`} style={{display: 'flex', justifyContent: 'center'}}>
                                        <Avatar 
                                            //src={ payload[0].payload.player_picture }
                                            alt="Color"
                                            style={ {backgroundColor: payload[0].payload[key], width: '52.16px', height: '52.16px', margin: '15px 0'} }
                                        />
                                    </div>
                        }else{
                            return  <div key={`custom-tool-${index}`}>
                                        <p className="label"><b>{key}: </b> {payload[0].payload[key]}</p>
                                    </div>
                        }
                    })

                }

            </div>
          );
        }
        return null;
    }

    function CustomTooltipScatterSummary({ payload, label, active }) {

        //console.log(payload);

        if (active && payload) {
        return (
            <div className={classes.customTooltip}>
                {   payload.map( (pay, index) => {
                        return  <p style={{color: pay.color}} key={`custom-tool-${index}`} className="label"><b>{pay.name}: </b> {pay.value}</p>
                    })
                }
            </div>
        )
        }
        return null;
    }

    const buildChartPower = () => {

        return  <ScatterChart
                    width={900}
                    height={400}
                    margin={{
                        top: 30, right: 20, bottom: 20, left: 20,
                    }}
                >
                    <CartesianGrid />
                    <XAxis type="number" dataKey="time" name="time" unit=". seg" domain={[0, Math.ceil( Math.max.apply(Math, graphics.power.map(function(o) { return o.time; })) + parseInt( Math.max.apply( Math, graphics.power.map(function (o) { return o.time; } ) ) * 0.10 ) ) ]} />
                    <YAxis type="number" dataKey="distance" name="distance" unit=". m" domain={[0, Math.ceil( Math.max.apply(Math, graphics.power.map(function(o) { return o.distance; })) + parseInt( Math.max.apply( Math, graphics.power.map(function (o) { return o.distance; } ) ) * 0.10 ) ) ]}  />
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltipScatter />}/>
                    <Scatter name="Power" data={graphics.power} fill="#8884d8">
                    {
                        graphics.power.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)
                    }
                    </Scatter>
                </ScatterChart>
    }

    const buildChartSumary = (data) => {

        let maxRange = ( parseInt(data[0].walk) > parseInt(data[0].run) ) ? parseInt(data[0].walk) + parseInt( data[0].walk * 0.10 ) : parseInt(data[0].run) + parseInt(data[0].run * 0.10 );

        return  <BarChart
                    width={300}
                    height={400}
                    data={data}
                    margin={{
                        top: 20, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" orientation="left" stroke="#8884d8" domain={[0, maxRange ]} />
                    <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" domain={[0, maxRange ]} />
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltipScatterSummary />} />
                    <Legend />
                    <Bar yAxisId="left" dataKey="walk" fill="#8884d8"  />
                    <Bar yAxisId="right" dataKey="run" fill="#82ca9d" />
                </BarChart>
    }

    useEffect( () => {
        if(open){
            console.log(graphics);
        }
    }, [open]);

    return (
    <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onClose={handleOnClose}
        open={open}
    >

        <div className={classes.paperGraphy}>
            <div className={classes.root}>

                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="summary"  />
                        <Tab label="power"  />

                    </Tabs>
                </AppBar>

                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={(0)} dir={theme.direction}>
                        { graphics.brief && showHeaderGraphy() }
                        <div className={classes.gpexeBoxSummary}>
                            { graphics.summary && buildChartSumary([{name: 'Duration (min.)', ...graphics.summary.time}]) }
                            { graphics.summary && buildChartSumary([{name: 'Distance (m.)', ...graphics.summary.distance}]) }
                            { graphics.summary && buildChartSumary([{name: 'Energy (J/Kg.)', ...graphics.summary.energy}]) }
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={(1)} dir={theme.direction}>
                        { graphics.brief && showHeaderGraphy() }
                        <div className={classes.gpexeBoxPower}>
                            { graphics.power && buildChartPower()}
                        </div>
                    </TabPanel>
                </SwipeableViews>
            </div>
        </div>
    </Modal>
    );
};
export default GraphyGpexePlayerModal;