import React from "react";

import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import ActionsToggle from "../accessories/actionsToggle/actionsToggle";

import { translate } from '../../lang/lang';

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: "3rem",
    borderCollapse: "collapse",
    border: "none",
    width: "100%",
    fontFamily: "Montserrat-Semibold",
    backgroundColor: "#1c1e24",
    borderRadius: 10,
    "& thead tr td": {
      padding: "15px 5px",
      fontSize: "1em",
      color: "#6bffe6",
      border: "none",
      width: 140,
      "&:first-child": {
        paddingLeft: "2rem",
      },
      // "&:last-child": {
      //   paddingRight: "2rem",
      // },
    },
    "& tbody tr td": {
      fontFamily: "Montserrat-Medium",
      fontSize: 14,
      fontWeight: "300",
      color: "white",
      padding: "5px",
      "&:first-child": {
        paddingLeft: "2rem",
      },
      // "&:last-child": {
      //   paddingRight: "2rem",
      // },
    },
    "& tbody tr:nth-child(odd)": {
      backgroundColor: "rgba(21, 23, 25, 0.7)",
    },
    "& tbody tr:nth-child(even)": {
      backgroundColor: "#1c1e24",
    },
  },
}));

function ListUsers(props) {

  const classes = useStyles();
  const {user} = props;

  const handleEditUser = item => {

    props.history.push(`/user-account/${item._id}`);
    console.log(item);
  };

  const handleDeleteUser = item => {
    console.log(item);
  };

  return (
    <>
      {props.users.length > 0 ? (
        <table className={classes.table}>
          <thead>
            <tr>
              <td>{translate(user.lang, 'name')}</td>
              <td>{translate(user.lang, 'lastname')}</td>
              <td>E-mail</td>
              <td>Rol</td>
            </tr>
          </thead>
          <tbody>
            {props.users.map(item => {
              return (
                <tr key={item._id} className='row-items'>
                  <td>{item.name}</td>
                  <td>{item.lastname}</td>
                  <td>{item.username}</td>
                  <td>{props.roles && props.roles[item.roleId] && props.roles[item.roleId].name}</td>
                  <td
                    /*
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: "2rem",
                    }}
                    */
                  >
                    <ActionsToggle
                      admin
                      user={user}
                      item={item}
                      handleEditUser={handleEditUser}
                      handleDeleteUser={handleDeleteUser}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div style={{ textAlign: "center", padding: "1rem", fontSize: "18px" }}>
          {translate(user.lang, 'no-result')}
        </div>
      )}
    </>
  );
}

export default withRouter(React.memo(ListUsers));
