import React from 'react';

import {Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

    chartBar: {
        background: 'white',
        position:'relative',    

        // '& > svg > g::after':{
            
        //         position:'absolute',
        //         content:'""',
        //         backgroundColor: 'white',
        //         width:'100%',
        //         height: '100%',
        //         top:'0',
        //         left: '0'
         
        // },
        '& > svg' : {
            margin: '15px auto'
        },
        transform: 'translateX(45px)',
        '& .recharts-default-tooltip': {
            backgroundColor: 'transparent !important',
        }
    },
}));

const GetGraphyGetTeamByDate = (props) => {
    
    const classes = useStyles();
    const {resetChartBuilderData, widthChart, metricSeleted} = props;
    let {data, colorsFilter, playersFilters} = resetChartBuilderData();
    let newDateChart = Object.keys(data).map( (entry) => {
        return data[entry];
    });

    return (
        <LineChart
            className={classes.chartBar}
            width={widthChart}
            height={585}
            data={newDateChart}
            margin={{
                top: 5, right: 50, left: 10, bottom: 15,
            }}
        >
            <CartesianGrid fill="#18191d" strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis unit={"_" + metricSeleted.measurement_unit} />
            <Tooltip wrapperStyle={{ backgroundColor: '#000000bd' }} />
            {
                playersFilters.map((entry, index) => ( 
                    <Line 
                        key={index}
                        type="monotone"
                        fill={colorsFilter[index]}
                        stroke={colorsFilter[index]}
                        dataKey={playersFilters[index]}
                    />
                ))
            }   
        </LineChart>
    );
};
export default React.memo(GetGraphyGetTeamByDate);