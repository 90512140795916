import React, { useState, useEffect } from "react";

import MetricsOptions from "../components/modals/MetricsOptions";
import GpsByTeamComponent from "../components/GpsByTeamComponent";
import GlobalLoading from "../components/accessories/GlobalLoading";
import {
  getAllMetricsByTeamAndDate,
  getteambydate,
  getteambyexercise,
  getteamenergyexpenditure,
  getallmetrics,
} from "../endpoints";
import NavCardPlayer from "../components/NavCardPlayer/NavCardPlayer";

const GpsByTeam = (props) => {
  const { user } = props;
  const teamId = props.match.params.teamId;

  const queryString = require("query-string");
  const [globalLoading, setGlobalLoading] = useState(true);
  const [rolestring, setRolestring] = useState([]); // players position
  const [dateEnd, setDateEnd] = useState(new Date());
  const [staticsType, setStaticsType] = useState(true); // true general stats false indivudual stats
  const [quickMetrics, setQuickMetrics] = useState(null);
  const [dateStart, setDateStart] = useState(new Date("01-01-2020"));
  const [metricSeleted, setMetricSeleted] = useState(null);
  const [metricOptions, setMetricOptions] = useState(null);
  const [compareSeleted, setCompareSeleted] = useState(null);
  const [compareOptions, setCompareOptions] = useState(null);
  const [exerciseOptions, setExerciseOptions] = useState(null);
  const [exerciseSelected, setExerciseSeleted] = useState(null);
  const [rolestringSelected, setRolestringSelected] = useState([]); // players position selected
  const [metricOptionsModal, setMetricOptionsModal] = useState(false);
  const [
    rolestringIndividualSelected,
    setRolestringIndividualSelected,
  ] = useState(null); // players position selected
  const [modal, setModal] = useState(false);
  const [view, setView] = useState(2);
  //const [staticsGraphy, setStaticsGraphy] = useState('getteambydate');

  const [statsGps, setStatsGps] = useState({ playersSelected: [], stats: [] });

  const getColors = (options) => {
    return options
      ? options.map((index) => {
          return `rgb(${Math.floor(Math.random() * 255 + 1)}, ${Math.floor(
            Math.random() * 255 + 1
          )}, ${Math.floor(Math.random() * 255 + 1)})`;
        })
      : null;
  };

  const getRoleString = (options) => {
    let roles = [];
    let optionsFiltered = options
      ? options.filter((option) => {
          if (option.rolestring) {
            let filtered = roles.find(
              (element) => element === option.rolestring
            );
            roles.push(option.rolestring);
            return filtered === undefined;
          } else {
            return null;
          }
        })
      : null;

    //console.log('filtered', optionsFiltered);
    return optionsFiltered
      ? optionsFiltered.map((option) => {
          return option.rolestring;
        })
      : null;
  };

  const handleDateStartChange = (date) => {
    if (date > dateEnd) {
      setDateEnd(date);
    }
    setDateStart(date);
  };

  const toggleModal = () => {
    setModal(!modal);
  };
  const handleDateEndChange = (date) => {
    if (dateStart > date) {
      setDateStart(date);
    }
    setDateEnd(date);
  };

  async function selectsCallBack(value, idSelect) {
    switch (idSelect) {
      case 1:
        if (value) {
          // select metric
          let metricFilter = Object.keys(metricOptions).filter((key) => {
            return key === value.short_name;
          });
          setExerciseSeleted(null);
          setMetricSeleted(metricOptions[metricFilter]);
          setMetricOptionsModal(false);
        }
        break;
      case 2: // compare by
        if (value) {
          setCompareSeleted(value);
        }
        break;
      case 3: // exercise number
        if (value) {
          setExerciseSeleted(value);
        }
        break;
      case 100: // individual stats
        setStaticsType(!staticsType);
        break;
      default:
    }
  }

  async function getStacts() {
    if (metricSeleted) {
      let query = null;
      let response = null;
      let to =
        dateEnd.getDate() +
        "-" +
        (dateEnd.getMonth() + 1) +
        "-" +
        dateEnd.getFullYear();
      let from =
        dateStart.getDate() +
        "-" +
        (dateStart.getMonth() + 1) +
        "-" +
        dateStart.getFullYear();

      //setGlobalLoading(true);
      if (!staticsType) {
        // general o individual
        //setGlobalLoading(true)
        query = { team_id: teamId, date: from };
        response = await getAllMetricsByTeamAndDate(query);

        let countExercise = response.data.wplayers
          ? response.data.wplayers[0].gps.map((exercice, index) => {
              let numTemp = index + 1;
              return { name: numTemp.toString() };
            })
          : null;

        if (countExercise) {
          setExerciseSeleted(countExercise[0]);
        }
        setExerciseOptions(countExercise);
        //setGlobalLoading(false)
      } else if (metricSeleted.short_name === "P_Ener_Per_Massa") {
        // found quadrant of energy
        setCompareSeleted(compareOptions[1]);
        query = { team_id: teamId, from: from, to: to };
        response = await getteamenergyexpenditure(query);
      } else {
        switch (compareSeleted.value) {
          case "getteambydate":
            //setTimeout( () => {setGlobalLoading(true)}, 1000 );
            query = {
              team_id: teamId,
              from: from,
              to: to,
              metric_name: metricSeleted.short_name,
            };
            response = await getteambydate(query);
            break;
          case "getteambyexercise":
            query = {
              team_id: teamId,
              date: from,
              metric_name: metricSeleted.short_name,
            };
            response = await getteambyexercise(query);
            break;
          case "getteambyplayers": // "to" with value "from" because by players is a day
            query = {
              team_id: teamId,
              from: from,
              to: from,
              metric_name: metricSeleted.short_name,
            };
            response = await getteambydate(query);
            break;
          default:
        }
      }
      if (response && response.status === 200) {
        let data = response.data.data
          ? response.data.data
          : response.data.wplayers;

        let colors = getColors(data);

        setRolestring(getRoleString(data));

        console.log("aqui", response);

        if (statsGps.stats && statsGps.stats.length === 0) {
          let defaultPlayerSelect = Object.keys(response.data.wplayers).filter(
            (playerIndex) => {
              return (
                response.data.wplayers[playerIndex]._id ===
                queryString.parse(props.location.search)["default-player"]
              );
            }
          );
          setStatsGps({
            playersSelected: [parseInt(defaultPlayerSelect[0])],
            type: compareSeleted.value,
            stats: data,
            skill: metricSeleted.short_name,
            colors: colors,
          });
        } else {
          setStatsGps({
            playersSelected: statsGps.playersSelected,
            type: compareSeleted.value,
            stats: data,
            skill: metricSeleted.short_name,
            colors: colors,
          });
        }
        setGlobalLoading(false);
      } else {
        console.log("error");
        setGlobalLoading(false);
      }
    }
  }

  const handleSelectPlayer = (index) => {
    let newPlayerSelected = statsGps.playersSelected;
    if (newPlayerSelected.indexOf(index) > -1) {
      if (rolestringSelected.includes(statsGps.stats[index].rolestring)) {
        handleSetRolestringSelected(
          "general",
          statsGps.stats[index].rolestring
        );
      }
      newPlayerSelected.splice(newPlayerSelected.indexOf(index), 1);
    } else {
      newPlayerSelected.push(index);
    }
    setStatsGps({ ...statsGps, playersSelected: newPlayerSelected });
  };

  const handleSelectAll = (select) => {
    if (select) {
      let selectAllTemp = statsGps.stats.map((stats, index) => {
        return index;
      });
      setStatsGps({ ...statsGps, playersSelected: selectAllTemp });
      setRolestringSelected(rolestring);
    } else {
      setStatsGps({ ...statsGps, playersSelected: [] });
      setRolestringSelected([]);
    }
  };

  const handleSetRolestringSelected = (type, value) => {
    let rolestringSelectedTemp =
      type === "general"
        ? [...rolestringSelected]
        : [...rolestringIndividualSelected];
    let index =
      type === "general"
        ? rolestringSelected.indexOf(value)
        : rolestringIndividualSelected.indexOf(value);
    let newPlayerSelected = [];
    if (index > -1) {
      rolestringSelectedTemp.splice(index, 1);
      if (type === "general") {
        statsGps.stats.map((player, index) => {
          if (player.rolestring === value) {
            newPlayerSelected.push(index);
          }
          return null;
        });
        newPlayerSelected = statsGps.playersSelected.filter((select) => {
          return newPlayerSelected.includes(select) === false;
        });
        setStatsGps({ ...statsGps, playersSelected: newPlayerSelected });
      }
    } else {
      rolestringSelectedTemp.push(value);
      if (type === "general") {
        statsGps.stats.map((player, index) => {
          if (player.rolestring === value) {
            newPlayerSelected.push(index);
          }
          return false;
        });
        setStatsGps({
          ...statsGps,
          playersSelected: [...statsGps.playersSelected, ...newPlayerSelected],
        });
      }
    }
    if (type === "individual") {
      setRolestringIndividualSelected(rolestringSelectedTemp);
    } else {
      setRolestringSelected(rolestringSelectedTemp);
    }
  };

  useEffect(() => {
    if (metricSeleted) {
      getStacts();
    }
  }, [dateStart, dateEnd, metricSeleted, compareSeleted, staticsType]);

  useEffect(() => {
    if (!rolestringIndividualSelected && rolestring.length > 0) {
      setRolestringIndividualSelected(rolestring);
    }
  }, [rolestring]);

  useEffect(() => {
    let compare = [
      { name: "player", value: "getteambyplayers" },
      { name: "date", value: "getteambydate" },
      { name: "excercises", value: "getteambyexercise" },
    ];
    setCompareOptions(compare);
    setCompareSeleted(compare[1]);

    async function getMetricsOptions() {
      let response = await getallmetrics();
      let filterDefaultOptions = [];
      Object.keys(response.data.metrics).filter((key, index) => {
        if (index < 5) {
          filterDefaultOptions.push(response.data.metrics[key]);
        }
        return null;
      });
      setQuickMetrics(filterDefaultOptions);
      setMetricOptions(response.data.metrics);
      setMetricSeleted(
        response.data.metrics[Object.keys(response.data.metrics)[0]]
      );
      setTimeout(function() {
        setGlobalLoading(false);
      }, 1000);
    }
    getMetricsOptions();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main container">
      <NavCardPlayer {...props} view={view} setView={setView} />
      {metricSeleted && (
        <>
          <MetricsOptions
            open={metricOptionsModal}
            metricOptions={metricOptions}
            selectsCallBack={selectsCallBack}
            handleCloseOpen={setMetricOptionsModal}
          />

          <GpsByTeamComponent
            user={user}
            modal={modal}
            dateEnd={dateEnd}
            statsGps={statsGps}
            dateStart={dateStart}
            rolestring={rolestring}
            toggleModal={toggleModal}
            staticsType={staticsType}
            quickMetrics={quickMetrics}
            metricSeleted={metricSeleted}
            metricOptions={metricOptions}
            compareSeleted={compareSeleted}
            compareOptions={compareOptions}
            exerciseOptions={exerciseOptions}
            handleSelectAll={handleSelectAll}
            selectsCallBack={selectsCallBack}
            exerciseSelected={exerciseSelected}
            rolestringSelected={rolestringSelected}
            handleSelectPlayer={handleSelectPlayer}
            handleDateEndChange={handleDateEndChange}
            setMetricOptionsModal={setMetricOptionsModal}
            handleDateStartChange={handleDateStartChange}
            handleSetRolestringSelected={handleSetRolestringSelected}
            rolestringIndividualSelected={rolestringIndividualSelected}
          />
        </>
      )}
      {globalLoading && <GlobalLoading />}
    </div>
  );
};
export default React.memo(GpsByTeam);
