import React, {useEffect, useState} from 'react';

import { connect } from "react-redux";
import { filterArrayByProperty } from '../services';
import { getAllCompetitionsHandler } from '../actions';
import GlobalLoading from '../components/accessories/GlobalLoading';
import CompetitionComponent from '../components/CompetitionComponent';
import { getAllCompetitions, deleteCompetitionsById } from '../endpoints';

function mapDispatchToProps(dispatch) {
    return {
        set_getAllCompetitionsHandler: comp => dispatch( getAllCompetitionsHandler(comp)),
    };
}

const mapStateToProps = state => {
    return {
        competitions: state.competitions
    }
}

export const Competition = (props) => {

    const [globalLoading, setGlobalLoading] = useState(false);
    const {competitions, set_getAllCompetitionsHandler} = props;

    const loadCompetitions = async () =>{
        setGlobalLoading(true)
        let response = await getAllCompetitions();
        set_getAllCompetitionsHandler(response.data.response);
        setGlobalLoading(false);
    }

    const handleShowTeams = async (comp) => {
        if(comp.team_ids){

        }
        console.log(comp)
    }

    const handleDeleteCompetition = async (comp) => {
        setGlobalLoading(true)
        let response = await deleteCompetitionsById(comp._id);
        if(response.code === 200){
            let newCompetitions = filterArrayByProperty(competitions, '_id', comp._id, '!=' ) /* (array, property, value to filter, operator:default "=") */
            set_getAllCompetitionsHandler(newCompetitions);
        }else{
            console.log('error');
        }
        setGlobalLoading(false)
    }

    useEffect(() => {
        loadCompetitions()
    }, []);

    return (<>
        <CompetitionComponent competitions={competitions} handleShowTeams={handleShowTeams} handleDeleteCompetition={handleDeleteCompetition} />
        { globalLoading &&  <GlobalLoading /> }
    </>)
}
const AppCompetition = connect(mapStateToProps, mapDispatchToProps)(Competition);
export default React.memo(AppCompetition);