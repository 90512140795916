import React, { useEffect, useRef, useState } from "react";

import Lottie from "react-lottie";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import successData from "../assets/img/check.json";
import searchIcon from "../assets/img/searchBold.svg";
import ListUsers from "../components/ListUsers/ListUsers";
import { makeStyles, Modal, Fade } from "@material-ui/core";
import Paginator from "../components/accessories/Paginator";
import GlobalLoading from "../components/accessories/GlobalLoading";
import {
  getObjectFromArrayWithKeysByProperty,
  filterObjectByProperty,
  debounce,
} from "../services";
import {
  createUser,
  getUserBySearch,
  getRoles,
  getClubs,
  resourceApiEndPoint,
} from "../endpoints";
import InfoModal from "../components/modals/InfoModal";

import { translate } from "../lang/lang";
//import { set } from "lodash";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    "& h2": {
      fontSize: 40,
      fontFamily: "DINCondensed",
    },
    "& p": {
      fontSize: 15,
      marginTop: 5,
      maxWidth: "50%",
      lineHeight: 1.6,
      fontFamily: "Montserrat-Medium",
    },
  },
  handlers: {
    marginTop: 20,
    display: "flex",
    justifyContent: "space-between",
  },
  searchContainer: {
    width: "50%",
    display: "flex",
    "& .inputField": {
      width: 180,
      color: "#000",
      height: "44px",
      outline: "none",
      fontSize: "15px",
      borderRadius: "4px",
      padding: "0 0.5rem",
      position: "relative",
      transition: "all .1s",
      boxSizing: "border-box",
      backgroundColor: "#fff",
      border: "solid 2px #ffffff",
      fontFamily: "Montserrat-Medium",
      "&::placeholder": {
        color: "#000",
        fontSize: "15px",
        fontFamily: "Montserrat-Medium",
      },
      "& input": {
        width: "100%",
        border: "none",
        height: "100%",
        outline: "none",
        fontSize: "15px",
        background: "transparent",
        fontFamily: "Montserrat-Medium",
        "&::placeholder": {
          color: "#000",
          fontSize: "15px",
          fontFamily: "Montserrat-Medium",
        },
      },
      marginRight: 20,
    },
  },
  icon: {
    right: 6,
    top: "50%",
    zIndex: "9",
    width: "16px",
    height: "16px",
    position: "absolute",
    transform: "translateY(-50%)",
  },
  createBtnContainer: {
    "& button": {
      width: 180,
      height: 44,
      fontSize: 15,
      color: "black",
      border: "none",
      borderRadius: 4,
      cursor: "pointer",
      fontWeight: "700",
      fontFamily: "Montserrat",
      backgroundColor: "#6cffe6",
    },
  },
  modalContainer: {
    width: 400,
    top: "50%",
    left: "50%",
    padding: 37,
    maxWidth: "70%",
    borderRadius: 6,
    position: "absolute",
    backgroundColor: "white",
    transform: "translate(-50%,-50%)",
  },
  close: {
    top: 10,
    right: 10,
    width: 32,
    height: 32,
    opacity: 0.3,
    color: "black",
    display: "flex",
    cursor: "pointer",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
  },
  formTitle: {
    fontSize: 28,
    color: "black",
    fontFamily: "Poppins",
  },
  form: {
    width: "100%",
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    "& input": {
      padding: 17,
      borderRadius: 4,
      marginBottom: 13,
      fontFamily: "Poppins",
      border: "1px solid #d3d3d3",

      "&::placeholder": {
        color: "#a5a5a5",
        fontFamily: "Poppins",
      },
      "&[type='submit']": {
        height: 50,
        padding: 0,
        width: "100%",
        border: "none",
        display: "flex",
        cursor: "pointer",
        marginLeft: "auto",
        fontWeight: "bold",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#6cffe6",
      },
    },
    "& select": {
      padding: 17,
      borderRadius: 10,
      marginBottom: 13,
      fontFamily: "Poppins",
      border: "1px solid #d3d3d3",
      "&::placeholder": {
        color: "#a5a5a5",
      },
    },
  },
}));

let mydebounce = null;

const defaultOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Admin = (props) => {
  const { user } = props;
  const classes = useStyles();
  const history = useHistory();

  let query = useQuery();

  const inputElement = useRef("");
  const [page, setPage] = useState(query.get("page") ? query.get("page") : 1);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [clubs, setClubs] = useState(null);
  const [roles, setRoles] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [defaultRol, setDefaultRol] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const { register, errors, handleSubmit } = useForm();
  const [roleSelected, setRoleSelected] = useState(null);
  const [clubSelected, setClubSelected] = useState(null);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(true);
  const [userCreatedId, setUserCreatedId] = useState(null);
  const [roleIsSelected, setRoleIsSelected] = useState(true);

  let response = null;

  const setPush = (value) => {
    history.push({ search: "?page=" + value });
  };

  const listUsers = async (offset) => {
    setGlobalLoading(true);
    try {
      const response = await getUserBySearch({
        ...search,
        wclubId: user.clubActive ? user.clubActive._id : null,
        from: offset,
      });
      if (!response.data.users) {
        setUsers([]);
        setTotalUsers(0);
        setGlobalLoading(false);
      } else {
        //console.log(response.data.total)
        setUsers(response.data.users);
        setTotalUsers(response.data.total);
        setGlobalLoading(false);
      }
    } catch (error) {
      console.log({ error });
      setGlobalLoading(false);
    }
    /*
      getUsers(offset).then(response => {
        if (!response.data) {
          return;
        }
        setUsers(response.data.response.users);
        setTotalUsers(response.data.response.total);
        setGlobalLoading(false);
      });
    */
  };

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = async (data) => {
    console.log(data);
    console.log(user);

    if (roleSelected) {
      setGlobalLoading(true);
      try {
        if (user.roleId === "1") {
          response = await createUser(data);
        } else {
          response = await createUser({
            ...data,
            wclub_id: user.clubActive._id,
          });
        }
        setGlobalLoading(false);
        if (!response.error) {
          setSuccess(true);
          setModalSuccess(true);
          setUserCreatedId(response._id);
        } else {
          setGlobalLoading(false);
          setModalSuccess(false);
        }
        setOpenModal(true);

        setTimeout(() => {
          refreshList();
          setIsOpen(false);
          setSuccess(false);
        }, 1500);
      } catch (error) {
        console.log(error);
        setGlobalLoading(false);
        setModalSuccess(false);
        setOpenModal(true);
      }
    } else {
      setRoleIsSelected(false);
    }
  };

  const goToEditPlayer = () => {
    console.log(userCreatedId);
    if (userCreatedId) {
      history.push(`/edit-player/${userCreatedId}`);
    } else {
      setOpenModal(false);
    }
  };

  const handleSelect = (data) => {
    setPage(1);
    setDefaultRol(data.target.value);
    setSearch({ ...search, roleId: data.target.value, from: 0 });
  };

  const handleInputSearch = (value) => {
    setSearch({ ...search, query: value, from: page });
  };

  const refreshList = () => {
    setGlobalLoading(true);
    setPush(page);
    listUsers((page - 1) * 9);
  };

  useEffect(() => {
    refreshList();
  }, [page]);

  useEffect(() => {
    if (mydebounce) {
      clearTimeout(mydebounce);
    }
    mydebounce = debounce(listUsers, 1000, (page - 1) * 9);
  }, [search]);

  useEffect(() => {
    async function handleGetRoles() {
      let response = await getRoles();
      if (response && response.status === 200) {
        setRoles(
          getObjectFromArrayWithKeysByProperty(response.data.response, "id")
        );
      } else {
        console.log("error");
      }
    }
    handleGetRoles();

    getClubs().then((response) => {
      setClubs(response.data.response);
    });
  }, []);

  return (
    <>
      <section className={classes.header}>
        <h2>{translate(user.lang, "users")}</h2>
        <p>{translate(user.lang, "user-title")}</p>
      </section>
      <section className={classes.handlers}>
        <div className={classes.searchContainer}>
          <select
            onChange={handleSelect}
            value={defaultRol ? defaultRol : "0"}
            required
            className="inputField"
            id="role"
            // onChange={e => handleChange(e.target)}
            // disabled={categories.length === 0}
          >
            <option value={0} disabled style={{ color: "#a5a5a5" }}>
              Rol
            </option>
            {user.rolId === "1" && <option value={1}>Admin</option>}
            <option value={2}>Directivo</option>
            <option value={3}>Coordinador</option>
            <option value={4}>Director Técnico</option>
            <option value={5}>Médico</option>
            <option value={6}>Preparador físico</option>
            <option value={7}>Jugador</option>
            <option value={8}>Psicólogo</option>
            {/* <option>VER TODOS</option> */}
            {/* {categories.map((data, index) => {
                return (
                  <option key={index} value={data._id}>
                    {data.name}
                  </option>
                );
              })} */}
          </select>
          <div className="inputField">
            <input
              ref={inputElement}
              name="query"
              placeholder={`${translate(user.lang, "search")}...`}
              id="query"
              onChange={(e) => handleInputSearch(e.target.value)}
            />
            <img className={classes.icon} src={searchIcon} alt="search" />
          </div>
        </div>
        <div className={classes.createBtnContainer}>
          <button onClick={handleModal}>
            {translate(user.lang, "create user")}
          </button>
        </div>
      </section>
      {globalLoading ? (
        <GlobalLoading />
      ) : (
        <ListUsers user={user} users={users} roles={roles} />
      )}
      <section>
        <Paginator
          totalItems={totalUsers}
          handleSetFilters={(value) => {
            setPage(value);
          }}
        />
      </section>

      <InfoModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        modalSuccess={modalSuccess}
        msg={
          modalSuccess
            ? "El usuario fue creado"
            : "El usuario no pudo ser creado"
        }
        callback={roleSelected === "7" ? goToEditPlayer : null}
        callbackLabel={"Ir al jugador"}
      />

      <Modal open={isOpen} closeAfterTransition>
        <Fade in={isOpen}>
          <div className={classes.modalContainer}>
            <div className={classes.close} onClick={() => setIsOpen(!isOpen)}>
              X
            </div>
            <h2 className={classes.formTitle}>
              {translate(user.lang, "create user")}
            </h2>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <input
                type="text"
                name="name"
                placeholder="Nombre"
                style={{
                  borderColor: errors.name ? "#cc0000" : " #d3d3d3",
                }}
                ref={register({ required: true })}
              />

              <input
                type="text"
                name="lastname"
                placeholder="Apellido"
                style={{
                  borderColor: errors.lastname ? "#cc0000" : " #d3d3d3",
                }}
                ref={register({ required: true })}
              />
              <input
                name="email"
                type="email"
                placeholder="E-mail"
                style={{
                  borderColor: errors.email ? "#cc0000" : " #d3d3d3",
                }}
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "No es un mail válido",
                  },
                })}
              />
              <input
                name="password"
                type="password"
                placeholder="Contraseña"
                style={{
                  borderColor: errors.password ? "#cc0000" : " #d3d3d3",
                }}
                ref={register({
                  required: true,
                  minLength: {
                    value: 8,
                    message:
                      "La contraseña debe contener al menos 8 caracteres",
                  },
                })}
              />
              {errors.password && (
                <p
                  style={{
                    fontFamily: "Poppins",
                    color: "#cc0000",
                    fontSize: 12,
                    marginBottom: 13,
                  }}
                >
                  {errors.password.message}
                </p>
              )}

              {user.rolId === "1" && (
                <select
                  ref={register({ required: true })}
                  name="wclub_id"
                  value={clubSelected ? clubSelected : "0"}
                  onChange={(e) => setClubSelected(e.target.value)}
                  style={
                    clubSelected && clubSelected !== "0"
                      ? {
                          backgroundImage: `url(${resourceApiEndPoint()}${
                            filterObjectByProperty(
                              clubs,
                              "_id",
                              clubSelected
                            )[0].img_file
                          })`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "50px",
                          backgroundPosition: "95% 50%",
                          display: user.rolId === "1" ? "inherit" : "hide",
                        }
                      : null
                  }
                >
                  <option value={0} style={{ color: "#a5a5a5" }} disabled>
                    Club
                  </option>

                  {clubs &&
                    clubs.map((data, index) => {
                      return (
                        <option key={index} value={data._id}>
                          {data.name}
                        </option>
                      );
                    })}
                </select>
              )}

              <select
                name="role_id"
                ref={register({ required: true })}
                onChange={(e) => setRoleSelected(e.target.value)}
              >
                <option value={0} style={{ color: "#a5a5a5" }}>
                  Rol
                </option>
                {user.rolId === "1" && <option value={1}>Admin</option>}
                <option value={2}>Directivo</option>
                <option value={3}>Coordinador</option>
                <option value={4}>Director Técnico</option>
                <option value={5}>Médico</option>
                <option value={6}>Preparador físico</option>
                <option value={7}>Jugador</option>
                <option value={8}>Psicólogo</option>
              </select>

              {!roleIsSelected && (
                <div style={{ color: "#ff0000" }}>Debe ingresar un rol</div>
              )}

              <div
                style={{
                  position: "relative",
                  width: "40%",
                  height: 50,
                  marginLeft: "auto",
                  backgroundColor: "#6cffe6",
                  borderRadius: 4,
                }}
              >
                {success ? (
                  <Lottie
                    options={{ animationData: successData, ...defaultOptions }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      width: "30px",
                      transform: "translate(-50%,-50%)",
                      height: "30px",
                    }}
                    height={20}
                    width={20}
                  />
                ) : (
                  <input type="submit" value="Crear" disabled={globalLoading} />
                )}
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default Admin;
