import React from 'react'
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import EventCards from '../components/Cards/EventCards'
import { translate } from '../lang/lang';

const useStyles = makeStyles( theme => ({
    headerContainer:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& .titleContainer': {
            width: '100%',
            "& span": {
                fontFamily: "DINCondensed",
                fontSize: "40px",
                lineHeight: "1",
              },
        },
        '& .eventsButton': {
            border: 'none',
            width: '224px',
            color: 'white',
            outline: 'none',
            height: '41.52px',
            fontWeight: '700',
            lineHeight: '40px',
            textAlign: 'center',
            transition: 'all .2s',
            borderRadius: '1.81px',
            backgroundColor: '#074682',
            textTransform: 'uppercase',
            transform: 'translateY(11%)',
        },
        '& .eventsButton:hover': {
            transform: 'scale(1.01)',
        }
    },
    eventCardContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    }
}));

const EventsComponents = (props) => {

    const { user, eventList, delEvents } = props;
    const classes = useStyles();

    return (
        <>
                <div className={classes.headerContainer}>
                    <div className='titleContainer'>
                        <span>{translate(user.lang, 'Events')}</span>
                    </div>
                    <NavLink  to={'/formEventInfo'} className='eventsButton'>{translate(user.lang, 'Create event')}</NavLink>
                </div>
                <div className={classes.eventCardContainer}>             
                    {eventList && eventList.map((event, index) =>
                    {   
                        return (
                            <EventCards key={index} event={event} eventNumber={index} delEvents={delEvents}/>
                        )
                    })
                }
                </div>
        </>
    )
}
export default React.memo(EventsComponents);