import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';

const useStyles = makeStyles(theme => ({
    chartBar: {
        zIndex:'3',
        background: 'white',
        '& > svg' : {
            margin: '15px auto'
        },
        transform: 'translateX(45px)',
        '& .recharts-default-tooltip': {
            backgroundColor: 'transparent !important',
        }
    }
}));

const GetGraphyGetTeamByExercise = (props) => {

    const classes = useStyles();
    const {resetChartBuilderData, metricSeleted, widthChart} = props;
    let {data, colorsFilter, playersFilters} = resetChartBuilderData();

    return (
        <LineChart
            className={classes.chartBar}
            width={widthChart}
            height={585}
            data={data}
            margin={{
                top: 5, right: 50, left: 10, bottom: 15,
            }}
        >
            <CartesianGrid fill="#18191d" strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis unit={"_" + metricSeleted.measurement_unit} />
            <Tooltip wrapperStyle={{ backgroundColor: '#000000bd' }} />
            {
                playersFilters.map((entry, index) => ( 
                    <Line 
                        key={index}
                        type="monotone"
                        fill={colorsFilter[index]}
                        stroke={colorsFilter[index]}
                        dataKey={playersFilters[index]}
                    />
                ))
            }   
        </LineChart>
    );
};
export default React.memo(GetGraphyGetTeamByExercise);