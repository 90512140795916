import React from "react";

import useStyles from "../assets/MakeStyles";
import Avatar from "@material-ui/core/Avatar";
import { resourceApiEndPoint } from "../endpoints";

import { translate } from "../lang/lang";

const RatingComponent = (props) => {
  const classes = useStyles();

  const {
    ratingType,
    voteChage,
    selectTypeRating,
    player,
    statsPlayer,
    showRateSection,
    statsPerformanceActive,
    selectPerformanceActive,
    user,
  } = props;

  const ratingTypeClass = [
    {
      cursor: "pointer",
      width: "219px",
      border: "3px solid #F8F8F8",
      height: "45.3px",
      justifyContent: "center",
      background: "#FFD802",
    },
    {
      cursor: "pointer",
      width: "219px",
      height: "51.3px",
      justifyContent: "center",
    },
  ];

  const ShowTarget = ({
    average,
    skillsVoted,
    buttonLabel,
    title,
    onClickType,
  }) => {
    return (
      <div className={classes.targetRating}>
        <h3>{title}</h3>
        <span>
          {translate(user.lang, "average")}: {average}
        </span>
        <span>
          {translate(user.lang, "voted skills")}: {skillsVoted}
        </span>
        <button
          type="button"
          className={classes.targetButton}
          onClick={() => {
            selectPerformanceActive(onClickType);
          }}
        >
          <b>{buttonLabel}</b>
        </button>
      </div>
    );
  };

  return (
    <div className={classes.RatingContainer}>
      <div className="col" style={{ marginTop: 0 }}>
        <span className={classes.titleBody}>
          {" "}
          {translate(user.lang, "evaluations")}
        </span>
      </div>

      <div className="col" style={{ marginTop: "30px" }}>
        <div
          className={classes.inputSelect + " " + classes.ratingTypeLeft}
          style={ratingType ? ratingTypeClass[0] : ratingTypeClass[1]}
          onClick={() => selectTypeRating(true)}
        >
          <span style={{ color: ratingType ? "#000000" : "#ffffff" }}>
            {translate(user.lang, "last evaluation")}
          </span>
        </div>

        <div
          className={classes.inputSelect + " " + classes.ratingTypeRight}
          style={ratingType ? ratingTypeClass[1] : ratingTypeClass[0]}
          onClick={() => selectTypeRating(false)}
        >
          <span style={{ color: ratingType ? "#ffffff" : "#000000" }}>
            {translate(user.lang, "record")}
          </span>
        </div>
      </div>

      <div className={classes.playerContainer}>
        {player ? (
          <>
            <Avatar
              src={
                resourceApiEndPoint() +
                (player.img_file.charAt(0) === "/"
                  ? player.img_file
                  : "/" + player.img_file)
              }
              alt="Player name"
              className={classes.avatar}
            />
            <h3>{player.name + " " + player.lastname}</h3>{" "}
          </>
        ) : (
          <Avatar
            //src={ resourceApiEndPoint() + (player.img_file.charAt(0) === '/' ? player.img_file : '/' + player.img_file) }
            alt="Player name"
          />
        )}
      </div>

      <div className="cols sm-2 lg-4">
        <ShowTarget
          title={translate(user.lang, "my evaluation")}
          average={statsPlayer ? statsPlayer.averageByUserVoter : 0}
          skillsVoted={
            statsPlayer && statsPlayer.ratePercentUserVoter
              ? statsPlayer.ratePercentUserVoter + "%"
              : 0
          }
          buttonLabel={
            statsPerformanceActive !== "user"
              ? translate(user.lang, "view votes")
              : voteChage
              ? translate(user.lang, "vote")
              : translate(user.lang, "see graph")
          }
          onClickType={"user"}
        />
        <ShowTarget
          title={translate(user.lang, "self appraisal")}
          average={statsPlayer ? statsPlayer.averageByUserVoted : 0}
          skillsVoted={
            statsPlayer && statsPlayer.ratePercentUserVoted
              ? statsPlayer.ratePercentUserVoted + "%"
              : 0
          }
          buttonLabel={translate(user.lang, "see graph")}
          onClickType={"self"}
        />
        <ShowTarget
          title={translate(user.lang, "evaluation staff")}
          average={statsPlayer ? statsPlayer.averageByStaff : 0}
          skillsVoted={
            statsPlayer && statsPlayer.ratePercentByStaff
              ? statsPlayer.ratePercentByStaff + "%"
              : 0
          }
          buttonLabel={
            statsPerformanceActive === "staff"
              ? "Cuerpo Técnico"
              : translate(user.lang, "view votes")
          }
          onClickType={"staff"}
        />
        <ShowTarget
          title={translate(user.lang, "peer evaluation")}
          average={statsPlayer ? statsPlayer.averageByOtherPlayers : 0}
          skillsVoted={
            statsPlayer && statsPlayer.ratePercentByPlayer
              ? statsPlayer.ratePercentByPlayers + "%"
              : 0
          }
          buttonLabel={
            statsPerformanceActive === "peer"
              ? "Compañeros"
              : translate(user.lang, "view votes")
          }
          onClickType={"peer"}
        />
      </div>

      {showRateSection()}
    </div>
  );
};

export default RatingComponent;
