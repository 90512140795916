import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import SaveIcon from '@material-ui/icons/Save';
import { update_user } from "../actions/index";
import { useLocation } from "react-router-dom";
import { makeStyles, Modal } from "@material-ui/core";
import Dropzone from "../components/accessories/Dropzone";
import GlobalLoading from "../components/accessories/GlobalLoading";
import {
  postUpdateUser,
  postUpdateProfileImg,
  getUser,
  resourceApiEndPoint,
} from "../endpoints";
import { debounce } from '../services';

import { translate } from '../lang/lang';

const useStyles = makeStyles(theme => ({
  modalTagPlayer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
  },
  headerContainer: {
    display: "flex",
    marginTop: "50px",
    flexDirection: "row",
    position: "relative",
    justifyContent: "center",
    "& div": {
      outline: "none",
    },
    "& img": {
      color: "#FFFFFF",
      fontSize: "23px",
      width: "264.2px",
      maxHeight: "264.2px",
      fontWeight: "700",
      textAlign: "left",
      lineHeight: "44px",
      position: "relative",
      textTransform: "uppercase",
      fontFamily: "Open Sans Condensed",
    },
  },
  fieldsContainer: {
    width: "100%",
    padding: "32px",
    marginTop: "50px",
    positon: "relative",
    borderRadius: "7.6px",
    boxSizing: "border-box",
    border: "0.5px solid #4a4848",
    backgroundColor: "rgba(21, 23, 25, 0.78)",
    fontFamily: "Open Sans Condensed",

    "& h2": {
      marginBottom: "10px;",
    },
  },
  inputContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "35px",
    marginBottom: "35px",
    position: "relative",
    boxSizing: "border-box",
    justifyContent: "space-between",
    fontFamily: "Open Sans Condensed",
    "& .largeInput": {
      width: "308px",
    },
    "& .input": {
      display: "flex",
      flexDirection: "column",
    },
    "& .label": {
      zIndex: "4",
      fontSize: "10px",
      display: "flex",
      marginLeft: "5px",
      flexDirection: "column",
    },
    "& span": {
      color: "red",
    },
    "& .inputField": {
      color: "white",
      outline: "none",
      marginTop: "2px",
      padding: "8px 6px",
      position: "relative",
      transition: "all .1s",
      borderRadius: "4.6px",
      boxSizing: "border-box",
      border: "1px solid white",
      backgroundColor: "transparent",
      fontFamily: "Open Sans Condensed",
    },
  },
  videoFormContainer: {
    marginTop: "35px",
    marginBottom: "35px",
    position: "relative",
    boxSizing: "borderBox",
    fontFamily: "Open Sans Condensed",
    "& hr": {
      marginLeft: "0",
    },
    "& .videoInputContainer": {
      position: "relative",
      display: "flex",
      flexWrap: "wrap",
      marginTop: "35px",
      marginBottom: "35px",
      boxSizing: "borderBox",
      fontFamily: "Open Sans Condensed",

      "& .inputField": {
        color: "white",
        outline: "none",
        marginTop: "2px",
        padding: "8px 6px",
        position: "relative",
        transition: "all .1s",
        borderRadius: "4.6px",
        boxSizing: "border-box",
        border: "1px solid white",
        backgroundColor: "transparent",
        fontFamily: "Open Sans Condensed",
      },

      "& .largeInput": {
        width: "308px",
      },

      "& div:nth-child(2), div:nth-child(4)": {
        marginLeft: "35px",
        "@media (max-width: 940px)": {
          marginLeft: "0px",
          marginRight: "35px",
        },
      },
    },
  },
  buttonCropContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "5px",
    marginBottom: "5px",

    "& div:nth-child(2)": {
      marginLeft: "15px",
    },

    "& button": {
      padding: "5px 10px",
      fontSize: "10px",
      outline: "none",
    },

    "& .saveButton": {
      backgroundColor: "#6558F5",
      border: "0px solid",
      color: "white",
      borderRadius: "10px",
    },

    "& .cancelButton": {
      backgroundColor: "transparent",
      border: "1px solid #6558F5",
      color: "#6558F5",
      borderRadius: "10px",
    },
  },
  buttonSaveContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: "35px",
    marginBottom: "35px",

    "& button": {
      padding: "10px 40px",
      marginLeft: "25px",
      fontSize: "20px",
      outline: "none",
    },

    "& .formSaveButton": {
      backgroundColor: "#6558F5",
      border: "0px solid",
      color: "white",
      borderRadius: "7px",
      cursor: "pointer",
      fontFamily: "Open Sans Condensed",
      fontWeight: "bold",
    },
  },
}));

const User = props => {

  let mydebounce = null;
  const location = useLocation();
  const currentPath = location.pathname.split("/");

  const classes = useStyles();
  const [upImg, setUpImg] = useState("");
  const [imgRef, setImgRef] = useState(null);
  const [previewImg, setPreviewImg] = useState();
  const [dniError, setDniError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [birthdayError, setBirthdayError] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [passwordGlooud, setPasswordGlooud] = useState({});
  const [crop, setCrop] = useState({ unit: "px", width: 264, height: 264 });
  const [user, setUser] = useState( currentPath[1] === 'user-account' ? null : props.user );
  const [errorPassword, setErrorPassword] = useState(false);
  const [updatedPassword, setUpdatedPassword] = useState(false);

  const handleChange = data => {
    setDniError(false);
    setBirthdayError(false);
    setUser({ ...user, [data.id]: data.value });
  };

  // UPDATE USER PASSWORD
  const handleChangePasswordGlooud = data => {
    if(errorPassword){ setErrorPassword(false); }
    if(updatedPassword){ setUpdatedPassword(false) }
    setPasswordGlooud({...passwordGlooud, [data.id]: data.value });
  }

  const handlePasswordGlooud = data => {
    if(mydebounce){
      clearTimeout(mydebounce);
    }
    mydebounce = debounce(handleChangePasswordGlooud, 500, data );
  }

  const handleUpdatePasswordGlooud = async () => {

    if( !passwordGlooud.currentPassword || passwordGlooud.newPassword !== passwordGlooud.repitPassword ){
      setErrorPassword(true);
    }else{
      setGlobalLoading(true);
      let response = await postUpdateUser(user._id, {password: passwordGlooud.newPassword, currentPassword: passwordGlooud.currentPassword});
      if (response && response.code === 200) {
        setUpdatedPassword(true);
      } else {
        setUpdatedPassword(false);
      }
      setGlobalLoading(false);
    }
  }
  // UPDATE USER PASSWORD

  const handleSubmit = async () => {

    /*
    if (
      !/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/.test(
        user.birthday
      )
    ) {
      return setBirthdayError(true);
    }
    */

    if (user.dni && user.dni.length !== 8) {
      return setDniError(true);
    }

    if(currentPath[1] !== 'user-account'){
      update_user(user);
    }

    setGlobalLoading(true);

    let response = await postUpdateUser(user._id, user);
    if (response && response.code === 200) {
      setGlobalLoading(false);
    } else {
      setGlobalLoading(false);
    }
  };

  const handleDrop = dropped => {
    setOpenModal(true);
    if (dropped && dropped.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(dropped[0]);
    }
  };

  const onLoad = useCallback(img => {
    setImgRef(img);
  }, []);

  const makeClientCrop = async crop => {
    if (imgRef && crop.width && crop.height) {
      createCropPreview(imgRef, crop, `${user.name + Date.now()}.jpeg`);
    }
  };

  const createCropPreview = async (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        blob.name = fileName;
        setPreviewImg(blob);
      }, "image/jpeg");
    });
  };

  const sendProfileImg = () => {

    postUpdateProfileImg(user._id, { img_file: previewImg }).then( () => {
      getUser(user._id).then( (response) => {
        setUser(response.data.response);
      });
    });
    return setOpenModal(false);
  };

  const closeProfileModal = () => {
    setPreviewImg("");
    return setOpenModal(false);
  };

  const handleGetUser = async (userId) => {

    setGlobalLoading(true);
    let response = await getUser(userId);
    if(response && response.data && response.data.code === 200){
      setUser(response.data.response);
    }
    setGlobalLoading(false);
  }

  useEffect( () => {
    if(currentPath[1] === 'user-account'){
      handleGetUser(currentPath[2]);
    }
  }, []);

  return (
    <>
      <div className={classes.headerContainer}>
        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={openModal}
          className={classes.modalTagPlayer}
        >
          <div>
            <ReactCrop
              src={upImg}
              style={{ maxWidth: "400px", maxHeight: "600px" }}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={c => setCrop(c)}
              onComplete={makeClientCrop}
              locked={true}
            />
            <div className={classes.buttonCropContainer}>
              {previewImg && (
                <div className={classes.buttonContainer}>
                  <button
                    className='saveButton'
                    onClick={() => sendProfileImg()}
                  >
                    Guardar Imagen
                  </button>
                </div>
              )}

              <div className={classes.buttonContainer}>
                <button
                  className='cancelButton'
                  onClick={() => closeProfileModal()}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Dropzone
          onDrop={dropped => handleDrop(dropped)}
          img={resourceApiEndPoint() + "/" + (user ? user.img_file : '') }
        />
      </div>
      <div className={classes.fieldsContainer}>
        <h2>{translate(user.lang, 'user data')}</h2>
        <div className={classes.inputContainer}>
          <div>
            <label>
              {translate(user.lang, 'name')}
              <br />
              <input
                className='inputField largeInput'
                placeholder={translate(user.lang, 'enter your name')}
                value={user? user.name: ''}
                type='text'
                id='name'
                onChange={e => handleChange(e.target)}
              />
            </label>
          </div>
          <div>
            <label>
              {translate(user.lang, 'lastname')}
              <br />
              <input
                className='inputField largeInput'
                placeholder={translate(user.lang, 'enter your lastname')}
                value={user ? user.lastname ?  user.lastname : '' : ''}
                type='text'
                id='lastname'
                onChange={e => handleChange(e.target)}
              />
            </label>
          </div>
          <div>
            <label>
              DNI
              <br />
              <input
                className='inputField largeInput'
                placeholder={translate(user.lang, 'enter your dni, without dot')}
                value={ user ? user.dni ? user.dni : '' : ''}
                type='text'
                id='dni'
                onChange={e => handleChange(e.target)}
                autocomplete="off"
              />
              <br />
              {dniError && <span>{translate(user.lang, 'wrong ID')}</span>}
            </label>
          </div>

          { false &&
          <div>
            <label>
              {translate(user.lang, 'birthdate')}
              <br />
              <input
                className='inputField largeInput'
                placeholder={"12/10/1990"}
                value={user ? user.birthday ? user.birthday : '' : ''}
                type='text'
                id='birthday'
                onChange={e => handleChange(e.target)}
                />
              <br />
              {birthdayError && (
                <span>{translate(user.lang, 'date-must-format')} DD/MM/AAAA</span>
                )}
            </label>
          </div>
          }

        </div>
      </div>
      <div className={classes.fieldsContainer}>
        <h2>{translate(user.lang, 'synchronization data')}</h2>
        <div>
          {translate(user.lang, 'complete-platform')}
        </div>

        <div className={classes.videoFormContainer}>
          <h2>{translate(user.lang, 'gloouds account')}</h2>
          <hr />
          <div className='videoInputContainer'>
            <div>
              <label>
                {translate(user.lang, 'current password')}
                <br />
                <input
                  className='inputField largeInput'
                  type='password'
                  id='currentPassword'
                  onChange={e => handlePasswordGlooud(e.target)}
                />
              </label>
            </div>
            <div>
              <label>
                {translate(user.lang, 'new password')}
                <br />
                <input
                  className='inputField largeInput'
                  type='password'
                  id='newPassword'
                  onChange={ e => handlePasswordGlooud(e.target)}
                />
              </label>
            </div>
            <div>
              <label>
                {translate(user.lang, 'repit new password')}
                <br />
                <input
                  className='inputField largeInput'
                  type='password'
                  id='repitPassword'
                  onChange={e => handlePasswordGlooud(e.target)}
                />
              </label>
            </div>
            <div>
            <label>
                {translate(user.lang, 'update password')}
                <br />
                <SaveIcon onClick={ handleUpdatePasswordGlooud } fontSize="large" style={{ cursor: 'pointer', width: "50px", color: "#5FDBC2", margin: "5px 5px 0 0" }} />
                { errorPassword &&           
                  <div>
                      <label style={{color: '#e45d5d'}}>
                        {translate(user.lang, 'password error')}...
                      </label>
                  </div>
                }
                { updatedPassword &&           
                  <div>
                      <label style={{color: '#74c166'}}>
                        {translate(user.lang, 'password updated')}...
                      </label>
                  </div>
                }
              </label>
              
            </div>
          </div>

        </div>

        <div className={classes.videoFormContainer}>
          <h2>{translate(user.lang, 'drive account')}</h2>
          <hr />
          <div className='videoInputContainer'>
            <div>
              <label>
                {translate(user.lang, 'user')}
                <br />
                <input
                  className='inputField largeInput'
                  type='text'
                  value={user ? user.username : ''}
                  id='wyscout_username'
                  onChange={e => handleChange(e.target)}
                />
              </label>
            </div>
            <div>
              <label>
                {translate(user.lang, 'password')}
                <br />
                <input
                  className='inputField largeInput'
                  type='password'
                  id='wyscout_password'
                  onChange={e => handleChange(e.target)}
                />
              </label>
            </div>
          </div>
        </div>

        <div className={classes.videoFormContainer}>
          <h2>{translate(user.lang, 'youtube account')}</h2>
          <hr />
          <div className='videoInputContainer'>
            <div>
              <label>
                Api Key
                <br />
                <input
                  className='inputField largeInput'
                  type='text'
                  id='youtube_api_key'
                  onChange={e => handleChange(e.target)}
                />
              </label>
            </div>
          </div>
        </div>
        <div className={classes.videoFormContainer}>
          <h2>{translate(user.lang, 'veo cam account')}</h2>
          <hr />
          <div className='videoInputContainer'>
            <div>
              <label>
                {translate(user.lang, 'user')}
                <br />
                <input
                  className='inputField largeInput'
                  type='text'
                  id='veo_username'
                  onChange={e => handleChange(e.target)}
                />
              </label>
            </div>
            <div>
              <label>
                {translate(user.lang, 'password')}
                <br />
                <input
                  className='inputField largeInput'
                  type='password'
                  id='veo_password'
                  onChange={e => handleChange(e.target)}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.buttonSaveContainer}>
        <button className='formSaveButton' onClick={() => handleSubmit()}>
          {translate(user.lang, 'save')}
        </button>
      </div>
      {globalLoading && <GlobalLoading />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, { update_user })(User);
