import React, { useEffect, useState, useRef } from "react";
import { getObjectFromArrayWithKeysByProperty } from "../services";
import GlobalLoading from "../components/accessories/GlobalLoading";
import VideoAnalysisModal from "../components/modals/VideoAnalysisModal";
import EventVideoListComponent from "../components/EventVideoListComponent";
import { getAnalysisVideoByUsername, getPlayersByTeamId } from "../endpoints";
import NavCardPlayer from "../components/NavCardPlayer/NavCardPlayer";

const queryString = require("query-string");

const EventVideoList = (props) => {
  let response = null;
  const { user } = props;
  const playerModalRef = useRef(null);
  const [matches, setMatches] = useState(null);
  const [eventsToShow, setEventsToShow] = useState(null);
  const [globalLoading, setGlobalLoading] = useState(true);
  const [analysisSelected, setAnalysisSelected] = useState(null);
  const [playersListByTeam, setPlayersListByTeam] = useState({});
  const [activeModalEventsToShow, setActiveModalEventsToShow] = useState(false);
  const [view, setView] = useState(3);

  const handlePlayEvent = (item, time) => {
    setEventsToShow({
      ...eventsToShow,
      eventActive: item,
      event_time: time,
      playing: true,
    });
    playerModalRef.current.seekTo(time, "seconds");
  };

  const handleGetPlayersByTeamId = async (e) => {
    let eTemp = { ...e };
    let newPlayerListByTeam = playersListByTeam;
    if (
      newPlayerListByTeam[e.team_id] &&
      newPlayerListByTeam[e.team_id][e.wplayer_id]
    ) {
      eTemp = {
        ...eTemp,
        wplayer: newPlayerListByTeam[e.team_id][e.wplayer_id],
      };
    } else {
      response = await getPlayersByTeamId(e.team_id);
      if (response.status === 200) {
        let playersByteam = getObjectFromArrayWithKeysByProperty(
          response.data.response,
          "_id"
        );
        newPlayerListByTeam = {
          ...newPlayerListByTeam,
          [e.team_id]: playersByteam,
        };
        eTemp = {
          ...eTemp,
          wplayer: newPlayerListByTeam[e.team_id][e.wplayer_id],
          tag: "events",
        };
      }
    }
    setPlayersListByTeam(newPlayerListByTeam);
    // console.log(eTemp)
    return eTemp;
  };

  const handleShowEvents = async (analysis) => {
    //console.log(analysis);

    let eventsToShowTemp = analysis.analysisVideoEvents;

    /* esto es para buscar los jugadores de los eventos, porque solo se tiene el id */
    eventsToShowTemp = await Promise.all(
      eventsToShowTemp.map(async (event) => {
        let eventTemp = event;
        let event_action_temp = await Promise.all(
          event.event_action.map((e) => handleGetPlayersByTeamId(e))
        );
        eventTemp["event_action"] = event_action_temp;
        return eventTemp;
      })
    );

    //console.log(eventsToShowTemp)

    /* esto es para buscar los jugadores de los eventos, porque solo se tiene el id */
    let eventTimeTemp = eventsToShowTemp[0]
      ? { playing: true }
      : { playing: false };
    setAnalysisSelected(analysis);
    setEventsToShow({
      action: "events",
      events: eventsToShowTemp,
      eventActive: 0,
      ...eventTimeTemp,
    });
    setActiveModalEventsToShow(!activeModalEventsToShow);

    if (eventsToShowTemp[0]) {
      setTimeout(function() {
        if (playerModalRef) {
          playerModalRef.current.seekTo(
            eventsToShowTemp[0].event_time,
            "seconds"
          );
        }
      }, 300);
    }
  };

  useEffect(() => {
    async function getVideoList() {
      console.log(queryString.parse(props.location.search));
      response = await getAnalysisVideoByUsername(user.username, {
        wplayer_id1: queryString.parse(props.location.search).wplayer_id1,
      });
      if (response.status === 200) {
        setMatches(
          response.data.response.filter((match) => {
            return match.analysisVideoEvents.length > 0;
          })
        );
      } else {
        console.log("error");
      }
      setGlobalLoading(false);
    }
    getVideoList();
  }, []);

  return (
    <>
      <NavCardPlayer {...props} view={view} setView={setView} />
      <EventVideoListComponent
        matches={matches}
        handleShowEvents={handleShowEvents}
        user={user}
      />

      <VideoAnalysisModal
        analysis={analysisSelected}
        eventsToShow={eventsToShow}
        open={activeModalEventsToShow}
        playerModalRef={playerModalRef}
        handlePlayEvent={handlePlayEvent}
        setActiveModalEventsToShow={setActiveModalEventsToShow}
      />
      {globalLoading && <GlobalLoading />}
    </>
  );
};
export default EventVideoList;
