import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    paper: {
        top: '50%',
        left: '50%',
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'fixed',
        alignItems: 'center',
        background: '#33333391',
        justifyContent: 'center',
        transform: 'translate(-50%, -50%)',
    },
    globalLoading: {
        '& .MuiCircularProgress-root' : {
            color: '#fff',
            width: '100px !important',
            height: '100px !important',
        }
    },
    containerLoading: {
        width: '100vw',
        zIndex: 10000,
        position: 'absolute',
    }
}));

const GlobalLoading = () => {

    const classes = useStyles();

    return (

        <div className={classes.containerLoading}>
            <div className={classes.paper}>
                <div style={{textAlign: 'center'}}>
                    <div className={classes.globalLoading}>
                        <CircularProgress />
                    </div>
                    <p style={{margin: '15px 0'}}>Loading...</p>
                </div>
            </div>
        </div>
    );
};

export default React.memo(GlobalLoading);