import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, Grid } from "@material-ui/core";

import { translate } from "../../lang/lang";

const useStyles = makeStyles(() => ({
  name: {
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: "Bebas-Bold",
  },
  AppBar: {
    backgroundColor: "#1E4249",
    position: "relative",
    marginLeft: "-7px",
  },
  text: {
    marginTop: "2px",
    fontFamily: "Bebas-Bold",
  },
  p: {
    fontSize: "18px",
    margin: "5px",
    fontFamily: "Bebas-Bold",
  },
  margin: {
    // marginLeft: theme.spacing(10),
  },
}));

const BasicPlayerInfo = (props) => {
  let { player, getCountryName } = props;

  const { user } = props;

  // const getSponsorName = (id) => {
  //   if (sponsor) {
  //     let temp = sponsor.filter((sp) => {
  //       return sp._id === player.sponsor_id;
  //     });
  //     if (temp[0]) {
  //       return temp[0].name;
  //     }
  //   }
  //   return null;
  // };

  const classes = useStyles();
  return (
    <div className={classes.margin}>
      <AppBar className={classes.AppBar}>
        <Toolbar>
          <Typography className={classes.name}>
            {player.name + " " + player.lastname}
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container>
        <Grid item xs={6} md={6} lg={6}>
          <div className={classes.text}>
            <Typography className={classes.p}>
              {/**Nacionalidad */}
              {translate(user.lang, "nationality")}:{" "}
              {player.wcountryids &&
                getCountryName(player.wcountryids.split(" ")[0])}
              {player.wcountryids && player.wcountryids.split(" ")[1] && (
                <>
                  {player.wcountryids &&
                    getCountryName(player.wcountryids.split(" ")[1])}
                </>
              )}
              - {player.birthdate} - {translate(user.lang, "male")} -{" "}
              {translate(user.lang, "dni")}:
            </Typography>
            <Typography className={classes.p}>
              {translate(user.lang, "passport")}: {player.passport_number}
            </Typography>
            <Typography className={classes.p}>
              {translate(user.lang, "telephone")}: {player.responsible_phone}
            </Typography>
            <Typography className={classes.p}>
              {translate(user.lang, "father")}/{translate(user.lang, "tutor")}:
              {player.father_name}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <div className={classes.text}>
            <Typography className={classes.p}>
              {translate(user.lang, "market")}:
            </Typography>
            <Typography className={classes.p}>
              {translate(user.lang, "federative law")}: {player.federal_law}
            </Typography>
            <Typography className={classes.p}>
              {translate(user.lang, "economic law")}: {player.economic_law}
            </Typography>
            <Typography className={classes.p}>
              {translate(user.lang, "right image")}: {player.image_rights}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default BasicPlayerInfo;
