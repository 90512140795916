import axios from "axios";
import environment from "environment";

const BASE_API = environment.BASEAPI;
const CLIENT_ENVIROMENT = environment.ENV;
const BASE_API_RESOURCE = environment.RESOURCE;

export function resourceApiEndPoint() {
  return BASE_API_RESOURCE;
}

function buildDateSend(data) {
  let dataSend = new FormData();
  Object.keys(data).map((input) => {
    dataSend.append(input, data[input]);
    return null;
  });
  return dataSend;
}

function getAxios(url, params) {
  const headers = {
    headers: { "X-AUTH-TOKEN": localStorage.getItem("apikey") },
    "Content-Type": "multipart/form-data",
  };
  let config = params ? { ...headers, params: params } : headers;
  return axios
    .get(url, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

async function postAxios(url, data) {
  const headers = {
    headers: { "X-AUTH-TOKEN": localStorage.getItem("apikey") },
    "Content-Type": "multipart/form-data",
  };

  let dataSend = buildDateSend(data);

  let response = await fetch(url, {
    mode: "cors",
    method: "POST",
    body: dataSend,
    headers: headers.headers,
  }).then(function(response) {
    return response.json();
  });
  return response;
}

function putAxios(url, data) {
  const headers = {
    headers: { "X-AUTH-TOKEN": localStorage.getItem("apikey") },
    "Content-Type": "multipart/form-data",
  };

  let dataSend = buildDateSend(data);
  dataSend.append("_method", "PUT");

  let response = fetch(url, {
    method: "POST",
    body: dataSend,
    headers: headers.headers,
  }).then(function(response) {
    return response.json();
  });
  return response;
}

function deleteAxios(url) {
  const headers = {
    headers: { "X-AUTH-TOKEN": localStorage.getItem("apikey") },
    "Content-Type": "multipart/form-data",
  };

  let response = fetch(url, {
    mode: "cors",
    method: "DELETE",
    headers: headers.headers,
  }).then(function(response) {
    return response.json();
  });
  return response;
}

/* GETS */

export function getRoles() {
  return getAxios(`${BASE_API_RESOURCE}/es/doc/roles`);
}

export function getSkillsByRoleVoterIdAndRoleVotedId(query) {
  return getAxios(`${BASE_API_RESOURCE}/es/doc/roleskill/getbyroles`, query);
}

export function getGeneralSkillsProgress(query) {
  return getAxios(
    `${BASE_API_RESOURCE}/es/doc/rates/progress/general_skills`,
    query
  );
}

export function getPrimarySkillsProgress(query) {
  return getAxios(
    `${BASE_API_RESOURCE}/es/doc/rates/progress/primary_skills`,
    query
  );
}

export function getSecundarySkillsProgress(query) {
  return getAxios(
    `${BASE_API_RESOURCE}/es/doc/rates/progress/secondary_skills`,
    query
  );
}

export function getRatesByUserVoterIDAndUserVotedID(user_voter, user_voted) {
  return getAxios(
    `${BASE_API_RESOURCE}/es/doc/rates/${user_voter}/${user_voted}`
  );
}

export function getStatsByPlayers(user_voted) {
  return getAxios(
    `${BASE_API_RESOURCE}/es/doc/rates/getstatsbyplayers?user_voted_id=${user_voted}`
  );
}

export function getStatsStaffRoles(user_voted) {
  return getAxios(
    `${BASE_API_RESOURCE}/es/doc/rates/getstatsstaffroles?user_voted_id=${user_voted}`
  );
}

export function getStatsStaffRolUsers(user_voted, role_voter_id) {
  return getAxios(
    `${BASE_API_RESOURCE}/es/doc/rates/getstatsstaffrolusers?user_voted_id=${user_voted}&role_voter_id=${role_voter_id}`
  );
}

export function getAllFields() {
  return getAxios(`${BASE_API}/fields`);
}

export function getClubs() {
  return getAxios(`${BASE_API}/wclubs`);
}

export function getCountries() {
  return getAxios(`${BASE_API}/wcountries`);
}

export async function getCountriesById(id) {
  return getAxios(`${BASE_API}/wcountry/${id}`);
}

export function getSponsor() {
  return getAxios(`${BASE_API}/sponsor`);
}

export function getSports() {
  return getAxios(`${BASE_API}/sports`);
}

export function getAllReferees() {
  return getAxios(`${BASE_API}/referees`);
}

export function getAllCompetitions() {
  return getAxios(`${BASE_API}/competitions`);
}

export function getFilesByFolder(id) {
  return getAxios(`${BASE_API}/fileByFolder?id=${id}`);
}

export function getAllPlayers() {
  return getAxios(`${BASE_API}/wplayers/getallplayers/getallplayers`);
}

export function getFoldersByUserId(id) {
  return getAxios(`${BASE_API}/folderByUserId?userId=${id}`);
}

export function downloadFile(id) {
  return getAxios(`${BASE_API}/fileupload/download/${id}`);
}

export function getAllDirectors() {
  return getAxios(`${BASE_API}/event_directors`);
}

export function getCompetitions() {
  return getAxios(`${BASE_API}/wcompetitions`);
}

export function listAnalysisVideo() {
  return getAxios(`${BASE_API}/analysis_video`);
}

export function getAllCompetitionTypes() {
  return getAxios(`${BASE_API}/competition_types`);
}

export function getAllEvents() {
  return getAxios(`${BASE_API}/competition_events`);
}

export function getAllEventTags() {
  return getAxios(`${BASE_API}/analysis_video/tags/get-all`);
}

export function getallmetrics() {
  if (CLIENT_ENVIROMENT === "default") {
    return getAxios(`${BASE_API}/gps/getallmetrics`);
  } else if (CLIENT_ENVIROMENT === "infinity") {
    return getAxios(`${BASE_API}/gps/gpexe/getallmetrics/match`);
  }
}

export function getGpsWPlayer(wplayer_id) {
  return getAxios(`${BASE_API}/gps/` + wplayer_id);
}

export function getWPlayerById(wplayer_id) {
  return getAxios(`${BASE_API}/wplayers/getbyid/` + wplayer_id);
}

export function getTeamsByCategoryID(categoryId) {
  return getAxios(`${BASE_API}/teams/getbycategory/${categoryId}`);
}

export function getTeamById(teamId) {
  return getAxios(`${BASE_API}/teams/getby_id/` + teamId);
}

export function getWClubByClubId(clubId) {
  return getAxios(`${BASE_API}/wclub/getbyid/` + clubId);
}

export function getWClubByName(cname) {
  return getAxios(`${BASE_API}/wclub/name/${cname}`);
}

export function getUsers(offset) {
  return getAxios(`${BASE_API}/users/${offset}/10`);
}

export function getUserBySearch(data) {
  return getAxios(`${BASE_API}/users/search`, data);
}

export function getUser(userId) {
  return getAxios(`${BASE_API}/users/getbyid/` + userId);
}

export function getPlayersByTeamId(teamId) {
  return getAxios(`${BASE_API}/team/players/` + teamId);
}

export function getPlayers(data) {
  let params = Object.keys(data)
    .map((key) => {
      if (key === "page") {
        return `${key}=${Number(data[key])}`;
      }
      return `${key}=${data[key]}`;
    })
    .join("&");
  return getAxios(`${BASE_API}/players/sponsor?${params}`);
}

export function getPlayersForFiles() {
  return getAxios(`${BASE_API}/players/sponsor`);
}

export function getFileById(id) {
  return getAxios(`${BASE_API}/fileById?id=${id}`);
}

export function getFileByUserId(id) {
  return getAxios(`${BASE_API}/fileByUsersId/${id}`);
}

export function getAnalysisFiltered(data) {
  let params = Object.keys(data)
    .map((key) => {
      if (key === "page") {
        return `${key}=${Number(data[key]) - 1}`;
      }
      return `${key}=${data[key]}`;
    })
    .join("&");

  return getAxios(`${BASE_API}/analysis_video?${params}`);
}

export function getPlayerBySearch(search) {
  return getAxios(`${BASE_API}/wplayers/search/${search}`);
}

export function getTeambyClubId(clubId) {
  return getAxios(`${BASE_API}/teams/` + clubId);
}
export function getteambyid(teamId) {
  return getAxios(`${BASE_API}/teams/getby_id/` + teamId);
}

export function getteambydate(query) {
  if (CLIENT_ENVIROMENT === "default") {
    return getAxios(`${BASE_API}/gps/getteambydate`, query);
  } else if (CLIENT_ENVIROMENT === "infinity") {
    return getAxios(`${BASE_API}/gps/gpexe/getteambydate/match`, query);
  }
}

export function getFixtureByCompetitionId(competitionId) {
  return getAxios(`${BASE_API}/fixtures/` + competitionId);
}

export function getWScoutMatchVideo(wmatchid) {
  return getAxios(`${BASE_API}/wyscout/match/` + wmatchid);
}

export function getAllMetricsByTeamAndDate(query) {
  return getAxios(`${BASE_API}/gps/getteamallmetrics`, query);
}

export function getstatsbyuservoteridanduservotedid(query) {
  return getAxios(
    `${BASE_API_RESOURCE}/es/doc/rates/getstatsbyuservoteridanduservotedid`,
    query
  );
}

export function getAnalysisVideoById(analysisId) {
  return getAxios(`${BASE_API}/analysis_video/` + analysisId);
}

export function getteambyexercise(query) {
  if (CLIENT_ENVIROMENT === "default") {
    return getAxios(`${BASE_API}/gps/getteambyexercise`, query);
  } else if (CLIENT_ENVIROMENT === "infinity") {
    return getAxios(`${BASE_API}/gps/gpexe/getteambyexercise/match`, query);
  }
}

export function getCompetitionsById(competitionId) {
  return getAxios(`${BASE_API}/competitions/` + competitionId);
}

export function getteamenergyexpenditure(query) {
  // quadrant of energy
  return getAxios(`${BASE_API}/gps/getteamenergyexpenditure`, query);
}

export function fetchCategoriesByClub(data) {
  return getAxios(`${BASE_API}/categories/${data}`);
}

export function getCategoriesByClubAndSport(clubId, sportId) {
  return getAxios(`${BASE_API}/categories/` + clubId + `/` + sportId);
}

export function searchVideosByWclubId(clubId) {
  return getAxios(`${BASE_API}/wyscout/clubs/` + clubId + `/lastmatchs`);
}

export function getListTeamsByClubSportCategory(
  clubId,
  sportName,
  categoryName
) {
  return getAxios(
    `${BASE_API}/teams/getby_wclubid_sport_category/${clubId}/${sportName}/${categoryName}`
  );
}

export function getStructSkill() {
  return getAxios(`${BASE_API_RESOURCE}/es/doc/skills/struct`);
}

export function getUserVoted(playerId) {
  return getAxios(
    `${BASE_API_RESOURCE}/es/doc/rates/get-user-voted/` + playerId
  );
}

export function getAnalysisVideoByUsername(username, query) {
  return getAxios(
    `${BASE_API}/analysis_video/getbyusername/${username}`,
    query
  );
}

export function searchGPexeTracksByTeams(query) {
  return getAxios(`${BASE_API}/gps/tracks`, query);
}

export function getStatsGPSById(playerId) {
  return getAxios(`${BASE_API}/gps/tracks/player_id/` + playerId);
}

export function searchGPexeSessionsByTeams(query) {
  return getAxios(`${BASE_API}/gps/sessions`, query);
}

export function searchGPexeSessionById(sessionId) {
  return getAxios(`${BASE_API}/gps/sessions/${sessionId}`);
}

export function searchGPexeByAthleteSessions(sessionId) {
  return getAxios(`${BASE_API}/gps/sessions/${sessionId}/athlete_sessions`);
}

export function searchGPexeAthleteSessionBrief(sessionId) {
  return getAxios(`${BASE_API}/gps/athletesessions/${sessionId}/brief`);
}

export function searchGPexeAthleteSessionSummary(sessionId) {
  return getAxios(`${BASE_API}/gps/athletesessions/${sessionId}/summary`);
}

export function searchGPexeAthleteSessionPowerseries(sessionId) {
  return getAxios(`${BASE_API}/gps/athletesessions/${sessionId}/powerseries`);
}

/* GETS */

/* POSTS */

export async function login(data) {
  return postAxios(`${BASE_API}/users/auth`, data);
}

export function createUser(data) {
  return postAxios(`${BASE_API}/users`, data);
}

export function postAnalysisVideo(data) {
  return postAxios(`${BASE_API}/analysis_video`, data);
}

export function createAnalysisVideoTag(data) {
  return postAxios(`${BASE_API}/analysis_video/tag`, data);
}

export function addDirector(director) {
  return postAxios(`${BASE_API}/event_directors`, director);
}

export function addFile(file) {
  return postAxios(`${BASE_API}/file`, file);
}

export function addFolder(folder) {
  return postAxios(`${BASE_API}/folder`, folder);
}

export function postCompetition(competition) {
  return postAxios(`${BASE_API}/competitions`, competition);
}

export function postAnalysisVideoEvent(data) {
  return postAxios(`${BASE_API}/analysis_video/event`, data);
}

export function postNewPlayers(data) {
  return postAxios(`${BASE_API}/wplayers`, data);
}

export function postNewClub(data) {
  return postAxios(`${BASE_API}/wclubs`, data);
}

export function newFootballClubCategoryTeam(data) {
  return postAxios(`${BASE_API}/wclubs/football-category-teams`, data);
}

export function postNewTeam(data) {
  return postAxios(`${BASE_API}/teams`, data);
}

export function postUpdateUser(userId, data) {
  return postAxios(`${BASE_API}/users/${userId}`, data);
}

export function postUpdateProfileImg(userId, data) {
  return postAxios(`${BASE_API}/users/${userId}`, data);
}

export function generateFixtureByCompetitionId(competitionId) {
  return postAxios(`${BASE_API}/competitions/generatefixture`, {
    _id: competitionId,
  });
}
export function postCSVPlayerList(file) {
  return postAxios(`${BASE_API}/wplayers-by-excel`, { file: file });
}
export function postEventForm(EventForm) {
  let {
    name,
    address,
    start_time,
    end_time,
    start_date,
    end_date,
    count_days,
    waiting_time_matches,
    description,
    color_primary,
    color_secondary,
    logo,
    selectedFields,
    selectedReferees,
    selectedCategories,
    directors,
  } = EventForm;

  const modifiedEventForm = {
    name,
    address,
    start_time,
    end_time,
    start_date,
    end_date,
    count_days,
    waiting_time_matches,
    description,
    colour_1: color_primary,
    colour_2: color_secondary,
    logo: logo,
    fieldid_1: selectedFields[0].fields,
    fieldid_2: selectedFields[1] === undefined ? "" : selectedFields[1].fields,
    fieldid_3: selectedFields[2] === undefined ? "" : selectedFields[2].fields,
    fieldid_4: selectedFields[3] === undefined ? "" : selectedFields[3].fields,
    fieldid_5: selectedFields[4] === undefined ? "" : selectedFields[4].fields,
    fieldid_6: selectedFields[5] === undefined ? "" : selectedFields[5].fields,
    fieldid_7: selectedFields[6] === undefined ? "" : selectedFields[6].fields,
    fieldid_8: selectedFields[7] === undefined ? "" : selectedFields[7].fields,
    competitionid_1: selectedCategories[0].id,
    competitionid_2:
      selectedCategories[1] === undefined ? "" : selectedCategories[1].id,
    competitionid_3:
      selectedCategories[2] === undefined ? "" : selectedCategories[2].id,
    competitionid_4:
      selectedCategories[3] === undefined ? "" : selectedCategories[3].id,
    refereeid_1: selectedReferees[0].id,
    refereeid_2:
      selectedReferees[1] === undefined ? "" : selectedReferees[1].id,
    refereeid_3:
      selectedReferees[2] === undefined ? "" : selectedReferees[2].id,
    refereeid_4:
      selectedReferees[3] === undefined ? "" : selectedReferees[3].id,
    refereeid_5:
      selectedReferees[4] === undefined ? "" : selectedReferees[4].id,
    refereeid_6:
      selectedReferees[5] === undefined ? "" : selectedReferees[5].id,
    refereeid_7:
      selectedReferees[6] === undefined ? "" : selectedReferees[6].id,
    refereeid_8:
      selectedReferees[7] === undefined ? "" : selectedReferees[7].id,
    directorid_1: directors[0] === undefined ? "" : directors[0].id,
    directorid_2: directors[1] === undefined ? "" : directors[1].id,
    directorid_3: directors[2] === undefined ? "" : directors[2].id,
    directorid_4: directors[3] === undefined ? "" : directors[3].id,
    directorid_5: directors[4] === undefined ? "" : directors[4].id,
    directorid_6: directors[5] === undefined ? "" : directors[5].id,
    directorid_7: directors[6] === undefined ? "" : directors[6].id,
    directorid_8: directors[7] === undefined ? "" : directors[7].id,
  };

  return postAxios(`${BASE_API}/competition_events`, modifiedEventForm);
}

/* POSTS */

/* PUTS */

export function createUpdateRate(user_voter_id, user_voted_id, params) {
  return putAxios(
    `${BASE_API_RESOURCE}/es/doc/rates/${user_voter_id}/${user_voted_id}`,
    params
  );
}

export function updateWplayer(playerId, params) {
  return putAxios(`${BASE_API}/wplayers/updatebyid/${playerId}`, params);
}

/* PUTS */

/* DELETES */

export function deleteWPlayerByWid(wid) {
  return deleteAxios(`${BASE_API}/wplayers/` + wid);
}

export function deleteFolder(id) {
  return deleteAxios(`${BASE_API}/_folder/` + id);
}

export function deleteFile(id) {
  return deleteAxios(`${BASE_API}/file/` + id);
}

export function deleteWPlayerById(id) {
  return deleteAxios(`${BASE_API}/wplayers-by-id/` + id);
}

export function deleteAnalysisVideoById(id) {
  return deleteAxios(`${BASE_API}/analysis_video/` + id);
}

export function deleteCompetitionsById(id) {
  return deleteAxios(`${BASE_API}/competitions/` + id);
}

export function deleteDirector(id) {
  return deleteAxios(`${BASE_API}/event_directors/${id}`);
}

export function deleteEvents(id) {
  return deleteAxios(`${BASE_API}/competition_events/${id}`);
}

/* DELETES */
