import React from 'react'

import { makeStyles } from '@material-ui/core';
import {ReactComponent as Borrar} from '../../assets/img/IconoBasura.svg';
import {ReactComponent as Editar}  from '../../assets/img/IconoEditar.svg';

const useStyles = makeStyles(theme => ({

    cardContainer: {
        width: '315px',
        height: '210px',
        marginTop: '50px',
        marginRight: '30px',
        borderRadius: '7.6px',
        boxSizing: 'border-box',
        border: '0.5px solid #4a4848',
        backgroundColor: 'rgba(21, 23, 25, 0.78)',
        '& .cardCompetitionName': {
            height: '48px',
            padding: '30px',
            fontSize: '16px',
            fontWeight: '700',
        }
    },
    cardInfo: {
        cursor: 'pointer',
        '&:hover': {
            color: '#08BBEE',
        }
    },
    infoContainer: {
        display: 'flex',
        padding: '0 30px',
        justifyContent: 'space-between',
        '& .cardImage':{
            width: '80px',
            height: '80px',
        },
        '& .cardTitle': {
            width: '193px',
        }
    },
    buttonContainer: {
        width: '100%',
        marginTop: '30px',
        backgroundColor: 'transparent',
        borderTop: '0.5px solid #4a4848',
        '& .cardButton': {
            width: '50%',
            border: 'none',
            height: '50px',
            outline: 'none',
            cursor: 'pointer',
            transition: 'all .2s',
            backgroundColor: 'transparent',
        },
        '& .cardButton:hover': {
            transform: 'scale(1.2)',
        },
        '& .buttonImage': {
            width: '24px',
            height: '25px',  
        }
    }
}));

const CompetitionCard = (props) => {

    const classes = useStyles();
    const { competition, handleShowTeams, handleDeleteCompetition } = props

    return (
        <div className={classes.cardContainer}>
            <div className={classes.cardInfo} onClick={ () => handleShowTeams( competition ) }>
                <h2 className='cardCompetitionName'>{competition.name}</h2>
                <div className={classes.infoContainer}>
                    <p>{competition.competition_type_id}</p>
                    <p> {competition.team_ids ? 'Teams: ' + competition.team_ids.length + '/' + competition.quantity_teams : 'Teams: 0/' + competition.quantity_teams }</p>
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <button className='cardButton' onClick={ () => { window.location.href = '/formEventConfirmation'}}><Editar className="buttonImage" /></button>
                <button onClick={ () => handleDeleteCompetition( competition ) } className='cardButton'><Borrar className="buttonImage"/></button>
            </div>
        </div>
    )
}
export default React.memo(CompetitionCard);
