import React from 'react'

import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { addEventHandler } from "../../../actions";
import { postEventForm } from '../../../endpoints';
import { ReactComponent as EventInfoIcon } from '../../../assets/img/Shape.svg';
import { ReactComponent as EventPaymentIcon } from '../../../assets/img/creditCard.svg';
import { ReactComponent as EventDirectorIcon } from '../../../assets/img/icon_team.svg';
import { ReactComponent as EventOrganizationIcon } from '../../../assets/img/drawer.svg';
import { ReactComponent as EventTournamentsIcon } from '../../../assets/img/trophy-ball.svg';

const useStyles = makeStyles({
    eventsButton:{
        width: '224px',
        border: 'none',
        color: 'white',
        outline: 'none',
        fontSize: '12px',
        marginTop: '20px',
        height: '38.52px',
        fontWeight: '700',
        lineHeight: '40px',
        textAlign: 'center',
        transition: 'all .2s',
        borderRadius: '1.81px',
        backgroundColor: '#074682',
        textTransform: 'uppercase',
        transform: 'translateY(11%)',
        '&:hover': {
            transform: 'scale(1.1)',
        },
    },
    buttonContainer:{
        display: 'flex',
        justifyContent: 'space-between',
    },
    subContainer: {
        padding: '0  44px',
        boxSizing: 'border-box',
        backgroundColor: 'rgba(21, 23, 25, 0.78)',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '50px',
        position: 'relative',
        justifyContent: 'space-between',
        '& .title': {
            color: '#FFFFFF',
            fontSize: '27px',
            width: '264.2px',
            fontWeight: '700',
            textAlign: 'left',
            lineHeight: '44px',
            position: 'relative',
            textTransform: 'uppercase',
            font: 'Open Sans Condensed',
            '&::after': {
                left: '0',
                top: '45px',
                content: '""',
                width: '118%',
                position: 'absolute',
                borderBottom: '1px solid white',
            },
        },
    },
    fieldsContainer: {
        width: '100%',
        padding: '25px',
        positon: 'relative',
        marginBottom: '25px',
        borderRadius: '2.6px',
        boxSizing: 'border-box',
        border: '0.5px solid #ffffff',
        '& .subtitle': {
            opacity: '1',
            fontSize: '15px',
            color: '#FFFFFF',
            position: 'relative',
            marginBottom: '24px',
            textTransform: 'uppercase',
        }, 
        '& .colorContainer': {
            width: '200px',
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'space-around',
        },
        '& .color_primary':{
            width: '57px',
            height: '22px',
            margin: '0 20px',
            borderTopLeftRadius: '9px',
            borderBottomLeftRadius: '9px',
            backgroundColor: props => props.colorPrimary,
        },
        '& .color_secondary':{
            width: '57px',
            height: '22px',
            margin: '0 20px',
            borderTopLeftRadius: '9px',
            borderBottomLeftRadius: '9px',
            backgroundColor: props => props.colorSecondary
        },
        '& .imageColorContainer': {
            display: 'flex',
            marginTop: '20px',
        },
        '& .field': {
            marginTop: '4px'
        },
        '& .cardImage':{
            width: '80px',
            height: '80px',
        },
    },
    sectionTitleContainer: {
        display: 'flex',
        marginTop: '50px',
        flexDirection: 'row',
        position: 'relative',
        justifyContent: 'space-between',
        '& .title': {
            width: '264.2px',
            color: '#FFFFFF',
            fontSize: '17px',
            fontWeight: '700',
            textAlign: 'left',
            lineHeight: '44px',
            paddingLeft: '36px',
            position: 'relative',
            textTransform: 'uppercase',
            font: 'Open Sans Condensed',
        },
        '& .titleIcon': {
            top: '13px',
            left: '4px',
            width: '17px',
            height: '17px',
            position: 'absolute',
        },
        '& .titleIcon2': {
            top: '4px',
            left: '2px',
            width: '26px',
            height: '26px',
            position: 'absolute',
        },
        '& .titleIcon3': {
            top: '11px',
            left: '0px',
            width: '21px',
            height: '21px',
            marginRight: '12px',
            position: 'absolute',
        },
        '& .titleIcon4': {
            top: '7px',
            left: '2px',
            width: '26px',
            height: '26px',
            marginRight: '12px',
            position: 'absolute',
        },
    }
});

export const EventConfirmation = ({eventForm, addEventHandler, initialState, ...props}) => {
    
    const handleClick = async (data) => {
        let newEventsSaved = await postEventForm(data);
        if(newEventsSaved && newEventsSaved.code === 200 ){
            newEventsSaved = {_id: newEventsSaved.event_id, ...data}
            addEventHandler(newEventsSaved);
            console.log(newEventsSaved)        
        }else{
            console.log('error');
        }
    }
    const colorPrimary = eventForm.color_primary;
    const colorSecondary = eventForm.color_secondary;
    const classes = useStyles({colorPrimary, colorSecondary});

    return (
        <>
            <div className={classes.headerContainer}>
                <h1 className='title'>Event {eventForm.name}</h1>
                
            </div>
            <div className={classes.subContainer}>
                <div className={classes.sectionTitleContainer}>
                    <EventInfoIcon className='titleIcon'/>
                    <h1 className='title'>Datos del evento</h1>
                </div>
                <div className={classes.fieldsContainer}>
                    <h2 className='subtitle'>Event Info</h2>
                    <p className='field'>Event Name: {eventForm.name}</p>
                    <p className='field'>City: {eventForm.address}</p>
                    <p className='field'>Start Time: {eventForm.start_time}</p>
                    <p className='field'>Start Date: {eventForm.start_date}</p>
                    <p className='field'>Ending Date: {eventForm.end_date}</p>
                    <p className='field'>Play days: {eventForm.count_days}</p>
                    <p className='field'>Waiting time: {eventForm.waiting_time_matches}</p>
                </div>
                <div className={classes.fieldsContainer}>
                    <h2 className='subtitle'>Event Personalization</h2>
                    <p className='field'>Description: {eventForm.description}</p>
                    <div className='imageColorContainer'>
                        <img className='cardImage' src={eventForm.logoPreview} alt="event logo"/>
                        <div className='colorContainer'>
                            <div className='color_primary'/> <p>Color 1</p>
                            <div className='color_secondary'/> <p>Color 2</p>
                        </div>
                    </div>
                </div>

                <div className={classes.sectionTitleContainer}>
                    <EventTournamentsIcon className='titleIcon2'/>
                    <h1 className='title'>Competitions</h1>
                </div>
                <div className={classes.fieldsContainer}>
                    <h2 className='subtitle'>Tournaments</h2>
                    {eventForm.selectedCategories.map((cat)=> {
                        return (<p className='field'>{cat.category}</p>)
                    })}
                    
                </div>

                <div className={classes.sectionTitleContainer}>
                    <EventOrganizationIcon className='titleIcon3'/>
                    <h1 className='title'>Organizacion</h1>
                </div>

                <div className={classes.fieldsContainer}>
                    <h2 className='subtitle'>Fields</h2>
                    {eventForm.selectedFields.map((fil) => {
                        return (<p className='field'>{fil.field}</p>)
                    })}
                </div>
            
                <div className={classes.fieldsContainer}>
                    <h2 className='subtitle'>Referees</h2>
                    {eventForm.selectedReferees.map((ref) => {
                        console.log(ref);
                        return (<p className='field'>{`Referee: ${ref.refName}  ${ref.refLastName}`}</p>)
                    })}
                </div>

                <div className={classes.sectionTitleContainer}>
                    <EventDirectorIcon className='titleIcon4'/>
                    <h1 className='title'>Director</h1>
                </div>
                <div className={classes.fieldsContainer}>
                    <h2 className='subtitle'>Director responsable</h2>
                    {eventForm.directors.map((dir) => {
                        return (
                            <>
                            <p className='field'>Nombre: {dir.name}</p>
                            <p className='field'>Apellido: {dir.lastname}</p>
                            <p className='field'>Mail: {dir.mail}</p>
                            <p className='field'>Telefono: {dir.phone}</p>
                            </>
                        )
                    })}
                
                </div>

                <div className={classes.sectionTitleContainer}>
                    <EventPaymentIcon className='titleIcon4'/>
                    <h1 className='title'>Metodo de Pago</h1>
                </div>
                <div className={classes.fieldsContainer}>
                    <p className='field'>{eventForm.paymentMethod}</p>
                </div>
                <div className={classes.buttonContainer}>
                    <NavLink to={'/formEventInfo'} className={classes.eventsButton}>Atras</NavLink>
                    <NavLink to={ '/events'} onClick={()=> handleClick(eventForm)}  className={classes.eventsButton}>Guardar</NavLink>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        eventForm: state.eventForm,
        eventList: state.eventList,
        initialState: state.initialState
    }
}

export default connect(mapStateToProps, { addEventHandler })(EventConfirmation);