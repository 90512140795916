import React from "react";

import _ from "lodash";
import moment from "moment";
import "./ListPlayersComponent.css";
import { translate } from "../../lang/lang";
import { resourceApiEndPoint } from "../../endpoints";
import ActionsToggle from "../accessories/actionsToggle/actionsToggle";

function ListPlayersComponent(props) {
  let {
    user,
    players,
    countries,
    clubSelect,
    handleGoToGps,
    handleGoToGpexe,
    fieldAndHeaders,
    handleGoTagPlayer,
    handleGoEditPlayer,
    handleGoDeletePlayer,
  } = props;

  return (
    <>
      {!_.isEmpty(props.players) ? (
        <table className="table">
          <thead>
            <tr>
              {Object.keys(fieldAndHeaders).map((header, i) => {
                let headerTD = null;
                switch (header) {
                  case "image":
                  case "action":
                    headerTD = "";
                    break;
                  default:
                    headerTD = header;
                    break;
                }
                //return <td key={i}>{headerTD}</td>;
                return <td key={i}>{translate(user.lang, headerTD)}</td>;
              })}
            </tr>
          </thead>
          <tbody>
            {players.map((item) => {
              return (
                <tr key={item._id} className={"row-items"}>
                  {Object.keys(fieldAndHeaders).map((header) => {
                    let renderTD = null;
                    switch (header) {
                      case "image":
                        renderTD = (
                          <div className="table-img-container">
                            <img
                              src={resourceApiEndPoint() + "/" + item.img_file}
                              alt=""
                            />
                          </div>
                        );
                        break;
                      case "players":
                        renderTD = (
                          <div className="table-name-container">
                            <p>{item.name + " " + item.lastname}</p>
                          </div>
                        );
                        break;
                      case "club":
                        renderTD = item[fieldAndHeaders[header]]
                          ? item[fieldAndHeaders[header]]
                          : item.wclub_name
                          ? item.wclub_name
                          : clubSelect;
                        break;

                      case "position":
                        renderTD = item[fieldAndHeaders[header]];
                        break;

                      case "category":
                        renderTD = item.infoTeam && item.infoTeam.category.name;
                        break;
                      case "team":
                        renderTD = item.infoTeam && item.infoTeam.name;
                        break;
                      case "nationality":
                        if (!item.wcountryids) return <td />;
                        let arrayNationSelected = item.wcountryids.split(" ");
                        //renderTD = item[fieldAndHeaders[header]] ? countries[Number(item[fieldAndHeaders[header]].slice(0,3))] ? countries[Number(item[fieldAndHeaders[header]].slice(0,3))].name : item[fieldAndHeaders[header]] : item[fieldAndHeaders[header]];
                        renderTD =
                          arrayNationSelected && arrayNationSelected[0]
                            ? arrayNationSelected[1]
                              ? `${countries[arrayNationSelected[0]].name} - ${
                                  countries[arrayNationSelected[1]].name
                                }`
                              : countries[arrayNationSelected[0]].name
                            : `${item[fieldAndHeaders[header]]}`;
                        break;
                      case "year-old":
                        renderTD = item[fieldAndHeaders[header]]
                          ? moment().diff(
                              moment(
                                item[props.fieldAndHeaders[header]],
                                "DD/MM/YYYY"
                              ),
                              "years"
                            ) + " años"
                          : "-";
                        break;
                      case "actions":
                        renderTD = (
                          <ActionsToggle
                            item={item}
                            user={user}
                            handleGoToGps={handleGoToGps}
                            handleGoToGpexe={handleGoToGpexe}
                            handleGoTagPlayer={handleGoTagPlayer}
                            handleGoEditPlayer={handleGoEditPlayer}
                            handleGoDeletePlayer={handleGoDeletePlayer}
                          />
                        );
                        break;
                      default:
                        break;
                    }
                    return <td key={header + 1}>{renderTD}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div style={{ textAlign: "center", padding: "1rem", fontSize: "18px" }}>
          We couldn't find any results
        </div>
      )}
    </>
  );
}

export default React.memo(ListPlayersComponent);
