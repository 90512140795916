import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import ShareIcon from "@material-ui/icons/Share";

import { translate } from "../../lang/lang";
import { downloadFile } from "../../endpoints";
import DeleteMessage from "../modals/deleteMessage";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: "3rem",
    borderCollapse: "collapse",
    border: "none",
    width: "100%",
    fontFamily: "Montserrat-Semibold",
    backgroundColor: "#1c1e24",
    borderRadius: 10,
    "& thead tr td": {
      padding: "15px 5px",
      fontSize: "1em",
      color: "#6bffe6",
      border: "none",
      width: 140,
      "&:first-child": {
        paddingLeft: "2rem",
      },
      // "&:last-child": {
      //   paddingRight: "2rem",
      // },
    },
    "& tbody tr td": {
      fontFamily: "Montserrat-Medium",
      fontSize: 14,
      fontWeight: "300",
      color: "white",
      padding: "5px",
      "&:first-child": {
        paddingLeft: "2rem",
      },
      // "&:last-child": {
      //   paddingRight: "2rem",
      // },
    },
    "& tbody tr:nth-child(odd)": {
      backgroundColor: "rgba(21, 23, 25, 0.7)",
    },
    "& tbody tr:nth-child(even)": {
      backgroundColor: "#1c1e24",
    },
  },
}));

function ListFiles(props) {
  const classes = useStyles();
  const { user, files, idParams, filesByUser } = props;
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  let rows = [];

  for (var key in files) {
    rows.push({
      _id: key,
      name: props.files[key].name,
      nameUser: props.files[key].nameUser,
      assigned_id: props.files[key].asigned_to,
      assigned: props.files[key].name_asigned,
      extension: props.files[key].extension,
      createdAt: props.files[key].createdAt,
      observations: props.files[key].observations,
    });
  }

  const data = idParams !== undefined ? filesByUser : rows;

  const getAssigneds = (assigneds) => {
    if (assigneds !== undefined) {
      const data = [];
      const assignedsArray = assigneds.split(",");
      assignedsArray.map((assigned) => {
        if (assigned !== " ") {
          data.push(assigned);
        }
      });
      return data;
    }
  };
  return (
    <>
      {data.length > 0 ? (
        <table className={classes.table}>
          <thead>
            <tr>
              <td>{translate(user.lang, "name table file")}</td>
              <td>{translate(user.lang, "author table file")}</td>
              <td>{translate(user.lang, "assigned")}</td>
              <td>{translate(user.lang, "type table file")}</td>
              <td>{translate(user.lang, "publish data table file")}</td>
              <td>{translate(user.lang, "comments table file")}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {data
              .filter(
                (row) =>
                  !props.search ||
                  row.name.toLowerCase().includes(props.search.toLowerCase())
              )
              .map((file, index) => {
                if (file !== undefined) {
                  return (
                    <tr key={index} className="row-items">
                      <td className={classes.tdHover} id={`${file._id}.jpeg`}>
                        {file.name}
                      </td>
                      <td className={classes.tdHover}>{file.nameUser}</td>
                      <td className={classes.tdHover}>
                        {getAssigneds(file.assigned)}
                      </td>
                      <td className={classes.tdHover}>{file.extension}</td>
                      <td className={classes.tdHover}>{file.createdAt}</td>
                      <td className={classes.tdHover}> {file.observations}</td>
                      <td>
                        <LightTooltip
                          title={translate(user.lang, "click download")}
                        >
                          <IconButton
                            className="iconbtn"
                            onClick={() => {
                              downloadFile(file._id + "." + file.extension)
                                .then((res) => {
                                  window.open(res.config.url);
                                })
                                .catch((err) => console.log(err));
                            }}
                          >
                            <GetAppIcon style={{ color: "#6CFFE6" }} />
                          </IconButton>
                        </LightTooltip>
                        <LightTooltip title={translate(user.lang, "soon")}>
                          <IconButton
                            className="iconbtn"
                            style={{ cursor: "default" }}
                          >
                            <ShareIcon style={{ color: "#6CFFE6" }} />
                          </IconButton>
                        </LightTooltip>
                        <LightTooltip title={translate(user.lang, "delete")}>
                          <IconButton
                            className="iconbtn"
                            onClick={() => {
                              setOpenDeleteMessage(true);
                              props.setId(file._id);
                            }}
                          >
                            <DeleteIcon style={{ color: "#6CFFE6" }} />
                          </IconButton>
                        </LightTooltip>
                      </td>
                    </tr>
                  );
                }
              })}
          </tbody>
        </table>
      ) : (
        <div
          style={{ textAlign: "center", padding: "1rem", fontSize: "18px" }}
        ></div>
      )}
      <DeleteMessage
        openDeleteMessage={openDeleteMessage}
        setOpenDeleteMessage={setOpenDeleteMessage}
        remove={props.removeFile}
        id={props.id}
        msg={props.msg}
      />
    </>
  );
}

export default withRouter(React.memo(ListFiles));
