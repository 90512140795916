import React, {useEffect, useState, useRef} from 'react';

import { connect } from "react-redux";
import GlobalLoading from '../components/accessories/GlobalLoading';
import VideoAnalysisModal from '../components/modals/VideoAnalysisModal';
import AnalysisVideoComponent from '../components/AnalysisVideoComponent';
import  { 
            //getteambyid,
            getPlayersByTeamId,
            getAnalysisVideoById,
            createAnalysisVideoTag,
            postAnalysisVideoEvent
        } from '../endpoints';

const mapStateToProps = state => {
  return { user: state.user, analysisVideo: state.analysisVideo, infoTeams: state.infoTeams };
};

const AppAnalysisVideo = (props) => {
    const { user /*, openSideBar, analysisVideo, infoTeams */ } = props;
    let response = null;
    //const { openSideBar } = props;
    const playerRef = useRef(null);
    const playerModalRef = useRef(null);
    const [analysis, setAnalysis] = useState(null);
    const analysisId = props.match.params.analysisId;
    const [newTagName, setNewTagName] = useState(null);
    const [tagActions, setTagActions] = useState(null);
    const [eventsToShow, setEventsToShow] = useState(null);
    const [globalLoading, setGlobalLoading] = useState(true);
    //const width = openSideBar ? 'calc(100vw - 332px)': '100%';
    const [tagPlayerSelect, setTagPlayerSelect] = useState(null);
    const [openNewTagModal, setOpenNewTagModal] = useState(false);
    const [openTagPlayer, handleCloseOpenTagPlayer] = useState(false);
    const [actionByPlayersTemp, setActionByPlayerTemp] = useState({});
    const [event, setEvent] = useState([]) // maneja un array de las acciones
    const [eventInfo, setEventInfo] = useState({
        analysis_id: analysisId,
        minute_event: 1,
        event_time: null,
        local_score: 0,
        visitor_score: 0
    })
    const [activeModalEventsToShow, setActiveModalEventsToShow] = useState(false);

    const handleEventInfo = (e) => {
        
        let {name, value} = e.target;
        value = parseInt(value)
        if( value >= 0 ){
            setEventInfo({...eventInfo, [name]: value })
        }else if (name !== 'minute_event'){
            setEventInfo({...eventInfo, [name]: 0 })
        }else{
            setEventInfo({...eventInfo, [name]: 1 })
        }
    }

    const handleModalClose = () =>{
        handleCloseOpenTagPlayer(!openTagPlayer);
        //setTagPlayerSelect(null);
    }
    const [stepper, setStepper] = useState({
        activeStep: 0,
        steps: [
            'jugador',
            'acción',
            'zona de la acción',
            'like',
            'comentario',
            'guardar'
        ]
    })

    async function handleSearchPlayersByTeamId(teamId) {
        response = await getPlayersByTeamId(teamId);
        if(response && response.status === 200){
            return response.data.response;
        }else{
            console.log('error');
            return null
        }
    }

    const loadTagPlayer = (player, teamId) => {

        if( !tagPlayerSelect || player._id !== tagPlayerSelect._id){
            setTagPlayerSelect({...player, teamId: teamId})
            if( !actionByPlayersTemp[player._id] ){
                setActionByPlayerTemp({...actionByPlayersTemp, [player._id]: {player: player, team_id: teamId} })
            }
            if( !actionByPlayersTemp[player._id] || !actionByPlayersTemp[player._id].tag_id ){
                handleCloseOpenTagPlayer(!openTagPlayer)
            }
        }else{
            setTagPlayerSelect(null)
        }
    }

    const handleFieldZoneSelected = (zone) => {

        let actionPlayerTemp =  {...actionByPlayersTemp[tagPlayerSelect._id]}
        actionPlayerTemp = {...actionPlayerTemp, 'event_field_position_id': zone }
        setActionByPlayerTemp({...actionByPlayersTemp, [tagPlayerSelect._id]: actionPlayerTemp })
    }

    const handleSetTagAction = (tag) => {
        
        let actionPlayerTemp =  {...actionByPlayersTemp[tagPlayerSelect._id]}
        actionPlayerTemp = {...actionPlayerTemp, 'tag_id': tag.tagId, 'tag_name': tag.tagName }
        setActionByPlayerTemp({...actionByPlayersTemp, [tagPlayerSelect._id]: actionPlayerTemp })
        handleCloseOpenTagPlayer(false)
    }

    const handleLike = (like) => {

        let actionPlayerTemp =  {...actionByPlayersTemp[tagPlayerSelect._id]}
        actionPlayerTemp = {...actionPlayerTemp, 'like_nolike': like }
        setActionByPlayerTemp({...actionByPlayersTemp, [tagPlayerSelect._id]: actionPlayerTemp })
    }

    const setActiveStep = (newStep) => {

        if( newStep === stepper.steps.length ){
            let eventTemp = [...event]
            eventTemp.push(actionByPlayersTemp[tagPlayerSelect._id])
            setActionByPlayerTemp({...actionByPlayersTemp, [tagPlayerSelect._id]: {player: actionByPlayersTemp[tagPlayerSelect._id].player, wplayer_id: tagPlayerSelect._id } })
            setEvent(eventTemp)
        }else{
            setStepper({...stepper, activeStep: newStep})
        }
    }

    const handleCommentWrited = (value) => {

        let actionPlayerTemp =  {...actionByPlayersTemp[tagPlayerSelect._id]}
        actionPlayerTemp = {...actionPlayerTemp, 'comment': value }
        setActionByPlayerTemp({...actionByPlayersTemp, [tagPlayerSelect._id]: actionPlayerTemp })
    }

    const handleSaveEvent = async () => {

        setGlobalLoading(true);
        let event_time = {event_time: Math.floor(playerRef.current.getCurrentTime()) } 
        let newDataEventToSend = { event_name: event[0].tag_name, ...eventInfo
        }
        newDataEventToSend = {...newDataEventToSend, ...event_time}
        event.forEach( (action, index) => {
            Object.keys(action).forEach( key => {
                if(key !== 'player'){
                    newDataEventToSend = {...newDataEventToSend, [key + (index + 1)]: action[key] }
                }else{
                    newDataEventToSend = {...newDataEventToSend, ['wplayer_id' + (index + 1)]: action.player._id }
                }
            })
        });
        let response = await postAnalysisVideoEvent(newDataEventToSend);
        if(response && response.code === 200){
            let analysisVideoEventsTemp = [...analysis.analysisVideoEvents]
            let responseEvents = response.result.event_action.map( event => {
                let tagTemp = analysis.analysisVideoResumeEvents.filter( tag => {
                    return event.tag_id === tag.tagId
                });
                event = {...event, tag: tagTemp[0]}
                let playerTemp = analysis.team_local.players.filter( player => {
                    return player._id === event.wplayer_id
                });
                if( playerTemp[0] ){
                    event = { ...event, wplayer: {...playerTemp[0],  wclub_name: analysis.team_local.club.name, wclub_id: analysis.team_local.club_id, wclub_img: analysis.team_local.club.img_file}, wclub: analysis.team_local }
                }else{
                    playerTemp = analysis.team_visitor.players.filter( player => {
                        return player._id === event.wplayer_id
                    });
                    event = { ...event, wplayer: {...playerTemp[0], wclub_name: analysis.team_visitor.club.name, wclub_id: analysis.team_visitor.club_id, wclub_img: analysis.team_visitor.club.img_file}, wclub: analysis.team_visitor }
                }
                return event
            });
            let eventNew = {...response.result, event_action: responseEvents}
            analysisVideoEventsTemp.push(eventNew)
            let analysisVideoResumeEvetsTemp = analysis.analysisVideoResumeEvents.map( (event) => {
                let actionIsEvent = response.result.event_action.filter( action => {
                    return action.tag_id === event.tagId
                });
                if (actionIsEvent.length > 0){
                    actionIsEvent = {...event, count: event.count + actionIsEvent.length }
                }else{
                    actionIsEvent = event
                }
                return actionIsEvent
            });
            setAnalysis({...analysis, analysisVideoEvents: analysisVideoEventsTemp, analysisVideoResumeEvents: analysisVideoResumeEvetsTemp })
            setEvent([])
        }else{
            console.log('error');
        }
        setGlobalLoading(false);
    }

    const handleShowEventsByTagId = (actionTag) => {

        let eventsToShowTemp = analysis.analysisVideoEvents.filter( event => {
            let tagIsIn = event.event_action.filter( action => {
                return action.tag_id === actionTag.tagId
            });
            return tagIsIn.length > 0
        });

        let eventTimeTemp = eventsToShowTemp[0] ? {playing: true} : {playing: false}
        setEventsToShow({action: actionTag, events: eventsToShowTemp, eventActive: 0, ...eventTimeTemp})
        setActiveModalEventsToShow(!activeModalEventsToShow)
        if(eventsToShowTemp[0]){
            setTimeout(function(){ 
                if( playerModalRef ){
                    playerModalRef.current.seekTo(eventsToShowTemp[0].event_time > 5 ? eventsToShowTemp[0].event_time - 5 : 0, 'seconds')
                }
            }, 300);
        }
    }

    const handlePlayEvent = (item, time) => {
        setEventsToShow({...eventsToShow, eventActive: item, event_time: time, playing: true})
        playerModalRef.current.seekTo(time, 'seconds')
    }

    const handleSaveNewTag = async () => {

        setGlobalLoading(true);
        let params = {name: newTagName, colour: '5D8AA8', font_colour: 'FFFFFF'}
        response = await createAnalysisVideoTag(params);
        if(response && response.code === 200){
            let analysisVideoResumeEventsTemp = analysis.analysisVideoResumeEvents;
            analysisVideoResumeEventsTemp.push({tagId: response.result.id, tagName: newTagName, count: 0})
            setTagActions(analysis.analysisVideoResumeEvents)
            setNewTagName(null);
            setGlobalLoading(false)
            handleCloseOpenTagPlayer(true);
            //setActionByPlayerTemp();
            //handleLoadAnalisys();
        }else{
            alert('error, see method "handleSaveNewTag"');
            setGlobalLoading(false);            
        }
        setOpenNewTagModal(false);
    }

    const handleLoadAnalisys = () => {
        async function searchTeamById() {
            response = await getAnalysisVideoById(analysisId)
            if(response && response.status === 200){
                if(response.data.response){
                    let analysisTemp = response.data.response
                    let teamLocalTemp = response.data.response.team_local
                    let teamVisitorTemp = response.data.response.team_visitor
                    let playersLocal = await handleSearchPlayersByTeamId(analysisTemp.teamid_local)
                    let playersVisitor = await handleSearchPlayersByTeamId(analysisTemp.teamid_visitor)
                    teamLocalTemp = {...teamLocalTemp, players: playersLocal }
                    teamVisitorTemp = {...teamVisitorTemp, players: playersVisitor }
                    analysisTemp = {...analysisTemp, team_local: teamLocalTemp, team_visitor: teamVisitorTemp}                   
                    setAnalysis(analysisTemp)
                }else{
                    console.log('error');
                }
            }else{
                console.log('error');
            }
            setGlobalLoading(false)
        }
        searchTeamById()
        window.scrollTo(0, 0)
    }

    useEffect( () => {
        let currentStep = 0
        
        if(tagPlayerSelect){

            if( !actionByPlayersTemp[tagPlayerSelect._id].tag_id ){
                currentStep = 0
            }else if( !actionByPlayersTemp[tagPlayerSelect._id].event_field_position_id ){
                currentStep = 2
            }else if( !actionByPlayersTemp[tagPlayerSelect._id].like_nolike ){
                currentStep = 3
            }else if( !actionByPlayersTemp[tagPlayerSelect._id].comment ){
                currentStep = 4
            }else{
                currentStep = 5
            }

        }
        
        
        setStepper({...stepper , activeStep: currentStep })
    }, [actionByPlayersTemp, tagPlayerSelect]);

    useEffect(() => {
        handleLoadAnalisys();
    }, []);

    useEffect(() => {
        if(analysis){ setTagActions(analysis.analysisVideoResumeEvents) }
    }, [analysis]);

    useEffect( () => {
        if(!openNewTagModal){
            setNewTagName(null);
        }
    },[openNewTagModal]);

    return (
        <>
            <AnalysisVideoComponent 
                user={user}
                event={event}
                stepper={stepper}
                analysis={analysis}
                eventInfo={eventInfo}
                playerRef={playerRef}
                newTagName={newTagName}
                handleLike={handleLike}
                analysisId={analysisId}
                tagActions={tagActions}
                setNewTagName={setNewTagName}
                setActiveStep={setActiveStep}
                loadTagPlayer={loadTagPlayer}
                openTagPlayer={openTagPlayer}
                handleSaveEvent={handleSaveEvent}
                handleEventInfo={handleEventInfo}
                openNewTagModal={openNewTagModal}
                tagPlayerSelect={tagPlayerSelect}
                handleSaveNewTag={handleSaveNewTag}
                handleModalClose={handleModalClose}
                setOpenNewTagModal={setOpenNewTagModal}
                handleSetTagAction={handleSetTagAction}
                handleCommentWrited={handleCommentWrited}
                actionByPlayersTemp={actionByPlayersTemp}
                handleShowEventsByTagId={handleShowEventsByTagId}
                handleFieldZoneSelected={handleFieldZoneSelected}
                handleCloseOpenTagPlayer={handleCloseOpenTagPlayer}
            />
            <VideoAnalysisModal
                analysis={analysis}
                eventsToShow={eventsToShow}
                open={activeModalEventsToShow}
                handlePlayEvent={handlePlayEvent}
                playerModalRef={playerModalRef}
                setActiveModalEventsToShow={setActiveModalEventsToShow}
            />
            { globalLoading &&  <GlobalLoading /> }
        </>
    );
};
const AnalysisVideo = connect(mapStateToProps, null)(AppAnalysisVideo);
export default React.memo(AnalysisVideo);