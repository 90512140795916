import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    
    cardContainer: {
        backgroundColor: 'rgba(21, 23, 25, 0.98)',
        padding: '10px 15px',
        marginRight: '10px',
        display: 'flex',
        border: '1px solid #ffffff',
        borderRadius: '3px',
        marginTop: '10px',
        cursor: 'pointer',
        transition: 'all .2s',

        '&:hover':{
            transform: 'scale(1.05)',
            border: '1px solid #00BBD6',
        }
    }
}));

export const CategoryCheckBox = ({modifyFormCategoryHandler, eventForm, ...props}) => {
    
    let isFound = []
    
    if(eventForm.selectedCategories){
        isFound = eventForm.selectedCategories.find(cat => cat.id === props.id);
    }
    const classes = useStyles();

    const [activeClass, setClass] = useState(isFound);

    const CategoryHandler = () => {
        setClass(!activeClass);
        modifyFormCategoryHandler({   
            id: props.id,
            category: props.name,
            isChecked: !activeClass
        })
    }
    return (
        <div onClick={()=>CategoryHandler()} className={ activeClass ? classes.cardContainer + ' isActive' : classes.cardContainer}>
            <div className='fieldsContainer'>
                <div className='field'>{props.name}</div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        eventForm: state.eventForm,
        categoriesList: state.categoriesList
    }
}

export default connect(mapStateToProps, {
})(CategoryCheckBox);