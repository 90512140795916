import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

const useStyles = makeStyles(theme => ({
    chartContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    chartBar: {
        background: 'white',
        
        '& > svg' : {
            margin: '15px auto'
        }
        
    }
}));

const GpsPlayerComponent = (props) => {

    const classes = useStyles();

    let widthChart = window.screen.width < 768 ? 420 : window.screen.width - 392

    console.log(window.screen.width);
    console.log(widthChart);

    const data = [
        {name: 'Page A', uv: 4000, pv: 2400, amt: 2400},
        {name: 'Page B', uv: 3000, pv: 1398, amt: 2210},
        {name: 'Page C', uv: 2000, pv: 9800, amt: 2290},
        {name: 'Page D', uv: 2780, pv: 3908, amt: 2000},
        {name: 'Page E', uv: 1890, pv: 4800, amt: 2181},
        {name: 'Page F', uv: 2390, pv: 3800, amt: 2500},
        {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
    ];

    let {statsGps} = props;

    let getStatsGps = (statsGps) => {
        if(statsGps){
            return Object.keys(statsGps).map( (key, index) => {
                return  <tr key={index} style={{border: '1px solid white'}}>
                            <td style={{padding: 15, border: '1px solid white'}}>{statsGps[key].P_Vmedia}</td>
                            <td style={{padding: 15, border: '1px solid white'}}>{statsGps[key].P_W}</td>
                            <td style={{padding: 15, border: '1px solid white'}}>{statsGps[key].P_Dist_min}</td>
                            <td style={{padding: 15, border: '1px solid white'}}>{statsGps[key].P_Ener_Per_Massa}</td>
                            <td style={{padding: 15, border: '1px solid white'}}>{statsGps[key].P_Max_Speed}</td>
                            <td style={{padding: 15, border: '1px solid white'}}>{statsGps[key].P_Dist_Tot}</td>
                            <td style={{padding: 15, border: '1px solid white'}}>{statsGps[key].P_Min_Reg}</td>
                            <td style={{padding: 15, border: '1px solid white'}}>{statsGps[key].exercise_date}</td>
                        </tr>;
            });
        }
    }

    return (
    <>
        <table style={{border: '1px solid white', margin: '30px auto'}}>
            <thead>
                <tr style={{border: '1p x solid white'}}>
                    <th style={{padding: 15, border: '1px solid white'}}>P_Vmedia</th>
                    <th style={{padding: 15, border: '1px solid white'}}>P_W</th>
                    <th style={{padding: 15, border: '1px solid white'}}>P_Dist_min</th>
                    <th style={{padding: 15, border: '1px solid white'}}>P_Ener_Per_Massa</th>
                    <th style={{padding: 15, border: '1px solid white'}}>P_Max_Speed</th>
                    <th style={{padding: 15, border: '1px solid white'}}>P_Dist_Tot</th>
                    <th style={{padding: 15, border: '1px solid white'}}>P_Min_Reg</th>
                    <th style={{padding: 15, border: '1px solid white'}}>exercise_date</th>
                </tr>
            </thead>
            <tbody>
                {getStatsGps(statsGps)}
            </tbody>
        </table>

        <div className={classes.chartContainer}>
            <BarChart
                className={classes.chartBar}
                width={widthChart}
                height={300}
                data={data}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" fill="#8884d8" />
                <Bar dataKey="uv" fill="#82ca9d" />
                <Bar dataKey="amt" fill="#dddddd" />
            </BarChart>
        </div>

    </>
    );
};
export default React.memo(GpsPlayerComponent);