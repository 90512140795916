import React from 'react';
import { connect } from "react-redux"
import { makeStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import { modifyFormHandler, modifyFormFieldHandler } from "../../actions/index"

const useStyles = makeStyles(theme => ({

    cardContainer: {
        display: 'flex',
        flexDirection:'column',
        width: '160px',
        border: '0.5px solid #FFFFFF',
        backgroundColor: 'rgba(21, 23, 25, 0.78)',
        boxSizing: 'border-box',
        borderRadius: '2.6px',
        padding: '15px',
        positon: 'relative',
        marginRight: '40px',

        
        '& .cardTitle': {
            fontSize: '10px',
            fontWeight: '400',
            marginBottom: '10px'
        },

        '& .cardHeader': {
            display: 'flex',
            justifyContent: 'space-between'
        },

        '& .closeButton': {
            backgroundColor: 'transparent',
            border: 'none',
            color: '#FFFFFF',
            transform: 'translateY(-5px)',

        },

        '& .fieldsContainer': {
            fontSize: '10px',
            display: 'flex',
            flexDirection: 'column'
        },
        
        '& .field': {
            textTransform: 'capitalize',
            // margin: '5px auto 5px 0',
        },

        '& .MuiTypography-body1': {
            fontSize: '10px',
            fontFamily: 'Montserrat',
        },
        '& .MuiFormControlLabel-root': {
            height: '25px',
        }

    },

    

}));

export const CheckBoxCards = ({ modifyFormHandler, eventForm, modifyFormFieldHandler, ...props }) => {

    const classes = useStyles();
    return (
        <div className={classes.cardContainer}>
            <div className='cardHeader'>
                <p className='cardTitle'>{props.complexName}</p>
                <button className='closeButton'>X</button>
            </div>
            <div className='fieldsContainer'>
                {props.fields && props.fields.map(fil => {
                    return (
                        <div>
                        
                        <div className='field'> <FormControlLabel  control={
                            <Checkbox value={fil.isChecked} defaultChecked={fil.isChecked} name='selectedFields' id='selectedFields' onChange={(e) => modifyFormFieldHandler({fields: fil.id, isChecked: e.target.checked})} />} color="primary" label={fil.name} />
                        </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        eventForm: state.eventForm,
    }
}

export default connect(mapStateToProps, {
    modifyFormHandler,
    modifyFormFieldHandler
})(CheckBoxCards);

