import React from 'react';

import { makeStyles, Checkbox, FormControlLabel, RadioGroup, Radio, FormControl, FormLabel } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    headerContainer: {
        display: 'flex',
        marginTop: '50px',
        flexDirection: 'row',
        position: 'relative',
        justifyContent: 'space-between',
        '& .title': {
            color: '#FFFFFF',
            fontSize: '23px',
            width: '264.2px',
            fontWeight: '700',
            textAlign: 'left',
            lineHeight: '44px',
            position: 'relative',
            textTransform: 'uppercase',
            font: 'Open Sans Condensed',
            '&::after': {
                left: '0',
                top: '45px',
                content: '""',
                width: '118%',
                position: 'absolute',
                borderBottom: '1px solid white',
            },
        },
        '& .eventsButton': {
            border: 'none',
            width: '224px',
            color: 'white',
            outline: 'none',
            fontSize: '12px',
            cursor: 'pointer',
            height: '38.52px',
            fontWeight: '700',
            lineHeight: '40px',
            textAlign: 'center',
            transition: 'all .2s',
            borderRadius: '1.81px',
            textTransform: 'uppercase',
            backgroundColor: '#074682',
            transform: 'translateY(11%)',
        },
        '& .eventsButton:hover': {
            //transform: 'scale(1.1)',
        },
        '& .titleIcon': {
            top: '6px',
            left: '4px',
            width: '30px',
            height: '30px',
            position: 'absolute',
        },
    },
    fieldsContainer: {
        width: '100%',
        padding: '32px',
        marginTop: '50px',
        positon: 'relative',
        borderRadius: '7.6px',
        boxSizing: 'border-box',
        border: '0.5px solid #4a4848',
        backgroundColor: 'rgba(21, 23, 25, 0.78)',
        '& .subtitle': {
            opacity: '1',
            fontSize: '19px',
            color: '#FFFFFF',
            position: 'relative',
            textTransform: 'uppercase',
            '&::after':{
                left:'0',
                top:'27px',
                content: '""',
                width: '100%',
                position:'absolute',
                borderBottom:'1px solid #FFFFFF',
            }
        },
    },
    inputContainer:  {
        height:'35px',
        display: 'flex',
        marginTop: '25px',
        position:'relative',
        boxSizing: 'borderBox',
        justifyContent: 'space-between',
        '& .largeInput': {
            width: '308px',
        },
        '& .shortInput': {
            width: '16vw',
        },
        '& .hugeInput': {
            width: '430px',
            resize: 'none',
            height: '138px',
        },
        '& .squareInput': {
            width: '138px',
            height: '138px',
        },
        '& .input': {
            display: 'flex',
            flexDirection: 'column',  
        },
        '& .label': {
            zIndex: '4',
            fontSize:'10px',
            display: 'flex',
            marginLeft: '5px',
            flexDirection: 'column',
        },
        '& .inputField': {
            color: 'white',
            outline:'none',
            marginTop: '2px',
            padding: '8px 6px',
            position: 'relative',
            transition: 'all .1s',
            borderRadius: '4.6px',
            boxSizing: 'border-box',
            border: '1px solid white',
            backgroundColor:'transparent',
        },
        '& .inputField:focus': {
            transform: 'scale(1.01)',
        },
        '& .inputFileField' : {
            opacity: '0',
            visibility: 'hidden',
        },
        '& .icon': {
            top: '32px',
            zIndex: '1',
            left: '23px',
            width: '90px',
            height: '68px',
            position: 'absolute',
        },
        '& .inputColor': {
            width: '104px',
            border: 'none',
            marginTop: '15px',
        },
        '& .imagePreview': {
            top: '8.6%',
            zIndex: '4',
            left: '54%',
            width: '141px',
            height: '141px',
            borderRadius: '8px',
            position: 'absolute',
        },
        '& .MuiTypography-body1': {
            fontSize: '13px',
            fontFamily: 'Montserrat',
        },
        '& .checkBox': {
            marginLeft: '-8px',
        },
        '& .MuiFormGroup-root': {
            flexDirection: 'row',
        },
    },
    marginTop: {
        marginTop:'40px',
    },
    noticeSection: {
        padding: '30px',
        fontSize: '14px',
        marginTop: '40px',
        borderRadius: '9px',
        textAlign: 'center',
        boxSizing: 'border-box',
        backgroundColor: '#404040',
        border: '1px solid #4a4848',
    },
    selectInput: {
        color: 'white',
        outline:'none',
        marginTop: '2px',
        padding: '8px 6px',
        position: 'relative',
        borderRadius: '4.6px',
        transition: 'all .1s',
        boxSizing: 'border-box',
        border: '1px solid white',
        backgroundColor:'transparent',
    }
}));

const CompetitionSetupComponent = (props) => {

    const classes = useStyles();
    const {values, asignValues, types, handleSaveCompetition} = props;

    return (
        <>
            <div className={classes.headerContainer}>
                <h1 className='title'>Basic Info</h1>
                <span onClick={ () => handleSaveCompetition(values)} className='eventsButton'>Save Competition</span>
            </div>
            <div className={classes.fieldsContainer}>
                <h2 className='subtitle'>Create Competition</h2>   
                <div className={classes.inputContainer}>
                    <div className='input'>
                        <label className='label' htmlFor="name" >Create Competition</label>
                        <input required name='name' onChange={asignValues} className='inputField shortInput' value={values.name}  type='text' id='name' />
                        {/* <select onChange={(e)=>setValues()}
                                className='inputField shortInput'>
                            {categories && categories.map(cat =>{
                                return <option  style={{backgroundColor: '#404040'}} key={cat._id} value={cat._id}>{cat.name}</option>
                            })}
                        </select> */}
                    </div>
                    <div className='input'>
                        <label className='label' htmlFor="Type">Tournament Type</label>
                        <select onChange={asignValues} name='competition_type_id' className='inputField shortInput' >
                        {types && types.map(type =>{
                                return <option style={{backgroundColor: '#404040'}} key={type._id} value={type._id}>{type.name}</option>
                            })}
                        </select>
                    </div> 
                    <div className='input'>
                        <label className='label' htmlFor="quantity_teams">Number of Teams</label>
                        {/* <select className='inputField shortInput'>
                            <option style={{backgroundColor: '#404040'}} value="4">4</option>
                            <option style={{backgroundColor: '#404040'}} value="8">8</option>
                        </select> */}
                        <input required name='quantity_teams' id='quantity_teams' className='inputField shortInput' type="number" onChange={asignValues}/>
                    </div>
                    <div className='input'>
                        <label className='label' htmlFor="end_time">Category level</label>
                        <select className='inputField shortInput'> 
                        <option style={{backgroundColor: '#404040'}} value='Bronze'>Bronze</option>
                        <option style={{backgroundColor: '#404040'}} value='Silver'>Silver</option>
                        <option style={{backgroundColor: '#404040'}} value='Gold'>Gold</option>
                        </select>
                    </div>
                </div>
                <div className={classes.inputContainer}>
                    <div className='input'>
                        <FormControlLabel className='checkBox' control={
                        <Checkbox checked />} color='primary' label='Single Match' />
                    </div>

                </div>
            </div>
            <div className={classes.fieldsContainer}>
                <h2 className='subtitle'>Format</h2> 
                <div className={classes.inputContainer}>
                    <div className='input'>
                        <label className='label' htmlFor="end_time">Deporte</label>
                        <select  className='inputField shortInput'>
                            <option style={{backgroundColor: '#404040'}}  value='Futbol'>Futbol</option>
                        </select>
                    </div>
                    <div className='input'>
                        <label className='label' htmlFor="end_time">Genero</label>
                        <select  className='inputField shortInput'>
                            <option style={{backgroundColor: '#404040'}}  value="Masculino">Masculino</option>
                            <option style={{backgroundColor: '#404040'}}  value="Femenino">Femenino</option>
                        </select>
                    </div>
                </div>
                <h2 className={classes.marginTop + ' subtitle'}>Scoring set - up</h2> 
                <div className={classes.inputContainer}>
                    <div className='input'>
                        <label className='label' htmlFor="name" >Points per Victory</label>
                        <input type='number' className='inputField shortInput' />
                    </div>
                    <div className='input'>
                        <label className='label' htmlFor="name" >Points per draw</label>
                        <input type='number' className='inputField shortInput' />
                    </div>
            
                    <FormControl className="child-color" component="fieldset">
                        <FormLabel component="legend">Bonus Points</FormLabel>
                        <RadioGroup aria-label="gender" name="skilful_foot">
                        <FormControlLabel value="0" control={<Radio color="primary" />} label="Yes" />
                        <FormControlLabel value="1" control={<Radio color="primary" />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <div className='input'>
                        <label className='label' htmlFor="name" >Bonus Points</label>
                        <input type='number' className='inputField shortInput' />
                    </div>
                </div>
            </div>

            <div className={classes.noticeSection}>
                <p>El fixture se generará automáticamente al crear la categoría. Se podrá ver en la sección de Fixture, dentro de la categoría correspondiente.</p>
            </div>
        </>
    );
};
export default React.memo(CompetitionSetupComponent);