import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>  ({
    Wrapper: {
        position: 'fixed',
        top: '0',
        left: '0',
        backgroundColor: 'rgba(0,0,0,.5)',
        zIndex: '100',
        width: '100%',
        height: '100vh',
        opacity:'1' ,
        visibility: 'visible',
        transition: 'all .1s'
    },

    closed: {
        display: 'none',
        visibility: 'hidden',
        opacity: '0'
    }


}));

const Backdrop = ({opened, close}) => {
    const classes = useStyles();
    return (
        <div className={ opened ? classes.Wrapper : classes.closed } onClick={()=>close()} opened={opened}/>
    )
}

export default Backdrop;
