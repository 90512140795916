import React from "react";
import { connect } from "react-redux";
import { update_user } from "../actions/index";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  section: {
    width: "100%",
    height: "100%",
    backgroundColor: "#131319",
    display: "flex",
    flexDirection: "column",
    padding: "25px 45px 30px 50px",
    position: "relative",
    boxSizing: "border-box",
    overflow: "auto",
    "& .tableContainer": {
      width: "100%",
      borderRadius: "7px",
      boxSizing: "border-box",
      backgroundColor: "#1c1e24",
    },
    "& .langs": {
      top: "5px",
      right: "15px",
      display: "flex",
      position: "absolute",
      "& span": {
        padding: "0 5px",
        cursor: "pointer",
        fontSize: "14px",
      },
      "& .selected": {
        color: "#6cffe6",
      },
    },
  },
}));

const layout = (props) => {
  const classes = useStyles();
  const { user, set_update_user } = props;
  const langs = ["en", "es", "it"];

  const changeLang = (lan) => {
    //console.log(lan);
    // falta setearlo al usuario en la db
    set_update_user({ ...user, lang: lan });
  };

  const buildLangSelected = () => {
    return langs.map((la, index) => {
      return (
        <p key={`lang-${index}`}>
          <span
            className={
              user.lang
                ? user.lang === la
                  ? "selected"
                  : ""
                : la === "en"
                ? "selected"
                : ""
            }
            onClick={() => changeLang(la)}
          >
            {la}
          </span>
          {index === langs.length - 1 ? "" : "|"}
        </p>
      );
    });
  };
  //console.log(user)

  return user.wclubs && user.wclubs.length > 0 ? (
    <section className={classes.section}>
      <div className="langs">{buildLangSelected()}</div>
      {props.children}
    </section>
  ) : user.roleId === "1" ? (
    <section className={classes.section}>{props.children}</section>
  ) : (
    <h1 style={{ padding: "50px" }}>
      Este usuario no pertenece a ningun club.
    </h1>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    set_update_user: (user) => dispatch(update_user(user)),
  };
}

export default connect(null, mapDispatchToProps)(layout);
