export const ADD_EVENT = "ADD_EVENT";
export const UPDATE_USER = "UPDATE_USER";
export const MODIFY_FORM = "MODIFY_FORM";
export const ADD_DIRECTOR = "ADD_DIRECTOR";
export const DELETE_EVENT = "DELETE_EVENT";
export const UPDATE_PLAYER = "UPDATE_PLAYER";
export const CREATE_PLAYER = "CREATE_PLAYER";
export const RESET_PLAYER = "RESET_PLAYER";
export const ADD_EVENT_LOGO = "ADD_EVENT_LOGO";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const MODIFY_DIRECTOR = "MODIFY_DIRECTOR";
export const UPDATE_INFO_TEAMS = "UPDATE_INFO_TEAMS";
export const MODIFY_FIELD_CHECKED = "MODIFY_FIELD_CHECKED";
export const UPDATE_ANALYSIS_VIDEO = "UPDATE_ANALYSIS_VIDEO";
export const MODIFY_REFEREE_CHECKED = "MODIFY_REFEREE_CHECKED";
export const GET_ALL_EVENTS = "GET_ALL_EVENTS";
export const GET_ALL_DIRECTORS = "GET_ALL_DIRECTORS";
export const GET_ALL_COMPETITIONS = "GET_ALL_COMPETITIONS";
export const GET_ALL_COUNTRIES_NAMES = "GET_ALL_COUNTRIES_NAMES";
export const ADD_COMPETITION = "ADD_COMPETITION";
export const LOGIN_MODAL = "LOGIN_MODAL";
export const LOGOUT_USER = "LOGOUT_USER";

export const SAVE_FILTER = "SAVE_FILTER";
export const DELETE_FILTER = "DELETE_FILTER";
