import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({

    matchesTableContainer: {
        borderRadius: '7px',
        background: '#1C1E24',
        border: '1px solid',
        margin: '30px 0',
        fontSize: '14px',
    },
    matchesTable: {
        width: '100%',
        borderCollapse: 'collapse',
        border: 'none',
        fontFamily: 'Montserrat-Semibold,sans-serif',
        '& thead': {
            /*color: '#6bffe6',*/
            textTransform: 'uppercase',
        },
        '& th': {
            padding: '15px 0',
        }
    },
    titleBody: {
        marginTop: '20px',
        fontWeight: '700',
        lineHeight: '36px',
        fontSize: '29.64px',
        textTransform: 'uppercase',
        fontFamily: 'Open Sans Condensed',
        borderBottom: '1.02px solid #FFFFFF'
    },
    StaffCell: {
        display: 'table-cell',
        padding: '15px',
        '& button': {
            margin: '15px 0 0',
            color: '#ffffff',
            border: 'none',
            cursor: 'pointer',
            padding: '15px 0',
            background: '#074682',
            borderRadius: '2px',
            textTransform: 'uppercase',
        }
    },
    StaffCellGroup: {
        display: 'grid',
    }

}));

const StaffTable = (props) => {

    const classes = useStyles();
    const {staffTable, handleBuildChart, player, handleBuildChartHistorical, ratingType} = props;

    const buildTable = () => {

        let endStaff = false;
        let rowsStaff = []
        let cellStaff = []
        let breakSave = 0;

        console.log(staffTable)

        do { /* construimos las filas y celdas del <tbody> */
            endStaff = true;
            cellStaff = [];
            Object.keys(staffTable).map( staffKey => {
                if(staffTable[staffKey].users && staffTable[staffKey].users[breakSave]){
                    cellStaff.push(staffTable[staffKey].users[breakSave]);
                    endStaff = false;
                }else{
                    cellStaff.push(null);
                }
                return null;
            });
            if(endStaff === true){
                breakSave = 100;
            }else{
                rowsStaff.push(cellStaff)
            }
            breakSave++;
        }while (breakSave < 100);
        //console.log( rowsStaff );
        return  <div className={classes.matchesTableContainer}><table className={classes.matchesTable}>
                    <thead>
                        <tr>
                            { Object.keys(staffTable).map( staffKey => {
                                //console.log(staffTable[staffKey])
                                return <th key={staffKey} >{staffTable[staffKey].name}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {console.log(rowsStaff)}
                        { rowsStaff.map( (row, index) => {
                        return  <tr key={index} style={{backgroundColor: index % 2 === 0 ? 'rgba(21,23,25,.7)' : 'none' }}>
                                    { row.map( (user, indexUser) => {
                                        if(user){
                                            console.log(indexUser, user)
                                            return  <td className={classes.StaffCell} key={user._id}>
                                                        <div className={classes.StaffCellGroup}>
                                                            <span>{`${user.name} ${user.lastname}`}</span>
                                                            <span>{`Average: ${user.average}`}</span>
                                                            <span>{`Skills voted: ${user.ratePercentVoted}%`}</span>
                                                            <button onClick={ () => ratingType ? handleBuildChart( user._id, player._id) : handleBuildChartHistorical(user._id, player._id) }>VER GRÁFICA</button>
                                                        </div>
                                                    </td>
                                        }else{
                                            return <td key={indexUser}></td>
                                        }
                                    })}
                                </tr>
                        }) }
                    </tbody>
                </table></div>
    }

    return (<>
        <span className={classes.titleBody}>Staff</span>
        { staffTable && buildTable() }
    </>);
};
export default React.memo(StaffTable);