import React from "react";

import Rating from "@material-ui/lab/Rating";
import useStyles from "../../assets/MakeStyles";
import StarBorderIcon from "@material-ui/icons/StarBorder";

import { translate } from "../../lang/lang";

const UserVoter = (props) => {
  const classes = useStyles();
  const { skillsVoted, handleSetSkillsVoted, user } = props;
  const handleNewVoteChange = (value, skillId) => {
    skillId = skillId.replace("parent-id-", "");
    skillId = skillId.replace("child-id-", "");
    skillId = skillId.split("-");

    let skillParentTemp = skillsVoted.filter((parent) => {
      return parent.id === parseInt(skillId[0]);
    });

    let skillChildTemp = skillParentTemp[0].skills.map((child) => {
      if (child.id === parseInt(skillId[1])) {
        child = { ...child, rate: value };
      }
      return child;
    });

    let skillesParent = skillsVoted.map((parent) => {
      if (parent.id === parseInt(skillId[0])) {
        parent = { ...parent, skills: skillChildTemp };
      }
      return parent;
    });
    console.log(skillesParent);

    handleSetSkillsVoted(skillesParent);
  };

  const showSkills = () => {
    let colsSkills = skillsVoted.map((skillParent, index) => {
      const getSkillsChild = () => {
        let skillChildBox = [];
        let skillChildItem = [];
        let childIndex = 0;
        let countLoop = 0;
        let breakSave = 0;

        do {
          skillChildItem.push(
            <div key={childIndex} className={classes.skillChildContainer}>
              <div className={classes.skillChildName}>
                {translate(user.lang, skillParent.skills[childIndex].name)}
              </div>
              <div>
                <Rating
                  className={classes.ratingStars}
                  name={
                    "parent-id-" +
                    skillParent.id +
                    "-child-id-" +
                    skillParent.skills[childIndex].id
                  }
                  precision={1}
                  defaultValue={skillParent.skills[childIndex].rate}
                  onChange={(event, newValue) => {
                    handleNewVoteChange(newValue, event.target.name);
                  }}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                />
              </div>
            </div>
          );
          if (countLoop === 3) {
            countLoop = -1;
            skillChildBox.push(
              <div
                className={classes.subSkillBox}
                key={skillParent.skills[childIndex].name + childIndex}
              >
                {skillChildItem}
              </div>
            );
            skillChildItem = [];
          }
          if (breakSave > 30) {
            break;
          }
          breakSave++;
          childIndex++;
          countLoop++;
        } while (skillParent.skills[childIndex]);

        if (skillChildItem.length > 0) {
          skillChildBox.push(
            <div
              className={classes.subSkillBox}
              key={skillParent.skills[childIndex - 1].name + childIndex - 1}
            >
              {skillChildItem}
            </div>
          );
        }
        return skillChildBox;
      };
      let skillCol =
        skillParent.cols === 1
          ? "self-lg-3 self-md-12"
          : skillParent.cols === 2
            ? "self-lg-6 self-md-12"
            : skillParent.cols === 3
              ? "self-lg-9 self-md-12"
              : "self-md-12";

      return (
        <div
          key={skillParent.name + "-" + index}
          className={"cols " + skillCol + " " + classes.rateContainer}
        >
          <div className={classes.rateTitle}>
            {translate(user.lang, skillParent.name)}
          </div>
          <div className={classes.rateContainerSubSkills}>
            {getSkillsChild()}
          </div>
        </div>
      );
    });
    return <div className={classes.ratesContainer}>{colsSkills}</div>;
  };

  return (
    <>
      <span className={classes.userVoterTitleBody}>
        {" "}
        {translate(user.lang, "my vote")}
      </span>

      {skillsVoted && showSkills()}
    </>
  );
};
export default React.memo(UserVoter);
