import React from "react";
import ReactFileReader from "react-file-reader";
import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CheckIcon from "@material-ui/icons/Check";

const Upload = (props) => {
  const handleFiles = (files) => {
    props.setFile(files[0]);
    props.setUploadFile({ ...props.uploadFile, upfile: files[0] });
  };

  let content = props.isLoading ? (
    <Typography variant="subtitle2" gutterBottom color="primary">
      . . .
    </Typography>
  ) : (
    <Typography variant="subtitle2" gutterBottom color="primary">
      <IconButton>
        <ReactFileReader handleFiles={handleFiles}>
          {props.file === null ? (
            <AttachFileIcon style={{ color: "#7F7D80" }} />
          ) : (
            <CheckIcon style={{ color: "#7F7D80" }} />
          )}
        </ReactFileReader>
      </IconButton>
      {props.file === null ? props.text : props.file.name}
    </Typography>
  );
  return content;
};

export default Upload;
