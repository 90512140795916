import React from 'react'

import Modal from '@material-ui/core/Modal';
import {resourceApiEndPoint} from '../../endpoints';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar';
import { ReactComponent as Upvote } from '../../assets/img/upVote.svg'
import { ReactComponent as Downvote } from '../../assets/img/downVote.svg'

import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';

const useStyles = makeStyles(theme => ({


    btnsModal: {
        display: 'flex',
        margin: '0 0 15px',
        justifyContent: 'flex-end'
    },
    paper: {
        top: '50%',
        left: '50%',
        padding: '15px',
        minWidth: '90vw',
        border: '2px solid',
        position: 'absolute',
        backgroundColor: '#19191D',
        transform: 'translate(-50%, -50%)',
    },

    InsideWrapper:{
        position: 'relative',
        padding: '15px 30px 15px 5px',
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '4px',
        flexDirection: 'column',
        overflowY: 'overlay',
        maxHeight: '80vh',
    },
    
    borderContainer:{
        border: '0.5px solid #4a4848',
        backgroundColor: 'rgba(21, 23, 25, 0.78)',
        boxSizing: 'border-box',
        borderRadius: '7.6px',
        padding: '32px',
        position: 'relative',
        margin: '10px 0',
        minWidth: '40vw',
        '& .subtitle': {
            fontSize: '18px',
            position: 'relative',
            opacity: '1',
            margin: '0 0 15px',
            '&::after':{
                content: '""',
                position:'absolute',
                width: '100%',
                top:'21px',
                left:'0',
                borderBottom:'1px solid #FFFFFF'
            }
        },
    },
    

    flexDirectionColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },

    flexDirectionRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .MuiAvatar-root': {
            width: '60px',
            height: '60px',
        }
    },

    footballIcon: {
        position: 'absolute',
        width: '20px',
        height: '20px',
        left: '10px'
    },

    textContainer: {
        margin: '5px 0',
        display: 'flex',
        padding: '10px',
        position: 'relative',
        justifyContent: 'space-between',
        borderRadius: '10px',
        '&.list-event:hover': {
            backgroundColor: '#808080',
            cursor: 'pointer',
        },
        '&.active': {
            backgroundColor: '#1CB9D3',
            color: 'black'
        }
    },

    textWithIcon: {
        marginLeft: '30px'
    },

    videoContainer: {
        width: '100%',
        height: '160px',
        backgroundColor: 'pink',
        marginTop: '20px'
    },
    alignSelf:{
        alignSelf: 'center'
    }
   
}));

export const VideoAnalysisModal = (props) => {

    const { eventsToShow, analysis, handlePlayEvent, playerModalRef } = props

    const classes = useStyles();

    let getEvents = (events) => {

        return  events.map( (action, index) => {
            let classActive =  eventsToShow.eventActive === index ? ' active' : ''
            if( classActive === ' active'){
                //console.log(action)
            }
            return  <div key={index} className={classes.textContainer + ' list-event' + classActive } onClick={ () => handlePlayEvent(index, action.event_time) } >
                        <p className={classes.textWithIcon}><SlowMotionVideoIcon className={classes.footballIcon}/>{action.event_name}</p>
                        <p>( {action.event_action.length} ) { action.event_action.length > 1 ? 'actions' : 'action'}</p>
                        <p>event minute: { action && action.minute_event ?  action.minute_event : 0 }</p>
                    </div>
        })

    }

    let getEventsAction = (indexSelect) => {

        if(eventsToShow && eventsToShow.events[indexSelect] ){
            return  eventsToShow.events[indexSelect].event_action.map( (action, index) => {
                
                return  <div key={index} className={classes.borderContainer}>   
                                <div className={classes.flexDirectionRow}>

                                    { action.tag && <p>{ (action.tag.name ? action.tag.name : action.tag.tagName) }</p>}
                                    { action.wplayer && <>                             
                                        <Avatar 
                                            src={ resourceApiEndPoint() + (action.wplayer.img_file.charAt(0) === '/' ? action.wplayer.img_file : '/' + action.wplayer.img_file) }
                                            alt="Player"
                                        />
                                        <p className={classes.textWithIcon}>{action.wplayer.name} {action.wplayer.lastname}</p>
                                        { action.wplayer.wclub_img && 
                                            <img alt="Logo club" style={{height: '60px'}} src={resourceApiEndPoint() + (action.wplayer.wclub_img.charAt(0) === '/' ? action.wplayer.wclub_img : '/' + action.wplayer.wclub_img) }></img>
                                        }
                                        </>
                                    }
                                    { action.comment && <p>{action.comment}</p> }
                                    { action.like_nolike && 
                                        <> {action.like_nolike === 'like' ? <Upvote /> : <Downvote />} </>
                                    }

                                </div>
                        </div>

            })
        }
    }
    
    return  <>

        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.open}
        >

            <div className={classes.paper}>

                <div className={classes.btnsModal}><span style={{cursor: 'pointer'}} onClick={ () => props.setActiveModalEventsToShow(false)}>Cerrar</span></div>

                <div className={classes.InsideWrapper}>

                    <div className={classes.flexDirectionColumn} >

                        <div className={classes.flexDirectionRow} >

                            <div className={classes.borderContainer} style={{maxWidth: '50%'}}>
                            { eventsToShow && eventsToShow.events[eventsToShow.eventActive] && 

                                <ReactPlayer
                                    ref={playerModalRef}
                                    className={classes.mediaPlayer}
                                    width={'100%'}
                                    url={analysis.video_filename}
                                    controls={true} 
                                    playing={eventsToShow.playing}
                                /> }

                            </div>

                            <div className={classes.borderContainer}>
                                <h2 className='subtitle'>Eventos con acciones de {eventsToShow && eventsToShow.action.name}</h2>
                                { eventsToShow && getEvents(eventsToShow.events) }
                            </div>
                        </div>

                        { eventsToShow && getEventsAction(eventsToShow.eventActive) }
                
                    </div>

                </div>

            </div>
        </Modal>
    </>
}
export default React.memo(VideoAnalysisModal);