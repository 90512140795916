import React, {useEffect} from 'react';

import Modal from '@material-ui/core/Modal';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';


//import { Radar, RadarChart, PolarGrid, Legend, PolarRadiusAxis } from 'recharts';

import {Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }


const useStyles = makeStyles( theme => ({
    paper: {
        top: '50%',
        left: '50%',
        padding: '15px',
        minWidth: '80vw',
        position: 'absolute',
        backgroundColor: '#19191D',
        transform: 'translate(-50%, -50%)',
        '&:focus': {
            outline: 'none'
        }
    },
    root: {
        backgroundColor: '#17181B',
        width: '100%',
        '& .MuiPaper-root': {
            background: '#17181b',
        },
        '& .MuiButtonBase-root': {
            color: '#ffffff',
        },
        '& .MuiTab-textColorPrimary': {
            color: '#959597',
        },
        '& .Mui-selected': {
            color: '#ffffff',
        },
        '& .MuiTabs-indicator': {
            background: '#08BBEE',
        }
    },
    chartContainer: {
        display: 'flex',
        background: '#17181B',
        justifyContent: 'center'
    },
    chartBar: {
        //background: 'white',
        '& .recharts-default-tooltip': {
            backgroundColor: 'transparent !important',
        }
    },
}));

const GraphyRatesHistorical = (props) => {

    const theme = useTheme();
    const classes = useStyles();
    const {open, handleClose, stats} = props;

    const handleOnClose = () => {
        handleClose(false);
    }

    const [value, setValue] = React.useState(0);
    const [valueSecundary, setValueSecundary] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleChangeSecundary = (event, newValue) => {
        setValueSecundary(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    const handleChangeIndexSecundary = (index) => {
      setValueSecundary(index);
    };

    //console.log(stats)
    const buildChart = (progress) => {

        return  <div name={progress.name} style={{background: '#17181B', display: 'flex', justifyContent: 'center'}}>
                    <LineChart
                        className={classes.chartBar}
                        width={900}
                        height={450}
                        data={progress.data}
                        margin={{
                            top: 5, right: 50, left: 10, bottom: 15,
                        }}
                    >
                        <CartesianGrid fill="#18191d" strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis unit={" AVG"} />
                        <Tooltip wrapperStyle={{ backgroundColor: '#000000bd' }} />

                        {
                            progress.lines.map((entry, index) => ( 
                                <Line 
                                    key={index}
                                    type="monotone"
                                    fill={progress.colors[index]}
                                    stroke={progress.colors[index]}
                                    dataKey={progress.lines[index]}
                                />
                            ))
                        }

                    </LineChart>
                </div>
    }

    const buildAppBarOfSecundarySkills = () => {

        return  <>
        <AppBar position="static" color="default">
            <Tabs
                value={valueSecundary}
                onChange={handleChangeSecundary}
                variant="fullWidth"
                aria-label="full width tabs example"
            >

                {   stats[2].skills.map( (skill, key) => {
                        return <Tab key={skill.skillParent + '-' + key} label={skill.skillParent}  />
                    })
                }

            </Tabs>
        </AppBar>

        <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={valueSecundary}
            onChangeIndex={handleChangeIndexSecundary}
        >

            {   stats[2].skills.map( (progress, key) => {

                return  <TabPanel key={progress.skillParent + '-' + key} value={valueSecundary} index={(key)} dir={theme.direction}>
                            { buildChart(progress.skills) }
                        </TabPanel>
                    
                })
            }
        </SwipeableViews>
        </>
    }

    useEffect( () => {

        if(open){
            //console.log(stats)
        }

    }, [open]);

    return (

        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            onClose={handleOnClose}
            open={open}
        >
            <div className={classes.paper}>

                { open && stats && 
            
                <div className={classes.root}>

                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >

                            {   stats.map( (skill, key) => {
                                    return <Tab key={skill.name + '-' + key} label={skill.name}  />
                                })
                            }

                        </Tabs>
                    </AppBar>

                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        {   stats.map( (progress, key) => {
                            if(key !== 2){
                                return  <TabPanel key={progress.name + '-' + key} value={value} index={(key)} dir={theme.direction}>
                                            { buildChart(progress) }
                                        </TabPanel>
                            }else{
                                return  <TabPanel key={key} value={value} index={(key)} dir={theme.direction}>

                                            {buildAppBarOfSecundarySkills()}

                                        </TabPanel>
                            }
                            })
                        }

                    </SwipeableViews>
                </div>
                }
            </div>
        </Modal>
    );
};
export default React.memo(GraphyRatesHistorical);