import en from './en.json';
import es from './es.json';
import it from './it.json';

export function translate(lang = 'en', text) {

    switch(lang) {
        case 'es':
            return es[text] ? es[text] : text;
        case 'it':
            return it[text] ? it[text] : text;
        default:
            return en[text] ? en[text] : text;
    }

}