import React from "react";
import environment from "environment";
//import { makeStyles } from "@material-ui/core";
//import LogoSvg from "./LogoSvg";
//import LogoSvg from '../../assets/img/Logo.svg';
import logoLogin from "../../assets/img/gloud-login.png";
import logoSideInfinity from "../../assets/img/infinity-logo.png";

/*
const useStyles = makeStyles(theme => ({
  Logo: {
    fontFamily: "Poppins-Bold",
    "& > span": {
      color: "#6bffe6",
    },
  },
}));
*/

const logo = props => {
  const LOGO_RESOURCE = environment.ENV;
  //const classes = useStyles();
  return (
    <div>
      {/* <h2
        className={classes.Logo}
        style={{
          fontSize: props.fontSize ? props.fontSize : "55px",
          margin: props.margin ? props.margin : "0",
        }}
      >
        Go<span>oL</span>
      </h2> */}
      {/* <LogoSvg /> 
      <svg style={{maxWidth: props.maxWidth}} className={`${classes.Logo} logo`}>
        <use href={LogoSvg+"#Logo"}></use>
      </svg>
      */}

      <div
        style={{ width: "100%", textAlign: "center", margin: " 15px 0 30px" }}
      >
        {LOGO_RESOURCE === "infinity" ? (
          <img
            style={{ maxWidth: "300px" }}
            src={logoSideInfinity}
            alt="Logo"
          />
        ) : (
          <img src={logoLogin} alt="Logo" />
        )}
      </div>
    </div>
  );
};

export default logo;
