import React, { useEffect, useState } from 'react';

import useStyles from '../assets/MakeStyles';
import GlobalLoading from "../components/accessories/GlobalLoading";

import {
    searchGPexeSessionsByTeams,
} from "../endpoints";

import { translate } from '../lang/lang';

const GpexeSessionsList = (props) => {

    const classes = useStyles();
    const {teamOfClub, user} = props;
    const queryString = require("query-string");
    const [globalLoading, setGlobalLoading] = useState(true);
    const [sessions, setSessions] = useState({sessions: {}, teamSelected: null});
    const [params, setParams] = useState({team_id: queryString.parse(props.location.search).team_id, limit: 10000, offset: 0});

    const handleChange = (data) => {

        if (data.id === "team") {
            setGlobalLoading(true);
            setPush({...params, team_id: data.value});
            setParams({...params, team_id: data.value});
        }
    };

    const setPush = ({team_id, limit, offset}) => {
        props.history.push({ search: `?limit=${limit}&offset=${offset}&team_id=${team_id}` });
    };

    const showSessionTable = () => {

        console.log(sessions.sessions[sessions.teamSelected])
        
        return  <table className='table gpexe-table'>
                    <thead>
                        <tr>
                            <th>category</th>
                            <th>type</th>
                            <th>drills</th>
                            <th>track</th>
                            <th>date</th>
                            <th>start time</th>
                            <th>total time</th>
                            <th>tags</th>
                        </tr>
                    </thead>
                    <tbody>
                        { sessions.sessions[sessions.teamSelected] && Object.keys(sessions.sessions[sessions.teamSelected]).map( key => {
                        return  <tr key={key} className={`row-items ${classes.rowSessionItem}`} onClick={ () => props.history.push(`/gpexe/sessions/${sessions.sessions[sessions.teamSelected][key].id}`) }>
                                    <td className="cell-row">{sessions.sessions[sessions.teamSelected][key].category.name}</td>
                                    <td className="cell-row">{sessions.sessions[sessions.teamSelected][key].type}</td>
                                    <td className="cell-row">{sessions.sessions[sessions.teamSelected][key].drills > 0 ? sessions.sessions[sessions.teamSelected][key].drills : '-'}</td>
                                    <td className="cell-row">{sessions.sessions[sessions.teamSelected][key].tracks}</td>
                                    {/*<td className="cell-row">{new Date(sessions.sessions[sessions.teamSelected][key].timestamp + 10800000).toLocaleDateString("it-IT")}</td>*/}
                                    <td className="cell-row">{ sessions.sessions[sessions.teamSelected][key].timestamp_formatted.split(" ")[0] }</td>
                                    <td className="cell-row">{sessions.sessions[sessions.teamSelected][key].timestamp_formatted.split(" ")[1]}</td>
                                    <td className="cell-row">{sessions.sessions[sessions.teamSelected][key].total_time_formatted}</td>
                                    <td className="cell-row">{sessions.sessions[sessions.teamSelected][key].tags[0]}</td>
                                </tr>
                        })
                        }
                        </tbody>
                </table>
    }

    useEffect( () => {

        if(params.team_id){
            if( !sessions.sessions[params.team_id] ){
                searchGPexeSessionsByTeams({ team_id: params.team_id, limit: 10000, offset: 0 })
                .then( (resp) => {
                    if(resp.data.code === 200){
                        setSessions({
                            teamSelected: params.team_id,
                            sessions: {...sessions.sessions, [params.team_id] : resp.data.sessions }
                        });
                    }else{
                        setSessions({
                            teamSelected: params.team_id,
                            sessions: {...sessions.sessions, [params.team_id] : [] }
                        });
                    }
                    setGlobalLoading(false);
                })
                .catch( (err) => {
                  console.log(err);
                  setGlobalLoading(false);
                });
            }else{
                setSessions({...sessions, teamSelected: params.team_id});
                setGlobalLoading(false);
            }
            
        }else{
            if( teamOfClub.length > 0 ){
                setParams({...params, team_id: teamOfClub[0]._id});
                setPush({...params, team_id: teamOfClub[0]._id});
            }
        }

        if(!props.location.search && sessions.teamSelected){
            setPush({...params, team_id: sessions.teamSelected});
        }

    }, [props.location.search, teamOfClub]);

    return (<>
    <div className={classes.headerContainer}>
        
        <div className="titleContainer">
            <span>{translate(user.lang, 'gpexe session listing')}</span>
        </div>

        <div className="paragraphContainer">
            <p>{translate(user.lang, 'gpexe-session-title')}</p>
        </div>

        <div className={classes.inputContainer}>
            <div className="largeInput">
                <select
                    value={ params.team_id ? params.team_id : 0 }
                    required
                    className="inputField"
                    id="team"
                    onChange={ (e) => handleChange(e.target) }
                >
                <option value="0" disabled>
                    {translate(user.lang, 'teams')}
                </option>
                {teamOfClub.map((data, index) => {
                    return (
                    <option key={index} value={data._id}>
                        {data.name}
                    </option>
                    );
                })}
                </select>
            </div>
        </div>

        {globalLoading ? (
            <GlobalLoading />
        ) : (
            <div className="tableContainer">
                { sessions.sessions[sessions.teamSelected].length > 0 ?
                    <div>{showSessionTable()}</div>
                :
                    <div>{translate(user.lang, 'no sessions')}</div>
                }
            </div>
        )}

    </div>
    </>);
};
export default GpexeSessionsList;