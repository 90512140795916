import React, {useEffect, useState} from 'react';

import { connect } from "react-redux";
import { getAllEvents, deleteEvents} from '../endpoints';
import EventsComponents from '../components/EventsComponents';
import { getAllEventHandler, deleteEventHandler } from '../actions';
import GlobalLoading from '../components/accessories/GlobalLoading';

function mapDispatchToProps(dispatch) {
    return {
        set_deleteEventHandler: id => dispatch(deleteEventHandler(id)),
        set_getAllEventHandler: event => dispatch(getAllEventHandler(event)),
    };
}

const mapStateToProps = state => {
    return {
        user: state.user,
        eventList: state.eventList
    }
}

const Events = (props) => {
    
    const [globalLoading, setGlobalLoading] = useState(false);
    const { user, eventList, set_getAllEventHandler, set_deleteEventHandler } = props;

    const loadEvents = async () => {
        setGlobalLoading(true);
        let response = await getAllEvents();
        if(response.status === 200){
            set_getAllEventHandler(response.data.events);
//            console.log(response.data.events)
        }else{
            console.log('error');
        }
        setGlobalLoading(false);
    }

    const delEvents = async (id) => {
        setGlobalLoading(true)
        let response = await deleteEvents(id)
        if(response.code === 200){
            set_deleteEventHandler(id);
        }else{
            console.log('error');
        }
        setGlobalLoading(false)
    }

    useEffect(() => {
        loadEvents();
    }, [])

    return (<>
        <EventsComponents user={user} eventList={eventList} delEvents={delEvents} />
        { globalLoading &&  <GlobalLoading /> }
    </>)
}
const AppEvents = connect(mapStateToProps, mapDispatchToProps)(Events);
export default React.memo(AppEvents);
