import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({

    titleBody: {
        marginTop: '20px',
        fontWeight: '700',
        lineHeight: '36px',
        fontSize: '29.64px',
        textTransform: 'uppercase',
        fontFamily: 'Open Sans Condensed',
        borderBottom: '1.02px solid #FFFFFF'
    },
    teammateContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        margin: '15px 0',
    },
    ratesTeammates: {
        display: 'flex',
        width: 'calc(25% - 52.75px)',
        margin: '15px',
        flexDirection: 'column',
        padding: '15px',
        background: '#1B1C1F',
        borderRadius: '7px',
        '& button': {
            color: '#ffffff',
            border: 'none',
            cursor: 'pointer',
            margin: '15px 0 0',
            padding: '15px 0',
            background: '#074682',
            borderRadius: '2px',
            textTransform: 'uppercase',
        },
        '& .userName': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    ratesTeammatesLeft: {
        marginLeft: '0px',
    },
    ratesTeammatesRight: {
        marginRight: '0px'
    }

}));

const PeerVotes = (props) => {

    const classes = useStyles();

    const {statsByPlayers, player, handleBuildChart, handleBuildChartHistorical, ratingType} = props;

    console.log(statsByPlayers)
    //console.log(player)

    const buildTeammate = () => {
        return statsByPlayers.map( (user, indexUser) => {
            return  <div key={indexUser} className={`${classes.ratesTeammates} ${(indexUser + 1) % 4 === 1 ? classes.ratesTeammatesLeft : (indexUser + 1) % 4 === 0 ? classes.ratesTeammatesRight : " " }`}>
                        <span className="userName">{`${user.name} ${user.lastname && user.lastname}`}</span>
                        <span>{`Average: ${user.average}`}</span>
                        <span>{`Skills voted: ${user.ratePercent}%`}</span>
                        <button onClick={ () => ratingType ? handleBuildChart( user._id, player._id) : handleBuildChartHistorical(user._id, player._id)}>See chart</button>
                    </div>
        });
    }

    return (<>
        <span className={classes.titleBody}>Compañeros</span>
        { statsByPlayers && <div className={classes.teammateContainer}>{buildTeammate()}</div> }
    </>);
};

export default React.memo(PeerVotes);