import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  accept: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const SnackBarDelete = (props) => {
  const classes = useStyles();
  const handleClose = () => {
    props.setSnackbarOpen(false);
  };

  return (
    <Snackbar
      open={props.snackbarOpen}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      message={props.msg}
      action={
        <React.Fragment>
          <p
            className={classes.accept}
            onClick={() => props.removeFolder(props.id)}
          >
            {props.accept}
          </p>
          <IconButton
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default SnackBarDelete;
