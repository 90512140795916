import React from 'react';

import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    btnsModal: {
        display: 'flex',
        margin: '0 0 15px',
        justifyContent: 'space-between'
    },
    paper: {
        top: '50%',
        left: '50%',
        padding: '15px',
        minWidth: '80vw',
        border: '2px solid',
        position: 'absolute',
        backgroundColor: '#19191D',
        transform: 'translate(-50%, -50%)',
    },
    metricsOptionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        maxWidth: '90vw',
        '& .metricsOptions': {
            display: 'flex',
            width: '220px',
            height: '50px',
            margin: '15px 0',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#4b4b5a40',
            padding: '5px'
        },
        '& .measurementName': {
            textAlign: 'center',
            fontSize: '12px',
            padding: '0 5px',
        },
        '& .measurementUnit': {
            textAlign: 'center',
            fontSize: '12px',
            color: '#A4A4A4'
        }
    },
    title: {
        fontSize: '23.75',
        fontWeight: '700',
        textTransform: 'uppercase'
    }
}));

const MetricsOptions = (props) => {

    const classes = useStyles();
    const {
        open,
        metricOptions,
        handleCloseOpen,
        selectsCallBack
    } = props;

    const getMetricsOptions = () => {

        let filterDefaultOptions = []
        Object.keys(metricOptions).filter( (key, index) => {
            if(index > 4){
                filterDefaultOptions.push(metricOptions[key])
            }
            return null
        })
        
        /*
        let filterDefaultOptions = metricOptions.filter( (option, index) => {
            return index > 4;
        })
        */

        return  filterDefaultOptions.map( (options, index) => {
            return  <div key={'option-metrics-'+index} className="metricsOptions" onClick={ () => selectsCallBack(options, 1) }>
                        <div>
                            <p className="measurementName">{options.name}</p>
                            <p className="measurementUnit">({options.measurement_unit})</p>
                        </div>
                    </div>
        })
    }

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
        >
            <div className={classes.paper}>
                <div className={classes.btnsModal}>
                    <span className={classes.title}>metrics</span>
                    <span style={{cursor: 'pointer'}} onClick={ () => handleCloseOpen(false)}>Close</span>
                </div>
                <div className={classes.metricsOptionsContainer}>
                    {getMetricsOptions()}
                </div>
            </div>
        </Modal>
    );
};
export default React.memo(MetricsOptions);