import React from 'react'
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom';
import { makeStyles} from '@material-ui/core';
import { modifyFormHandler } from "../../../actions/index";
import { ReactComponent as TitleBall }  from '../../../assets/img/Shape.svg';
import { ReactComponent as EventsButtonIcon } from '../../../assets/img/eventIconInput.svg';

const useStyles = makeStyles(theme => ({
    
    stepscontainer: {
        width: '540px',
        display: 'flex',
        margin: '0 auto',
        position: 'relative',
        justifyContent: 'space-between',
        '&::after': {
            left: '5px',
            width: '96%',
            top: '6.5px',
            content: '""',
            position: 'absolute',
            border: 'solid 1.5px grey',
        },
        '& .yellowStep': {
            zIndex: '3',
            width: '16.07px',
            height: '16.07px',
            borderRadius: '100%',
            backgroundColor: '#FDCA40',
        },
        '& .greyStep': {
            zIndex: '3',
            width: '16.07px',
            height: '16.07px',
            borderRadius: '100%',
            backgroundColor: '#B2B2B2',
        },
    },
    headerContainer: {
        display: 'flex',
        marginTop: '50px',
        flexDirection: 'row',
        position: 'relative',
        justifyContent: 'space-between',
        '& .title': {
            color: '#FFFFFF',
            fontSize: '23px',
            width: '264.2px',
            fontWeight: '700',
            textAlign: 'left',
            lineHeight: '44px',
            paddingLeft: '30px',
            position: 'relative',
            textTransform: 'uppercase',
            font: 'Open Sans Condensed',
            '&::after': {
                left: '0',
                top: '45px',
                content: '""',
                width: '118%',
                position: 'absolute',
                borderBottom: '1px solid white',
            },
        },
        '& .eventsButton': {
            width: '224px',
            border: 'none',
            color: 'white',
            outline: 'none',
            fontSize: '12px',
            height: '38.52px',
            fontWeight: '700',
            lineHeight: '40px',
            textAlign: 'center',
            transition: 'all .2s',
            borderRadius: '1.81px',
            backgroundColor: '#074682',
            textTransform: 'uppercase',
            transform: 'translateY(11%)',
        },
        '& .eventsButton:hover': {
            transform: 'scale(1.01)',
        },
        '& .titleIcon': {
            left: '4px',
            top: '12px',
            width: '20px',
            height: '20px',
            position: 'absolute',
        },
    },
    nameContainer: {
        width: '630px',
        display: 'flex',
        margin: '10px auto',
        justifyContent: 'space-between',
        '& .stepName': {
            fontSize: '12px',
            fontWeight: '400',
            textAlign: 'center',
        }
    },
    fieldsContainer: {
        width: '100%',
        height: '262px',
        padding: '32px',
        marginTop: '50px',
        positon: 'relative',
        borderRadius: '7.6px',
        boxSizing: 'border-box',
        border: '0.5px solid #4a4848',
        backgroundColor: 'rgba(21, 23, 25, 0.78)',
        '& .subtitle': {
            opacity: '1',
            fontSize: '19px',
            color: '#FFFFFF',
            position: 'relative',
            textTransform: 'uppercase',
            '&::after':{
                left:'0',
                top:'21px',
                content: '""',
                width: '100%',
                position:'absolute',
                borderBottom:'1px solid #FFFFFF'
            }
        },
    },
    inputContainer:  {
        display: 'flex',
        marginTop: '20px',
        position:'relative',
        boxSizing: 'borderBox',
        justifyContent: 'space-between',
        '& .largeInput': {
            width: '308px',
        },
        '& .shortInput': {
            width: '100%',
        },
        '& .hugeInput': {
            width: '430px',
            resize: 'none',
            height: '138px',
        },
        '& .squareInput': {
            position: 'relative',
            width: '138px',
            height: '138px',
        },
        '& .input': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& .label': {
            zIndex: '4',
            fontSize:'10px',
            display: 'flex',
            marginLeft: '5px',
            flexDirection: 'column',
        },
        '& .inputField': {
            outline:'none',
            color: 'white',
            marginTop: '2px',
            padding: '8px 6px',
            position: 'relative',
            transition: 'all .1s',
            borderRadius: '4.6px',
            boxSizing: 'border-box',
            border: '1px solid white',
            backgroundColor:'transparent',
        },
        '& .inputField:focus': {
            transform: 'scale(1.01)',
        },
        '& .inputFileField' : {
            opacity: '0',
            visibility: 'hidden',
        },
        '& .icon': {
            top: '32px',
            zIndex: '1',
            left: '23px',
            width: '90px',
            height: '68px',
            position: 'absolute',
        },
        '& .inputColor': {
            width: '104px',
            border: 'none',
            marginTop: '15px',
        },
        '& .imagePreview': {
            top: '-1px',
            zIndex: '4',
            left: '-1px',
            width: '144px',
            height: '144px',
            borderRadius: '8px',
            position: 'absolute',
        }
    }
}));

export const EventInfo = ({modifyFormHandler, eventForm,  ...props}) => {

    const classes = useStyles();
    
    const handleFile = (event, reducerName) => {
        
        if (event.target.files && event.target.files[0] && event.target.files[0].size < 500000) {
        if (event.target.files[0].type === "image/jpeg"|| event.target.files[0].type === "image/png") {
            modifyFormHandler({ value: event.target.files[0], name:reducerName})
            let reader = new FileReader();

            reader.onload = e => {
                modifyFormHandler({ value: e.target.result, name: 'logoPreview' });
            }
            reader.readAsDataURL(event.target.files[0]);

            modifyFormHandler({ value: event.target.files[0].name.slice(0, 10) + "...", name: 'logoPreview' });
        } else {
            modifyFormHandler({ value: "jpeg/png unicamente", name: 'logoPreview'  });
        }
        } else {
        modifyFormHandler({
            value: "No puede superar 500kb", name: 'logoPreview'  
        });
        }
        // this.props.setImage(event.target.files[0]);
    }

    return (
        <>
            <div className={classes.stepscontainer}>
                <span className='yellowStep'></span>
                <span className='greyStep'></span>
                <span className='greyStep'></span>
                <span className='greyStep'></span>
                <span className='greyStep'></span>
            </div>
            <div className={classes.nameContainer}>
                <p className={'stepName'}>Datos Generales</p>
                <p className={'stepName'}>Competencias</p>
                <p className={'stepName'}>Organización</p>
                <p className={'stepName'}>Directores</p>
                <p className={'stepName'}>Medio de pago</p>
            </div>
            <div className={classes.headerContainer}>
                <TitleBall className='titleIcon'/>
                <h1 className='title'>Datos del evento</h1>
                <NavLink to={'/formEventCompetition'}className='eventsButton'>Continuar</NavLink>
            </div>
            <div className={classes.fieldsContainer}>
                <h2 className='subtitle'>Información del evento</h2>   
                <div className={classes.inputContainer}>
                    <div className='input'>
                        <label className='label' htmlFor="name" >Nombre del evento</label>
                        <input name="name" onChange={(e) => modifyFormHandler(e.target)} className='inputField largeInput' value={eventForm.name} defaultValue={eventForm.name} type="text" id="name"/>
                    </div>
                    <div className='input'>
                        <label className='label' htmlFor="address">Ciudad</label>
                        <input onChange={(e) => modifyFormHandler(e.target)} value={eventForm.address} defaultValue={eventForm.address} name="address" className='inputField shortInput' type="text" id="address"/>
                    </div> 
                    <div className='input'>
                        <label className='label' htmlFor="start_time">Hora de Inicio</label>
                        <input name="start_time" onChange={(e) => modifyFormHandler(e.target)} value={eventForm.start_time} defaultValue={eventForm.start_time}className='inputField shortInput' type="number" id="start_time"/>
                    </div>
                    <div className='input'>
                        <label className='label' htmlFor="end_time">Hora de Finalizacion</label>
                        <input name="end_time" onChange={(e) => modifyFormHandler(e.target)} value={eventForm.end_time} defaultValue={eventForm.end_time}className='inputField shortInput' type="number" id="end_time"/>
                    </div>
                </div>
                <div className={classes.inputContainer}>
                    <div className='input'>
                        <label className='label' htmlFor="start_date">Fecha de Inicio</label>
                        <input name="start_date" onChange={(e) => modifyFormHandler(e.target)} value={eventForm.start_date} defaultValue={eventForm.start_date} className='inputField shortInput' type="date" id="start_date"/>
                    </div>
                    <div className='input'>
                        <label className='label' htmlFor="end_date">Fecha de finalizacion</label>
                        <input name="end_date" onChange={(e) => modifyFormHandler(e.target)} value={eventForm.end_date} defaultValue={eventForm.end_date} className='inputField shortInput' type="date" id="end_date"/>
                    </div>
                    <div className='input'>
                        <label className='label' htmlFor="count_days">Selecciona los dias de juego</label>
                        <input name='count_days' onChange={(e) => modifyFormHandler(e.target)} value={eventForm.count_days}  defaultValue={eventForm.count_days} className='inputField shortInput' type="text" id="count_days"/>
                    </div>
                    <div className='input'>
                        <label className='label' htmlFor="waiting_time_matches">Espera entre partidos</label>
                        <input name='waiting_time_matches' onChange={(e) => modifyFormHandler(e.target)} value={eventForm.waiting_time_matches} defaultValue={eventForm.waiting_time_matches} className='inputField shortInput' type="number" id="waiting_time_matches"/>
                    </div>
                </div>
            </div>
            <div className={classes.fieldsContainer}>
                <h2 className='subtitle'>Personalización</h2> 
                <div className={classes.inputContainer}>
                    <div className='input'>
                        <label className='label' htmlFor="description">Descripción del evento
                        </label>
                        <textarea name="description" onChange={(e) => modifyFormHandler(e.target)} value={eventForm.description} defaultValue={eventForm.description} className='inputField hugeInput' id="description"/>
                    </div>
                    <div className='input'>
                        <label className='label' htmlFor='logo'>
                            Cargar logo
                            <span className='inputField squareInput'>
                                <EventsButtonIcon className='icon'/>
                                <img className='imagePreview' src={eventForm.logoPreview} alt=""/>
                            </span>
                        </label>
                        
                        <input type="file" className='inputFileField' onChange={(e)=> handleFile(e, 'logo')}  id='logo'/>
                    </div>

                    <div className='input'>
                        <label className='label'>
                            Colores del evento
                        </label>
                        
                        <input name='color_primary' type='color' onChange={(e) => modifyFormHandler(e.target)}id='color_primary' value={eventForm.color_primary} defaultValue={eventForm.color_primary} className='inputColor'/>
                            
                        <input name='color_secondary' type='color' onChange={(e) => modifyFormHandler(e.target)}id='color_secondary' value={eventForm.color_secondary} defaultValue={eventForm.color_secondary} className='inputColor'/>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        eventForm: state.eventForm
    }
}

export default connect(mapStateToProps, { modifyFormHandler} )(EventInfo);