import React, { useEffect, useState, useRef } from "react";

import GlobalLoading from "../components/accessories/GlobalLoading";
import VideoAnalysisModal from "../components/modals/VideoAnalysisModal";
import AnalysisVideoListComponent from "../components/AnalysisVideoListComponent";
import {
  getObjectFromArrayWithKeysByProperty,
  filterArrayByProperty,
} from "../services";
import {
  listAnalysisVideo,
  getClubs,
  getAllEventTags,
  deleteAnalysisVideoById,
  getPlayersByTeamId,
  getWPlayerById,
  getAnalysisFiltered,
  //getTeambyClubId,
} from "../endpoints";
import searchIcon from "../assets/img/searchBold.svg";
//import Select from "../components/accessories/Select/Select";
import { makeStyles } from "@material-ui/core";

import { translate } from '../lang/lang';

const useStyles = makeStyles(theme => ({
  TitleContainer: {
    width: "100%",
    "& span": {
      fontFamily: "DINCondensed",
      fontSize: "40px",
      lineHeight: "1",
    },
  },
  inputContainer: {
    minWidth: "80%",
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "25px",
    position: "relative",
    boxSizing: "border-box",
    fontFamily: "Open Sans Condensed",
    color: "#FFF",
    "& .largeInput": {
      width: "17%",
      marginRight: "2.5%",
      marginTop: "25px",
      position: "relative",
      "& .icon": {
        position: "absolute",
        top: "30%",
        right: "5%",
        width: "16px",
        height: "16px",
        zIndex: "9",
      },
    },
    "& .label": {
      fontSize: "15px",
      fontFamily: "Montserrat-Medium",
    },
    "& .inputField": {
      width: "100%",
      height: "40px",
      color: "#000",
      outline: "none",
      padding: "0 0.75rem",
      position: "relative",
      transition: "all .1s",
      borderRadius: "4px",
      boxSizing: "border-box",
      border: "solid 2px #ffffff",
      backgroundColor: "#fff",
      fontFamily: "Montserrat-Medium",
      fontSize: "15px",
      "&::placeholder": {
        color: "#000",
        fontSize: "15px",
      },
    },
  },
}));

const AnalysisVideoList = props => {
  let response = null;
  const classes = useStyles();
  const {user} = props;
  const playerModalRef = useRef(null);
  const [listTags, setListTags] = useState([]);
  const [resumenEvents, setResumenEvents] = useState({});
  const [eventsToShow, setEventsToShow] = useState(null);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [analysisSelected, setAnalysisSelected] = useState(null);
  const [listVideoAnalysis, setListVideoAnalysis] = useState([]);
  const [playersListByTeam, setPlayersListByTeam] = useState({});
  const [defaultClub, setDefaultClub] = useState(null);
  // const [teams, setTeams] = useState({});

  const [timeOutInput, setTimeOutInput] = useState(0);
  const [params, setParams] = useState({});
  const [clubs, setClubs] = useState([]);
  const [activeModalEventsToShow, setActiveModalEventsToShow] = useState(false);

  const setPush = value => {
    // props.history.push({ search: "?page=" + value });
  };

  const handleChange = data => {
    setGlobalLoading(true);
    if (data.value === "1") getListAnalysisVideo();
    setDefaultClub(data.value);
    const dataToSend = { ...params, club_id: data.value };
    getAnalysisFiltered(dataToSend).then(res => {
      if (!res.data) {
        return;
      }
      setListVideoAnalysis(res.data.response);
      setGlobalLoading(false);
    });
  };

  const makeParams = (key, value) => {
    setGlobalLoading(true);
    let getParams;
    if (key === "dateStart") {
      let newDate = value.split("-");
      value = `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
      getParams = { ...params, [key]: value, dateEnd: value };
    } else {
      getParams = { ...params, [key]: value };
    }
    if (params.hasOwnProperty(key) && key !== "page") {
      getParams = { ...getParams, page: 1 };
      setPush(getParams["page"]);
    }
    setParams(getParams);
    filterAnalisis(getParams);
    if (key === "page") {
      setPush(getParams["page"]);
    }
  };

  const showResumenEvents = analysis => {
    let resumentEventsTemp = [];
    if (analysis.analysisVideoEvents.length > 0) {
      let events = analysis.analysisVideoEvents;
      listTags.forEach((tag, index) => {
        resumentEventsTemp[index] = { ...tag, count: 0 };
        events.forEach(event => {
          event.event_action.forEach(action => {
            if (action.tag_id === tag.id) {
              resumentEventsTemp[index] = {
                ...resumentEventsTemp[index],
                count: resumentEventsTemp[index].count + 1,
              };
            }
          });
        });
      });
      setResumenEvents({ ...resumenEvents, [analysis.id]: resumentEventsTemp });
    } else {
      listTags.forEach((tag, index) => {
        resumentEventsTemp[index] = { ...tag, count: 0 };
      });
      setResumenEvents({ ...resumenEvents, [analysis.id]: resumentEventsTemp });
    }
  };

  const getListAnalysisVideo = async () => {
    setGlobalLoading(true);
    response = await listAnalysisVideo();
    if (response && response.status === 200) {
      console.log( response );
      setListVideoAnalysis(response.data.response);
      setGlobalLoading(false);
    } else {
      console.log("error");
      setGlobalLoading(false);
    }
  };

  const handleDeleteVidioAnalysis = async analysis => {
    setGlobalLoading(true);
    response = await deleteAnalysisVideoById(analysis.id);
    if (response && response.code === 200) {
      setTimeout(function() {
        getListAnalysisVideo();
      }, 1000);
    } else {
      console.log("error");
      setGlobalLoading(false);
    }
  };

  const getTags = async () => {
    response = await getAllEventTags();
    if (response && response.status === 200) {
      setListTags(response.data.response);
    } else {
      console.log("error");
    }
  };

  const filterAnalisis = params => {
    getAnalysisFiltered(params).then(response => {
      if (!response.data) {
        return;
      }
      setListVideoAnalysis(response.data.response);
      setGlobalLoading(false);
    });
  };

  const handleGetPlayersByTeamId = async e => {
    let eTemp = { ...e };
    let newPlayerListByTeam = playersListByTeam;
    if (
      newPlayerListByTeam[e.team_id] &&
      newPlayerListByTeam[e.team_id][e.wplayer_id]
    ) {
      eTemp = {
        ...eTemp,
        wplayer: newPlayerListByTeam[e.team_id][e.wplayer_id],
      };
    } else {
      if (e.team_id) {
        response = await getPlayersByTeamId(e.team_id);
        if (response.status === 200) {
          let playersByteam = getObjectFromArrayWithKeysByProperty(
            response.data.response,
            "_id"
          );
          newPlayerListByTeam = {
            ...newPlayerListByTeam,
            [e.team_id]: playersByteam,
          };
          let tagFilter = filterArrayByProperty(
            listTags,
            "id",
            eTemp.tag_id,
            "="
          );
          eTemp = {
            ...eTemp,
            wplayer: newPlayerListByTeam[e.team_id][e.wplayer_id],
            tag: tagFilter[0],
          };
        }
      } else {
        response = await getWPlayerById(e.wplayer_id);
        if (response.status === 200) {
          let tagFilter = filterArrayByProperty(
            listTags,
            "id",
            eTemp.tag_id,
            "="
          );
          eTemp = {
            ...eTemp,
            wplayer: response.data.response,
            tag: tagFilter[0],
          };
        }
      }
    }
    setPlayersListByTeam(newPlayerListByTeam);
    return eTemp;
  };

  const handleShowEventsByTagId = async (actionTag, analysis) => {
    let eventsToShowTemp = analysis.analysisVideoEvents.filter(event => {
      let tagIsIn = event.event_action.filter(action => {
        return action.tag_id === actionTag.id;
      });
      return tagIsIn.length > 0;
    });

    /* esto es para buscar los jugadores de los eventos, porque solo se tiene el id */
    eventsToShowTemp = await Promise.all(
      eventsToShowTemp.map(async event => {
        let eventTemp = event;
        let event_action_temp = await Promise.all(
          event.event_action.map(e => handleGetPlayersByTeamId(e))
        );
        eventTemp["event_action"] = event_action_temp;
        return eventTemp;
      })
    );
    /* esto es para buscar los jugadores de los eventos, porque solo se tiene el id */
    let eventTimeTemp = eventsToShowTemp[0]
      ? { playing: true }
      : { playing: false };
    setAnalysisSelected(analysis);
    setEventsToShow({
      action: actionTag,
      events: eventsToShowTemp,
      eventActive: 0,
      ...eventTimeTemp,
    });
    setActiveModalEventsToShow(!activeModalEventsToShow);

    if (eventsToShowTemp[0]) {
      setTimeout(function() {
        if (playerModalRef) {
          //se retrocede 5 seg. el video de este evento
          playerModalRef.current.seekTo(
            eventsToShowTemp[0].event_time > 5 ? eventsToShowTemp[0].event_time - 5 : 0,
            "seconds"
          );
        }
      }, 300);
    }
  };

  const handlePlayEvent = (item, time) => {
    setEventsToShow({
      ...eventsToShow,
      eventActive: item,
      event_time: time,
      playing: true,
    });
    playerModalRef.current.seekTo(time, "seconds");
  };

  useEffect(() => {
    getTags();
    getListAnalysisVideo();
    makeParams("page", 1);
    getClubs().then(response => {
      setClubs(response.data.response);
    });
  }, []);

  const handleInputSubmit = (key, e) => {
    if (!e.target.value) {
      removeParams(key);
      //   setGlobalLoading(true);
      //   filterAnalisis(paramsTemp);
      return;
    }
    if (timeOutInput !== 0) {
      clearTimeout(timeOutInput);
    }
    setTimeOutInput(
      setTimeout(
        e => {
          setGlobalLoading(true);
          // eslint-disable-next-line
          if (key === "dateStart") {
          }
          makeParams(key, e);
          /*
          let response = getPlayerBySearch(e).then((response) => {
            if(response && response.status === 200){     
              console.log(response.data);
              console.log(params) 
              setPlayers(response.data.response);
              setGlobalLoading(false);
              //setSelectDisable(true);
            }
          });
          */
        },
        1000,
        e.target.value
      )
    );
  };

  const removeParams = key => {
    setGlobalLoading(true);
    let { [key]: omit, ...paramsRem } = params;
    setParams(paramsRem);
    filterAnalisis(paramsRem);
    setPush(1);
  };

  let clubNames = getObjectFromArrayWithKeysByProperty(clubs, "wid", "_id");
  let clubSelected = params["club_id"] || null;
  if (clubSelected !== undefined)
    clubSelected = clubNames[clubSelected]
      ? clubNames[clubSelected].name
      : clubSelected;

  return (
    <div className='container main'>
      <div className={classes.TitleContainer}>
        <span>{translate(user.lang, 'video list')}</span>
      </div>
      <div className={classes.inputContainer}>
        {/* {clubs.length > 0 ? ( */}
        <div className='largeInput'>
          <input
            name='Jugador'
            placeholder={translate(user.lang, 'player')}
            className='inputField'
            onChange={e => handleInputSubmit("player_name", e)}
          />
          <img className='icon' src={searchIcon} alt='' />
        </div>
        {/* ) : null} */}

        {clubs.length > 0 ? (
          <div className='largeInput'>
            {/* <Select
              nameSelect={"Club"}
              selectedItem={clubSelected}
              newItemSelected={makeParams}
              component="AnalisisVideo"
              items={clubs}
              keyName="club_id"
              newItemSelectedEmpty={removeParams}
            /> */}
            <select
              value={defaultClub ? defaultClub : "0"}
              required
              className='inputField'
              id='wclubid'
              onChange={e => handleChange(e.target)}
            >
              <option value='0' disabled>
                Club
              </option>
              <option value='1'>{translate(user.lang, 'see all')}</option>
              {clubs.map((data, index) => {
                return (
                  <option key={index} value={data._id}>
                    {data.name}
                  </option>
                );
              })}
            </select>
          </div>
        ) : null}

        {/* {clubs.length > 0 ? ( */}

        <div className='largeInput'>
          <input
            className='inputField'
            type='date'
            onChange={e => handleInputSubmit("dateStart", e)}
          />
        </div>
        {/* ) : null} */}
      </div>
      <AnalysisVideoListComponent
        resumenEvents={resumenEvents}
        listVideoAnalysis={listVideoAnalysis}
        showResumenEvents={showResumenEvents}
        handleShowEventsByTagId={handleShowEventsByTagId}
        handleDeleteVidioAnalysis={handleDeleteVidioAnalysis}
      />
      <VideoAnalysisModal
        analysis={analysisSelected}
        eventsToShow={eventsToShow}
        open={activeModalEventsToShow}
        playerModalRef={playerModalRef}
        handlePlayEvent={handlePlayEvent}
        setActiveModalEventsToShow={setActiveModalEventsToShow}
      />
      {globalLoading && <GlobalLoading />}
    </div>
  );
};
export default React.memo(AnalysisVideoList);
