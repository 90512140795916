import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

//Imagenes .ico
import Altura from "../../assets/img/player-icons/Altura.ico";
import Balanza from "../../assets/img/player-icons/Balanza.ico";

import PieIzq from "../../assets/img/player-icons/Pie-izquierdo_1.ico";
import PieDer from "../../assets/img/player-icons/Derecho.ico";

const useStyles = makeStyles(() => ({
  div: {
    width: "100%",
    height: "100px",
    display: "inline-flex",
    // marginTop: "-35px", //theme.spacing(1),
    // marginBottom: "15px", //theme.spacing(2),
  },
  items: {
    paddingRight: "5%",
    textAlign: "center",
  },
}));

const BasicPlayerIndicators = (props) => {
  let { player } = props;
  const classes = useStyles();
  return (
    <>
      <div className={classes.div}>
        <div className={classes.items}>
          <img src={player.foot === "Right" ? PieDer : PieIzq} alt="" />
          <p
            style={{
              fontSize: "20px",
              fontWeight: "800",
              color: "cyan",

              marginTop: "8px",
            }}
          >
            {player.foot}
          </p>
        </div>
        <div className={classes.items}>
          <img
            src={Balanza}
            style={{
              marginLeft: "16px",
            }}
            alt=""
          />
          <p
            style={{
              fontSize: "20px",
              fontWeight: "800",
              color: "cyan",
              marginTop: "8px",
              align: "center",
            }}
          >
            {player.weight} KG - TALLA {player.size}
          </p>
        </div>
        <div className={classes.items}>
          <img src={Altura} alt="" style={{ marginLeft: "10px" }} />
          <p
            style={{
              fontSize: "20px",
              fontWeight: "800",
              color: "cyan",
              marginTop: "8px",
            }}
          >
            {player.height} CM
          </p>
        </div>
        {/* <div className={classes.items}>
          <p
            style={{
              fontSize: "45px",
              fontWeight: "800",
            }}
          >
            PJ
          </p>
          <p
            style={{
              fontSize: "45px",
              fontWeight: "800",
              color: "cyan",
              marginTop: "8px",
            }}
          >
            728
          </p>
        </div>
        <div className={classes.items}>
          <p
            style={{
              textAlign: "center",
            }}
          >
            PG
            <PlayCircleFilledIcon
              color="primary"
              style={{
                fontSize: "small",
                color: "cyan",
              }}
            />
          </p>
          <p
            style={{
              color: "cyan",
              fontSize: "25px",
              marginTop: "5px",
              padding: "5px",
            }}
          >
            4545
          </p>
          <p
            style={{
              marginTop: "2px",
            }}
          >
            GOLES
            <PlayCircleFilledIcon
              color="primary"
              style={{ fontSize: "small", color: "cyan" }}
            />
          </p>
          <p
            style={{
              color: "cyan",
              fontSize: "25px",
              textAlign: "center",
              marginTop: "5px",
              padding: "5px",
            }}
          >
            4545
          </p>
        </div>

        <div className={classes.items}>
          <p style={{ textAlign: "center" }}>
            ASSIST
            <PlayCircleFilledIcon
              color="primary"
              style={{ fontSize: "small", color: "cyan" }}
            />
          </p>
          <p
            style={{
              color: "cyan",
              fontSize: "25px",
              marginTop: "5px",
              padding: "5px",
            }}
          >
            4545
          </p>
          <p style={{ textAlign: "center" }}>
            PA
            <PlayCircleFilledIcon
              style={{ fontSize: "small", color: "cyan" }}
            />
          </p>
          <p
            style={{
              color: "cyan",
              fontSize: "25px",
              textAlign: "center",
              marginTop: "5px",
              padding: "5px",
            }}
          >
            4545
          </p>
        </div>

        <div className={classes.items}>
          <p style={{ textAlign: "center" }}>
            1vs1OF
            <PlayCircleFilledIcon
              style={{ fontSize: "small", color: "cyan" }}
            />
          </p>
          <p
            style={{
              color: "cyan",
              fontSize: "25px",
              marginTop: "5px",
              padding: "5px",
            }}
          >
            2456
          </p>
          <p style={{ textAlign: "center" }}>
            P1v1OF
            <PlayCircleFilledIcon
              style={{ fontSize: "small", color: "cyan" }}
            />
          </p>
          <p
            style={{
              color: "cyan",
              fontSize: "25px",
              textAlign: "center",
              marginTop: "5px",
              padding: "5px",
            }}
          >
            0,52%
          </p>
        </div>

        <div className={classes.items}>
          <p style={{ textAlign: "center" }}>
            1vs1DE
            <PlayCircleFilledIcon
              style={{ fontSize: "small", color: "cyan" }}
            />
          </p>
          <p
            style={{
              color: "cyan",
              fontSize: "25px",
              marginTop: "5px",
              padding: "5px",
            }}
          >
            2456
          </p>
          <p style={{ textAlign: "center" }}>
            P1v1DE
            <PlayCircleFilledIcon
              style={{ fontSize: "small", color: "cyan" }}
            />
          </p>
          <p
            style={{
              color: "cyan",
              fontSize: "25px",
              textAlign: "center",
              marginTop: "5px",
              padding: "5px",
            }}
          >
            0,44%
          </p>
        </div>

        {/*ultimos 5 
        <div className={classes.items}>
          <p
            style={{
              fontSize: "28px",
              fontWeight: "600",
            }}
          >
            Ultimos 5
          </p>
          <p>
            <PlayCircleFilledIcon
              color="secondary"
              style={{
                fontSize: "18px",
                color: "red",
              }}
            />
            <PlayCircleFilledIcon
              color="primary"
              style={{
                fontSize: "18px",
                color: "yellow",
              }}
            />
            <PlayCircleFilledIcon
              color="secondary"
              style={{
                fontSize: "18px",
                color: "green",
              }}
            />
            <PlayCircleFilledIcon
              color="primary"
              style={{
                fontSize: "18px",
                color: "yellow",
              }}
            />
            <PlayCircleFilledIcon
              color="secondary"
              style={{
                fontSize: "18px",
                color: "green",
              }}
            />
          </p>
        </div> */}
      </div>
    </>
  );
};

export default BasicPlayerIndicators;
