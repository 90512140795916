import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import {resourceApiEndPoint} from '../../endpoints';
import { makeStyles } from '@material-ui/core/styles';
import {BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';

const useStyles = makeStyles(theme => ({
    customTooltip: {
        background: '#000000bd',
        borderRadius: '8px',
        padding: '15px',
        opacity: '0.9'
    },
    BarChart: {
        transform: 'translateX(45px)'
    }
}));

const GetGraphyGetTeamByPlayer = (props) => {

    const classes = useStyles();
    const {resetChartBuilderData, statsGps, widthChart, metricSeleted, dateStart} = props;
    const {data, colorsFilter, playersFilters} = resetChartBuilderData();
        
    let name = "";
    let picture = "";

    let newDateChart = Object.keys(playersFilters).map( (entry) => {

        if( Object.keys(data)[0] ) {

            statsGps.stats.map( (entryTwo, index) => {
                if( statsGps.playersSelected.indexOf(index) > -1 ){
                    if(statsGps.stats[index].name.charAt(0) + '. ' + statsGps.stats[index].lastname === playersFilters[entry]){
                        name = statsGps.stats[index].name + ' ' + statsGps.stats[index].lastname;
                        picture = resourceApiEndPoint() + (statsGps.stats[index].img_file.charAt(0) === '/' ? statsGps.stats[index].img_file : '/' + statsGps.stats[index].img_file) 
                    }
                }
                return null
            })

            return {
                name: playersFilters[entry],
                value: data[ Object.keys(data)[0] ][playersFilters[entry]] ? data[ Object.keys(data)[0] ][playersFilters[entry]].toFixed(2) : null,
                player_name: name,
                player_picture: picture,
            };

        }else{
            return null
        }
        //console.log(playersFilters[entry])
    })

    function CustomTooltip({ payload, label, active }) {

        if (active && payload && payload[0]) {
          return (
            <div className={classes.customTooltip}>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <p>{ dateStart.getDate() < 10 ? '0' + dateStart.getDate() : dateStart.getDate() }/{ (dateStart.getMonth() + 1) < 10 ? '0' + (dateStart.getMonth() + 1) : dateStart.getMonth() + 1 }/{dateStart.getFullYear()}</p>
                </div>
                <br/>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Avatar 
                        src={ payload[0].payload.player_picture }
                        alt="Player name"
                        style={ {backgroundColor: '#ffffff', width: '52.16px', height: '52.16px', paddingTop: '0'} }
                    />
                </div>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <p className="label"><b>{payload[0].payload.player_name} </b></p>
                </div>

                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <p className="label"><b>{metricSeleted.name} ({metricSeleted.measurement_unit}): </b>{`${payload[0].value}`}</p>
                </div>
            </div>
          );
        }
        return null;
    }

    //console.log(minYAxis)
    const getMinYAxis = () => {
        let minYAxis = 0;
        Object.keys(playersFilters).map( (entry) => {
            if( Object.keys(data)[0] ) {
                if( parseInt( data[ Object.keys(data)[0] ][playersFilters[entry]].toFixed() ) > minYAxis){
                    minYAxis = parseInt(data[ Object.keys(data)[0] ][playersFilters[entry]].toFixed() ) + 1;
                }
            }
            return null;
        });
        return minYAxis;
    }

    return (
        <BarChart
            className={classes.BarChart}
            width={widthChart}
            height={615}
            data={newDateChart}
            margin={{
                top: 20, right: 50, left: 10, bottom: 15,
            }}
        >
            <CartesianGrid fill="#18191d" strokeDasharray="5 5" />
            <XAxis dataKey="name" tick={null} />
            <YAxis unit={"_" + metricSeleted.measurement_unit} domain={[0, getMinYAxis()]} />
            <Tooltip content={<CustomTooltip />}/>
            
            <Bar dataKey="value" barSize={20} fill="transparent">
                {
                    playersFilters.map((entry, index) => ( 
                        <Cell key={`cell-${index}`} fill={colorsFilter[index]} />
                    ))
                } 
            </Bar>
        </BarChart>
    );
};
export default React.memo(GetGraphyGetTeamByPlayer);