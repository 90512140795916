import React, { useRef, useState } from "react";

import ReactPlayer from "react-player";
import { translate } from "../lang/lang";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import useStyles from "../assets/MakeStyles";
import Avatar from "@material-ui/core/Avatar";
import { resourceApiEndPoint } from "../endpoints";
import AnalysisVideoStepper from "./AnalysisVideoStepper";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import boxLocal from "../assets/img/textures/box-local-team.png";
import soccerBallIco from "../assets/img/soccer-ball-variant.svg";
import boxVisitor from "../assets/img/textures/box-visitor-team.png";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import Shield from "../assets/img/shield.png";

const AnalysisVideo = (props) => {
  const ref = useRef(null);
  const classes = useStyles();
  const [currentPlayerY, setcurrentPlayerY] = useState(0);
  const [currentPlayerX, setcurrentPlayerX] = useState(0);
  const [mousePosition, setMousePosition] = useState(0);
  const {
    user,
    event,
    stepper,
    analysis,
    eventInfo,
    playerRef,
    newTagName,
    tagActions,
    handleLike,
    setActiveStep,
    setNewTagName,
    loadTagPlayer,
    openTagPlayer,
    openNewTagModal,
    handleSaveEvent,
    handleEventInfo,
    tagPlayerSelect,
    handleSaveNewTag,
    handleModalClose,
    setOpenNewTagModal,
    handleSetTagAction,
    actionByPlayersTemp,
    handleCommentWrited,
    handleShowEventsByTagId,
    handleFieldZoneSelected,
  } = props;

  const onClickHandler = (e, player, teamId) => {
    loadTagPlayer(player, teamId);
    setMousePosition(e.nativeEvent);
    setcurrentPlayerY(e.nativeEvent.clientY - 15);
    setcurrentPlayerX(`${38}%`);
    if (e.nativeEvent.clientX >= window.screen.width / 2) {
      setcurrentPlayerX(`${62}%`);
    }
  };

    const getBodyPlayers = (players, teamId) => {

        let rolestring = {"GKP": [], "DEF": [], "MID": [], "FWD": [], "UNDEFINED": []};

        players.forEach( (player) => {
            if( rolestring[player.rolestring] ){
                console.log('si', player.rolestring)
                rolestring[player.rolestring] = [...rolestring[player.rolestring], player ];
            }else{
                console.log('no', player.rolestring)
                rolestring["UNDEFINED"] = [...rolestring["UNDEFINED"], player ];
            }
        });
        
        return Object.keys(rolestring).map( role => {

            if( rolestring[role].length > 0 ){
                return  <ExpansionPanel className={`${classes.expansionPanelContainer} expansion-panel-players`} key={`role-${role}`} defaultExpanded={false}>
                            <div className={classes.roleContainer}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={"panel1a-content-"+role}
                                >
                                    <h3 className={classes.role}>{role}</h3>
                                </ExpansionPanelSummary>
                            </div>
                            <ExpansionPanelDetails>
                            <>
                            {   rolestring[role] && rolestring[role].map( (player, index) => {
                                    return  <div ref={ref} className={`${classes.playerBoxContainer} ${tagPlayerSelect && tagPlayerSelect._id === player._id ? "active" : "inactive" }`}  key={index} onClick={ (e) => onClickHandler(e, player, teamId) }>
                                    <Avatar 
                                        src={ resourceApiEndPoint() + (player.img_file.charAt(0) === '/' ? player.img_file : '/' + player.img_file) }
                                        alt="Player name"
                                    />
                                    <div style={{padding: '0 0 0 15px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', cursor: 'pointer'}} >
                                        <span style={{fontSize: '13.02px'}}>{player.name + ' ' + player.lastname}</span>
                                    </div>
                                </div>
                            })}
                            </>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
            }else{
                return null
            }

        });
    }

  const getHeadTeam = (team, visitorLocal) => {
    return (
      <div className={classes.teamNameContainer}>
        <img
          onError={(e) => (e.target.src = Shield)}
          alt={`Logo ${visitorLocal} club`}
          className="local-visitor-logo"
          src={
            resourceApiEndPoint() +
            (team.img_file.charAt(0) === "/"
              ? team.img_file
              : "/" + team.img_file)
          }
        ></img>
        <span className="teamName">{team.name}</span>
      </div>
    );
  };

  const getTagActions = () => {
    if (tagActions) {
      return tagActions.map((tag, index) => {
        let classTagAction =
          tagPlayerSelect &&
          actionByPlayersTemp[tagPlayerSelect._id].tag_id &&
          actionByPlayersTemp[tagPlayerSelect._id].tag_id === tag.tagId
            ? classes.tagActionSelect
            : "tag-action";
        return (
          <div
            key={index}
            className={classTagAction}
            onClick={() => handleSetTagAction(tag)}
          >
            {/*<span>{tag.tagName}</span>*/}
            <span>{translate(user.lang, tag.tagName)}</span>
          </div>
        );
      });
    }
  };

  const getResummentEvents = (actions, type) => {
    if (actions) {
      return actions.map((action, index) => {
        return (
          <div key={index} className="event-resummen-container">
            {type === "name" ? (
              <div className={"tag-container"}>
                <span>{action.tagName}</span>
              </div>
            ) : (
              <div
                className={"tag-count-container"}
                onClick={() => handleShowEventsByTagId(action)}
              >
                <p style={{ color: "transparent" }}>{action.tagName}</p>
                <span>{action.count}</span>
              </div>
            )}
          </div>
        );
      });
    }
  };

  return (
    <>
      <div className={classes.resumenContainer}>
        <div
          className={classes.boxLocalVisitor}
          style={{ backgroundImage: `url(${boxLocal} )` }}
        >
          {analysis && getHeadTeam(analysis.team_local.club, "local")}
          {analysis &&
            getBodyPlayers(analysis.team_local.players, analysis.teamid_local)}
        </div>

        <div className={classes.boxDateMatch}>
          {analysis && analysis.video_filename && (
            <>
              <div className="col margin-none-x space-around">
                <div className="col margin-none">
                  <img
                    className={classes.soccerBallIco}
                    src={soccerBallIco}
                    alt="soccer ball icon"
                  />
                  <span className={classes.titleMatch}> {analysis.name}</span>
                </div>
              </div>

              <ReactPlayer
                ref={playerRef}
                className={classes.mediaPlayer}
                width={"100%"}
                url={analysis.video_filename}
                controls={true}
              />
            </>
          )}

          <AnalysisVideoStepper
            event={event}
            stepper={stepper}
            analysis={analysis}
            eventInfo={eventInfo}
            handleLike={handleLike}
            getTagActions={getTagActions}
            setActiveStep={setActiveStep}
            handleSaveEvent={handleSaveEvent}
            handleEventInfo={handleEventInfo}
            tagPlayerSelect={tagPlayerSelect}
            actionByPlayersTemp={actionByPlayersTemp}
            handleCommentWrited={handleCommentWrited}
            handleFieldZoneSelected={handleFieldZoneSelected}
          />
        </div>

        <div
          className={classes.boxLocalVisitor}
          style={{ backgroundImage: `url(${boxVisitor} )` }}
        >
          {analysis && getHeadTeam(analysis.team_visitor.club, "visitor")}
          {analysis &&
            getBodyPlayers(
              analysis.team_visitor.players,
              analysis.teamid_visitor
            )}
        </div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalTagPlayerAV}
          open={openTagPlayer}
          closeAfterTransition
        >
          <Fade in={openTagPlayer}>
            <div
              onMouseLeave={() => handleModalClose()}
              className={classes.paperTagPlayer}
              style={{ top: currentPlayerY - 150, left: currentPlayerX }}
            >
              <span
                className={
                  mousePosition.clientX >= 800
                    ? "indicatorRight"
                    : "indicatorLeft"
                }
              />
              <div className={classes.tagsContainer}>
                <input
                  placeholder="Buscar..."
                  type="text"
                  className="modalInput"
                />
                {getTagActions()}
              </div>
              <button
                className={classes.addTagButton}
                onClick={() => setOpenNewTagModal(true)}
              >
                Agregar Acción
              </button>
              {/* <div style={{cursor: 'pointer', textAlign: 'right'}} onClick={ () => handleCloseOpenTagPlayer(!openTagPlayer)}>close</div> */}
            </div>
          </Fade>
        </Modal>
      </div>
      <div className={classes.resummenEventsContainerRoot}>
        <div className={classes.boxSummaryEvents}>
          <div>
            <span style={{ fontWeight: 700, textTransform: "uppercase" }}>
              {translate(user.lang, "statistics")}
            </span>
          </div>
          <div className={classes.resummenEventsContainer}>
            <div className="resummenEventsSubContainerName">
              {getResummentEvents(tagActions, "name")}
            </div>
          </div>
          <div className={classes.resummenEventsContainer}>
            <div className="resummenEventsSubContainerCount">
              {getResummentEvents(tagActions, "count")}
            </div>
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalTagPlayerAV}
        open={openNewTagModal}
        closeAfterTransition
      >
        <Fade in={openNewTagModal}>
          <div className={classes.paperTagAction}>
            <div className={classes.tagsContainer}>
              <input
                onChange={(e) => setNewTagName(e.target.value)}
                placeholder="Nombre"
                type="text"
                className="modalInput"
              />
            </div>
            <button
              disabled={newTagName ? false : true}
              className={classes.addTagButton}
              onClick={() => handleSaveNewTag()}
            >
              Guardar Acción
            </button>
            <button
              className={classes.addTagButton}
              onClick={() => setOpenNewTagModal(false)}
            >
              Cancelar
            </button>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
export default React.memo(AnalysisVideo);
