import React, { useState, useEffect } from 'react'

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllCompetitionsHandler } from '../actions';
import GlobalLoading from '../components/accessories/GlobalLoading';
import { getAllCompetitionTypes, postCompetition } from '../endpoints';
import CompetitionSetupComponent from '../components/CompetitionSetupComponent';

function mapDispatchToProps(dispatch) {
    return {
        set_getAllCompetitionsHandler: comp => dispatch( getAllCompetitionsHandler(comp)),
    };
}

const mapStateToProps = state => {
    return {
        competitions: state.competitions
    }
}

const CompetitionsSetup = (props) => {

    const history = useHistory();
    const [types, setTypes] = useState([]);
    const [globalLoading, setGlobalLoading] = useState(false);
    const {competitions, set_getAllCompetitionsHandler} = props;
    const [values, setValues] = useState({name:'', confederation_id:'AW3P3C5JFA-2FJhHZory', competition_type_id: 'LEAGUE', quantity_teams: null, quantity_groups: 1, unique_match: true, quantity_classify_teams: 1});

    const asignValues = e => {
        const { value, name } = e.target;
        setValues({...values, [name]: value });
    };
    
    const loadState = async() => {
        const response = await getAllCompetitionTypes();
        setTypes(response.data.response);
    }

    const handleSaveCompetition = async() => {
        console.log(values)
        setGlobalLoading(true)
        let response = await postCompetition(values);
        if(response.code === 200){
            let newCompetition = {_id: response._id, ...values}
            let newCompetitions = [...competitions];
            newCompetitions.push(newCompetition);
            console.log(newCompetition)
            console.log(competitions)
            console.log(newCompetitions)
            set_getAllCompetitionsHandler(newCompetitions)
            setTimeout( function(){ 
                history.push( '/competition' )
            }, 500);
            
        }else{
            console.log('error');
        }
        console.log(response);
        setGlobalLoading(false)
    }

    useEffect(() => {
        loadState();
    }, [])
    
    return (<>
        <CompetitionSetupComponent values={values} asignValues={asignValues} types={types} handleSaveCompetition={handleSaveCompetition} />
        { globalLoading &&  <GlobalLoading /> }
    </>)
}
const AppCompetitionsSetup = connect(mapStateToProps, mapDispatchToProps)(CompetitionsSetup);
export default React.memo(AppCompetitionsSetup);