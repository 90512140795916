import React from 'react';
import ReactPlayer from 'react-player';
import field from '../assets/img/field.jpg';
import cupIcon from '../assets/img/cup.svg';
import DateFnsUtils from '@date-io/date-fns';
import timerIcon from '../assets/img/timer.svg';
import { AccessAlarm } from '@material-ui/icons';
import {resourceApiEndPoint} from '../endpoints';
import whistleIcon from '../assets/img/whistle.svg';
import stadiumIcon from '../assets/img/stadium.svg';
import clearIcon from '../assets/img/clear-icon.svg';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import calendarIcon from '../assets/img/calendar.svg';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import boxMatch from '../assets/img/textures/box-match.png';
import boxHeader from '../assets/img/textures/box-header.png';
import boxLocal from '../assets/img/textures/box-local-team.png';
import soccerBallIco from '../assets/img/soccer-ball-variant.svg';
import boxVisitor from '../assets/img/textures/box-visitor-team.png';
import boxDateMatch from '../assets/img/textures/box-dates-match.png';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';

import { translate } from '../lang/lang';
//import wyscout from '../assets/img/wyscout.png';
import { ReactComponent as Video } from '../assets/img/video.svg';
//import { ReactComponent as Youtube } from '../assets/img/youtube.svg';

const useStyles = makeStyles(theme => ({
    userText: {
        fontWeight: '400',
        fontSize: '19.64px',
        textTransform: 'uppercase',
        fontFamily: 'montserrat',
        position: 'relative',

        '&::after': {
            content: '""',
            position: 'absolute',
            borderBottom: '1px solid white',
            width: '100%',
            top: '25px',
            left: '0',
        },
    },
    questionText: {
        fontWeight: '400',
        lineHeight: '38px',
        fontSize: '26.35px',
        fontFamily: 'montserrat'
    },
    inputSelect: {
        width: '100%',
        height: '45px',
        display: 'flex',
        fontWeight: 700,
        borderRadius: '8px',
        alignItems: 'center',
        transition: 'all .3s',
        backgroundColor: '#3A3C3E',
        textTransform: 'uppercase',
        justifyContent: 'space-between',
        '& select option': {
            color: 'red'
        },
        '& fieldset': {
            border: 'none'
        },
        '& .MuiFormLabel-root': {
            width: '100%',
            color: '#ffffff',
            overflow: 'hidden',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        '& .MuiInputLabel-outlined': {
            fontFamily: 'Open Sans',
            transition: 'all .3s',
            transform: 'translate(-50%, 12px) scale(1)',
            left: '50%',
            fontSize: '17.96px'
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(-30px, -18px) scale(0.75) !important',
            left: 0,
            transition: 'all .3s',
        },
        '& .MuiAutocomplete-endAdornment': {
            right: '-10px !important',
            background: '#3a3c3e',
            opacity: 0
        },
        '&:hover .MuiAutocomplete-endAdornment': {
            opacity: 1
        },
        '& .backNextOption:hover': {
            color: '#1BDDDB',
            cursor: 'pointer',
            transition: 'all .3s',
            transform: 'scale(1.8)',
        },
        '& .MuiAutocomplete-root': {
            margin: 0
        },
        '& .MuiAutocomplete-inputRoot': {
            padding: 0
        },
        '& .MuiAutocomplete-input': {
            margin: 0,
            width: '100%',
            color: '#ffffff',
            overflow: 'hidden',
            fontSize: '17.96px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            background: '#3a3c3e',
            textOverflow: 'ellipsis',
            fontFamily: 'Open Sans',
            textTransform: 'uppercase',
            font: 'initial'
        },
        '@media (max-width: 576px)': {
            width: '100%',
            flexDirection: 'row'
        },
        '& > span': {
            fontFamily: 'OpenSans',
            fontWeight: 400
        }
    },
    defaultButtom: {
        width: 252,
        '@media (max-width: 576px)': {
            width: 'calc(100% - 60px)'
        }
    },
    titleMediaGame: {
        margin: '60px 15px'
    },
    soccerBallIco: {
        marginRight: 15,
        width: 20.9,
        height: 20.9
    },
    clearIcon: {
        width: 19,
        height: 19,
        cursor: 'pointer',
        transition: 'all .3s',
        '&:hover': {
            transform: 'scale(1.8)',
            transition: 'all .3s',
        },
    },
    resumenContainer: {
        display: 'flex',
        margin: '0 0 30px',
        '& > div' : {
            '@media (max-width: 576px)': {
                width: 'calc(100% - 30px)',
                flexDirection: 'row'
            }
        }
    },
    localContainer: {
        width: 'calc(20% - 30px)',
        margin: '15px 0 0',
        padding: '15px',
        '& .MuiAutocomplete-input': {
            textAlign: 'center',
            fontSize: '14.25px'
        },
        '& .MuiInputLabel-outlined': {
            fontFamily: 'Open Sans',
            transition: 'all .3s',
            transform: 'translate(-50%, 12px) scale(1)',
            left: '50%',
            fontSize: '14.25px'
        },

        '& .MuiInputBase-root': {
            paddingRight: '0!important'
        }
    },
    matchContainer: {
        width: 'calc(60% - 30px)',
        margin: '15px 15px 0',
        padding: '30px',
        '& .MuiInputBase-root, .MuiInputBase-input' : {
            background: 'transparent'
        }
    },
    visitorContainer: {
        width: 'calc(20% - 30px)',
        margin: '15px 0 0',
        padding: '30px',
        '& .MuiAutocomplete-input': {
            textAlign: 'center',
            fontSize: '14.25px'
        },
        '& .MuiInputLabel-outlined': {
            fontFamily: 'Open Sans',
            transition: 'all .3s',
            transform: 'translate(-50%, 12px) scale(1)',
            left: '50%',
            fontSize: '14.25px'
        },
        '& .MuiInputBase-root': {
            paddingRight: '0!important'
        }
    },
    categoryTitle: {
        fontSize: 20,
        textTransform: 'uppercase',
        fontWeight: 700,
        margin: '60px 0 30px'
    },
    inputSelectMatchContainer: {
        display: 'flex',
        justifyContent: 'space-between',

    },
    inputSelectMatch: {
        height: '63px',
        display: 'flex',
        fontWeight: 700,
        margin: '15px 0',
        borderRadius: '4px',
        alignItems: 'center',
        transition: 'all .3s',
        textTransform: 'uppercase',
        border: '1px solid #798285',
        justifyContent: 'space-between',
        background: '#3a3c3e',
        '& div.MuiAutocomplete-root': {
            width: '100%'
        },
        '& fieldset': {
            border: 'none'
        },
        '& .MuiFormLabel-root': {
            color: '#ffffff'
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(-26px, -6px) scale(0.75) !important',
            left: 0,
            transition: 'all .3s',
        },
        '& .MuiAutocomplete-endAdornment': {
            right: '-10px !important',
            background: 'transparent',
            opacity: 0
        },
        '&:hover .MuiAutocomplete-endAdornment': {
            opacity: 1
        },
        '& .backNextOption:hover': {
            color: '#1BDDDB',
            cursor: 'pointer',
            transition: 'all .3s',
            transform: 'scale(1.8)',
        },
        '& .MuiAutocomplete-root': {
            margin: 0
        },
        '& .MuiAutocomplete-inputRoot': {
            padding: '0',

        },
        '& .MuiAutocomplete-input': {
            textAlign: 'center',
            margin: 0,
            width: '100%',
            color: '#ffffff',
            overflow: 'hidden',
            fontSize: '14.25px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontFamily: 'Open Sans',
            textTransform: 'uppercase',
        },
        '@media (max-width: 576px)': {
            width: '100%',
            flexDirection: 'row'
        },
        '& > span': {
            fontFamily: 'OpenSans',
            fontWeight: 400
        },
        '& .MuiInputLabel-outlined': {
            fontFamily: 'Open Sans',
            transition: 'all .3s',
            transform: 'translate(-50%, 12px) scale(1)',
            left: '50%',
            fontSize: '14.25px'
        },
    },
    inputSelectMatchCup: {
        width: 'calc(60% - 15px)',
        '@media (max-width: 576px)': {
            width: '100%'
        }
    },
    inputSelectMatchReferee: {
        width: 'calc(40% - 15px)',
        '@media (max-width: 576px)': {
            width: '100%'
        }
    },
    inputSelectMatchStadium: {
        width: 'calc(40% - 15px)',
        '@media (max-width: 576px)': {
            width: '100%'
        }
    },
    inputSelectMatchCalendar: {
        background: '#3a3c3e',
        width: 'calc(30% - 15px)',
        '@media (max-width: 576px)': {
            width: '100%'
        }
    },
    inputSelectMatchTime: {
        width: 'calc(30% - 15px)',
        '@media (max-width: 576px)': {
            width: '100%'
        }
    },
    mediaPlayerContainer: {
        margin: '15px 0'
    },
    titleMatch: {
        fontFamily: 'Montserrat',
        fontSize: '17.1px',
        fontWeight: 400,
        textAlign: 'left'
    },
    resumenTitle: {
        fontFamily: 'Open Sans Condensed',
        fontWeight: 700,
        fontSize: '29.64px',
    },
    categorySubTitle: {
        fontFamily: 'Open Sans Condensed',
        fontSize: '20px',
        fontWeight: 700,
        marginTop: '15px',
        textTransform: 'uppercase',
    },
    inputContainerDefault: {
        width: '100%',
        maxWidth: '361px',
    },
    inputDefault: {
        width: '100%',
        borderBottom: '1px solid #ffffff',
        margin: '15px 0px !Important',
        color: '#ffffff'
    },
    inputLabelDefault: {
        color: '#ffffff'
    },
    boxHeader: {
        backgroundImage: `url(${boxHeader} )`,
        backgroundPosition: 'center',
        borderRadius: '5.7px',
        backgroundSize: 'cover',
        padding: '26px',

        '& .MuiInputBase-root': {
            paddingRight: '0!important'
        }
    },
    boxLocal: {
        backgroundImage: `url(${boxLocal} )`,
        backgroundPosition: 'center',
        borderRadius: '5.7px',
        backgroundSize: 'cover',
    },
    boxVisitor: {
        backgroundImage: `url(${boxVisitor} )`,
        backgroundPosition: 'center',
        borderRadius: '5.7px',
        backgroundSize: 'cover',
    },
    boxMatch: {
        backgroundImage: `url(${boxMatch} )`,
        backgroundPosition: 'center',
        borderRadius: '5.7px',
        backgroundSize: 'cover',
    },
    boxDateMatch: {
        backgroundImage: `url(${boxDateMatch} )`,
        backgroundPosition: 'center',
        borderRadius: '5.7px',
        backgroundSize: 'cover',
    },
    keyboardDatePicker: {
        border: '1px solid #798285',
        height: '63px',
        transition: 'all .3s',
        borderRadius: '4px',
        textTransform: 'uppercase',
        '& label' : {
            color: 'white'
        },
        '& .MuiInput-underline:after' : {
            borderBottom: 'none'
        },
        '& .MuiInputBase-input': {
            color: '#fff',
            textAlign: 'center',
            fontSize: '14px',
            padding: '0 0 0 5px'
        },
        '& .MuiInput-formControl': {
            marginTop: '5px',
            height: '100%',
        },
        '& .MuiFormLabel-root.MuiInputLabel-shrink': {
            transform: 'translate(18px, 9px) scale(0.75)',
            transformOrigin: 'top left',
            top: 'auto',
            left: 'auto',
        },
        '& .MuiFormLabel-root': {
            top: '50%',
            left: '50%',
            fontSize: '14.25px',
            transform: 'translate(-50%, -50%)',
        },
        '& .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none'
        },
        '& .MuiSvgIcon-root': {
            width: '32px',
            height: '32px',
        },
    },
    turnField: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        '& button': {
            padding: '0',

        },
        '& svg': {
            fontSize: '50px',
        },
        '&.turnLeft': {
            left: '25%'
        },
        '&.turnRight': {
            left: '75%'
        }
    },
    wyscoutSelect: {
        flexGrow: 1,
        background: '#08BBEE',
        padding: '0 30px',
        margin: 0,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '0 8px 8px 0',
        color: '#000000',
        cursor: 'pointer',
        '& svg': {
            width: '30px'
        },
        '& svg g':{
            stroke: '#000000'
        }
    },
    youtubeSelect: {
        flexGrow: 1,
        background: '#08BBEE',
        padding: '0 30px',
        margin: 0,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px 0 0 8px',
        color: '#000000',
        cursor: 'pointer',
        '& svg': {
            width: '30px'
        },
        '& svg g':{
            stroke: '#000000'
        },
    },
    inputYoutube: {
        width: '100%',
        border: 'none',
        height: '16.9px',
        color: '#FFFFFF',
        fontWeight: '700',
        fontSize: '14.25px',
        background: '#3A3C3E',
        fontFamily: 'montserrat',
        '&:focus': {
            outline: 'none'
        }
    }
}));

const AnalysisVideoSetupComponent = (props) => {

    const top100Films = [
        { title: 'test 1', year: 1994 },
        { title: 'test 2', year: 1972 },
        { title: 'test 3', year: 1974 },
    ];

    const classes = useStyles();
    const   {   user,
                sport,
                clubs,
                sports,
                localTeam,
                timeMatch,
                dateMatch,
                videoList,
                countries,
                localClub,
                localTeams,
                visitorTeam,
                visitorClub,
                inputYoutube,
                visitorTeams,
                competitions,
                searchVideoBy,
                videoMediaInfo,
                initialQuestion,
                handleSaveSetup,
                localCategories,
                selectsCallBack,
                videoListYoutube,
                //clubLocalDefault,
                visitorCategories,
                //clubVisitorDefault,
                localOnLeftToStart,
                localCategorySelect,
                competitionsSelected,
                hadleSetSearchVideoBy,           
                visitorCategorySelect,
                handleDateMatchChange,
                handleTimeMatchChange,
                removeMatchVideoSource,
                videoMediaWScoutYoutube,
                setVideoMediaWScoutYoutube,
                handleCloseOpenWscoutVideos,
                handleSetLocalOnLeftToStart,
            } = props;

    const getSelect = (label, options, idSelect) => {

        let defaultValue = null;

        if( options && idSelect === 3 ){ // asignamos el deporte seleccionado por defecto options[0] que por ahora es futbol
            defaultValue = options[0];
            if(!sport){
                selectsCallBack(options[0], 3)
            }
        }else if( options && idSelect === 5 && localClub ){
            if(localClub){
                defaultValue = Object.keys(options).filter( key => {
                    return options[key]._id === localClub._id
                })
            }
            defaultValue = options[defaultValue[0]]
        }else if( options && idSelect === 7 && visitorTeam ){
            options = options.filter( option => {
                return option._id !== visitorTeam._id
            })
        }else if( options && idSelect === 14 && visitorClub ){
            if(visitorClub){
                defaultValue = Object.keys(options).filter( (key) => {
                    return options[key]._id === visitorClub._id
                })
            }
            defaultValue = options[defaultValue[0]]
        }else if( options && idSelect === 16 && localTeam ){
            options = options.filter( option  => {
                return option._id !== localTeam._id
            })
        }
        
        return  <>
                    <div className="backNextOption"></div>

                    { options ?
                    
                        <>
                        { idSelect === 5 | idSelect === 14 ?
                            <Autocomplete
                                value={defaultValue}
                                options={options}
                                getOptionLabel={option => option.name ? option.name : option.title}
                                renderOption={option => (
                                    option.img_file ? 
                                        <>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
                                            <img alt={ option.name ? option.name : option.title + ` picture`} style={{width: '30px', margin: '10px 10px 10px 0px'}} src={resourceApiEndPoint() + (option.img_file.charAt(0) === '/' ? option.img_file : '/' + option.img_file) }></img>
                                            <span style={{fontSize: '14px'}}>{option.name ? option.name : option.title}</span>
                                        </div>
                                        </>
                                    :
                                        <span style={{fontSize: '14px'}}>{option.name ? option.name : option.title}</span>
                                )}
                                renderInput={params => (
                                    <TextField {...params} label={label} variant="outlined" fullWidth />
                                )}
                                onChange={ (e, value) => selectsCallBack(value, idSelect) }
                            />
                        :
                            <Autocomplete
                                options={options}
                                getOptionLabel={option => option.name ? option.name : option.title}
                                renderOption={option => (
                                    option.img_file ? 
                                        <>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
                                            <img alt={ option.name ? option.name : option.title + ` picture`} style={{width: '30px', margin: '10px 10px 10px 0px'}} src={resourceApiEndPoint() + (option.img_file.charAt(0) === '/' ? option.img_file : '/' + option.img_file) }></img>
                                            <span style={{fontSize: '14px'}}>{option.name ? option.name : option.title}</span>
                                        </div>
                                        </>
                                    :
                                        <span style={{fontSize: '14px'}}>{option.name ? option.name : option.title}</span>
                                )}
                                renderInput={params => (
                                    <TextField {...params} label={label} variant="outlined" fullWidth />
                                )}
                                onChange={ (e, value) => selectsCallBack(value, idSelect) }
                            />
                        }
                        </>
                    :
                        <p>{label}</p>
                    }
                    <div className="backNextOption"></div>
                </>
    }

    return (
    <>
        <div className={classes.boxHeader}>

            <div className="col">
                <span className={classes.userText}>{translate(user.lang, 'hello')} {user.name} {user.lastname} !</span>
            </div>

            { initialQuestion && 
                <div className="center-col">
                    <span className={classes.questionText}>{translate(user.lang, 'video-setup-title')}</span>
                </div>
            }

            <div className="cols center-row" style={{margin: '15px 0'}}>

                { false &&             
                    <div className={classes.inputSelect + ' content-margin-x'} style={{width: '262px'}}>
                        {getSelect(translate(user.lang, 'country'), countries, 1)}
                    </div>
                }

                <div className={classes.inputSelect + ' content-margin-x'} style={{width: '262px'}}>

                    { searchVideoBy === 'wyscout' && 
                        <div style={{flexGrow: 1, textAlign: 'center'}}>
                            {/* <img src={wyscout} alt="wyscout select" /> */}
                            {translate(user.lang, 'my videos')}
                        </div>
                    }

                    { searchVideoBy === 'wyscout' ? 
                        <div className={classes.wyscoutSelect} onClick={ () => hadleSetSearchVideoBy('youtube') }> <Video /> </div>
                    :
                        <div className={classes.youtubeSelect} onClick={ () => hadleSetSearchVideoBy('wyscout') }> <Video /> </div>
                    }
                    
                    { searchVideoBy === 'youtube' && 
                    <div style={{flexGrow: 1, textAlign: 'center'}}>
                        youtube
                    </div>
                    }

                </div>

                { searchVideoBy === 'wyscout' ?
                    <div className={classes.inputSelect + ' content-margin-x'} style={{width: '262px'}}>
                        {getSelect( translate(user.lang, 'club videos'), clubs, 2)}
                    </div>
                :
                    <div className={classes.inputSelect + ' content-margin-x'} style={{width: '262px'}}>
                        <input ref={inputYoutube} type="text" className={classes.inputYoutube} defaultValue={videoListYoutube.value} ></input>
                    </div>
                }

                <div className={classes.inputSelect + ' content-margin-x'} style={{width: '262px', display: 'none'}}>
                    {getSelect('deporte', sports, 3)}
                </div>

                { videoList & typeof(videoList) === 'object' || searchVideoBy === 'youtube' ?          
                    <div className={classes.defaultButtom + ' default-buttom'} onClick={ () => handleCloseOpenWscoutVideos(true) }><span>{translate(user.lang, 'search match')}</span></div>
                    :
                    null
                }

            </div>

            { false &&         
                <div className="center-col">
                    <TextField id="import-youtube" label="Youtube resource..." 
                        value={videoMediaWScoutYoutube}
                        className={classes.inputContainerDefault}
                        InputLabelProps={{
                            className: classes.inputLabelDefault
                        }}
                        InputProps={{
                            className: classes.inputDefault
                        }}
                        onChange={ (e) => setVideoMediaWScoutYoutube(e.target.value) }
                    />
                </div>
            }

        </div>

        <div className={classes.resumenContainer}>

            <div className={classes.localContainer + ' ' + classes.boxLocal}>

                <h3 className={classes.resumenTitle}>{translate(user.lang, 'local')}</h3>

                {localClub && localClub.img_file ? 
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
                        <img alt="Logo local club" style={{width: '100%', margin: '15px 0'}} src={resourceApiEndPoint() + (localClub.img_file.charAt(0) === '/' ? localClub.img_file : '/' + localClub.img_file) }></img>
                    </div>
                :
                    null
                }

                { false &&                 
                    <div className={classes.inputSelect + ' content-margin-x'} style={{marginTop: 15}}>
                        {getSelect(translate(user.lang, 'country'), countries, 4)}
                    </div>
                }

                { sport ?                 
                    <div className={classes.inputSelect + ' content-margin-x'} style={{marginTop: 20}}>
                        {getSelect('club', clubs, 5 )}
                    </div>
                :
                    <div className={classes.inputSelect + ' content-margin-x'} style={{marginTop: 20, opacity: '.4'}}>
                        <span style={{width: '100%', textAlign: 'center', fontFamily: 'Open Sans', cursor: 'default', fontSize: '14.25px'}}>club</span>
                    </div>
                }

                { !localCategorySelect && 
                    <div className={classes.categorySubTitle}>{translate(user.lang, 'category')}</div>
                }

                { localClub ? 
                    <div className={classes.inputSelect + ' content-margin-x'} style={{marginTop: 20}}>
                        {getSelect( translate(user.lang, 'category'), localCategories, 6)}
                    </div>
                :
                    <div className={classes.inputSelect + ' content-margin-x'} style={{marginTop: 20, opacity: '.4'}}>
                        <span style={{width: '100%', textAlign: 'center', fontFamily: 'Open Sans', cursor: 'default', fontSize: '14.25px'}}>{translate(user.lang, 'category')}</span>
                    </div>
                }

                { localCategorySelect ? 
                    <div className={classes.inputSelect + ' content-margin-x'} style={{marginTop: 20}}>
                        {getSelect( translate(user.lang, 'team'), localTeams, 7)}
                    </div>
                :
                    <div className={classes.inputSelect + ' content-margin-x'} style={{marginTop: 20, opacity: '.4'}}>
                        <span style={{width: '100%', textAlign: 'center', fontFamily: 'Open Sans', cursor: 'default', fontSize: '14.25px'}}>{translate(user.lang, 'team')}</span>
                    </div>
                }

                { localClub &&
                <div style={{margin: '30px 0 0', position: 'relative'}}>

                    <div className={classes.turnField + (!localOnLeftToStart ? ' turnLeft': ' turnRight')} onClick={ () => handleSetLocalOnLeftToStart() }>
                        <IconButton aria-label="share">
                            <SettingsBackupRestoreIcon />
                        </IconButton>
                    </div>

                    { localOnLeftToStart ? 
                        <div style={{ width: '50px', position: 'absolute', top: '50%', transform: 'translate(-50%, -50%)', left: '25%'}}>
                            <img alt="Logo local club" style={{width: '100%', margin: '15px 0'}} src={resourceApiEndPoint() + (localClub.img_file.charAt(0) === '/' ? localClub.img_file : '/' + localClub.img_file) }></img>
                        </div>
                    :
                        <div style={{ width: '50px', position: 'absolute', top: '50%', transform: 'translate(-50%, -50%)', left: '75%'}}>
                            <img alt="Logo local club" style={{width: '100%', margin: '15px 0'}} src={resourceApiEndPoint() + (localClub.img_file.charAt(0) === '/' ? localClub.img_file : '/' + localClub.img_file) }></img>
                        </div>
                    }

                    <img src={field} alt="field" style={{width: '100%'}} />

                </div>
                }

            </div>

            <div className={classes.matchContainer  + ' ' + classes.boxDateMatch}>
                <h3 className={classes.resumenTitle}>{translate(user.lang, 'match data')}</h3>

                <div className={classes.inputSelectMatchContainer}>
                    <div className={ `${classes.inputSelectMatch  + ' content-margin-x'} ${classes.inputSelectMatchCup}` }>
                        <img src={cupIcon} style={{marginRight: '-5px'}} alt="selección de torneo" />
                        {getSelect( translate(user.lang, 'tournament'), competitions, 8)}
                    </div>
                    <div className={ `${classes.inputSelectMatch + ' content-margin-x'} ${classes.inputSelectMatchReferee}` }>
                        <img src={whistleIcon} style={{marginRight: '-5px', width: '35px'}} alt="selección de arbitro" />
                        {getSelect( translate(user.lang, 'referee'), top100Films, 9)}
                    </div>
                </div>

                <div className={classes.inputSelectMatchContainer}>
                    
                    <div className={ `${classes.inputSelectMatch  + ' content-margin-x'} ${classes.inputSelectMatchStadium}` }>
                        <img src={stadiumIcon} style={{marginRight: '-5px'}} alt="selección de estadium" />
                        {getSelect( translate(user.lang, 'stadium'), top100Films, 10)}
                    </div>


                    { false &&
                    <div className={ `${classes.inputSelectMatch  + ' content-margin-x'} ${classes.inputSelectMatchCalendar}` }>
                        <img src={calendarIcon} style={{marginRight: '-5px'}} alt="selección de fecha" />
                        {getSelect( translate(user.lang, 'date'), top100Films, 11)}
                    </div>
                    }

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{marginRight: 0}}
                            className={classes.keyboardDatePicker + ' ' + classes.inputSelectMatchCalendar}
                            margin="normal"
                            format="dd/MM/yyyy"
                            label={translate(user.lang, 'date')}
                            value={dateMatch}
                            onChange={handleDateMatchChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            keyboardIcon={<img src={calendarIcon} alt={translate(user.lang, 'select date')} />}
                        />
                        <KeyboardTimePicker
                            className={classes.keyboardDatePicker + ' ' + classes.inputSelectMatchCalendar}
                            margin="normal"
                            label={translate(user.lang, 'time')}
                            value={timeMatch}
                            onChange={handleTimeMatchChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                            keyboardIcon={<AccessAlarm />}
                        />
                    </MuiPickersUtilsProvider>

                    { false &&                     
                        <div className={ `${classes.inputSelectMatch  + ' content-margin-x'} ${classes.inputSelectMatchTime}` }>
                            <img src={timerIcon} style={{marginRight: '-5px'}} alt={ translate(user.lang, 'select time') } />
                            {getSelect( translate(user.lang, 'time'), top100Films, 12)}
                        </div>
                    }

                </div>   

                { videoMediaInfo && 
                    <div className="col margin-none-x space-between">
                        <div className="col margin-none">
                            <img className={classes.soccerBallIco} src={soccerBallIco} alt="soccer ball icon" />
                            <span className={classes.titleMatch}> {videoMediaInfo.subtitle} {videoMediaInfo.title}</span>
                        </div>
                        { false && 
                            <div className="col">
                                <div style={{marginRight: 15}}>---------------- barra de progreso ----------------</div>
                                <span>53'</span>
                            </div>
                        }
                        <div style={{margin: '0 15px'}} className="col align-items-center">
                            <img className={classes.clearIcon} src={clearIcon} alt="clear button" onClick={ removeMatchVideoSource } />
                        </div>
                        { false &&             
                            <div>
                                <div className="default-buttom"><span>preview match</span></div>
                            </div>
                        }
                    </div>
                }

                <div className={classes.mediaPlayerContainer} >
                    <ReactPlayer width={'100%'} url={videoMediaWScoutYoutube} playing={true} controls={true} />
                </div>
                
                { videoMediaWScoutYoutube && visitorTeam && localTeam && competitionsSelected &&
                    <div className={classes.defaultButtom + ' default-buttom'} style={{width: 'calc(100% - 30px)'}} onClick={() =>  handleSaveSetup()}><span>start analysis</span></div>
                }
            </div>

            <div className={classes.visitorContainer  + ' ' + classes.boxVisitor}>
                <h3 className={classes.resumenTitle}>{translate(user.lang, 'visitor')}</h3>

                {visitorClub && visitorClub.img_file ? 
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
                        <img alt="Logo visitor club" style={{width: '100%', margin: '15px 0'}} src={resourceApiEndPoint() + (visitorClub.img_file.charAt(0) === '/' ? visitorClub.img_file : '/' + visitorClub.img_file) }></img>
                    </div>
                :
                    null
                }

                { false &&
                <div className={classes.inputSelect + ' content-margin-x'} style={{marginTop: 15}}>
                    {getSelect( translate(user.lang, 'country'), countries, 13)}
                </div>
                }

                { sport ?                 
                    <div className={classes.inputSelect + ' content-margin-x'} style={{marginTop: 20}}>
                        {getSelect('club', clubs, 14)}
                    </div>
                :
                    <div className={classes.inputSelect + ' content-margin-x'} style={{marginTop: 20, opacity: '.4'}}>
                        <span style={{width: '100%', textAlign: 'center', fontFamily: 'Open Sans', cursor: 'default', fontSize: '14.25px'}}>club</span>
                    </div>
                }

                { !visitorCategorySelect && 
                    <div className={classes.categorySubTitle}>{translate(user.lang, 'category')}</div>
                }

                { visitorClub ? 
                    <div className={classes.inputSelect + ' content-margin-x'} style={{marginTop: 20}}>
                        {getSelect( translate(user.lang, 'category'), visitorCategories, 15)}
                    </div>
                :
                    <div className={classes.inputSelect + ' content-margin-x'} style={{marginTop: 20, opacity: '.4'}}>
                        <span style={{width: '100%', textAlign: 'center', fontFamily: 'Open Sans', cursor: 'default', fontSize: '14.25px'}}>{translate(user.lang, 'category')}</span>
                    </div>
                }

                { visitorCategorySelect ? 
                    <div className={classes.inputSelect + ' content-margin-x'} style={{marginTop: 20}}>
                        {getSelect( translate(user.lang, 'team'), visitorTeams, 16)}
                    </div>
                :
                    <div className={classes.inputSelect + ' content-margin-x'} style={{marginTop: 20, opacity: '.4'}}>
                        <span style={{width: '100%', textAlign: 'center', fontFamily: 'Open Sans', cursor: 'default', fontSize: '14.25px'}}>{translate(user.lang, 'team')}</span>
                    </div>
                }

                { visitorClub &&
                <div style={{margin: '30px 0 0', position: 'relative'}}>

                    <div className={classes.turnField + (localOnLeftToStart ? ' turnLeft': ' turnRight')} onClick={ () => handleSetLocalOnLeftToStart() }>
                        <IconButton aria-label="share">
                            <SettingsBackupRestoreIcon />
                        </IconButton>
                    </div>

                    { !localOnLeftToStart ? 
                        <div style={{ width: '50px', position: 'absolute', top: '50%', transform: 'translate(-50%, -50%)', left: '25%'}}>
                            <img alt="Logo visitor club" style={{width: '100%', margin: '15px 0'}} src={resourceApiEndPoint() + (visitorClub.img_file.charAt(0) === '/' ? visitorClub.img_file : '/' + visitorClub.img_file) }></img>
                        </div>
                    :
                        <div style={{ width: '50px', position: 'absolute', top: '50%', transform: 'translate(-50%, -50%)', left: '75%'}}>
                            <img alt="Logo visitor club" style={{width: '100%', margin: '15px 0'}} src={resourceApiEndPoint() + (visitorClub.img_file.charAt(0) === '/' ? visitorClub.img_file : '/' + visitorClub.img_file) }></img>
                        </div>
                    }

                    <img src={field} alt="field" style={{width: '100%'}} /> 
                </div>
                }

            </div>
        </div>
    </>
    );
};
export default React.memo(AnalysisVideoSetupComponent);