import React, { useRef, useState, useEffect } from "react";

import "./actionsToggle.css";
import { Link /*useLocation*/ } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import editIcon from "../../../assets/img/editBold.svg";
import { resourceApiEndPoint } from "../../../endpoints";
import VisibilityIcon from "@material-ui/icons/Visibility";
import videoIcon from "../../../assets/img/videocameraBold.svg";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
//import { get } from "lodash";

const actionToggle = (props) => {
  //const CLIENT_ENVIROMENT = process.env.REACT_APP_CLIENT_ENVIROMENT;
  //const location = useLocation();
  //const currentPath = location.pathname;
  const { user, item } = props;
  const [showActions, setShowActions] = useState(false);
  const [open, setOpen] = useState(false);
  const node = useRef();
  const handleClick = () => {
    setShowActions(!showActions);
  };
  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setShowActions(false);
  };
  const handleOpenDeleteModal = (player) => {
    setOpen(true);
  };
  const handleConfirmDeletePlayer = (player) => {
    setOpen(false);
    props.handleGoDeletePlayer(player);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getClubId = (item) => {
    let clubid = item.club_id
      ? item.club_id
      : item.wclub
      ? item.wclub._id
      : item.infoTeam
      ? item.infoTeam.club_id
      : null;
    return clubid;
  };

  let getRatesLink = (ratedUserId) => {
    // return (
    //   <li>
    //     <img src={starIcon} alt="" />
    //     <Link to={`/rating/${ratedUserId}`}>Votaciones</Link>
    //   </li>
    // );
  };

  useEffect(() => {
    if (showActions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showActions]);

  return (
    <div ref={node} className="table-actions-container">
      <div
        className={`table-actions-dots ${showActions ? "active" : ""}`}
        onClick={() => handleClick()}
      >
        <span className="actions-dot"></span>
        <span className="actions-dot"></span>
        <span className="actions-dot"></span>
      </div>

      {showActions && (
        <div className="table-actions-action">
          {/* {user.clubActive && user.clubActive._id && props.item.infoTeam && (
            // <li>
            //   <img src={videoIcon} alt="" />
            //   <Link to={`event-video-list?wplayer_id1=${props.item._id}`}>
            //     Análisis de video
            //   </Link>
            // </li>
          )} */}

          {user.clubActive && user.clubActive._id && props.item.infoTeam && (
            <li>
              <VisibilityIcon
                style={{ color: "#5FDBC2", margin: "0 5px 0 0" }}
              />
              <a
                href={`card-player/${props.item._id}`}
                target="_blank"
                onClick={() => setShowActions(false)}
              >
                Perfil de jugador
              </a>
            </li>
          )}

          {/* {[1, 2, 3, 4, 6].includes(parseInt(user.rolId)) &&
            user.clubActive &&
            user.clubActive._id &&
            user.clubActive._id === getClubId(props.item) && (
              <li onClick={() => props.handleGoToGps(props.item)}>
                <img src={gpsIcon} alt="GPS" />
                GPS
              </li>
            )} */}

          {/*[1, 2, 3, 4, 6].includes( parseInt(user.rolId) ) && CLIENT_ENVIROMENT === 'infinity' &&
            <li onClick={() => props.handleGoToGpexe()}>
              <img src={gpsIcon} alt='GPEXE' />
              GPEXE
            </li>
          */}

          {user.clubActive &&
            user.clubActive._id &&
            user.clubActive._id === getClubId(props.item) && (
              <>
                {user._id === props.item._id ? (
                  getRatesLink(props.item._id)
                ) : (
                  <>
                    {user.roleId !== 7 ? getRatesLink(props.item._id) : <></>}
                  </>
                )}
              </>
            )}

          {props.handleGoTagPlayer && (
            <>
              {user.rolId !== 7 ? (
                <></>
              ) : (
                <>{user._id === props.item._id && <></>}</>
              )}
            </>
          )}

          {props.handleEditUser && (
            <>
              <></>
              <li onClick={() => props.handleDeleteUser(props.item)}>
                <DeleteForeverIcon
                  style={{ color: "#5FDBC2", margin: "0 5px 0 0" }}
                />
                Eliminar
              </li>
            </>
          )}

          {[1, 2, 3].includes(parseInt(user.rolId)) &&
            user.clubActive &&
            user.clubActive._id &&
            user.clubActive._id === getClubId(item) && (
              <>
                <li onClick={() => props.handleGoEditPlayer(item)}>
                  <img src={editIcon} alt="Editar jugador" />
                  Editar
                </li>
                <li onClick={() => handleOpenDeleteModal(item)}>
                  <DeleteForeverIcon
                    style={{ color: "#5FDBC2", margin: "0 5px 0 0" }}
                  />
                  Eliminar
                </li>
              </>
            )}
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal-paper">
          <div>¿Seguro que desea eliminar a este jugador?</div>
          <div className="avatar">
            <img
              src={resourceApiEndPoint() + "/" + props.item.img_file}
              alt="profile"
            />
          </div>
          <div>{`${props.item.fullname}`}</div>
          <div className="button-group">
            <button onClick={() => handleConfirmDeletePlayer(props.item)}>
              Aceptar
            </button>
            <button onClick={handleClose}>Cancelar</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default actionToggle;
