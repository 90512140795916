import environment from "./base";

const BASEAPI = "https://infinity-api.gloouds.com/api";
const RESOURCE = "https://infinity-api.gloouds.com";
const DEMO = "AXBgsWnVdOi3yl7MMLTT";
const LOGO = "infinity";
const ENV = "infinity";
const params = { BASEAPI, RESOURCE, DEMO, LOGO, ENV };
const env = environment(params);
export default {
  ...env,
  intervals: {
    ...env.intervals
  },
  isProduction: true,
  isDevelopment: false
};
