import React, { useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import User from "./js/containers/User";
import Login from "./js/containers/Login";
import Admin from "./js/containers/Admin";
import Rating from "./js/containers/Rating";
import PlayerCard from "./js/containers/PlayerCard/PlayerCard";
import Modal from "@material-ui/core/Modal";
import Events from "./js/containers/Events";
import Layout from "./js/containers/Layout";
import GpsByTeam from "./js/containers/GpsByTeam";
import TagPlayer from "./js/containers/TagPlayer";
import AddTeam from "./js/containers/AddTeam/AddTeam";
import AddClub from "./js/containers/AddClub/AddClub";
import Competition from "./js/containers/Competition";
import GpsByPlayer from "./js/containers/GpsByPlayer";
import ListPlayers from "./js/containers/ListPlayers";
import AnalysisVideo from "./js/containers/AnalysisVideo";
import GpexeSession from "./js/containers/GpexeSession";
import GpexeTrackList from "./js/containers/GpexeTrackList";
import EventVideoList from "./js/containers/EventVideoList";
import ScoreBoard from "./js/components/Fixtures/ScoreBoard";
import AddPlayer from "./js/containers/AddPlayers/AddPlayers";
import SideNavBar from "./js/components/accessories/SideNavBar";
import CompetitionSetup from "./js/containers/CompetitionSetup";
import AnalysisVideoList from "./js/containers/AnalysisVideoList";
import EventInfo from "./js/components/Forms/EventForm/EventInfo";
import GpexeSessionsList from "./js/containers/GpexeSessionsList";
import CompetitionFixture from "./js/containers/CompetitionFixture";
import AnalysisVideoSetup from "./js/containers/AnalysisVideoSetup";
import { getObjectFromArrayWithKeysByProperty } from "./js/services";
import { getUser, getCountries, getTeambyClubId } from "./js/endpoints";
import ProtectedRoute from "./js/components/ProtectedRoute/ProtectedRoute";
import DirectorsForm from "./js/components/Forms/EventForm/EventDirectors";
import EventCompetitions from "./js/components/Forms/EventForm/EventCompetitions";
import EventOrganization from "./js/components/Forms/EventForm/EventOrganization";
import EventConfirmation from "./js/components/Forms/EventForm/EventConfirmation";
import FilesFolders from "./js/containers/FilesFolders";

import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";

import {
  update_user,
  login_modal,
  logout_user,
  getAllCountriesNames,
} from "./js/actions/index";

function mapDispatchToProps(dispatch) {
  return {
    set_update_user: (user) => dispatch(update_user(user)),
    set_logout_user: (user) => dispatch(logout_user(user)),
    set_countries_names: (countriesNames) =>
      dispatch(getAllCountriesNames(countriesNames)),
    set_login_modal: (loginModal) => dispatch(login_modal(loginModal)),
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    loginModal: state.loginModal,
    countriesNames: state.countriesNames,
  };
};

const paper = {
  top: "50%",
  left: "50%",
  display: "flex",
  padding: "15px",
  minWidth: "50vw",
  border: "2px solid",
  position: "absolute",
  justifyContent: "center",
  backgroundColor: "#19191D",
  transform: "translate(-50%, -50%)",
};

const AppIndex = (props) => {
  const [teamOfClub, setTeamOfClub] = useState([]);

  const existToken = () => {
    return localStorage.getItem("apikey") !== null;
  };

  const { user, loginModal } = props;
  const propertyRef = useRef(props);
  const openSideNavBar = true;

  //REFACTOR ID USER
  async function verifyUser() {
    if (localStorage.getItem("userId") && localStorage.getItem("apikey")) {
      let response = await getUser(localStorage.getItem("userId"));
      if (response && response.status === 200) {
        let userTemp = {
          ...user,
          ...response.data.response,
          roleId: response.data.response.roleId * 1,
          rolId: response.data.response.roleId * 1,
        };
        if (!user.clubActive && user.wclubs && user.wclubs.length > 0) {
          userTemp = { ...userTemp, clubActive: user.wclubs[0] };
          propertyRef.current.set_update_user(userTemp);
        }
        propertyRef.current.set_update_user(userTemp);
      } else {
        console.log("error");
        localStorage.removeItem("apikey");
        propertyRef.current.set_update_user(null);
      }
    }
  }

  async function getAllCountriesName() {
    let countries = await getCountries();
    if (countries && countries.status === 200) {
      countries = getObjectFromArrayWithKeysByProperty(
        countries.data.response,
        "wid"
      );
      propertyRef.current.set_countries_names(countries);
    }
    //console.log("error en countries");
  }

  const handleLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("apikey");
    propertyRef.current.set_logout_user({});
  };

  useEffect(() => {
    verifyUser();
    getAllCountriesName();
  }, []);

  useEffect(() => {
    if (user && user.clubActive) {
      getTeambyClubId(user.clubActive._id).then((response) => {
        if (response.data) {
          setTeamOfClub(response.data.response);
        }
      });
    }
  }, [user]);

  return (
    <>
      <Router>
        {existToken() ? (
          <>
            <SideNavBar
              user={user}
              teamOfClub={teamOfClub}
              handleLogout={handleLogout}
              handleLoginModal={propertyRef.current}
            />

            <Switch>
              {/* roles: 1-8 */}
              <Route
                exact
                path="/card-player/:id"
                render={(props) => (
                  <PlayerCard
                    openSideBar={false}
                    user={user}
                    {...props}
                    handleLogout={handleLogout}
                  />
                )}
              ></Route>

              <Layout user={user}>
                {/* roles: all*/}
                <Route exact path="/analysis-video">
                  <AnalysisVideoList user={user} />
                </Route>

                {/* roles: 1-6, 8 */}
                <Route exact path="/analysis-video/settings/setup">
                  <AnalysisVideoSetup
                    user={user}
                    openSideBar={openSideNavBar}
                  />
                </Route>

                {/* roles: 1-6, 8 */}
                <Route
                  exact
                  path="/analysis-video/:analysisId"
                  render={(props) => (
                    <AnalysisVideo
                      {...props}
                      openSideBar={openSideNavBar}
                      user={user}
                    />
                  )}
                />

                {/* roles: 1-8 */}
                <Route exact path="/list-players">
                  <ListPlayers openSideBar={openSideNavBar} user={user} />
                </Route>

                {/* roles: 1-8 */}
                <Route
                  exact
                  path="/card-player/:id/event-video-list"
                  render={(props) => <EventVideoList {...props} user={user} />}
                />

                {/* roles: 1-8 */}
                <Route
                  exact
                  path="/tag-player/:playerId"
                  render={(props) => <TagPlayer {...props} user={user} />}
                />

                {/* roles: 2 */}
                {/*<Route exact path="/edit-player/:playerId" component={EditPlayer} />*/}
                <Route
                  exact
                  path="/edit-player/:playerId"
                  render={(props) => <AddPlayer {...props} user={user} />}
                />

                {/* roles: 1-6, 8 */}
                <Route exact path="/gps-player">
                  <GpsByPlayer />
                </Route>

                {/* roles: 1-6, 8 */}
                <Route
                  exact
                  path="/card-player/:id/gps/team/:teamId"
                  render={(props) => <GpsByTeam {...props} user={user} />}
                />

                {/* roles: 1-8 */}
                <Route exact path="/competition">
                  <Competition />
                </Route>

                {/* roles: 1-6, 8 */}
                <Route exact path="/competition/setup">
                  <CompetitionSetup />
                </Route>

                {/* roles: 1-8 */}
                <Route exact path="/events">
                  <Events />
                </Route>

                {/* roles: 1-2 */}
                <Route exact path="/formEventInfo">
                  <EventInfo />
                </Route>

                {/* roles: 1-2 */}
                <Route exact path="/formEventOrganization">
                  <EventOrganization />
                </Route>

                {/* roles: 1-2 */}
                <Route exact path="/formEventDirectors">
                  <DirectorsForm />
                </Route>

                {/* roles: 1-2 */}
                <Route exact path="/formEventConfirmation">
                  <EventConfirmation />
                </Route>

                {/* roles: 1-2 */}
                <Route exact path="/formEventCompetition">
                  <EventCompetitions />
                </Route>

                <Route exact path="/competition/fixture">
                  <CompetitionFixture />
                </Route>

                <Route exact path="/scoreBoard">
                  <ScoreBoard />
                </Route>

                {/* roles: 1-8 */}
                <Route
                  exact
                  path={"/myfolders"}
                  render={(props) => <FilesFolders user={user} {...props} />}
                />

                <Route
                  exact
                  path={"/card-player/:id/myfolders"}
                  render={(props) => <FilesFolders user={user} {...props} />}
                />

                {/* roles: 1-8 */}
                <Route exact path={"/myaccount"}>
                  <User />
                </Route>

                {/* roles: 1-8 */}
                <Route
                  exact
                  path={"/user-account/:userId"}
                  render={(props) => <User {...props} user={user} />}
                />

                {/* roles: 1*/}
                <ProtectedRoute
                  user={user}
                  exact
                  path={"/users"}
                  component={Admin}
                />

                <Route
                  exact
                  path="/gpexe/tracks"
                  render={(props) => (
                    <GpexeTrackList
                      {...props}
                      user={user}
                      teamOfClub={teamOfClub}
                    />
                  )}
                />

                <Route
                  exact
                  path="/gpexe/sessions"
                  render={(props) => (
                    <GpexeSessionsList
                      {...props}
                      user={user}
                      teamOfClub={teamOfClub}
                    />
                  )}
                />

                <Route
                  exact
                  path="/gpexe/sessions/:sessionId"
                  render={(props) => <GpexeSession {...props} user={user} />}
                />

                {/* roles: 1-8 */}
                <Route
                  exact
                  path="/card-player/:id/rating/:playerId"
                  render={(props) => <Rating {...props} user={user} />}
                />

                {/* roles: 1-2 */}
                <Route
                  exact
                  path="/add-player"
                  render={(props) => <AddPlayer {...props} user={user} />}
                />

                {/* roles: 1 */}
                <Route exact path="/add-club">
                  <AddClub user={user} />
                </Route>

                {/* roles: 1-2 */}
                <Route
                  exact
                  path="/add-team"
                  render={(props) => <AddTeam {...props} user={user} />}
                />

                {/* roles: 1-8 */}
                <Route exact path={"/"}>
                  <Redirect to={"/list-players"} />
                </Route>

                <Route exact path={"/login"}>
                  <Login
                    user={user}
                    handleLoginModal={propertyRef.current}
                    component={Login}
                  />
                </Route>
              </Layout>
            </Switch>
          </>
        ) : (
          <Route path={"/"}>
            <Login
              user={user}
              handleLoginModal={propertyRef.current}
              component={Login}
            />
          </Route>
        )}

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={loginModal ? loginModal : false}
          onClose={() => propertyRef.current.set_login_modal(false)}
        >
          <div style={paper}>
            <Login handleLoginModal={propertyRef.current} />
          </div>
        </Modal>
      </Router>
    </>
  );
};
const App = connect(mapStateToProps, mapDispatchToProps)(AppIndex);
export default React.memo(App);
