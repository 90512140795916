import React, { useState, useEffect } from "react";

import UserVoter from "../components/ratings/UserVoter";
import RatingComponent from "../components/RatingComponent";
import GlobalLoading from "../components/accessories/GlobalLoading";
import {
  getRoles,
  getStructSkill,
  getWPlayerById,
  createUpdateRate,
  getStatsByPlayers,
  getStatsStaffRoles,
  getStatsStaffRolUsers,
  getGeneralSkillsProgress,
  getPrimarySkillsProgress,
  getSecundarySkillsProgress,
  getstatsbyuservoteridanduservotedid,
  getRatesByUserVoterIDAndUserVotedID,
  getSkillsByRoleVoterIdAndRoleVotedId,
} from "../endpoints";

import {
  getObjectFromArrayWithKeysByProperty,
  debounce,
  getColorsByOptions,
} from "../services";
import RadarGraphyRates from "../components/charts/RadarGraphyRates";
import StaffTable from "../components/ratings/StaffTable";
import PeerVotes from "../components/ratings/PeerVotes";
import GraphyRatesHistorical from "../components/charts/GraphyRatesHistorical";
import NavCardPlayer from "../components/NavCardPlayer/NavCardPlayer";

const Rating = (props) => {
  const { user } = props;

  const [player, setPlayer] = useState(null);
  const [statsPlayer, setStatsPlayer] = useState(null);
  const [skillsVoted, setSkillsVoted] = useState([]);
  const [globalLoading, setGlobalLoading] = useState(true);
  const [ratingType, setRatingType] = useState(true); // true Last Rating false historical rating
  const [statsPerformanceActive, setStatePerformanceActive] = useState("user"); // user; self; staff; peer
  const [lastStatsPerformanceActive, setLastStatsPerformanceActive] = useState(
    "user"
  );
  const [voteChage, setVoteChange] = useState(false);
  const [structSkill, setStructSkill] = useState(null);
  const [staffTable, setStaffTable] = useState({});
  const [roles, setRoles] = useState(null);
  const [modalRate, setModalRate] = useState(false);
  const [radarChartRates, setRadarChartRates] = useState(null);
  const [statsByPlayers, setStatsByPlayers] = useState(null);
  const [view, setView] = useState(1);

  const selectTypeRating = (val) => {
    setRatingType(val);
  };

  const handleSetSkillsVoted = (newSkillsVoted) => {
    setSkillsVoted(newSkillsVoted);
    setVoteChange(true);
  };

  const handleSaveVotes = async () => {
    setGlobalLoading(true);

    let skill_id_rate = "";
    skillsVoted.forEach((parent) => {
      parent.skills.forEach((child) => {
        skill_id_rate += skill_id_rate
          ? "&" + child.id + "=" + child.rate
          : child.id + "=" + child.rate;
      });
    });

    //let params = {skill_id_rate: JSON.stringify( skill_id_rate) }
    let params = { skill_id_rate: skill_id_rate };
    let response = await createUpdateRate(user._id, player._id, params);
    if (response && response._shards && response._shards.failed === 0) {
      debounce(refreshStatsUserVoterAndVoted, 1000);
      debounce(setVoteChange, 1000, false);
    } else {
      console.log("error");
    }
    //console.log(response);
    debounce(setGlobalLoading, 1000, false);
  };

  const handleBuildChartHistorical = async (voter, voted) => {
    console.log(voter, voted);

    setGlobalLoading(true);

    let generalSkill = await getGeneralSkillsProgress({
      user_voter_id: voter,
      user_voted_id: voted,
      period: "1d",
    });
    let colorsGeneralSkills = getColorsByOptions([0]);
    let XAxisTemp = 0;
    if (generalSkill) {
      generalSkill = generalSkill.data.response.map((XAxis) => {
        if (XAxis.avg_of_rates.value) {
          XAxisTemp = XAxis.avg_of_rates.value.toFixed(2);
        }
        return { name: XAxis.key_as_string, general: Number(XAxisTemp) };
      });
    } else {
      setGlobalLoading(false);
      return false;
    }

    let primarySkill = await getPrimarySkillsProgress({
      user_voter_id: voter,
      user_voted_id: voted,
      period: "1d",
    });
    let XAxisNameTemp = primarySkill.data[0].progress.map((XAxis) => {
      return { name: XAxis.key_as_string };
    });

    let colorsPrimarySkill = getColorsByOptions(primarySkill.data);
    XAxisTemp = 0;
    primarySkill.data.forEach((primary) => {
      primary.progress.forEach((keyString, key) => {
        if (keyString.avg_of_rates.value) {
          XAxisTemp = keyString.avg_of_rates.value.toFixed(2);
        }
        XAxisNameTemp[key] = {
          ...XAxisNameTemp[key],
          [primary.primarySkillName]: Number(XAxisTemp),
        };
      });
    });

    let linesPrimary = [];

    let secundarySkill = await Promise.all(
      primarySkill.data.map(async (skill) => {
        linesPrimary.push(skill.primarySkillName);
        let response = await getSecundarySkillsProgress({
          user_voter_id: voter,
          user_voted_id: voted,
          period: "1d",
          primary_skill_id: skill.primarySkillId,
        });

        // let XAxisSkillChild = response.data[0].progress.map( XAxis => {
        //     return {name: XAxis.key_as_string };
        // });

        let linesSecundary = response.data.map((childSkills) => {
          return childSkills.secondarySkillName;
        });

        let colorsSecundarySkill = getColorsByOptions(linesSecundary);

        let XAxisSecundaryNameTemp = response.data[0].progress.map((XAxis) => {
          return { name: XAxis.key_as_string };
        });
        XAxisTemp = 0;
        response.data.forEach((secundary) => {
          secundary.progress.forEach((keyString, key) => {
            if (keyString.avg_of_rates.value) {
              XAxisTemp = keyString.avg_of_rates.value.toFixed(2);
            }
            XAxisSecundaryNameTemp[key] = {
              ...XAxisSecundaryNameTemp[key],
              [secundary.secondarySkillName]: Number(XAxisTemp),
            };
          });
        });

        return {
          skillParent: skill.primarySkillName,
          skills: {
            lines: linesSecundary,
            colors: colorsSecundarySkill,
            data: XAxisSecundaryNameTemp,
          },
        };
      })
    );

    setRadarChartRates([
      {
        name: "general skills progress",
        data: generalSkill,
        colors: colorsGeneralSkills,
        lines: ["general"],
      },
      {
        name: "primary skills progress",
        data: XAxisNameTemp,
        colors: colorsPrimarySkill,
        lines: linesPrimary,
      },
      { name: "secundary skills progress", skills: secundarySkill },
    ]);
    setModalRate(true);
    setGlobalLoading(false);
  };

  const handleBuildChart = async (voter, voted) => {
    setGlobalLoading(true);
    let userAverageSkillVoted = await getRatesByUserVoterIDAndUserVotedID(
      voter,
      voted
    );
    if (userAverageSkillVoted.status === 200) {
      userAverageSkillVoted = userAverageSkillVoted.data.hits.hits.map(
        (skill) => {
          return skill._source;
        }
      );
      userAverageSkillVoted = getObjectFromArrayWithKeysByProperty(
        userAverageSkillVoted,
        "skill_id"
      );
      //console.log(structSkill);
      //console.log(userAverageSkillVoted);
      let radarChart = Object.keys(structSkill).map((key) => {
        let rateParent = 0;
        let avg = 0;
        let skills = structSkill[key].skills.map((childSkill, index) => {
          // console.log(index, childSkill)
          // console.log(userAverageSkillVoted[childSkill.id].rate)
          let rateTemp = userAverageSkillVoted[childSkill.id]
            ? userAverageSkillVoted[childSkill.id].rate
            : 0;
          if (
            userAverageSkillVoted[childSkill.id] &&
            userAverageSkillVoted[childSkill.id].rate > 0
          ) {
            avg++;
            rateParent += rateTemp;
          }
          return {
            ...userAverageSkillVoted[childSkill.id],
            rate: rateTemp,
            name: childSkill.name,
          };
        });
        //console.log(rateParent)
        return {
          ...structSkill[key],
          rate: avg > 0 ? rateParent / avg : 0,
          skills: skills,
        };
      });
      //setLastStatsPerformanceActive(lastStatsPerformanceActive);
      setRadarChartRates(radarChart);
      setModalRate(true);
      //console.log(radarChart);
    } else {
      console.log("error");
    }
    setGlobalLoading(false);
  };

  const handleBuildStaffTable = async () => {
    setGlobalLoading(true);
    let staffTableTemp = staffTable;
    let response = await getStatsStaffRoles(player._id);
    if (response.status === 200) {
      let count = Object.keys(response.data).length;
      Object.entries(response.data).forEach(async ([key, staff]) => {
        if (key !== "error") {
          staffTableTemp = {
            ...staffTableTemp,
            [key]: { ...staff, users: null, name: roles[staff.roleId].name },
          };
          response = await getStatsStaffRolUsers(player._id, staff.roleId);
          if (response.status === 200 && response.data.error === 0) {
            staffTableTemp = {
              ...staffTableTemp,
              [key]: {
                ...staff,
                users: response.data.users,
                name: roles[staff.roleId].name,
              },
            };
          }
        }
        count = count - 1;
        if (count === 0) {
          //console.log(staffTableTemp)
          setStaffTable(staffTableTemp);
        }
      });
    } else {
      console.log("error");
    }
    setGlobalLoading(false);
  };

  const handleBuildStatsByPlayers = async () => {
    setGlobalLoading(true);
    let response = await getStatsByPlayers(player._id);
    if (response.status === 200) {
      setStatsByPlayers(response.data.players);
    } else {
      console.log("error");
    }
    setGlobalLoading(false);
  };

  const handleSelectStatsPerformanceActive = (type) => {
    switch (type) {
      case "user":
        if (voteChage & (statsPerformanceActive === "user")) {
          handleSaveVotes();
        } else if (statsPerformanceActive === "user") {
          if (ratingType) {
            handleBuildChart(user._id, player._id);
          } else {
            handleBuildChartHistorical(user._id, player._id);
          }
        }
        setStatePerformanceActive(type);
        break;
      case "self":
        if (ratingType) {
          handleBuildChart(player._id, player._id);
        } else {
          handleBuildChartHistorical(player._id, player._id);
        }
        break;
      case "staff":
        setLastStatsPerformanceActive("staff");
        setStatePerformanceActive(type);
        handleBuildStaffTable();
        break;
      default:
        setLastStatsPerformanceActive("peer");
        setStatePerformanceActive(type);
        handleBuildStatsByPlayers();
    }
  };
  const showRateSection = () => {
    switch (statsPerformanceActive) {
      case "user":
        return (
          <UserVoter
            skillsVoted={skillsVoted}
            handleSetSkillsVoted={handleSetSkillsVoted}
            user={user}
            player={player}
          />
        );
      case "self":
        let comp =
          lastStatsPerformanceActive === "user" ? (
            <UserVoter
              skillsVoted={skillsVoted}
              handleSetSkillsVoted={handleSetSkillsVoted}
              user={user}
            />
          ) : lastStatsPerformanceActive === "staff" ? (
            <h1>Staff rating</h1>
          ) : (
            <h1>Peer rating</h1>
          );
        return comp;
      case "staff":
        return (
          <StaffTable
            staffTable={staffTable}
            handleBuildChart={handleBuildChart}
            handleBuildChartHistorical={handleBuildChartHistorical}
            player={player}
            ratingType={ratingType}
          />
        );
      default:
        return (
          <PeerVotes
            statsByPlayers={statsByPlayers}
            player={player}
            handleBuildChart={handleBuildChart}
            handleBuildChartHistorical={handleBuildChartHistorical}
            ratingType={ratingType}
          />
        );
    }
  };

  const refreshStatsUserVoterAndVoted = async () => {
    let response = await getstatsbyuservoteridanduservotedid({
      user_voter_id: user._id,
      user_voted_id: props.match.params.playerId,
    });

    if (response && response.status === 200) {
      setStatsPlayer(response.data);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      refreshStatsUserVoterAndVoted();

      let userAverageSkillVoted = await getRatesByUserVoterIDAndUserVotedID(
        user._id,
        player._id
      );
      if (userAverageSkillVoted.status === 200) {
        //console.log(userAverageSkillVoted)
        userAverageSkillVoted = userAverageSkillVoted.data.hits.hits.map(
          (skill) => {
            return skill._source;
          }
        );
        userAverageSkillVoted = getObjectFromArrayWithKeysByProperty(
          userAverageSkillVoted,
          "skill_id"
        );
      } else {
        console.log("error");
      }

      let skillsUserVoted = await getSkillsByRoleVoterIdAndRoleVotedId({
        role_voter_id: parseInt(user.roleId),
        role_voted_id: player.roleId ? player.roleId : 7,
      });

      if (
        (skillsUserVoted.status === 200) &
        (skillsUserVoted.data.length > 0)
      ) {
        skillsUserVoted = skillsUserVoted.data.map((skillsParent) => {
          let average = 0;
          return {
            ...skillsParent,
            skills: skillsParent.skills.map((skill) => {
              average = userAverageSkillVoted.skill_id
                ? userAverageSkillVoted.skill_id.rate + average
                : average;
              return {
                ...skill,
                rate: userAverageSkillVoted[skill.id]
                  ? userAverageSkillVoted[skill.id].rate
                  : 0,
              }; // colocar el verdadero rate o por defecto 0
            }),
            average: average,
          };
        });
        setSkillsVoted(skillsUserVoted);
        let colSkillVoted = null;

        if (skillsUserVoted && skillsUserVoted.length) {
          colSkillVoted = skillsUserVoted.map((skill) => {
            let boxWidth =
              skill.skills.length < 5
                ? 1
                : skill.skills.length < 9
                ? 2
                : skill.skills.length < 13
                ? 3
                : skill.skills.length < 17
                ? 4
                : 5;
            //console.log(boxWidth);
            return { skill: skill, boxWidth: boxWidth }; //<div key={'skill-'+skill.name}>{boxWidth}</div>;
          });
        } else {
          console.log("error");
        }

        let breakSave = 0;
        let newColSkillVoted = [];
        let activeColPosition = 0;
        let colSkillVotedPosition = 0;

        do {
          breakSave++;
          if (colSkillVoted.length < 3) {
            colSkillVoted.forEach((colSkill) => {
              newColSkillVoted.push({
                ...colSkill.skill,
                cols: colSkill.boxWidth,
              });
            });
            break;
          } else {
            if (colSkillVotedPosition > colSkillVoted.length) {
              colSkillVotedPosition = 0;
            } else {
              if (
                activeColPosition !== colSkillVotedPosition &&
                colSkillVoted[activeColPosition] &&
                colSkillVoted[colSkillVotedPosition] &&
                colSkillVoted[activeColPosition].boxWidth +
                  colSkillVoted[colSkillVotedPosition].boxWidth ===
                  4
              ) {
                newColSkillVoted.push(
                  {
                    ...colSkillVoted[activeColPosition].skill,
                    cols: colSkillVoted[activeColPosition].boxWidth,
                  },
                  {
                    ...colSkillVoted[colSkillVotedPosition].skill,
                    cols: colSkillVoted[colSkillVotedPosition].boxWidth,
                  }
                );
                colSkillVoted.splice(colSkillVotedPosition, 1);
                colSkillVoted.splice(activeColPosition, 1);
                activeColPosition = 0;
                colSkillVotedPosition = 0;
              }
              colSkillVotedPosition++;
            }
          }
        } while (breakSave < 50);
        // setSkillsVoted(newColSkillVoted);
      } else {
        console.log("error or this role do not have skill to vote");
      }
      /* reorder column to show */

      setTimeout(function() {
        setGlobalLoading(false);
      }, 2000);
    };
    if (player) {
      fetchData();
    }
  }, [player]);

  useEffect(() => {
    // if(player){
    //     handleSelectStatsPerformanceActive(statsPerformanceActive);
    // }
  }, [ratingType]);

  useEffect(() => {
    async function handleGetPlayerById() {
      let response = await getWPlayerById(props.match.params.playerId);
      // console.log(response);
      // console.log(user);
      if (response && response.status === 200) {
        setPlayer(response.data.response);
      } else {
        console.log("error");
      }
      response = await getStructSkill();

      if (response && response.status === 200) {
        setStructSkill(response.data);
      } else {
        console.log("error");
      }
      response = await getRoles();

      if (response && response.status === 200) {
        setRoles(
          getObjectFromArrayWithKeysByProperty(response.data.response, "id")
        );
      } else {
        console.log("error");
      }
    }
    handleGetPlayerById();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavCardPlayer {...props} view={view} setView={setView} />
      <RatingComponent
        player={player}
        voteChage={voteChage}
        ratingType={ratingType}
        statsPlayer={statsPlayer}
        showRateSection={showRateSection}
        selectTypeRating={selectTypeRating}
        statsPerformanceActive={statsPerformanceActive}
        lastStatsPerformanceActive={lastStatsPerformanceActive}
        selectPerformanceActive={handleSelectStatsPerformanceActive}
        user={user}
      />
      {ratingType ? (
        <RadarGraphyRates
          open={modalRate}
          handleClose={setModalRate}
          stats={radarChartRates}
        />
      ) : (
        <GraphyRatesHistorical
          open={modalRate}
          handleClose={setModalRate}
          stats={radarChartRates}
        />
      )}
      {globalLoading && <GlobalLoading />}
    </>
  );
};
export default Rating;
