import React from "react";
import environment from "environment";

import "date-fns";
import { translate } from "../lang/lang";
import DateFnsUtils from "@date-io/date-fns";
import Avatar from "@material-ui/core/Avatar";
import { resourceApiEndPoint } from "../endpoints";
import weatherIcon from "../assets/img/weather.png";
import TextField from "@material-ui/core/TextField";
import Backdrop from "../components/modals/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import GetGraphyGetTeamByDate from "./charts/GetGraphyGetTeamByDate";
import GetTeamEnergyExpediture from "./charts/GetTeamEnergyExpediture";
import GetGraphyGetTeamByPlayer from "./charts/GetGraphyGetTeamByPlayer";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import GetGraphyGetTeamByExercise from "./charts/GetGraphyGetTeamByExercise";
import GpsOnboarding from "../components/modals/GpsOnboarding";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
//import MetricsOptions from './modals/MetricsOptions';

const useStyles = makeStyles((theme) => ({
  selectOptionsGpsContainer: {
    padding: "45px 15px 15px",
    width: "calc(100% - 30px)",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },

  optionGps: {
    display: "flex",
    alignSelf: "flex-end",
    marginTop: "40px",
  },

  chartContainer: {
    margin: "15px 0",
    width: "calc(100% - 30px)",
    display: "flex",
    position: "relative",
    flexWrap: "wrap",
    "& .measurementSelected": {
      fontSize: "12px",
    },
    "& .recharts-wrapper": {
      background: "transparent",
    },
    "& .recharts-text": {
      fill: "#ffffff",
    },
    "& .recharts-xAxis": {
      transform: "translateY(10px)",
    },
    "& .recharts-tooltip-cursor": {
      fill: "transparent",
    },
    "& .recharts-surface": {
      overflow: "visible",
    },
    "& .recharts-xAxis line": {
      transform: "translateY(-10px)",
    },
  },
  metricsContainer: {
    height: "374px",
    width: "219.45px",
    borderRadius: "3.84px",
    border: "1px solid #979797",
    backgroundColor: "rgba(21, 23, 25, 0.78)",

    "& .metricOptions": {
      height: "50px",
      margin: "5px 0",
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#ffffff0d",
      position: "relative",
      borderLeft: "0px solid #1EB9D3",
      transition: "all .2s",

      "&::after": {
        content: '""',
        position: "absolute",
        width: "97.7%",
        top: "100%",
        left: "0",
        border: "2.5px solid #707070",
      },
    },
    "& .metricOptionsSelected": {
      height: "50px",
      margin: "5px 0",
      display: "flex",
      color: "#00BBD6",
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#ffffff0d",
      position: "relative",
      borderLeft: "3px solid #1EB9D3",
      transition: "all .2s",

      "&::after": {
        content: '""',
        position: "absolute",
        width: "98.9%",
        top: "100%",
        left: "0",
        border: "2.5px solid #707070",
        transform: "translateX(-3px)",
      },
    },
    "& div:nth-child(2)": {
      marginTop: "0px",
    },
    "& div:last-child": {
      marginBottom: "0px",
    },
    "& .measurementName": {
      textAlign: "center",
      fontSize: "12px",
      padding: "0 5px",
    },
    "& .measurementUnit": {
      textAlign: "center",
      fontSize: "12px",
      color: "#A4A4A4",
    },
  },
  moreMetrics: {
    height: "48px",
    width: "220px",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      fontSize: "15px",
      fontWeight: "700",
      fontFamily: "Montserrat",
      textTransform: "uppercase",
    },
  },
  metricsHead: {
    fontFamily: "DINCondensed",
    fontSize: "23.75px",
    fontWeight: 700,
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#393C3E",
    borderRadius: "3.84px 3.84px 0 0",
  },
  weaterContainer: {
    height: "200px",
    width: "219.45px",
    margin: "15px 0 0",
    background: "#16181c",
    borderRadius: "3.84px",
    border: "1px solid #979797",
  },
  keyboardDatePickerWeather: {
    transition: "all .3s",
    textTransform: "uppercase",
    "& .MuiInputLabel-shrink": {
      transform: "translate(15px, 5px) scale(0.75)",
      transformOrigin: "top left",
      color: "#ffffff",
      fontSize: "17.96px",
    },
    "& .MuiInputBase-input": {
      color: "#fff",
      textAlign: "center",
      padding: "0 0 0 55px",
    },
    "& .makeStyles-keyboardDatePicker-20 button": {
      transform: "scale(1.2) translateX(-30px)",
    },
    "& label + .MuiInput-formControl": {
      marginTop: "0",
    },
    "& .MuiIconButton-root": {
      transform: "translateX(-30px)",
    },
    "& .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
  },
  keyboardDatePicker: {
    width: "219px",
    margin: "0 15px",
    height: "40px",
    transition: "all .3s",
    borderRadius: "8px",
    textTransform: "uppercase",
    backgroundColor: "#3A3C3E",
    "& label": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInputBase-input": {
      color: "#fff",
      textAlign: "center",
    },
    "& .MuiInput-formControl": {
      marginTop: "5px",
      height: "100%",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(0px, -25px) scale(0.75)",
      transformOrigin: "top left",
      fontFamily: "DINCondensed",
      fontSize: "19px",
      fontWeight: 700,
      textTransform: "capitalize",
    },
    "& .MuiFormLabel-root": {
      fontSize: "19px",
    },
    "& .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& button": {
      transform: "scale(0.8) translateY(-4px)",
    },
  },
  selectPlayersContainer: {
    width: "100%",
    border: "0.5px solid #4a4848",
    borderRadius: "3.6px",
    backgroundColor: "rgba(21, 23, 25, 0.78)",

    "& .MuiAvatar-root img": {
      position: "relative",
      top: "0",
      height: "auto",
      width: "calc(100% - 15px)",
    },
    "& .positionSelectContainer": {
      padding: "20px",
    },
  },
  inputSelect: {
    width: "100%",
    height: "40px",
    display: "flex",
    fontWeight: 700,
    borderRadius: "8px",
    alignItems: "center",
    transition: "all .3s",
    backgroundColor: "#3A3C3E",
    textTransform: "uppercase",
    justifyContent: "space-between",
    "& select option": {
      color: "red",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiFormLabel-root": {
      width: "100%",
      color: "#ffffff",
      overflow: "hidden",
      textAlign: "center",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    "& .MuiInputLabel-outlined": {
      fontFamily: "Open Sans",
      transition: "all .3s",
      transform: "translate(-50%, 12px) scale(1)",
      left: "50%",
      fontSize: "17.96px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(-50px, -25px) scale(0.75) !important",
      left: 0,
      transition: "all .3s",
      fontFamily: "DINCondensed",
      fontSize: "19px",
      fontWeight: 700,
      textTransform: "capitalize",
    },
    "& .MuiAutocomplete-endAdornment": {
      background: "#3a3c3e",
      opacity: 0,
    },
    "&:hover .MuiAutocomplete-endAdornment": {
      opacity: 1,
    },
    "& .backNextOption:hover": {
      color: "#1BDDDB",
      cursor: "pointer",
      transition: "all .3s",
      transform: "scale(1.8)",
    },
    "& .MuiAutocomplete-root": {
      margin: 0,
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: 0,
      background: "transparent",
    },
    "& .MuiAutocomplete-input": {
      margin: 0,
      width: "100%",
      color: "#ffffff",
      overflow: "hidden",
      borderRadius: "8px",
      fontSize: "17.96px",
      textAlign: "center",
      whiteSpace: "nowrap",
      background: "#3a3c3e",
      textOverflow: "ellipsis",
      fontFamily: "Open Sans",
      textTransform: "uppercase",
      font: "initial",
    },
    "@media (max-width: 576px)": {
      width: "100%",
      flexDirection: "row",
    },
    "& > span": {
      fontFamily: "montserrat",
      fontWeight: 600,
    },
  },
  gpsContainer: {
    margin: "0",
    borderRadius: "14.84px",
    minHeight: "calc(100vh - 120px)",
  },
  titleBody: {
    fontWeight: 700,
    color: "#FFFFFF",
    fontSize: "38px",
    marginTop: "20px",
    lineHeight: "36px",
    textTransform: "uppercase",
    fontFamily: "DINCondensed",
    borderBottom: "1.02px solid #FFFFFF",
  },
  staticsTypeLeft: {
    borderRadius: "3.8px 0 0 3.8px",
    "& span": {
      color: "#19191D",
      fontFamily: "DINCondensed",
      fontSize: "23.75px",
      fontWeight: 700,
      lineHeight: "29px",
      marginBottom: "-5px",
      textTransform: "initial",
    },
  },
  staticsTypeRight: {
    borderRadius: "0 3.8px 3.8px 0",
    "& span": {
      color: "#19191D",
      fontFamily: "DINCondensed",
      fontSize: "23.75px",
      fontWeight: 700,
      lineHeight: "29px",
      marginBottom: "-5px",
      textTransform: "initial",
    },
  },
  positionSelectContainer: {
    padding: "40px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  positionSelectOptionsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  positionSelectOptions: {
    display: "flex",
    cursor: "pointer",
    "& span": {
      margin: "0 15px 0 5px",
    },
  },
  checkSelect: {
    backgroundColor: "#323641",
    borderRadius: "2.85px",
    width: "23.75px",
    height: "23.75px",
    margin: "0 5px 0 0",
  },
  clearSelectAllPlayers: {
    width: "122px",
    height: "35px",
    display: "flex",
    justifyContent: "space-between",
  },
  selectAllNone: {
    width: "60px",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    background: "#004687",
    borderRadius: "3.8px",
    justifyContent: "center",
  },
  statcsTitle: {
    color: "#FFFFFF",
    fontFamily: "DINCondensed",
    fontSize: "38px",
    fontWeight: "700",
    lineHeight: "46px",
    borderBottom: "1.02px solid #FFFFFF",
  },
  individualListContainer: {
    border: "1px solid #979797",
    borderRadius: "7.6px",
    margin: "0 0 30px 0",
  },
  individualListHead: {
    display: "flex",
    background: "#313642",
    borderRadius: "7.6px 7.6px 0 0",
    padding: "15px 0",
  },
  individualListHeadItem: {
    flex: 1,
    textAlign: "center",
  },
  individualListBody: {
    "& .MuiExpansionPanel": {
      borderRadius: 0,
      background: "transparent",
    },
    "& .MuiExpansionPanelSummary-root": {
      padding: 0,
      minHeight: "50px",
    },
    "& .MuiExpansionPanelSummary-content": {
      margin: 0,
    },
    "& .MuiIconButton-edgeEnd": {
      margin: 0,
      position: "absolute",
      right: "2px",
      top: "12px",
      width: 0,
      height: 0,
    },
    "& .MuiExpansionPanel-root": {
      background: "transparent",
      boxShadow: "none",
    },
    "& .MuiCollapse-container": {
      backgroundColor: "#373940",
    },
    "& .MuiExpansionPanelSummary-root.Mui-expanded": {
      minHeight: "50px",
    },
    "& .MuiExpansionPanelDetails-root": {
      padding: "0",
      flexWrap: "wrap",
      justifyContent: "space-around",
    },
  },
  individualListBodyItemContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "#ffffff",
  },
  individualListBodyItem: {
    flex: 1,
    textAlign: "center",
    minHeight: "50px",
    display: "flex",
    border: "1px solid #ffffff1a",
    justifyContent: "center",
    alignItems: "center",
  },
  MuiExpansionPanelSummaryRoot: {
    background: "red",
  },
  selectExercise: {
    width: "180px",
    "& .MuiAutocomplete-inputRoot": {
      padding: 0,
      height: "40px",
      margin: "0 15px",
      transition: "all .3s",
      borderRadius: "8px",
      textTransform: "uppercase",
      backgroundColor: "#3A3C3E",
    },
    "& label": {
      fontFamily: "DINCondensed",
      fontSize: "19px",
      fontWeight: 700,
      textTransform: "capitalize",
      color: "#fff",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      fontSize: "17.96px",
      transform: "translate(15px, -22px) scale(0.75) !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused, .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      color: "#ffffff",
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      transform: "translate(25px, 15px) scale(1)",
    },
    "& .MuiAutocomplete-input:first-child": {
      paddingLeft: "20px",
    },
  },
  metricsIndividualContainer: {
    padding: "15px",
  },
  metricsIndividualContainerTitle: {
    display: "flex",
    color: "#ffffff",
    fontWeight: "600",
    fontSize: "15.84px",
    justifyContent: "center",
    fontFamily: "Open Sans Condensed",
  },
  metricsIndividualContainerData: {
    display: "flex",
    color: "#ffffff",
    width: "163.53px",
    height: "33.57px",
    fontSize: "16.25px",
    alignItems: "center",
    justifyContent: "center",
    border: "0.97px solid #FFFFFF",
  },

  analysisContainer: {
    display: "flex",
    width: "100%",
    margin: "0 0 30px",
  },
  playersSubContainer: {
    maxHeight: "220px",
    overflowY: "auto",
    width: "96.5%",
    margin: "10px auto 20px",

    "&::-webkit-scrollbar": {
      width: "10px",
    },

    "&::-webkit-scrollbar-track": {
      background: "#4F4F4F",
      borderRadius: "7px",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "7px",
    },
  },
  leyend: {
    width: "705px",
    margin: "5px 0 30px 0",
  },
  modalButton: {
    marginLeft: "auto",
    width: "258.5px",
    height: "51.3px",
    backgroundColor: "#6cffe6",
    borderRadius: "4px",
    border: "none",
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "18px",
    cursor: "pointer",
  },
}));

const GpsByTeamComponent = (props) => {
  const CLIENT_ENVIROMENT = environment.ENV;

  const classes = useStyles();

  let widthChart =
    window.screen.width < 768
      ? 420
      : window.screen.width >= 1440
      ? 840
      : window.screen.width - 652;

  const {
    user,
    modal,
    dateEnd,
    statsGps,
    dateStart,
    rolestring,
    toggleModal,
    staticsType,
    quickMetrics,
    metricOptions,
    metricSeleted,
    compareOptions,
    compareSeleted,
    handleSelectAll,
    selectsCallBack,
    exerciseOptions,
    exerciseSelected,
    rolestringSelected,
    handleSelectPlayer,
    handleDateEndChange,
    handleDateStartChange,
    setMetricOptionsModal,
    handleSetRolestringSelected,
    rolestringIndividualSelected,
  } = props;

  const getQuickMetrics = () => {
    return quickMetrics.map((metric, index) => {
      return (
        <div
          key={"metricOption-" + index}
          className={
            metricSeleted.short_name === metric.short_name
              ? "metricOptionsSelected"
              : "metricOptions"
          }
          onClick={() => selectsCallBack(metric, 1)}
        >
          <div>
            <p className="measurementName">{metric.name}</p>
            <p className="measurementUnit">({metric.measurement_unit})</p>
          </div>
        </div>
      );
    });
  };

  const staticsTypeClass = [
    {
      cursor: "pointer",
      width: "256.5px",
      border: "3px solid #F8F8F8",
      height: "45.3px",
      justifyContent: "center",
      background: "#FFD802",
    },
    {
      cursor: "pointer",
      width: "256.5px",
      height: "51.3px",
      justifyContent: "center",
    },
  ];

  const resetChartBuilderData = () => {
    let data = [];
    let colorsFilter = [];
    let playersFilters = [];

    statsGps.stats.map((entry, index) => {
      if (statsGps.playersSelected.indexOf(index) > -1) {
        playersFilters.push(
          statsGps.stats[index].name.charAt(0) +
            ". " +
            statsGps.stats[index].lastname
        );
        colorsFilter.push(statsGps.colors[index]);

        if (metricSeleted.short_name === "P_Ener_Per_Massa") {
          if (!data[statsGps.stats[index].rolestring]) {
            data[statsGps.stats[index].rolestring] = [];
          }

          data[statsGps.stats[index].rolestring].push({
            player_name:
              statsGps.stats[index].name + " " + statsGps.stats[index].lastname,
            player_picture:
              resourceApiEndPoint() + "/" + statsGps.stats[index].img_file,
            x: statsGps.stats[index]["gps"].P_Min_Reg,
            y: statsGps.stats[index]["gps"].P_W,
            z: statsGps.stats[index]["gps"].P_Ener_Per_Massa,
            time: statsGps.stats[index]["gps"].P_Min_Reg_hms,
          });
        } else {
          if (
            statsGps.stats[index]["gps"] &&
            statsGps.stats[index]["gps"].length
          ) {
            statsGps.stats[index]["gps"].map((entry, subIndex) => {
              if (compareSeleted.name === "excercises") {
                // agrupamos por ejecicios
                if (!data[subIndex]) {
                  data[subIndex] = { name: "E" + (subIndex + 1) };
                }
                if (statsGps.stats[index]["gps"][subIndex]) {
                  data[subIndex] = {
                    ...data[subIndex],
                    [statsGps.stats[index].name.charAt(0) +
                    ". " +
                    statsGps.stats[index].lastname]: statsGps.stats[index][
                      "gps"
                    ][subIndex].value,
                  };
                }
              } else {
                if (
                  statsGps.stats[index]["gps"][subIndex].exercise_date in data
                ) {
                  data[statsGps.stats[index]["gps"][subIndex].exercise_date] = {
                    ...data[
                      statsGps.stats[index]["gps"][subIndex].exercise_date
                    ],
                    [statsGps.stats[index].name.charAt(0) +
                    ". " +
                    statsGps.stats[index].lastname]: statsGps.stats[index][
                      "gps"
                    ][subIndex].value,
                  };
                } else {
                  data[statsGps.stats[index]["gps"][subIndex].exercise_date] = {
                    date: statsGps.stats[index]["gps"][subIndex].exercise_date,
                    [statsGps.stats[index].name.charAt(0) +
                    ". " +
                    statsGps.stats[index].lastname]: statsGps.stats[index][
                      "gps"
                    ][subIndex].value,
                  };
                }
              }
              return null;
            });
          } else {
            data = [];
          }
        }
      }
      return null;
    });
    return { data, colorsFilter, playersFilters };
  };

  const getRolestring = (type) => {
    let typeRolestringSelect =
      type === "individual" ? rolestringIndividualSelected : rolestringSelected;
    return rolestring
      ? rolestring.map((option, key) => {
          return (
            <div
              key={key}
              className={classes.positionSelectOptions}
              onClick={() => handleSetRolestringSelected(type, option)}
            >
              <div className={classes.checkSelect}>
                {typeRolestringSelect.includes(option) && (
                  <div style={{ transform: "translateY(-6px)" }}>
                    <span style={{ fontSize: "30px", margin: 0 }}>✔</span>
                  </div>
                )}
              </div>
              <span>{option}</span>
            </div>
          );
        })
      : null;
  };

  const getIndividualList = () => {
    let listIndividual = statsGps.stats
      ? statsGps.stats.filter((player, index) => {
          //console.log(player)
          return rolestringIndividualSelected.indexOf(player.rolestring) >= 0;
        })
      : null;

    return listIndividual
      ? listIndividual.map((player, key) => {
          return (
            <ExpansionPanel key={key}>
              <ExpansionPanelSummary
                className={classes.MuiExpansionPanelSummaryRoot}
                style={{
                  backgroundColor: key % 2 !== 0 ? "#ffffff05" : "transparent",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.individualListBodyItemContainer}>
                  <div
                    className={classes.individualListBodyItem}
                    style={{ flex: ".5" }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Avatar
                        src={resourceApiEndPoint() + "/" + player.img_file}
                        alt="Player name"
                        style={{
                          backgroundColor: "#ffffff",
                          width: "35px",
                          height: "35px",
                          paddingTop: "0px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={classes.individualListBodyItem}
                    style={{ flex: "2" }}
                  >
                    <span>
                      <b>{player.name + " " + player.lastname}</b>
                    </span>
                  </div>
                  <div
                    className={classes.individualListBodyItem}
                    style={{ flex: ".5" }}
                  >
                    <span>
                      <b>{player.yearold}</b>
                    </span>
                  </div>
                  <div
                    className={classes.individualListBodyItem}
                    style={{ flex: ".5" }}
                  >
                    <span>
                      <b>{player.gender}</b>
                    </span>
                  </div>
                  <div className={classes.individualListBodyItem}>
                    <span>
                      <b>{player.rolestring}</b>
                    </span>
                  </div>
                  <div
                    className={classes.individualListBodyItem}
                    style={{ flex: "1.5" }}
                  >
                    <span>
                      <b>
                        {player.wclubNames
                          ? player.wclubNames
                          : player.wclubName}
                      </b>
                    </span>
                  </div>
                </div>
              </ExpansionPanelSummary>

              {exerciseSelected &&
                player.gps &&
                player.gps[exerciseSelected.name - 1] &&
                player.gps[exerciseSelected.name - 1].metrics && (
                  <ExpansionPanelDetails>
                    {Object.keys(
                      player.gps[exerciseSelected.name - 1].metrics
                    ).map((key) => {
                      return (
                        <div
                          key={`metric-` + key}
                          className={classes.metricsIndividualContainer}
                        >
                          <div
                            className={classes.metricsIndividualContainerTitle}
                          >
                            <p>{metricOptions[key].name}</p>
                          </div>
                          <div
                            className={classes.metricsIndividualContainerData}
                          >
                            {player.gps[exerciseSelected.name - 1].metrics[
                              key
                            ] +
                              " (" +
                              metricOptions[key].measurement_unit +
                              ")"}
                          </div>
                        </div>
                      );
                    })}
                  </ExpansionPanelDetails>
                )}
            </ExpansionPanel>
          );
        })
      : null;
  };

  const getSelect = (label, options, idSelect) => {
    let defaultValue = null;
    if (idSelect === 1) {
      // metric select
      defaultValue = metricSeleted;
    } else if (idSelect === 2) {
      // compared select
      defaultValue = { name: "date", value: "getteambydate" };
    } else if (idSelect === 3) {
      // exercise select
      defaultValue = exerciseSelected;
    }

    return (
      <>
        <div className="backNextOption"></div>
        {options ? (
          <Autocomplete
            defaultValue={defaultValue}
            options={options}
            getOptionLabel={(option) =>
              option.name ? option.name : option.title
            }
            renderOption={(option) =>
              option.img_file ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      alt={`Logo ` + option.name ? option.name : option.title}
                      style={{ width: "30px", margin: "10px 10px 10px 0px" }}
                      src={
                        resourceApiEndPoint() +
                        (option.img_file.charAt(0) === "/"
                          ? option.img_file
                          : "/" + option.img_file)
                      }
                    ></img>
                    <span style={{ fontSize: "14px" }}>
                      {option.name ? option.name : option.title}
                    </span>
                  </div>
                </>
              ) : (
                <span style={{ fontSize: "14px" }}>
                  {option.name ? option.name : option.title}
                </span>
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                fullWidth
              />
            )}
            onChange={(e, value) => selectsCallBack(value, idSelect)}
          />
        ) : (
          <p>{label}</p>
        )}
        <div className="backNextOption"></div>
      </>
    );
  };

  return (
    <div className={classes.gpsContainer}>
      <GpsOnboarding open={modal} closeModal={toggleModal} />
      <div className="col" style={{ marginTop: 0 }}>
        <span className={classes.titleBody}>
          {translate(user.lang, "statistics")}
        </span>
      </div>

      <div className="col">
        <span>{translate(user.lang, "rating-title")}</span>
      </div>

      <div className="col" style={{ marginTop: "30px" }}>
        <div
          className={classes.inputSelect + " " + classes.staticsTypeLeft}
          style={staticsType ? staticsTypeClass[0] : staticsTypeClass[1]}
          onClick={() => selectsCallBack(null, 100)}
        >
          <span style={{ color: staticsType ? "#000000" : "#ffffff" }}>
            {translate(user.lang, "general statistics")}
          </span>
        </div>

        <div
          className={classes.inputSelect + " " + classes.staticsTypeRight}
          style={staticsType ? staticsTypeClass[1] : staticsTypeClass[0]}
          onClick={() => selectsCallBack(null, 100)}
        >
          <span style={{ color: staticsType ? "#ffffff" : "#000000" }}>
            {translate(user.lang, "individual statistics")}
          </span>
        </div>
        {CLIENT_ENVIROMENT !== "infinity" && (
          <button className={classes.modalButton} onClick={() => toggleModal()}>
            {translate(user.lang, "import gps data")}
          </button>
        )}
      </div>
      {!staticsType ? (
        <div className={classes.stacsIndividualContainer}>
          <div className={classes.positionSelectContainer}>
            <div style={{ display: "flex" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ marginRight: 0 }}
                  className={classes.keyboardDatePicker}
                  margin="normal"
                  format="dd/MM/yyyy"
                  label="Elegir fecha"
                  value={dateStart}
                  onChange={handleDateStartChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              <div className={classes.selectExercise}>
                {exerciseOptions &&
                  getSelect("Elegir ejercicio", exerciseOptions, 3)}
              </div>
            </div>

            <div className={classes.positionSelectOptionsContainer}>
              {getRolestring("individual")} {/* posición de jugadores */}
            </div>
          </div>

          <div className={classes.individualListContainer}>
            <div className={classes.individualListHead}>
              <div
                className={classes.individualListHeadItem}
                style={{ flex: ".5" }}
              >
                <span>
                  <b>Foto</b>
                </span>
              </div>
              <div
                className={classes.individualListHeadItem}
                style={{ flex: "2" }}
              >
                <span>
                  <b>Nombre</b>
                </span>
              </div>
              <div
                className={classes.individualListHeadItem}
                style={{ flex: ".5" }}
              >
                <span>
                  <b>Edad</b>
                </span>
              </div>
              <div
                className={classes.individualListHeadItem}
                style={{ flex: ".5" }}
              >
                <span>
                  <b>Género</b>
                </span>
              </div>
              <div className={classes.individualListHeadItem}>
                <span>
                  <b>Posición</b>
                </span>
              </div>
              <div
                className={classes.individualListHeadItem}
                style={{ flex: "1.5" }}
              >
                <span>
                  <b>Club / Equipo</b>
                </span>
              </div>
            </div>
            <div className={classes.individualListBody}>
              {getIndividualList()}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="col" style={{ marginTop: 0 }}>
            <span className={classes.titleBody}>
              {translate(user.lang, "player selection")}
            </span>
          </div>

          <div className="col">
            <span className={classes.leyend}>
              {translate(user.lang, "player selection text")}
            </span>
          </div>

          <div
            className={"select-players-gps " + classes.selectPlayersContainer}
          >
            <div
              className={
                classes.positionSelectContainer + " positionSelectContainer"
              }
            >
              <div className={classes.positionSelectOptionsContainer}>
                {getRolestring("general")} {/* posición de jugadores */}
              </div>

              <div className={classes.clearSelectAllPlayers}>
                <div
                  className={classes.selectAllNone}
                  onClick={() => handleSelectAll(false)}
                >
                  <span>-</span>
                </div>
                <div
                  className={classes.selectAllNone}
                  onClick={() => handleSelectAll(true)}
                >
                  <span>+</span>
                </div>
              </div>
            </div>

            <div
              className={"cols sm-4 md-6 lg-8 " + classes.playersSubContainer}
            >
              {statsGps &&
                statsGps.stats &&
                statsGps.stats.length > 1 &&
                statsGps.stats.map((entry, index) => (
                  <div
                    key={index}
                    className="MuiAvatar-root-container"
                    style={{ textAlign: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "15px",
                      }}
                    >
                      <Avatar
                        src={
                          resourceApiEndPoint() +
                          "/" +
                          statsGps.stats[index].img_file
                        }
                        key={`avatar-${index}`}
                        alt="Player name"
                        style={
                          statsGps.playersSelected.indexOf(index) > -1
                            ? {
                                backgroundColor: "#ffffff",
                                border: "5px solid " + statsGps.colors[index],
                                margin: "-5px",
                                boxShadow: "0px 0px 10px -1px  #a8a8a859",
                                width: "52.16px",
                                height: "52.16px",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                width: "52.16px",
                                height: "52.16px",
                                cursor: "pointer",
                              }
                        }
                        onClick={() => handleSelectPlayer(index)}
                      />
                    </div>
                    <span>{statsGps.stats[index].lastname}</span>
                  </div>
                ))}
            </div>
          </div>

          <div className={classes.selectOptionsGpsContainer}>
            <div>
              {false && (
                <div className={classes.inputSelect} style={{ width: "219px" }}>
                  {getSelect("metrics", metricOptions, 1)}
                </div>
              )}
              <span className={classes.statcsTitle}>
                {translate(user.lang, "stadistical analysis")}
              </span>
            </div>

            <div className={classes.optionGps}>
              {metricSeleted.short_name !== "P_Ener_Per_Massa" && (
                <div className={classes.inputSelect} style={{ width: "219px" }}>
                  {getSelect(
                    translate(user.lang, "compare by"),
                    compareOptions,
                    2
                  )}
                </div>
              )}

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ marginRight: 0 }}
                  className={classes.keyboardDatePicker}
                  margin="normal"
                  format="dd/MM/yyyy"
                  label={
                    compareSeleted.value === "getteambydate"
                      ? translate(user.lang, "start date")
                      : translate(user.lang, "date")
                  }
                  value={dateStart}
                  onChange={handleDateStartChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>

              {compareSeleted.value === "getteambydate" && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    style={{ marginRight: 0 }}
                    className={classes.keyboardDatePicker}
                    margin="normal"
                    format="dd/MM/yyyy"
                    label={translate(user.lang, "end date")}
                    value={dateEnd}
                    onChange={handleDateEndChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              )}
            </div>
          </div>

          <div className={classes.analysisContainer}>
            <div style={{ padding: "30px 0 0" }}>
              <div className={classes.metricsContainer}>
                <div className={classes.metricsHead}>
                  <span>{translate(user.lang, "metrics")}</span>
                </div>
                {quickMetrics && getQuickMetrics()}
                <div
                  className={classes.moreMetrics}
                  onClick={() => setMetricOptionsModal(true)}
                >
                  <span>{translate(user.lang, "see more")}</span>
                </div>
              </div>
              <div className={classes.weaterContainer}>
                <div
                  style={{
                    margin: "15px 0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={weatherIcon}
                    style={{ marginRight: "5px", width: "30px" }}
                    alt="select weather"
                  />
                  <span style={{ fontSize: "17px", fontWeight: "900" }}>
                    {translate(user.lang, "weather condition")}
                  </span>
                </div>
                <div style={{ margin: "5px 0px", width: "100%" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ marginRight: 0 }}
                      className={classes.keyboardDatePickerWeather}
                      format="dd/MM/yyyy"
                      label={translate(user.lang, "weather date")}
                      value={dateStart}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{ margin: "5px 13px", width: "100%" }}>
                  <span style={{ fontSize: "16px" }}>
                    {translate(user.lang, "weather pressure")}: 1011.6 hPa
                  </span>
                </div>
                <div style={{ margin: "5px 13px", width: "100%" }}>
                  <span style={{ fontSize: "16px" }}>
                    {translate(user.lang, "weather temperature")}: 24 °C
                  </span>
                </div>
                <div style={{ margin: "5px 13px", width: "100%" }}>
                  <span style={{ fontSize: "16px" }}>
                    {translate(user.lang, "weather altitude")}: 800 ft
                  </span>
                </div>
              </div>
            </div>

            <div
              className={classes.chartContainer}
              style={{
                justifyContent:
                  metricSeleted.short_name === "P_Ener_Per_Massa"
                    ? "center"
                    : "flex-end",
              }}
            >
              {statsGps && statsGps.stats && statsGps.stats.length > 1 && (
                <>
                  {}{" "}
                  <p className="measurementSelected">
                    Metric selected: <b>{metricSeleted.name}</b>
                  </p>
                  {metricSeleted.short_name === "P_Ener_Per_Massa" ? (
                    <GetTeamEnergyExpediture
                      resetChartBuilderData={resetChartBuilderData}
                    />
                  ) : compareSeleted.value === "getteambydate" ? (
                    <GetGraphyGetTeamByDate
                      resetChartBuilderData={resetChartBuilderData}
                      widthChart={widthChart}
                      metricSeleted={metricSeleted}
                    />
                  ) : compareSeleted.value === "getteambyexercise" ? (
                    <GetGraphyGetTeamByExercise
                      widthChart={widthChart}
                      metricSeleted={metricSeleted}
                      resetChartBuilderData={resetChartBuilderData}
                    />
                  ) : (
                    <GetGraphyGetTeamByPlayer
                      statsGps={statsGps}
                      dateStart={dateStart}
                      widthChart={widthChart}
                      metricSeleted={metricSeleted}
                      resetChartBuilderData={resetChartBuilderData}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <Backdrop open={modal} close={toggleModal} />
        </>
      )}
    </div>
  );
};
export default React.memo(GpsByTeamComponent);
