import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles(theme => ({
  container: {
    top: "50%",
    left: "50%",
    padding: "50px 50px 40px",
    width: "677px",
    height: "597px",
    border: "2px solid",
    borderRadius: "13px",
    boxSizing: "border-box",
    position: "absolute",
    backgroundColor: "#ffffff",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",

    "& p": {
      color: "#000000",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "600",
      marginTop: "23px",
    },
    "& .extraMargin": {
      marginTop: "34px",
    },
  },
  title: {
    fontFamily: "DINCondensed",
    fontSize: "50px",
    color: "#000000",
  },
  closeButton: {
    boxSizing: "border-box",
    width: "155.3px",
    height: "53.8px",
    backgroundColor: "#6cffe6",
    border: "none",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: "600",
    borderRadius: "4px",
    margin: "51.2px auto 0",
  },
}));

export const GpsOnboarding = props => {
  const classes = useStyles();

  const { open, closeModal } = props;

  return (
    <Modal open={open}>
      <div className={classes.container}>
        <h2 className={classes.title}>Importar Datos GPS</h2>
        <p>
          Para importar los datos de GPS tienes que hacerlo desde la plataforma
          de Lagalacolli.
        </p>
        <p className='extraMargin'>
          1 - Ingresa a Lagalacolli con tu usuario y contraseña
        </p>
        <p>2 - Descarga los datos de tu GPS </p>
        <p>
          3 - En la columna “name” completa el nombre y apellido del jugador en
          este formato:
        </p>
        <p>nombreapellido: ej: lionelmessi</p>
        <p>4 - Hace click en el botón “upload to”</p>
        <button className={classes.closeButton} onClick={() => closeModal()}>
          Entendido
        </button>
      </div>
    </Modal>
  );
};

export default GpsOnboarding;
