import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';


import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles(theme => ({

    videoListContainer: {
        height: 'calc(100% - 15px)'
    },
    ExpansionPanel: {
        background: 'transparent', color: '#ffffff',
        '& .MuiExpansionPanelSummary-root': {
            borderRadius: '5px'
        },
    },
    ExpansionPanelSummary: {
        '& .MuiExpansionPanelSummary-content': {
            width: 'calc(100% - 36px)',
        },
        '& span:first-child': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
            marginRight: '0 15px',
        }
    },
    ExpansionPanelDetails: {
        justifyContent: 'space-around',
        background: '#ffffff30',
        padding: '20px 24px 20px',
        '& > div': {
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
        },
        '& span': {
            padding: '5px 0'
        }
    },
    NavLink: {
        color: '#ffffff',
    },
    resummenEventsContainer: {
        '& .tag-count-container': {
            cursor: 'pointer',
            fontSize: '13.68px',
            transition: 'all .3s',
        },
        '& .tag-count-container:hover': {
            color: '#1CB9D3',
            transform: 'scale(1.6) translateY( 2px)',
        }
    }
}));

const AnalysisVideoListComponent = (props) => {

    const {listVideoAnalysis, showResumenEvents, resumenEvents, handleDeleteVidioAnalysis, handleShowEventsByTagId} = props;

    const classes = useStyles();

    const buildResumenEvent = (analysis) => {

        if( resumenEvents[analysis.id] && resumenEvents[analysis.id].length > 0 ){
            //console.log(analysis)
            return resumenEvents[analysis.id].map( (event, index) => {
                //console.log(event)
                return  <div className={classes.resummenEventsContainer} key={index}>
                                <span>{event.name}</span>
                                <span className="tag-count-container" onClick={ () => handleShowEventsByTagId(event, analysis) }>{event.count}</span>
                        </div>
            })
        }
    }

    const showListVideoAnalysis = () => {
        return listVideoAnalysis.map( (analysis, index) => {
            return  <ExpansionPanel key={index} className={classes.ExpansionPanel} onClick={ () => showResumenEvents(analysis) }>
                        <ExpansionPanelSummary
                            style={{backgroundColor: index % 2 !== 0 ? '#ffffff14': 'transparent'}}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            className={classes.ExpansionPanelSummary}
                        >
                            <>
                            <span>{analysis.name}</span>

                            <NavLink to={ "/analysis-video/" + analysis.id} className={classes.NavLink} style={{margin: '0 30px'}} >
                                <SlowMotionVideoIcon />
                            </NavLink>

                            <DeleteIcon onClick={ (e) => { e.stopPropagation(); handleDeleteVidioAnalysis(analysis)}  } />

                            </>

                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
                            <> { buildResumenEvent( analysis ) } </>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
        });
    }

    return (
        <div className={classes.videoListContainer}>
            {showListVideoAnalysis()}
        </div>
    );
};

export default AnalysisVideoListComponent;