import React, {useEffect, useState} from 'react'
import { makeStyles, Checkbox, FormControlLabel} from '@material-ui/core';
import { ReactComponent as TitleIcon } from '../../../assets/img/drawer.svg';
import { ReactComponent as SearchIcon } from '../../../assets/img/search.svg';
import CheckBoxCards from '../../Cards/CheckBoxCards'
import { NavLink } from 'react-router-dom';
import { connect } from "react-redux"
import { modifyFormHandler, modifyFormRefereeHandler } from "../../../actions/index"
import { getAllReferees, getAllFields } from '../../../endpoints'

const useStyles = makeStyles(theme => ({

    stepscontainer: {
        width: '540px',
        position: 'relative',
        display: 'flex',
        margin: '0 auto',
        justifyContent: 'space-between',

        '&::after': {
            content: '""',
            position: 'absolute',
            width: '96%',
            border: 'solid 1.5px #B2B2B2',
            top: '6.5px',
            left: '5px',
        },

        '&::before': {
            content: '""',
            position: 'absolute',
            width: '50%',
            border: 'solid 1.5px #FDCA40',
            top: '6.5px',
            left: '5px',
            zIndex: '3'
        },

        '& .yellowStep': {
            width: '16.07px',
            height: '16.07px',
            borderRadius: '100%',
            backgroundColor: '#FDCA40',
            zIndex: '3',
        },

        '& .greyStep': {
            width: '16.07px',
            height: '16.07px',
            borderRadius: '100%',
            backgroundColor: '#B2B2B2',
            zIndex: '3',
        },
    },

    nameContainer: {
        width: '630px',
        display: 'flex',
        margin: '10px auto',
        justifyContent: 'space-between',
        

        '& .stepName': {
            fontSize: '12px',
            textAlign: 'center',
            fontWeight: '400',
            
           
        }
    },

    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '50px',
        position: 'relative',

        '& .title': {
            color: '#FFFFFF',
            fontSize: '23px',
            fontWeight: '700',
            lineHeight: '44px',
            width: '264.2px',
            textAlign: 'left',
            position: 'relative',
            textTransform: 'uppercase',
            paddingLeft: '30px',
            font: 'Open Sans Condensed',

            '&::after': {
                content: '""',
                position: 'absolute',
                borderBottom: '1px solid white',
                width: '118%',
                top: '45px',
                left: '0',
            },

        },

        '& .eventsButton': {
            backgroundColor: '#074682',
            borderRadius: '1.81px',
            width: '224px',
            height: '38.52px',
            border: 'none',
            textTransform: 'uppercase',
            color: 'white',
            fontWeight: '700',
            textAlign: 'center',
            transform: 'translateY(11%)',
            outline: 'none',
            transition: 'all .2s',
            lineHeight: '40px',
            fontSize: '12px'
        },

        '& .eventsButton:hover': {
            transform: 'scale(1.1)'
        },

        '& .titleIcon': {
            position: 'absolute',
            width: '20px',
            height: '20px',
            top: '12px',
            left: '4px',

        },
    },

    cardContainer:  {
        marginTop: '20px',
        display: 'flex',
        boxSizing: 'borderBox',

        '& .largeInput': {
            width: '450px'
        },

        '& .shortInput': {
            width: '180px'
        },

        '& .hugeInput': {
            width: '430px',
            height: '138px',
            resize: 'none'
        },

        '& .squareInput': {
            width: '138px',
            height: '138px',
        },

        '& .input': {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
            
        },

        '& .label': {
            fontSize:'10px',
            marginLeft: '5px',
            display: 'flex',
            flexDirection: 'column'

        },

        '& .inputField': {
            marginTop: '2px',
            border: '1px solid white',
            borderRadius: '4.6px',
            backgroundColor:'transparent',
            padding: '8px 6px',
            outline:'none',
            color: 'white',
            transition: 'all .1s',
            boxSizing: 'border-box',
            position: 'relative',
        },

        '& .inputField:focus': {
            transform: 'scale(1.01)'
        },

        '& .inputFileField' : {
            visibility: 'hidden',
            opacity: '0'
        },

        '& .icon': {
            width: '90px',
            height: '68px',
            position: 'absolute',
            top: '32px',
            left: '23px'
        },

        '& .inputColor': {
            width: '104px',
            border: 'none',
            marginTop: '15px'
        },

        '& .searchIcon': {
            position: 'absolute',
            width: '20px',
            height: '20px',
            top: '22px',
            right: '7px',
            filter: 'invert(1)'
        }
        
    },

    fieldsContainer: {
        border: '0.5px solid #4a4848',
        backgroundColor: 'rgba(21, 23, 25, 0.78)',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: '7.6px',
        padding: '32px',
        positon: 'relative',
        marginTop: '50px',

        '& .subtitle': {
            fontSize: '19px',
            textTransform: 'uppercase',
            color: '#FFFFFF',
            position: 'relative',
            opacity: '1',


            '&::after':{
                content: '""',
                position:'absolute',
                width: '100%',
                top:'21px',
                left:'0',
                borderBottom:'1px solid #FFFFFF'
            }
        }, 
    },

    RefereeContainer: {
        border: '0.5px solid #4a4848',
        backgroundColor: 'rgba(21, 23, 25, 0.78)',
        width: '100%',
        height: '240px',
        boxSizing: 'border-box',
        borderRadius: '7.6px',
        padding: '32px',
        positon: 'relative',
        marginTop: '10px',

        
        '& .subtitle': {
            fontSize: '19px',
            textTransform: 'uppercase',
            color: '#FFFFFF',
            position: 'relative',
            opacity: '1',
            marginBottom: '30px',


            '&::after':{
                content: '""',
                position:'absolute',
                width: '100%',
                top:'21px',
                left:'0',
                borderBottom:'1px solid #FFFFFF'
            }
        },

        '& .listContainer': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            height: '95%',
            flexWrap: 'wrap',
            width: '30%',
        },
        
        '& .referee': {
            textTransform: 'capitalize',
        },

        '& .MuiTypography-body1': {
            fontSize: '10px',
            fontFamily: 'Montserrat',
        },
        '& .MuiFormControlLabel-root': {
            height: '35px',
        }
    }
}));

export const EventOrganization = ({ modifyFormHandler, eventForm,modifyFormRefereeHandler, ...props }) => {

    const [referees, setReferees] = useState([]);
    const [complexs, setComplexs] = useState([]);

    useEffect(() => {
        loadReferees();
        loadComplexs();
    }, [])

    const loadReferees = async() => {
        let response = await getAllReferees();
        setReferees(response.data.fields);
    }  

    const loadComplexs = async() => {
        let response = await getAllFields()
        setComplexs(response.data.fields)
    }
 
    const classes = useStyles();
    console.log(eventForm)
    return (
        <>
            <div className={classes.stepscontainer}>
                <span className='yellowStep'></span>
                <span className='yellowStep'></span>
                <span className='yellowStep'></span>
                <span className='greyStep'></span>
                <span className='greyStep'></span>
            </div>
            <div className={classes.nameContainer}>
                <p className={'stepName'}>Datos Generales</p>
                <p className={'stepName'}>Competencias</p>
                <p className={'stepName'}>Organización</p>
                <p className={'stepName'}>Directores</p>
                <p className={'stepName'}>Medio de pago</p>
            </div>
            <div className={classes.headerContainer}>
                <TitleIcon className='titleIcon'/>
                <h1 className='title'>Organización</h1>
                <NavLink to={'/formEventDirectors'}className='eventsButton'>Continuar</NavLink>
            </div>
            <div className={classes.fieldsContainer}>
                <h2 className='subtitle'>Complejos y Canchas</h2>   
                <div className={classes.cardContainer}>
                    <div className='input'>
                        <label className='label' htmlFor="name">Buscá y seleccioná los torneos del evento <span><SearchIcon className='searchIcon'/></span></label>
                        <input className='inputField largeInput' type="text" id="name"/>
                    </div>
                </div>
                <div className={classes.cardContainer}>
                    {complexs && complexs.map((com, index) => {
                        return(
                            <CheckBoxCards key={index} complexName={com.name} fields={com.fields}/>
                        )})}
                    
                    
                </div>
            </div>

            <div className={classes.RefereeContainer}>
                <h2 className='subtitle'>Referees</h2>   
                <div className='listContainer'>
                    {referees && referees.map(ref => {
                        return (
                            <div key={ref.id} className='referee'> <FormControlLabel  control={
                                <Checkbox value={ref.isChecked} name='selectedReferees' id='selectedReferees' onChange={(e) => modifyFormRefereeHandler({id: ref._id, refName: ref.name, refLastName: ref.lastname, isChecked: e.target.checked})} />} color="primary" label={ref.name + ' ' + ref.lastname} />
                            </div>
                        )
                    })}
                </div>
            </div>            
        </>
    )
}

const mapStateToProps = state => {
    return {
        eventForm: state.eventForm
    }
}

export default connect(mapStateToProps, {
    modifyFormHandler,
    modifyFormRefereeHandler
})(EventOrganization);