import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import DirectorCard from "../../Cards/DirectorCard";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { addDirector, getAllDirectors } from "../../../endpoints";
import { getAllDirectorHandler, addDirectorHandler } from "../../../actions";

function mapDispatchToProps(dispatch) {
  return {
    set_getAllDirectorsHandler: (dir) => dispatch(getAllDirectorHandler(dir)),
    set_addDirectorHandler: (dir) => dispatch(addDirectorHandler(dir)),
  };
}

const useStyles = makeStyles((stheme) => ({
  stepscontainer: {
    width: "540px",
    position: "relative",
    display: "flex",
    margin: "0 auto",
    justifyContent: "space-between",

    "&::after": {
      content: '""',
      position: "absolute",
      width: "96%",
      border: "solid 1.5px #B2B2B2",
      top: "6.5px",
      left: "5px",
    },

    "&::before": {
      content: '""',
      position: "absolute",
      width: "73%",
      border: "solid 1.5px #FDCA40",
      top: "6.5px",
      left: "5px",
      zIndex: "3",
    },

    "& .yellowStep": {
      width: "16.07px",
      height: "16.07px",
      borderRadius: "100%",
      backgroundColor: "#FDCA40",
      zIndex: "3",
    },

    "& .greyStep": {
      width: "16.07px",
      height: "16.07px",
      borderRadius: "100%",
      backgroundColor: "#B2B2B2",
      zIndex: "3",
    },
  },

  nameContainer: {
    width: "630px",
    display: "flex",
    margin: "10px auto",
    justifyContent: "space-between",

    "& .stepName": {
      fontSize: "12px",
      textAlign: "center",
      fontWeight: "400",
    },
  },

  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "50px",
    position: "relative",

    "& .title": {
      color: "#FFFFFF",
      fontSize: "23px",
      fontWeight: "700",
      lineHeight: "44px",
      width: "264.2px",
      textAlign: "left",
      position: "relative",
      textTransform: "uppercase",
      paddingLeft: "40px",
      font: "Open Sans Condensed",

      "&::after": {
        content: '""',
        position: "absolute",
        borderBottom: "1px solid white",
        width: "118%",
        top: "45px",
        left: "0",
      },
    },

    "& .eventsButton": {
      backgroundColor: "#074682",
      borderRadius: "1.81px",
      width: "224px",
      height: "38.52px",
      border: "none",
      textTransform: "uppercase",
      color: "white",
      fontWeight: "700",
      textAlign: "center",
      transform: "translateY(11%)",
      outline: "none",
      transition: "all .2s",
      lineHeight: "40px",
      fontSize: "12px",
    },

    "& .eventsButton:hover": {
      transform: "scale(1.1)",
    },

    "& .titleIcon": {
      position: "absolute",
      width: "30px",
      height: "30px",
      top: "6px",
      left: "4px",
    },
  },

  fieldsContainer: {
    border: "0.5px solid #4a4848",
    backgroundColor: "rgba(21, 23, 25, 0.78)",
    width: "100%",
    boxSizing: "border-box",
    borderRadius: "7.6px",
    padding: "32px",
    positon: "relative",
    marginTop: "50px",

    "& .subtitle": {
      fontSize: "19px",
      textTransform: "uppercase",
      color: "#FFFFFF",
      position: "relative",
      opacity: "1",

      "&::after": {
        content: '""',
        position: "absolute",
        width: "100%",
        top: "29px",
        left: "0",
        borderBottom: "1px solid #FFFFFF",
      },
    },
  },

  inputContainer: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-around",
    boxSizing: "borderBox",

    "& .largeInput": {
      width: "358px",
      height: "40px",
    },

    "& .extramargin": {
      marginTop: "20px",
    },

    "& .input": {
      display: "flex",
      flexDirection: "column",
    },

    "& .label": {
      fontSize: "13px",
      marginLeft: "5px",
      marginBottom: "6px",
      display: "flex",
      flexDirection: "column",
    },

    "& .inputField": {
      marginTop: "2px",
      border: "1.5px solid white",
      borderRadius: "4.6px",
      backgroundColor: "transparent",
      padding: "8px 6px",
      outline: "none",
      color: "white",
      transition: "all .1s",
      boxSizing: "border-box",
      position: "relative",
    },

    "& .inputField:focus": {
      transform: "scale(1.01)",
    },

    "& .inputFileField": {
      visibility: "hidden",
      opacity: "0",
    },

    "& .icon": {
      width: "90px",
      height: "68px",
      position: "absolute",
      top: "32px",
      left: "23px",
    },

    "& .inputColor": {
      width: "104px",
      border: "none",
      marginTop: "15px",
    },
  },

  addDirectorButton: {
    display: "flex",
    border: "none",
    backgroundColor: "transparent",
    color: "#00BBD6",
    fontSize: "19px",
    fontWeight: "700",
    outline: "none",
    transition: "all .2s",
    marginTop: "20px",
    marginLeft: "auto",

    "&:hover": {
      transform: "scale(1.03)",
    },

    "&:disabled": {
      color: "grey",
    },
  },

  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
}));

export const DirectorsForm = ({
  set_getAllDirectorsHandler,
  modifyFormDirectorHandler,
  set_addDirectorHandler,
  directorList,
  ...props
}) => {
  useEffect(() => {
    if (!directorList || directorList < 1) {
      loadApiDirectors();
    }
  }, []);

  const initialDirectors = { name: "", lastname: "", email: "", phone: "" };
  const classes = useStyles();
  const [localdirectors, setLocalDirectors] = useState([]);

  const loadApiDirectors = async () => {
    let response = await getAllDirectors();
    set_getAllDirectorsHandler(response.data.eventDirectors);
  };

  const setDirectorHandler = () => {
    addDirector(localdirectors);
    set_addDirectorHandler(localdirectors);
    setLocalDirectors(initialDirectors);
  };

  return (
    <>
      <div className={classes.stepscontainer}>
        <span className="yellowStep"></span>
        <span className="yellowStep"></span>
        <span className="yellowStep"></span>
        <span className="yellowStep"></span>
        <span className="greyStep"></span>
      </div>
      <div className={classes.nameContainer}>
        <p className={"stepName"}>Datos Generales</p>
        <p className={"stepName"}>Competencias</p>
        <p className={"stepName"}>Organización</p>
        <p className={"stepName"}>Directores</p>
        <p className={"stepName"}>Medio de pago</p>
      </div>
      <div className={classes.headerContainer}>
        <h1 className="title">Direccion</h1>
        <NavLink to={"/formEventConfirmation"} className="eventsButton">
          Continuar
        </NavLink>
      </div>
      <div className={classes.fieldsContainer}>
        <h2 className="subtitle">Director</h2>
        <div className={classes.inputContainer}>
          <div className="input">
            <label className="label" htmlFor="directorName">
              Nombre
            </label>
            <input
              name="directorName"
              onChange={(e) =>
                setLocalDirectors({ ...localdirectors, name: e.target.value })
              }
              className="inputField largeInput"
              value={localdirectors.name}
              type="text"
              id="directorName"
            />
          </div>
          <div className="input ">
            <label className="label" htmlFor="directorLastName">
              Apellido
            </label>
            <input
              name="directorLastName"
              onChange={(e) =>
                setLocalDirectors({
                  ...localdirectors,
                  lastname: e.target.value,
                })
              }
              className="inputField largeInput"
              value={localdirectors.lastname}
              type="text"
              id="directorLastName"
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className="input extramargin">
            <label className="label" htmlFor="directorMail">
              Mail
            </label>
            <input
              name="directorMail"
              onChange={(e) =>
                setLocalDirectors({ ...localdirectors, email: e.target.value })
              }
              className="inputField largeInput"
              value={localdirectors.email}
              type="text"
              id="directorMail"
            />
          </div>
          <div className="input extramargin">
            <label className="label" htmlFor="directorPhone">
              Telefono
            </label>
            <input
              name="directorPhone"
              onChange={(e) =>
                setLocalDirectors({ ...localdirectors, phone: e.target.value })
              }
              className="inputField largeInput"
              value={localdirectors.phone}
              type="text"
              id="directorPhone"
            />
          </div>
        </div>
        <button
          disabled={!localdirectors.name || !localdirectors.lastname}
          className={classes.addDirectorButton}
          onClick={setDirectorHandler}
        >
          Add director +
        </button>
      </div>
      <div className={classes.cardContainer}>
        {directorList &&
          directorList.map((dir, index) => {
            return (
              <DirectorCard
                key={index}
                name={dir.name}
                lastname={dir.lastname}
                mail={dir.email}
                phone={dir.phone}
                id={dir._id}
              ></DirectorCard>
            );
          })}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    eventForm: state.eventForm,
    directorList: state.directorList,
  };
};

//  export default connect(mapStateToProps, {
//      modifyFormDirectorHandler,
//      addDirectorHandler
//  })(DirectorsForm);

const AppDirectorsComponents = connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectorsForm);
export default React.memo(AppDirectorsComponents);
