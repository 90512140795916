import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (user.rolId < "4") {
          return <Component user={user} {...rest} {...props} />;
        } else {
          return <Redirect to={"/list-players"} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
