import React from "react";
import { withRouter } from "react-router-dom";

const queryString = require("query-string");

const Paginator = (props) => {
  
  let { totalItems, handleSetFilters } = props;
  let paginator = [];
  let totalPage = Math.ceil(totalItems / 10);
  
  let page = parseInt(queryString.parse(props.location.search).page);  

  const buildPaginator = (pages) => {
    let newPages = pages.map((itemPage, index) => {
      let classPage =
        itemPage === page ? "paginator-item active" : "paginator-item";
      let newItemPage =
        itemPage === ">" ? (
          <div className={classPage} key={index} onClick={ () => handleSetFilters(page + 1)}>
            <span className="arrow right"></span>
          </div>
        ) : (
          <div
            className={classPage}
            key={index}
            onClick={() => handleSetFilters(itemPage)}>
            {itemPage}
          </div>
        );
      return newItemPage;
    });

    return <div className="paginator-container">{newPages}</div>;
  };

  const setPaginator = () => {
    if (totalItems > 0) {
      if (page > 3 && totalPage > page + 5) {
        paginator.push(1);
        paginator.push("...");
        for (let i = 0; i < 2; i++) {
          paginator.push(page - 2 + i);
        }
        paginator.push(page);
        for (let i = 1; i < 5; i++) {
          paginator.push(page + i);
        }
        if (totalPage - (page + 5) > 1) {
          paginator.push(">");
        }       
      } else if (totalPage > page + 5) {
        for (let i = 1; i <= page; i++) {
          paginator.push(i);
        }
        for (let i = 1; i < 6; i++) {
          paginator.push(page + i);
        }
        paginator.push(">");
      } else if (page > 7) {
        paginator.push(1);
        paginator.push("...");
        for (let i = 0; i < 5; i++) {
          paginator.push(page - 5 + i);
        }
        for (let i = page; i <= totalPage; i++) {
          paginator.push(i);
        }
      } else {
        for (let i = 1; i <= totalPage; i++) {
          paginator.push(i);
        }

      }
      return buildPaginator(paginator);
    } else {
      return null;
    }
  };
  return <div style={{ marginTop: "20px" }}>{setPaginator()}</div>;
};
export default withRouter(Paginator);
