import React, {useState, useEffect, useRef} from 'react';

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import WscoutVideos from '../components/modals/WscoutVideos';
import GlobalLoading from '../components/accessories/GlobalLoading';
import { update_analysis_video, update_info_teams } from "../../js/actions/index";
import AnalysisVideoSetupComponent from '../components/AnalysisVideoSetupComponent';
import  {   getClubs,
            getSports,
            getCompetitions,
            getWClubByClubId,
            postAnalysisVideo,
            getWScoutMatchVideo,
            searchVideosByWclubId,
            getCategoriesByClubAndSport,
            getListTeamsByClubSportCategory,
        } from '../endpoints';

import YTSearch from 'youtube-api-search';
const youtube_api_key = 'AIzaSyB1qQJnHJ3uSPY8w1ovVdlmYCG1NS3knXk'

function mapDispatchToProps(dispatch) {
    return {
        set_update_info_teams: teams => dispatch(update_info_teams(teams)),
        set_update_analysis_video: user => dispatch(update_analysis_video(user))
    };
}

const AnalysisVideoSetup = (props) => {

    const history = useHistory();
    const [sport, setSport] = useState(null);
    const [videoList, setVideoList] = useState([]);
    const [localTeams, setLocalTeams] = useState(null);
    const [visitorTeams, setVisitorTeams] = useState(null);
    const [localTeam, setLocalTeamSelect] = useState(null);
    const [dateMatch, setDateMatch] = useState( new Date() );
    const [timeMatch, setTimeMatch] = useState( new Date() );
    const [localClub, setLocalClubSelected] = useState(null);
    const [resourceWMath, setResourceWMath] = useState(null);
    const [globalLoading, setGlobalLoading] = useState(false);
    const [videoMediaInfo, setVideoMediaInfo] = useState(null);
    const [visitorTeam, setVisitorTeamSelect] = useState(null);
    const [initialQuestion, setInitialQuestion] = useState(true);
    const [visitorClub, setVisitorClubSelected] = useState(null);
    const [localCategories, setLocalCategories] = useState(null);
    const [searchVideoBy, setSearchVideoBy] = useState('wyscout');
    const [clubLocalDefault, setClubLocalDefault] = useState(null);
    const [openWscoutVideos, setOpenWscoutVideos] = useState(false);
    const [visitorCategories, setVisitorCategories] = useState(null);
    const [clubs, setClubs] = useState({initial: null, filter: null});
    const [localOnLeftToStart, setLocalOnLeftToStart] = useState(true);
    const [clubVisitorDefault, setClubVisitorDefault] = useState(null);
    const [videosClubSelected, setVideosClubSelected] = useState(null);
    const [sports, setSports] = useState({initial: null, filter: null});
    const [localCategorySelect, setLocalCategorySelect] = useState(null);
    const [competitionsSelected, setCompetitionsSelected] = useState(null);
    const [visitorCategorySelect, setVisitorCategorySelect] = useState(null);
    const [countries, setCountries] = useState({initial: null, filter: null});
    const [videoMediaWScoutYoutube, setVideoMediaWScoutYoutube] = useState('');
    const [videoListYoutube, setVideoListYoutube] = useState({value: '', list: []})
    const [competitions, setCompetitions] = useState({initial: null, filter: null});
    const { user, openSideBar, set_update_analysis_video, set_update_info_teams } = props;
    const width = openSideBar ? 'calc(100vw - 332px)': '100%';
    //const [redirectToAnalysis, setRedirectToAnalysis] = useState({redirect: false, path: null});

    const inputYoutube = useRef(null)

    async function getListCategories(club, sport) {

        const sportFiltered = Object.keys(sports.initial).filter( (key) => {
            return sports.initial[key].club_id === club._id && sports.initial[key].name === sport.name
        }).map( function(key) {
            return sports.initial[key];
        });

        let response = await getCategoriesByClubAndSport(club._id, sportFiltered[0]._id);
        if(response && response.status === 200){
            return response.data.response;
        }else{
            console.log('error');
            return null;
        }
    }

    async function getListTeams(clubId, sportName, categoryName) {
        let response = await getListTeamsByClubSportCategory(clubId, sportName, categoryName) 
        if(response && response.status === 200){
            return response.data.response;
        }else{
            console.log('error');
            return null;
        }
    }

    async function getListVideosByWClubId(clubId, sportName, categoryName) {
        let response = await searchVideosByWclubId(clubId, sportName, categoryName)

        if(response && response.status === 200 && response.data.error === 0 ){
            return response.data.response;
        }else{
            console.log('error');
            return null;
        }
    }

    async function searchWclubIdByClubId(clubId) {
        let response = await getWClubByClubId(clubId)
        if(response && response.data.code === 200){
            return response.data.response;
        }else{
            console.log('error');
            return null;
        }
    }

    async function selectsCallBack(value, idSelect) {
        let listCategories = null
        let listTeams = null
        switch (idSelect) {
            case 1:

                break;
            case 2: // al seleccionar videos de un club
                if( value ){
                    setGlobalLoading(true);
                    let wclubId = await searchWclubIdByClubId(value._id)
                    let videoListMatch = await getListVideosByWClubId(wclubId.wid)
                    setVideoList(videoListMatch)
                    if(videoListMatch){ setOpenWscoutVideos(true); setInitialQuestion(false) }
                    setVideosClubSelected(value);
                    setGlobalLoading(false);
                }else{
                    setVideosClubSelected(null);
                }
                break;
            case 3:
                setSport(value)
                break;
            case 4:

                break;
            case 5: // Al seleccionar club local
                if(value){
                    listCategories = await getListCategories(value, sport)  
                    setLocalCategories( listCategories )
                    setLocalClubSelected(value);
                    //listCategories = await getListCategories(value, sport)
                    setVisitorCategories( listCategories )
                    if( videosClubSelected && value._id !== videosClubSelected._id ){
                        if( !visitorClub || visitorClub._id !== value._id){
                            setClubVisitorDefault(videosClubSelected)
                            setVisitorClubSelected(videosClubSelected)
                        }
                    }
                }else{
                    setLocalTeamSelect(null)
                    setLocalCategorySelect(null)
                    setLocalClubSelected(null); 
                }
                break;
            case 6: // Al seleccionar categoria local
                if(value){
                    listTeams = await getListTeams(localClub._id, value.sport_name, value.name)  
                    setLocalTeams( listTeams )
                    setLocalCategorySelect( value )
                }else{
                    setLocalTeamSelect(null)
                    setLocalCategorySelect(null)
                }
                break;
            case 7: // Al seleccionar team local
                setLocalTeamSelect( value )
                break;
            case 8: // Al seleccionar torneo
                setCompetitionsSelected(value)
                break;
            case 14: // Al seleccionar club visitor
                if(value){
                    listCategories = await getListCategories(value, sport)
                    setVisitorCategories( listCategories )
                    setVisitorClubSelected(value);
                    //listCategories = await getListCategories(value, sport)  
                    setLocalCategories( listCategories )
                    if(videosClubSelected && value._id !== videosClubSelected._id ){
                        if( !localClub || localClub._id !== value._id){
                            setClubLocalDefault(videosClubSelected)
                            setLocalClubSelected(videosClubSelected)
                        }
                    }
                }else{
                    setVisitorTeamSelect(null)
                    setVisitorCategorySelect(null)
                    setVisitorClubSelected(null);
                }
                break;
            case 15: // Al seleccionar categoria visitor
                if(value){
                    listTeams = await getListTeams(visitorClub._id, value.sport_name, value.name)  
                    setVisitorTeams( listTeams )
                    setVisitorCategorySelect( value )
                }else{
                    setVisitorTeamSelect(null)
                    setVisitorCategorySelect(null)
                }
                break;
            case 16: // Al seleccionar team visitor
                    setVisitorTeamSelect( value )
                break;
            default:
        }
    }

    async function listCompetition() {
        let response = await getCompetitions();
        if(response && response.status === 200){
            setCompetitions( { ...competitions, initial: response.data.response} );
            setCountries( { ...countries, initial: Array.from( new Set( response.data.response.map( x => x.wcountryid )))
                    .map( wcountryid => {
                        return {
                            id: wcountryid,
                            name: response.data.response.find(x => x.wcountryid === wcountryid).wcountry
                        }
                    })
                }
            )
        }else{
            console.log('error');
        }
    }

    async function listClub() {
        let response = await getClubs();
        if(response && response.status === 200){
            setClubs( {...clubs, initial: response.data.response } )
        }else{
            console.log('error');
        }   
    }

    async function listSport() {
        let response = await getSports();
        if(response && response.status === 200){
            let sportsFiltered = Array.from( new Set( response.data.response.map( x => x.name )))
                .map( name => {
                    return {
                        id: response.data.response.find( x => x.name === name ).club_id, 
                        name: name
                    }
                })
            setSports( { initial: response.data.response, filter: sportsFiltered } )
        }else{
            console.log('error');
        }   
    }

    const handleDateMatchChange = date => {
        setDateMatch(date);
    }

    const handleTimeMatchChange = date => {
        setTimeMatch(date);
    }

    const handleCloseOpenWscoutVideos = (val) => {

        if( searchVideoBy === 'wyscout' || val === false ){
            setOpenWscoutVideos(val);
        }else{
            if( videoListYoutube.value !== inputYoutube.current.value ){
                setGlobalLoading(true);
                YTSearch({key: youtube_api_key, term: inputYoutube.current.value}, function(data) {
                    setVideoListYoutube({value: inputYoutube.current.value, list: data})
                    if( initialQuestion ){ setInitialQuestion(false) }
                });
                setTimeout( function(){
                    setGlobalLoading(false);
                    setOpenWscoutVideos(val);
                }, 500);
            }else{
                setOpenWscoutVideos(val);
            }
        }
    }

    const importMatchVideoSource = async (resource, time) => {

        setGlobalLoading(true);
        let response = await getWScoutMatchVideo( resource.objId );
        if(response && response.status === 200){
            let dateMatchTemp = response.data.response[0].subtitle ? response.data.response[0].subtitle.substring(0, 10) : null;
            dateMatchTemp = dateMatchTemp ? dateMatchTemp.substring(3, 5) + "-" + dateMatchTemp.substring(0, 2) + "-"  + dateMatchTemp.substring(6, 10) : dateMatchTemp;
            setDateMatch(dateMatchTemp);
            if(!dateMatchTemp){
                response.data.response[0].subtitle = 'Untitled, possibly the match has been postponed';
                response.data.response[1].subtitle = 'Untitled, possibly the match has been postponed';
            }
            setVideoMediaWScoutYoutube( time === 0 ? response.data.response[0].source : response.data.response[1].source )
            setVideoMediaInfo( time === 0 ? response.data.response[0] : response.data.response[1] )
            setOpenWscoutVideos(false);
            setResourceWMath({match_half: (time + 1), wmatchid: resource.objId})
        }else{
            console.log('error');
        }
        setGlobalLoading(false);
    }

    const importMatchVideoSourceYoutube  = (videoYoutube) => {
        let videoYoutubeInfo = {title: videoYoutube.snippet.title, subtitle: videoYoutube.snippet.description ? videoYoutube.snippet.description : videoYoutube.snippet.title }
        if( typeof(dateMatch) === 'object' ){
            let dd = dateMatch.getDate();
            let mm = dateMatch.getMonth() + 1; //January is 0!
            let yyyy = dateMatch.getFullYear();
            if (dd < 10) {
              dd = '0' + dd;
            }
            if (mm < 10) {
              mm = '0' + mm;
            }
            setDateMatch(dd + '-' + mm + '-' + yyyy)
        }
        setVideoMediaInfo(videoYoutubeInfo)
        setVideoMediaWScoutYoutube('https://www.youtube.com/watch?v=' + videoYoutube.id.videoId)
        setResourceWMath({match_half: 1, wmatchid: null})
        setOpenWscoutVideos(false);
    }

    const removeMatchVideoSource = async () => {
        setVideoMediaWScoutYoutube( '' )
        setVideoMediaInfo( null )
    }

    const handleSetLocalOnLeftToStart = () => {
        setLocalOnLeftToStart(!localOnLeftToStart);
    }

    const parseDate = (date, separator) => {

        let today = date? new Date(date) : new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; //January is 0!
        
        let yyyy = today.getFullYear();
        if (dd < 10) {
          dd = '0' + dd;
        }
        if (mm < 10) {
          mm = '0' + mm;
        } 
        today = dd + separator + mm + separator + yyyy;
        return today
    }

    const handleSaveSetup = async () => {

        setGlobalLoading(true);
        let today = parseDate(null, '-');
        let fixMatchDate = null;

        if( typeof(dateMatch) === 'object' ){
            fixMatchDate = parseDate(dateMatch, '-');
        }else{
            fixMatchDate = dateMatch.substring(3, 5) + "-" + dateMatch.substring(0, 2) + "-"  + dateMatch.substring(6, 10)
        }

        let mathName = videoMediaInfo.subtitle && videoMediaInfo.title ? videoMediaInfo.subtitle + ' ' + videoMediaInfo.title : null

        let data = {
            name: mathName,
            created_date: today,
            export_filename: null,
            username: user.username,
            match_date: fixMatchDate,
            teamid_local: localTeam._id,
            teamid_visitor: visitorTeam._id,
            video_filename: videoMediaWScoutYoutube,
            wcompetitionid: competitionsSelected.wid,
            local_on_left_to_start: localOnLeftToStart,
            wmatchid: resourceWMath ? resourceWMath.wmatchid: null,
            match_half: resourceWMath ? resourceWMath.match_half : null ,
        }
        let response = await postAnalysisVideo(data);

        if(response && response.code === 200){
            set_update_analysis_video(response.result)
            set_update_info_teams([localTeam, visitorTeam])
            /*
            if( window.location.hostname === 'gool'){
                history.push('/app_dev.php/react-route/analysis-video/' + response.result.id)
            }else{
                history.push('/react-route/analysis-video/' + response.result.id)
            }
            */
            history.push( '/analysis-video/' + response.result.id )
        }else{
            console.log('error');
            setGlobalLoading(false);
        }

    }

    useEffect( () => {
        listClub();
        listSport();
        listCompetition();
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className="container main" style={{width: width}} >
            <AnalysisVideoSetupComponent
                user={ user } 
                sport={sport}
                timeMatch={timeMatch}
                dateMatch={dateMatch}
                videoList={videoList}
                localClub={localClub}
                localTeam={localTeam}
                localTeams={localTeams}
                visitorTeam={visitorTeam}
                visitorClub={visitorClub}
                inputYoutube={inputYoutube}
                visitorTeams={visitorTeams}
                searchVideoBy={searchVideoBy}
                videoMediaInfo={videoMediaInfo}
                handleSaveSetup={handleSaveSetup}
                selectsCallBack={selectsCallBack}
                localCategories={localCategories}
                initialQuestion={initialQuestion}
                videoListYoutube={videoListYoutube}
                setGlobalLoading={setGlobalLoading}
                clubLocalDefault={clubLocalDefault}
                visitorCategories={visitorCategories}
                localOnLeftToStart={localOnLeftToStart}
                clubVisitorDefault={clubVisitorDefault}
                hadleSetSearchVideoBy={setSearchVideoBy}
                localCategorySelect={localCategorySelect}
                competitionsSelected={competitionsSelected}
                handleTimeMatchChange={handleTimeMatchChange}
                handleDateMatchChange={handleDateMatchChange}
                visitorCategorySelect={visitorCategorySelect}
                removeMatchVideoSource={removeMatchVideoSource}
                videoMediaWScoutYoutube={videoMediaWScoutYoutube}
                clubs={ clubs.filter ? clubs.filter : clubs.initial}
                setVideoMediaWScoutYoutube={setVideoMediaWScoutYoutube}
                sports= { sports.filter ? sports.filter: sports.initial}
                handleSetLocalOnLeftToStart={handleSetLocalOnLeftToStart}
                handleCloseOpenWscoutVideos={handleCloseOpenWscoutVideos}
                countries={ countries.filter ? countries.filter : countries.initial }
                competitions={ competitions.filter ? competitions.filter : competitions.initial }
            />

            <WscoutVideos 
                videoList={videoList}
                open={openWscoutVideos}
                searchVideoBy={searchVideoBy}
                setGlobalLoading={setGlobalLoading}
                videoListYoutube={videoListYoutube}
                handleCloseOpen={handleCloseOpenWscoutVideos}
                importMatchVideoSource={importMatchVideoSource}
                importMatchVideoSourceYoutube={importMatchVideoSourceYoutube}
            />

            { globalLoading && 
                <GlobalLoading />
            }

        </div>
    );
};
const AppAnalysisVideoSetup = connect(null, mapDispatchToProps)(AnalysisVideoSetup);
export default React.memo(AppAnalysisVideoSetup);