import React, { useState } from "react";
import environment from "environment";
import { useLocation } from "react-router-dom";
//import Logo from "../Logo/Logo";
import { NavLink } from "react-router-dom";
import useStyles from "../../assets/MakeStyles";
import { resourceApiEndPoint } from "../../endpoints";
import iconsprite from "../../assets/img/iconsprite.svg";
import logoSideBar from "../../assets/img/gloud-sidebar.png";
import logoSideInfinity from "../../assets/img/infinity-logo.png";

import { translate } from "../../lang/lang";

import shield from "../../assets/img/shield.png";

const CLIENT_ENVIROMENT = environment.ENV;

const menu = [
  {
    active: true,
    title: "admin",
    role: [1, 2, 3, 4, 5, 6, 7, 8],
    submenu: [
      {
        name: "players list",
        href: "/list-players?page=0&rolestring=&wclubId=&from_age=&to_age=",
        icon: "profile",
        role: [1, 2, 3, 4, 5, 6, 7, 8],
      },
      /*
      {
        name: "Crear Jugador",
        href: "/add-player",
        icon: "duplicate",
        role: [1, 2, 3]
      },
      */
      {
        name: "create club",
        href: "/add-club",
        icon: "duplicate",
        role: [1],
      },
      {
        name: "create team",
        href: "/add-team",
        icon: "duplicate",
        role: [1, 2, 3],
      },
      {
        name: "users",
        href: "/users",
        icon: "faceid",
        role: [1, 2, 3],
      },
      {
        name: "my account",
        href: "/myaccount",
        icon: "myaccount",
        role: [1, 2, 3, 4, 5, 6, 7, 8],
      },
      {
        name: "myfiles",
        href: "/myfolders",
        icon: "folder",
        role: [1, 2, 3, 4, 5, 6, 7, 8],
      },
    ],
  },
  {
    active: CLIENT_ENVIROMENT === "infinity" ? false : true,
    title: "GPS",
    role: [1, 2, 3, 4, 6],
    submenu: [
      {
        name: "gps stats",
        //href: "/gps/team/AXBgav93dOi3yl7MMK6z",
        href: "/gps/team/",
        icon: "location",
        role: [1, 2, 3, 4, 5, 6, 8],
      },
    ],
  },
  {
    active: CLIENT_ENVIROMENT === "infinity" ? true : false,
    title: "GPEXE",
    role: [1, 2, 3, 4, 6],
    submenu: [
      {
        name: "Tracks",
        href: "/gpexe/tracks",
        icon: "location",
        role: [1, 2, 3, 4, 5, 6, 8],
      },
      {
        name: "Sessions",
        href: "/gpexe/sessions",
        icon: "location",
        role: [1, 2, 3, 4, 5, 6, 8],
      },
    ],
  },
  {
    active: true,
    title: "player analysis",
    role: [1, 2, 3, 4, 5, 6, 8],
    submenu: [
      {
        name: "analyzed videos",
        href: "/analysis-video",
        icon: "videocamera",
        role: [1, 2, 3, 4, 5, 6, 7, 8],
      },
      {
        name: "new analysis",
        href: "/analysis-video/settings/setup",
        icon: "play",
        role: [1, 2, 3, 4, 9],
      },
    ],
  },
  /*
  {
    active: true,
    title: "Torneos",
    role: [1, 2, 3, 4, 5, 6, 7, 8],
    submenu: [
      {
        name: "Torneos existentes",
        href: "/events",
        icon: "trophy",
        role: [1, 2, 3, 4, 5, 6, 7, 8]
      },
      {
        name: "Crear Torneo",
        href: "/formEventInfo",
        icon: "settings",
        role: [1, 2, 3]
      },
    ],
  },
  */
];

const SideNavBar = (props) => {
  const LOGO_RESOURCE = environment.ENV;
  const location = useLocation();
  const splitLocation = location.pathname.split("/")[1];
  const classes = useStyles();
  let classSiseNavBar = classes.sideNavBar;
  const [gpsOpen, setGpsOpen] = useState(false);
  const [shieldError, setShieldError] = useState(false);
  const { user, handleLoginModal, handleLogout, teamOfClub } = props;
  const getTeamOfClubs = () => {
    return teamOfClub.map((team, indexTeam) => {
      return (
        <div key={indexTeam}>
          <NavLink
            className="SubmenuItemLinkGps"
            exact
            to={`/gps/team/${team._id}`}
            activeClassName="active"
          >
            {team.name}
          </NavLink>
        </div>
      );
    });
  };

  const showLogo = () => {
    return (
      <>
        {/*<Logo maxWidth={'200px'} /> */}
        <div
          style={{ width: "100%", textAlign: "center", margin: " 0px 0 15px" }}
        >
          {LOGO_RESOURCE === "infinity" ? (
            <img
              style={{ maxWidth: "80%" }}
              src={logoSideInfinity}
              alt="Logo"
            />
          ) : (
            <img style={{ maxWidth: "80%" }} src={logoSideBar} alt="Logo" />
          )}
        </div>
      </>
    );
  };

  const showInfo = () => {
    return (
      <>
        <div className={classes.userInfo}>
          <div style={{ width: "40%", textAlign: "center" }}>
            {user.clubActive && !shieldError ? (
              <img
                src={resourceApiEndPoint() + "/" + user.clubActive.img_file}
                alt={user.clubActive.name}
                onError={() => setShieldError(true)}
              />
            ) : (
              <img src={shield} alt={user.clubActive.name} />
            )}
          </div>
          <div style={{ width: "60%" }}>
            <p>
              {user.name} {user.lastname}
            </p>
            {user.clubActive && (
              <>
                <p>{user.clubActive.name}</p>
                <p>{user.clubActive.roleName}</p>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return splitLocation === "card-player"
    ? null
    : user.id && (
        <div id="side-nav-bar" className={classSiseNavBar}>
          {showLogo()}

          {showInfo()}

          <div className="MenuItemsContainer">
            {menu.map((option, index) => {
              if (option.active) {
                return option.role.includes(parseInt(user.rolId)) ? (
                  <div
                    key={option.title + index}
                    className={classes.SubmenuContainer}
                  >
                    <h4 className={classes.SubmenuTitle}>
                      {translate(user.lang, option.title)}
                    </h4>

                    {(option.submenu && user.clubActive) || user.roleId === "1"
                      ? option.submenu.map((submenu, index) => {
                          return submenu.role.includes(parseInt(user.rolId)) ? (
                            <li
                              key={submenu.name + index}
                              className={classes.SubmenuItem}
                              onMouseLeave={() =>
                                option.title === "GPS" && setGpsOpen(false)
                              }
                            >
                              {option.title === "GPS" ? (
                                <>
                                  <div
                                    className="SubmenuItemLink"
                                    onClick={() => setGpsOpen(true)}
                                  >
                                    <svg className="Icon">
                                      <use
                                        href={iconsprite + `#${submenu.icon}`}
                                      ></use>
                                    </svg>
                                    {translate(user.lang, submenu.name)}
                                  </div>

                                  {gpsOpen && (
                                    <>
                                      <div className="team-list">
                                        {teamOfClub && getTeamOfClubs()}
                                      </div>

                                      <div className="gpexe-container">
                                        <div>
                                          <NavLink
                                            className="SubmenuItemLinkGps"
                                            exact
                                            to={`/gpexe/tracks/list`}
                                            activeClassName="active"
                                          >
                                            GPEXE Tracks
                                          </NavLink>
                                        </div>

                                        <div>
                                          <NavLink
                                            className="SubmenuItemLinkGps"
                                            exact
                                            to={`/gpexe/sessions/list`}
                                            activeClassName="active"
                                          >
                                            GPEXE Sessions
                                          </NavLink>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <NavLink
                                  className="SubmenuItemLink"
                                  exact
                                  to={submenu.href}
                                  activeClassName="active"
                                >
                                  <svg className="Icon">
                                    <use
                                      href={iconsprite + `#${submenu.icon}`}
                                    ></use>
                                  </svg>
                                  {translate(user.lang, submenu.name)}
                                </NavLink>
                              )}
                            </li>
                          ) : null;
                        })
                      : null}
                  </div>
                ) : null;
              } else {
                return null;
              }
            })}
          </div>

          {!user.id ? (
            <span
              onClick={() => handleLoginModal.set_login_modal(true)}
              className="loginButtom"
            >
              {translate(user.lang, "login")}
            </span>
          ) : (
            <li className={`${classes.SubmenuItem} Logout`}>
              <span
                onClick={() => handleLogout()}
                className="SubmenuItemLink Logout"
              >
                <svg className="Icon">
                  <use href={iconsprite + `#reply`}></use>
                </svg>
                {translate(user.lang, "sign out")}
              </span>
            </li>
          )}
        </div>
      );
};
export default React.memo(SideNavBar);
