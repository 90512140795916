import React from 'react';

import Modal from '@material-ui/core/Modal';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    btnsModal: {
        display: 'flex',
        margin: '0 0 15px',
        justifyContent: 'flex-end'
    },
    paper: {
        top: '50%',
        left: '50%',
        padding: '15px',
        minWidth: '80vw',
        border: '2px solid',
        position: 'absolute',
        backgroundColor: '#19191D',
        transform: 'translate(-50%, -50%)',
    },
    tableVideoList: {
        width: '100%',
        display: 'block',
        maxHeight: '80vh',
        overflowY: 'auto',
        '& th, td' : {
            padding: '5px 5px'
        }
    },
    videoListYoutubeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around'
    },
    previewYotubeContainer: {
        maxWidth: '300px',
        margin: '15px',

    }
}));

const WscoutVideos = (props) => {

    const classes = useStyles();

    const {
            open,
            videoList,
            searchVideoBy,
            handleCloseOpen,
            videoListYoutube,
            importMatchVideoSource,
            importMatchVideoSourceYoutube
        } = props;

    const showVideoList = (list) => {

        if( list ){
            return list.map( (item, key) => {

                return  <tr key={key}>
                            <td className="text-center"> {item.title}</td>
                            <td className="text-center"> {item.date}</td>
                            <td className="text-center">{ ReactHtmlParser(item.subtitle) }</td>
                            <td className="text-center">
                                <div style={{cursor: 'pointer'}} onClick={ () => importMatchVideoSource(item, 0)}>Show</div>
                            </td>
                            <td className="text-center">
                                <div style={{cursor: 'pointer'}} onClick={ () => importMatchVideoSource(item, 1)}>Show</div>
                            </td>
                        </tr>
            })
        }
    }

    const showVideoListYoutube = (list) => {

        return list.map( (item, key) => {

            return  <div key={key} className={classes.previewYotubeContainer}>
                        <div>
                            <iframe src={'https://www.youtube.com/embed/' + item.id.videoId} title={item.snippet.title}></iframe>
                        </div>
                        <div onClick={ () => importMatchVideoSourceYoutube(item) }  style={{cursor: 'pointer'}}>{item.snippet.title}</div>
                    </div>
        })
    }

    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
            >
                <div className={classes.paper}>
                    
                    <div className={classes.btnsModal}><span style={{cursor: 'pointer'}} onClick={ () => handleCloseOpen(false)}>Close</span></div>

                    { searchVideoBy === 'wyscout' ? 
                    
                        <div className={classes.tableVideoList}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Match</th>
                                        <th>Date</th>
                                        <th>Competition</th>
                                        <th>1fst Time</th>
                                        <th>2sec Time</th>
                                    </tr>
                                </thead>
                        
                                <tbody>
                                    { showVideoList( videoList ) }
                                </tbody>

                            </table>
                        </div>
                    :
                        <div className={classes.videoListYoutubeContainer}>
                            { showVideoListYoutube( videoListYoutube.list ) }
                        </div>
                    }
                </div>
            </Modal>
        </div>
    );
};
export default React.memo(WscoutVideos);