import React from 'react'

import { makeStyles } from '@material-ui/core';
import {ReactComponent as Borrar} from '../../assets/img/IconoBasura.svg';
import {ReactComponent as Editar}  from '../../assets/img/IconoEditar.svg';

const useStyles = makeStyles(theme => ({

    cardContainer: {
        width: '353px',
        height: '262px',
        marginTop: '50px',
        marginRight: '30px',
        borderRadius: '7.6px',
        boxSizing: 'border-box',
        border: '0.5px solid #4a4848',
        backgroundColor: 'rgba(21, 23, 25, 0.78)',  
        '& .cardEventNumber': {
            padding: '30px',
            color: '#A2A2A2',
            fontSize: '12px',
            fontWeight: '400',
        }
    },
    infoContainer: {
        display: 'flex',
        padding: '0 30px',
        cursor: 'pointer',
        justifyContent: 'space-between',
        '& .cardImage':{
            width: '80px',
            height: '80px',
        },
        '& .cardTitle': {
            width: '193px',
        }
    },
    buttonContainer: {
        width: '100%',
        backgroundColor: 'transparent',
        borderTop: '0.5px solid #4a4848',
        marginTop: '50px',
        '& .cardButton': {
            width: '50%',
            border: 'none',
            height: '50px',
            outline: 'none',
            cursor: 'pointer',
            transition: 'all .2s',
            backgroundColor: 'transparent',
        },
        '& .cardButton:hover': {
            transform: 'scale(1.2)',
        },
        '& .buttonImage': {
            width: '24px',
            height: '25px',
        }
    }
}));

const EventCards = ({delEvents, event, ...props}) => {

    const classes = useStyles();
    const {_id: event_id, logo, logoPreview, name} = event;

    return (
        <div className={classes.cardContainer}>
            <h3 className='cardEventNumber'>Evento {props.eventNumber + 1 }</h3>
            <div className={classes.infoContainer}>
                <img className='cardImage' src={logoPreview ? logoPreview : logo} alt="event logo"/>
                <h1 className='cardTitle'>{name}</h1>
            </div>
            <div className={classes.buttonContainer}>
                <button className='cardButton' onClick={()=>{ window.location.href = '/formEventConfirmation'}}><Editar className="buttonImage" /></button>
                <button onClick={ ()=> delEvents(event_id)} className='cardButton'><Borrar className="buttonImage"/></button>
            </div>
        </div>
    )
}
export default React.memo(EventCards);