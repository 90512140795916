import React, {useState} from 'react';
import { makeStyles} from '@material-ui/core';
import { modifyFormDirectorHandler } from "../../actions/index";
import { connect } from "react-redux";
// import { deleteDirector } from '../../endpoints';

const useStyles = makeStyles(theme => ({

    cardContainer: {

        display: 'flex',
        flexDirection:'column',
        width: '277px',
        border: '0.5px solid #4a4848',
        backgroundColor: 'rgba(21, 23, 25, 0.78)',
        boxSizing: 'border-box',
        borderRadius: '2.6px',
        padding: '22px',
        position: 'relative',
        marginTop: '40px',
        transition: 'all .1s',

        '&:hover': {
            transform: 'scale(1.01)',
            
        },
        
        '& .cardTitle': {
            fontSize: '19px',
            fontWeight: '700',
            marginBottom: '10px'
        },

        '& .cardHeader': {
            display: 'flex',
            justifyContent: 'space-between'
        },

        '& .closeButton': {
            backgroundColor: 'transparent',
            border: 'none',
            color: '#FFFFFF',
            transform: 'translateY(-5px)',
            outline: 'none'

        },

        '& .closeButton:hover': {
            color: '#00BBD6'
        },

        '& .fieldsContainer': {
            fontSize: '15px',
            display: 'flex',
            flexDirection: 'column'
        },

        '& .field': {
            marginTop: '6px'
        },

        '&.isActive': {
            border: '2px solid #FFFFFF'
        }
    },

    hiddenButton: {
        position: 'absolute',
        width: '277px',
        height: '178px',
        top: '0',
        left: '0',
        visibility: 'hidden',
        opacity: '0'
    }

}));

export const DirectorCard = ({modifyFormDirectorHandler, eventForm, ...props}) => {
    let isFound = eventForm.directors.find(dir => dir.id === props.id);
    const classes = useStyles();
    const [activeClass, setClass] = useState(isFound ? true : false);

    const directorHandler = () => {
        setClass(!activeClass)
        modifyFormDirectorHandler({   
            id: props.id,
            name: props.name,
            lastname: props.lastname,
            mail: props.mail,
            phone: props.phone,
            isChecked: !activeClass
        })
    }

    // const delDirector = async (id) => {
    //     let response = await deleteDirector(id);
    // }
    return ( 
        <div onClick={directorHandler} className={ activeClass ? classes.cardContainer + ' isActive' : classes.cardContainer}>
              
        <div className='cardHeader'>
            <p className='cardTitle'>Director</p>
            {/* <button onClick={() => delDirector(props.id)} className='closeButton'>X</button> */}
        </div>
        <div className='fieldsContainer'>
            <div className='field'>Nombre: {props.name}</div>
            <div className='field'>Apellido: {props.lastname}</div>
            <div className='field'>Mail: {props.mail}</div>
            <div className='field'>Telefono: {props.phone}</div>
        </div>
        </div>
        
    )
}

const mapStateToProps = state => {
    return {
        eventForm: state.eventForm,
    }
}

export default connect(mapStateToProps, {
    modifyFormDirectorHandler,
})(DirectorCard);